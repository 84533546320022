import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import ClassificacaoImovelForm from './Formularios/ClassificacaoImovelForm';
import TokenService from '../../../services/Auth/token.service';

interface PropsData {
    rotulo?: string;
    valor_m2?: string;
}

function CreateClassificacaoImoveis() {
    const { setUrl, setBody, setShowButtonSave } = useAppContext();
    const [formBasico, setFormBasico] = useState<PropsData>({});

    useEffect(() => {
        setUrl(`${url}/api/v1/admin/vendas/classificacoesimoveis`);
        setShowButtonSave(true);
        setFormBasico((prevImovelData) => {
            return {
                ...prevImovelData,
                ['empreendimento_id']: TokenService.getEmpreendimentoId()?.id
            };
        });
        setBody(formBasico)
    }, []);

    useEffect(() => {
        setBody(formBasico)
    }, [formBasico]);


    const handleChange = (field: string, value: string) => {
        setFormBasico((prevGrupoData) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
        setBody(formBasico)
    }
    return (
        <>
            <div className="body-content">
                <div className="form-create-group">
                    <div className="form-create-group-header">
                        <h3>Cadastrar Classificação Imovéis</h3>
                    </div>
                </div>
                <div className="form-group-create">
                    <Container>
                        <ClassificacaoImovelForm
                            initialRightValues={[]}
                            Data={formBasico}
                            onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default CreateClassificacaoImoveis;
