import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, Navigate, useNavigate, Router } from 'react-router-dom';
import Layout from '../pages/Layout';
import Login from '../pages/Login';
import Financeiro from './../pages/Financeiro';
import ContasPagar from './../pages/Financeiro/ContasPagar';
import ContasReceber from './../pages/Financeiro/ContasReceber';
import MovCaixa from './../pages/Financeiro/MovCaixa';
import ContaFinanceira from './../pages/Financeiro/ContaFinanceira';
import CentroCusto from './../pages/Financeiro/CentroCusto';
import PlanoConta from './../pages/Financeiro/PlanoConta';
import Empreendimento from '../pages/Empreendimento';
import Carteira from './../pages/Carteira';
import Pessoas from './../pages/Pessoas';
import Vendas from './../pages/Vendas';
import Home from './../pages/Home';
import NotFound from '../pages/ErrorPage/NotFound';
import TokenService from '../services/Auth/token.service';
import Usuario from '../pages/Configuracao/Usuario';
import Configuracao from '../pages/Configuracao';
import { AppProvider } from '../contexts/AppContext';
import Perfil from '../pages/Perfil';
import CreateUsuario from '../pages/Configuracao/Usuario/create';
import EditUsuario from '../pages/Configuracao/Usuario/edit';
import ListGroup from '../pages/Configuracao/GrupoPermissoes/index';
import CreateGroup from '../pages/Configuracao/GrupoPermissoes/create';
import EditGroup from '../pages/Configuracao/GrupoPermissoes/edit';
import ListFilial from '../pages/Configuracao/filial/index';
import CreateFilial from '../pages/Configuracao/filial/create';
import EditFilial from '../pages/Configuracao/filial/edit';
import SignUp from '../pages/SignUp/index';
import ForgotPassword from '../pages/ForgotPassword/index';
import ResetPassword from '../pages/ResetPassword/index';
import Imoveis from '../pages/Empreendimento/Imoveis/index';
import TipoImoveis from '../pages/Empreendimento/TipoImoveis/index';
import CreateTipoImoveis from '../pages/Empreendimento/TipoImoveis/create';
import EditTipoImoveis from '../pages/Empreendimento/TipoImoveis/edit';
import SubTipoImoveis from '../pages/Empreendimento/SubTipoImoveis/index';
import ClassificacaoImoveis from '../pages/Empreendimento/ClassificacaoImoveis/index';
import { showToast } from '../components/ToastComponent';
import CreateSubTipoImoveis from '../pages/Empreendimento/SubTipoImoveis/create';
import EditSubTipoImoveis from '../pages/Empreendimento/SubTipoImoveis/edit';
import CreateClassificacaoImoveis from '../pages/Empreendimento/ClassificacaoImoveis/create';
import EditClassificacaoImoveis from '../pages/Empreendimento/ClassificacaoImoveis/edit';
import EmpreendimentosObra from '../pages/Empreendimento/ObraEmpreendimento';
import EditEmpreendimentosObra from '../pages/Empreendimento/ObraEmpreendimento/edit';
import CreateEmpreendimentosObra from '../pages/Empreendimento/ObraEmpreendimento/create';
import CreateImovel from '../pages/Empreendimento/Imoveis/create';
import EditImoveis from '../pages/Empreendimento/Imoveis/edit';
import TabelaPreco from '../pages/Empreendimento/TabelaPreco';
import CreateTabelaPreco from '../pages/Empreendimento/TabelaPreco/create';
import EditTabelaPreco from '../pages/Empreendimento/TabelaPreco/edit';
import Corretores from '../pages/Pessoas/Corretores';
import CreateCorretores from '../pages/Pessoas/Corretores/create';
import EditCorretores from '../pages/Pessoas/Corretores/edit';
import Clientes from '../pages/Pessoas/Clientes';
import CreateClientes from '../pages/Pessoas/Clientes/create';
import EditClientes from '../pages/Pessoas/Clientes/edit';
import NovaVenda from '../pages/Vendas/NovaVenda';
import EditContasPagar from '../pages/Financeiro/ContasPagar/edit';
import CreateContasPagar from '../pages/Financeiro/ContasPagar/create';
import CreateContasReceber from '../pages/Financeiro/ContasReceber/create';
import EditContasReceber from '../pages/Financeiro/ContasReceber/edit';
import CreateContaFinanceira from '../pages/Financeiro/ContaFinanceira/create';
import EditContaFinanceira from '../pages/Financeiro/ContaFinanceira/edit';
import CreateCentroCusto from '../pages/Financeiro/CentroCusto/create';
import EditCentroCusto from '../pages/Financeiro/CentroCusto/edit';
import EditMovCaixa from '../pages/Financeiro/MovCaixa/edit';
import CreateMovCaixa from '../pages/Financeiro/MovCaixa/create';
import CreatePlanoConta from '../pages/Financeiro/PlanoConta/create';
import EditPlanoConta from '../pages/Financeiro/PlanoConta/edit';
import Contratos from '../pages/Carteira/GestaoContratos';
import EditContratos from '../pages/Carteira/GestaoContratos/edit';
import CreateContratos from '../pages/Carteira/GestaoContratos/create';
import RotinasEspeciais from '../pages/Configuracao/RotinasEspeciais';
import GestaoBoletos from '../pages/Carteira/GestaoBoletos';
import Relatorio from '../pages/Financeiro/Relatorio';
import RelatorioVendaIntermed from '../pages/Carteira/Relatorio';
import Importador from '../pages/Configuracao/Importador';
import Audit from '../pages/Configuracao/Audit';
import AuditShow from '../pages/Configuracao/Audit/show';
import ExclusaoLotesInit from '../pages/Configuracao/ExclusaoLotes';
import ContaCorrenteClienteForm from '../pages/Carteira/ContaCorrenteCliente';
import ModeloContrato from '../pages/Carteira/ModeloContrato/index';
import CreateModeloContrato from '../pages/Carteira/ModeloContrato/create';
import EditModeloContrato from '../pages/Carteira/ModeloContrato/edit';
import TipoIndice from '../pages/Configuracao/TipoIndice/index';
import CreateTipoIndice from '../pages/Configuracao/TipoIndice/create';
import EditTipoIndice from '../pages/Configuracao/TipoIndice/edit';
import Indice from '../pages/Configuracao/Indice/index';
import CreateIndice from '../pages/Configuracao/Indice/create';
import EditIndice from '../pages/Configuracao/Indice/edit';
import Cemiterio from '../pages/Cemiterio';
import TipoJazigo from '../pages/Cemiterio/Estrutura/TipoJazigo';
import CreateTipoJazigo from '../pages/Cemiterio/Estrutura/TipoJazigo/create';
import EditTipoJazigo from '../pages/Cemiterio/Estrutura/TipoJazigo/edit';
import Inumados from '../pages/Cemiterio/Inumados';
import CreateInumados from '../pages/Cemiterio/Inumados/create';
import EditInumados from '../pages/Cemiterio/Inumados/edit';
import Jazigo from '../pages/Cemiterio/Estrutura/Jazigo';
import CreateJazigo from '../pages/Cemiterio/Estrutura/Jazigo/create';
import EditJazigo from '../pages/Cemiterio/Estrutura/Jazigo/edit';
import ReajusteForm from '../pages/Carteira/Reajuste';
import CreateJazigoGroup from '../pages/Cemiterio/Estrutura/Jazigo/create-group';
import CreateCessaoDeDireito from '../pages/Carteira/CessaoDeDireito/create';
import CessaoDeDireito from '../pages/Carteira/CessaoDeDireito';
import Produto from '../pages/Cemiterio/Produtos/index';
import CreateProduto from '../pages/Cemiterio/Produtos/create';
import EditProduto from '../pages/Cemiterio/Produtos/edit'
import Fornecedores from '../pages/Pessoas/Fornecedores/index';
import CreateFornecedores from '../pages/Pessoas/Fornecedores/create';
import EditFornecedores from '../pages/Pessoas/Fornecedores/edit';
import ShowCessaoDeDireito from '../pages/Carteira/CessaoDeDireito/show';
import Renegociacao from '../pages/Financeiro/Renegociacao';
import CreateRenegociacao from '../pages/Financeiro/Renegociacao/create';
import ShowGestaoContrato from '../pages/Carteira/GestaoContratos/show';
import Licenca from './../pages/Configuracao/Licenca/index';
import CreateLicenca from './../pages/Configuracao/Licenca/create';
import EditLicenca from './../pages/Configuracao/Licenca/edit';
import RelatorioDisponibilidade from '../pages/Empreendimento/Relatorios/Disponibilidade';
interface ProtectedRouteProps {
  element: JSX.Element;
  path: string;
}

interface NotFoundProps {
  navigate: (to: string) => void;
}
const NotFoundPage: React.FC<NotFoundProps> = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // showToast('error', 'URL não encontrada. Redirecionando para a página inicial.');
    // navigate('/home');
  }, [])
  return null;
};
function MainRoutes() {

  const permissoes = TokenService.getPermissoes();
  const location = useLocation();
  const navigate = useNavigate();
  const [hasPermission, setHasPermission] = useState(true);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/home');
    }
    if (permissoes) {
      const ValidationPermission = permissoes[0].permission.some(
        (perm: any) => cleanRoute('/' + perm.slug) === location.pathname
      );
      setHasPermission(ValidationPermission);
    }
  }, [hasPermission, location.pathname, navigate, permissoes]);

  function cleanRoute(route: string | null | undefined) {
    if (typeof route !== 'string') {
      return '';
    }
    return route.replace(/\/index$/, '');
  }

  if (!hasPermission && location.pathname !== '/login' && location.pathname !== '/' && location.pathname !== '/home') {
    return <NotFoundPage navigate={navigate} />
  }
  const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, path }) => {
    const isAuthenticated = TokenService.getLocalAccessToken();
    const isLoginTime = new Date(TokenService.getLocalTimeLogin());
    const elapsedTime = new Date().getTime() - isLoginTime.getTime();
    const isAuthLogin = isAuthenticated ? true : false;

    if (!isAuthLogin) {
      TokenService.clearLocalStorage();
      window.location.href = '/login';
      TokenService.setLoginTime();
    }
    return <>{element}</>;
  };


  return (
    <Routes>
      <Route path='/login' element={<AppProvider><Login /></AppProvider>} errorElement={<NotFound />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path='/' element={<ProtectedRoute element={<AppProvider><Layout /></AppProvider>} path='/' />} errorElement={<NotFound />}>
        <Route path='home' element={<ProtectedRoute element={<Home />} path='home' />} />
        <Route
          path='empreendimento'
          element={<ProtectedRoute element={<Empreendimento />} path='empreendimento' />}
        />
        <Route
          path='cliente'
          element={<ProtectedRoute element={<Clientes />} path='cliente' />}
        />
        <Route
          path='cliente/store'
          element={<ProtectedRoute element={<CreateClientes />} path='cliente/store' />}
        />
        <Route
          path='cliente/update'
          element={<ProtectedRoute element={<EditClientes />} path='cliente/update' />}
        />
        <Route
          path='corretores'
          element={<ProtectedRoute element={<Corretores />} path='corretores' />}
        />
        <Route
          path='corretores/store'
          element={<ProtectedRoute element={<CreateCorretores />} path='corretores/store' />}
        />
        <Route
          path='corretores/update'
          element={<ProtectedRoute element={<EditCorretores />} path='corretores/update' />}
        />

        <Route
          path='modelo-contrato'
          element={<ProtectedRoute element={<ModeloContrato />} path='modelo-contrato' />}
        />
        <Route
          path='modelo-contrato/store'
          element={<ProtectedRoute element={<CreateModeloContrato />} path='modelo-contrato/store' />}
        />
        <Route
          path='modelo-contrato/put'
          element={<ProtectedRoute element={<EditModeloContrato />} path='modelo-contrato/put' />}
        />
        <Route
          path='tabelapreco'
          element={<ProtectedRoute element={<TabelaPreco />} path='tabelapreco' />}
        />
        <Route
          path='tabelapreco/store'
          element={<ProtectedRoute element={<CreateTabelaPreco />} path='tabelapreco/store' />}
        />
        <Route
          path='tabelapreco/update'
          element={<ProtectedRoute element={<EditTabelaPreco />} path='tabelapreco/update' />}
        />
        <Route
          path='imovel'
          element={<ProtectedRoute element={<Imoveis />} path='imovel' />}
        />
        <Route
          path='imovel/store'
          element={<ProtectedRoute element={<CreateImovel />} path='imovel/store' />}
        />
        <Route
          path='imovel/update'
          element={<ProtectedRoute element={<EditImoveis />} path='imovel/update' />}
        />
        <Route
          path='tipoimovel'
          element={<ProtectedRoute element={<TipoImoveis />} path='tipoimovel' />}
        />
        <Route
          path='tipoimovel/store'
          element={<ProtectedRoute element={<CreateTipoImoveis />} path='tipoimovel/store' />}
        />
        <Route
          path='tipoimovel/update'
          element={<ProtectedRoute element={<EditTipoImoveis />} path='tipoimovel/update' />}
        />
        <Route
          path='subtipoimovel'
          element={<ProtectedRoute element={<SubTipoImoveis />} path='subtipoimovel' />}
        />
        <Route
          path='subtipoimovel/store'
          element={<ProtectedRoute element={<CreateSubTipoImoveis />} path='subtipoimovel/store' />}
        />
        <Route
          path='subtipoimovel/update'
          element={<ProtectedRoute element={<EditSubTipoImoveis />} path='subtipoimovel/update' />}
        />
        <Route
          path='classificacoesimoveis'
          element={<ProtectedRoute element={<ClassificacaoImoveis />} path='classificacoesimoveis' />}
        />
        <Route
          path='classificacoesimoveis/store'
          element={<ProtectedRoute element={<CreateClassificacaoImoveis />} path='classificacoesimoveis/store' />}
        />
        <Route
          path='classificacoesimoveis/update'
          element={<ProtectedRoute element={<EditClassificacaoImoveis />} path='classificacoesimoveis/update' />}
        />
        <Route
          path='empreendimentos'
          element={<ProtectedRoute element={<EmpreendimentosObra />} path='empreendimentos' />}
        />
        <Route
          path='empreendimentos/store'
          element={<ProtectedRoute element={<CreateEmpreendimentosObra />} path='empreendimentos/store' />}
        />
        <Route
          path='empreendimentos/update'
          element={<ProtectedRoute element={<EditEmpreendimentosObra />} path='empreendimentos/update' />}
        />
        <Route
          path='relatorio-disponibilidade'
          element={<ProtectedRoute element={<RelatorioDisponibilidade />} path='relatorio-disponibilidade' />}
        />
        <Route
          path='configuracoes'
          element={<ProtectedRoute element={<Configuracao />} path='configuracoes' />}
        />
        <Route
          path='rotinas/especial'
          element={<ProtectedRoute element={<RotinasEspeciais />} path='rotinas/especial' />}
        />
        <Route
          path='tipoIndice'
          element={<ProtectedRoute element={<TipoIndice />} path='tipoIndice' />}
        />
        <Route
          path='tipoIndice/store'
          element={<ProtectedRoute element={<CreateTipoIndice />} path='tipoIndice/store' />}
        />
        <Route
          path='tipoIndice/update'
          element={<ProtectedRoute element={<EditTipoIndice />} path='tipoIndice/update' />}
        />
        <Route
          path='Indice'
          element={<ProtectedRoute element={<Indice />} path='Indice' />}
        />
        <Route
          path='Indice/store'
          element={<ProtectedRoute element={<CreateIndice />} path='Indice/store' />}
        />
        <Route
          path='Indice/update'
          element={<ProtectedRoute element={<EditIndice />} path='Indice/update' />}
        />
        <Route
          path='licenca'
          element={<ProtectedRoute element={<Licenca />} path='licenca' />}
        />
        <Route
          path='licenca/store'
          element={<ProtectedRoute element={<CreateLicenca />} path='licenca/store' />}
        />
        <Route
          path='licenca/update'
          element={<ProtectedRoute element={<EditLicenca />} path='licenca/update' />}
        />
        <Route
          path='usuario'
          element={<ProtectedRoute element={<Usuario />} path='usuario' />}
        />
        <Route
          path='usuario/store'
          element={<ProtectedRoute element={<CreateUsuario />} path='usuario/store' />}
        />
        <Route
          path='usuario/update'
          element={<ProtectedRoute element={<EditUsuario />} path='usuario/update' />}
        />
        <Route
          path='usuario/perfil'
          element={<ProtectedRoute element={<Perfil />} path='usuario/perfil' />}
        />
        <Route
          path='filial'
          element={<ProtectedRoute element={<ListFilial />} path='filial' />}
        />
        <Route
          path='filial/store'
          element={<ProtectedRoute element={<CreateFilial />} path='filial/store' />}
        />
        <Route
          path='filial/update'
          element={<ProtectedRoute element={<EditFilial />} path='filial/update' />}
        />
        <Route
          path='grupo'
          element={<ProtectedRoute element={<ListGroup />} path='grupo' />}
        />
        <Route
          path='grupo/store'
          element={<ProtectedRoute element={<CreateGroup />} path='grupo/store' />}
        />
        <Route
          path='grupo/update'
          element={<ProtectedRoute element={<EditGroup />} path='grupo/update' />}
        />
        <Route
          path='exclusao/lotes'
          element={<ProtectedRoute element={<ExclusaoLotesInit />} path='exclusao/lotes' />}
        />
        <Route
          path='import'
          element={<ProtectedRoute element={<Importador />} path='import' />}
        />
        <Route
          path='audit'
          element={<ProtectedRoute element={<Audit />} path='audit' />}
        />
        <Route
          path='audit/show'
          element={<ProtectedRoute element={<AuditShow />} path='audit/show' />}
        />
        <Route
          path='financeiro'
          element={<ProtectedRoute element={<Financeiro />} path='financeiro' />}
        />
        <Route
          path='financeiro/relatorio-financeiro'
          element={<ProtectedRoute element={<Relatorio />} path='financeiro/relatorio-financeiro' />}
        />
        <Route
          path='relatorio/venda-intermediacao'
          element={<ProtectedRoute element={<RelatorioVendaIntermed />} path='relatorio/venda-intermediacao' />}
        />
        <Route
          path='/financeiro/contas-pagar'
          element={<ProtectedRoute element={<ContasPagar />} path='financeiro/contas-pagar' />}
        />
        <Route
          path='financeiro/contas-pagar/store'
          element={<ProtectedRoute element={<CreateContasPagar />} path='financeiro/contas-pagar/store' />}
        />
        <Route
          path='financeiro/contas-pagar/update'
          element={<ProtectedRoute element={<EditContasPagar />} path='financeiro/contas-pagar/update' />}
        />
        <Route
          path='financeiro/contas-receber'
          element={<ProtectedRoute element={<ContasReceber />} path='financeiro/contas-receber' />}
        />
        <Route
          path='financeiro/contas-receber/store'
          element={<ProtectedRoute element={<CreateContasReceber />} path='financeiro/contas-receber/store' />}
        />
        <Route
          path='financeiro/contas-receber/update'
          element={<ProtectedRoute element={<EditContasReceber />} path='financeiro/contas-receber/update' />}
        />
        <Route
          path='financeiro/lancamento/caixa'
          element={<ProtectedRoute element={<MovCaixa />} path='financeiro/lancamento/caixa' />}
        />
        <Route
          path='financeiro/lancamento/caixa/store'
          element={<ProtectedRoute element={<CreateMovCaixa />} path='financeiro/lancamento/caixa/store' />}
        />
        <Route
          path='financeiro/lancamento/caixa/update'
          element={<ProtectedRoute element={<EditMovCaixa />} path='financeiro/lancamento/caixa/update' />}
        />
        <Route
          path='financeiro/conta-financeira'
          element={<ProtectedRoute element={<ContaFinanceira />} path='financeiro/conta-financeira' />}
        />
        <Route
          path='financeiro/conta-financeira/store'
          element={<ProtectedRoute element={<CreateContaFinanceira />} path='financeiro/conta-financeira/store' />}
        />
        <Route
          path='financeiro/conta-financeira/update'
          element={<ProtectedRoute element={<EditContaFinanceira />} path='financeiro/conta-financeira/update' />}
        />
        <Route
          path='conta-financeira'
          element={<ProtectedRoute element={<ContaFinanceira />} path='conta-financeira' />}
        />
        <Route
          path='conta-financeira/store'
          element={<ProtectedRoute element={<CreateContaFinanceira />} path='conta-financeira/store' />}
        />
        <Route
          path='conta-financeira/update'
          element={<ProtectedRoute element={<EditContaFinanceira />} path='conta-financeira/update' />}
        />
        <Route
          path='financeiro/centro-custo'
          element={<ProtectedRoute element={<CentroCusto />} path='financeiro/centro-custo' />}
        />
        <Route
          path='financeiro/centro-custo/store'
          element={<ProtectedRoute element={<CreateCentroCusto />} path='financeiro/centro-custo/store' />}
        />
        <Route
          path='financeiro/centro-custo/update'
          element={<ProtectedRoute element={<EditCentroCusto />} path='financeiro/centro-custo/update' />}
        />
        <Route
          path='financeiro/plano-conta'
          element={<ProtectedRoute element={<PlanoConta />} path='financeiro/plano-conta' />}
        />
        <Route
          path='financeiro/plano-conta/store'
          element={<ProtectedRoute element={<CreatePlanoConta />} path='financeiro/plano-conta/store' />}
        />
        <Route
          path='financeiro/plano-conta/update'
          element={<ProtectedRoute element={<EditPlanoConta />} path='financeiro/plano-conta/update' />}
        />
        <Route
          path='financeiro/renegociacao'
          element={<ProtectedRoute element={<Renegociacao />} path='financeiro/renegociacao' />}
        />
        <Route
          path='financeiro/renegociacao/store'
          element={<ProtectedRoute element={<CreateRenegociacao />} path='financeiro/renegociacao/store' />}
        />
        <Route
          path='carteira'
          element={<ProtectedRoute element={<Carteira />} path='carteira' />}
        />
        <Route
          path='contrato'
          element={<ProtectedRoute element={<Contratos />} path='contrato' />}
        />
        <Route
          path='contrato/store'
          element={<ProtectedRoute element={<CreateContratos />} path='contrato/store' />}
        />
        <Route
          path='contrato/update'
          element={<ProtectedRoute element={<EditContratos />} path='contrato/update' />}
        />
        <Route
          path='contrato/show'
          element={<ProtectedRoute element={<ShowGestaoContrato />} path='contrato/show' />}
        />
        <Route
          path='reajuste'
          element={<ProtectedRoute element={<ReajusteForm />} path='reajuste' />}
        />
        <Route
          path='conta/corrente'
          element={<ProtectedRoute element={<ContaCorrenteClienteForm />} path='conta/corrente' />}
        />
        <Route
          path='boletos'
          element={<ProtectedRoute element={<GestaoBoletos />} path='boletos' />}
        />
        <Route
          path='pessoas'
          element={<ProtectedRoute element={<Pessoas />} path='pessoas' />}
        />
        <Route
          path='vendas'
          element={<ProtectedRoute element={<Vendas />} path='vendas' />}
        />
        <Route
          path='cemiterio'
          element={<ProtectedRoute element={<Cemiterio />} path='cemiterio' />}
        />
        <Route
          path='inumados'
          element={<ProtectedRoute element={<Inumados />} path='inumados' />}
        />
        <Route
          path='inumados/store'
          element={<ProtectedRoute element={<CreateInumados />} path='inumados/store' />}
        />
        <Route
          path='inumados/update'
          element={<ProtectedRoute element={<EditInumados />} path='inumados/update' />}
        />
        <Route
          path='cemiterio/tipo-jazigo'
          element={<ProtectedRoute element={<TipoJazigo />} path='cemiterio/tipo-jazigo' />}
        />
        <Route
          path='cemiterio/tipo-jazigo/store'
          element={<ProtectedRoute element={<CreateTipoJazigo />} path='cemiterio/tipo-jazigo/store' />}
        />
        <Route
          path='cemiterio/tipo-jazigo/update'
          element={<ProtectedRoute element={<EditTipoJazigo />} path='cemiterio/tipo-jazigo/update' />}
        />
        <Route
          path='estrutura/jazigo'
          element={<ProtectedRoute element={<Jazigo />} path='estrutura/jazigo' />}
        />
        <Route
          path='estrutura/jazigo/store'
          element={<ProtectedRoute element={<CreateJazigo />} path='estrutura/jazigo/store' />}
        />
        <Route
          path='estrutura/jazigo/update'
          element={<ProtectedRoute element={<EditJazigo />} path='estrutura/jazigo/update' />}
        />
        <Route
          path='venda/gerar-venda'
          element={<ProtectedRoute element={<NovaVenda />} path='venda/gerar-venda' />}
        />
        <Route
          path='estrutura/jazigo-grupo'
          element={<ProtectedRoute element={<CreateJazigoGroup />} path='estrutura/jazigo-grupo' />}
        />
        <Route
          path='carteira/contrato-transferencia/store'
          element={<ProtectedRoute element={<CreateCessaoDeDireito />} path='carteira/contrato-transferencia/store' />}
        />
        <Route
          path='carteira/contrato-transferencia/show'
          element={<ProtectedRoute element={<ShowCessaoDeDireito />} path='carteira/contrato-transferencia/show' />}
        />
        <Route
          path='carteira/contrato-transferencia'
          element={<ProtectedRoute element={<CessaoDeDireito />} path='carteira/contrato-transferencia' />}
        />
        <Route
          path='produto'
          element={<ProtectedRoute element={<Produto />} path='produto' />}
        />
        <Route
          path='produto/store'
          element={<ProtectedRoute element={<CreateProduto />} path='produto/store' />}
        />
        <Route
          path='produto/update'
          element={<ProtectedRoute element={<EditProduto />} path='produto/update' />}
        />
        <Route
          path='fornecedor'
          element={<ProtectedRoute element={<Fornecedores />} path='fornecedor' />}
        />
        <Route
          path='fornecedor/store'
          element={<ProtectedRoute element={<CreateFornecedores />} path='fornecedor/store' />}
        />
        <Route
          path='fornecedor/update'
          element={<ProtectedRoute element={<EditFornecedores />} path='fornecedor/update' />}
        />
      </Route>
    </Routes>
  );
}

export default MainRoutes;
