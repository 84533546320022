import React, { useState, useEffect, useRef } from 'react';
import { AlertService } from './AlertService';
import { url, url_boleto, url_remessa } from '../environments/environments-develop';
import api from '../services/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Column } from 'primereact/column';
import { MDBCol, MDBPageItem, MDBPageNav, MDBPagination, MDBRow } from 'mdbreact';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Link } from 'react-router-dom';
import { useAppContext } from '../contexts/AppContext';
import { TreeNode } from 'primereact/treenode';
import { TreeTable } from 'primereact/treetable';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import ButtonClose from 'react-bootstrap/Button';
import { Toast } from 'primereact/toast';
import iconImpressora from '../../assets/images/iconImpressora.png';
import { DataTable } from 'primereact/datatable';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../assets/images/icon-calendar.png';
import pt from 'date-fns/locale/pt';
import TokenService from '../services/Auth/token.service';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
import { Skeleton } from 'primereact/skeleton';
import DetalhesContrato from '../pages/Carteira/GestaoBoletos/Componentes/DetalhesContrato';

interface TableComponentsBoletosProps {
    urlSearch: any;
    urlSearchDataPut: string;
    url_redirect_edit: string;
    urlRedirectCreated: string;
    typeLancamento: string;
    key: any;
    id: any;
}

const TableComponentsBoletos: React.FC<TableComponentsBoletosProps> = ({ id, urlRedirectCreated, urlSearch, urlSearchDataPut, url_redirect_edit, typeLancamento, key }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [nodes, setNodes] = useState<any | null>(null);
    const [titles, setTitles] = useState<any | null>(null);
    const [totalPages, setTotalPages] = useState(0);
    const { setUrl_button_create } = useAppContext();
    const [selectedNode, setSelectedNode] = useState<any[]>([]);
    const [selectedArray, setSelectedArray] = useState<any[]>([]);
    const [showModalEmissaoBoleto, setShowModalEmissaoBoleto] = useState(false);
    const [showModalEmissaoRemessa, setShowModalEmissaoRemessa] = useState(false);
    const [showModalAtualizarBoleto, setShowModalAtualizarBoleto] = useState(false);
    const [showModalConfirmarImpressao, setShowModalConfirmarImpressao] = useState(false);
    const [showModalListRetorno, setShowModalListRetorno] = useState(false);
    const [showModalLeituraRetorno, setShowModalLeituraRetorno] = useState(false);
    const [selectedNodeKeys, setSelectedNodeKeys] = useState<any>(null);
    const toast = useRef<any>(null);
    const [listContaFinanceira, setListContaFinanceira] = useState([]);
    const [validar, setValidar] = useState(true);
    const [data_vencimento, setDataVencimento] = useState<Date | null>(null);
    const [listRetorno, setListRetorno] = useState<any>([]);
    const [formEmissao, setFormEmissao] = useState<any>({
        lancamentos: [],
        conta_financeira_id: null,
        antecipadas: false,
        juros: false,
        data_vencimento: null
    });
    const [formAtualizarBoleto, setFormAtualizarBoleto] = useState<any>({
        contrato_id: null,
        intervaloInicial: null,
        intervaloFinal: null,
        data_vencimento: null,
        conta_financeiro_id: null,
    });
    const [isLoading, setLoading] = useState(false);
    const onDrop = (acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            setSelectedFile(file);
        }
    };
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    const handleChange = (field: string, value: any) => {
        setFormEmissao((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    };

    const save = (id: any) => {
        if (selectedFile) {
            const fileName = selectedFile.name;
            const reader = new FileReader();
            reader.onload = () => {
                const result = reader.result as string;
                if (result) {
                    let form = {
                        file: {
                            nome: fileName,
                            base64: 'data:application/octet-stream;base64,' + result.split(',')[1],
                        }
                    };
                    retornoRemessa(form, id);
                } else {
                    AlertService.error('error', 'Houve um erro ao tentar montar o formulário de importação. Caso o erro persista, entre em contato com o suporte.');
                }
            };

            reader.readAsDataURL(selectedFile);
        }
    };
    async function retornoRemessa(form: any, id: any): Promise<void> {
        try {
            const response: any = await api.post(`${url}/api/v1/admin/financeiro/envio-retorno/${id}`, JSON.stringify(form));
            if (response.status === 200) {
                if (response.data.error && response.data.error === '400') {
                    toast.current.show({ severity: 'error', summary: 'Error!', detail: String(response.data.mensagem) });
                } else {
                    setListRetorno(response.data);
                    toast.current.show({ severity: 'success', summary: 'Sucesso!', detail: 'Retorno Enviado!' });
                    setShowModalLeituraRetorno(false);
                    setShowModalListRetorno(true);
                    listarBoletos();
                }
            }
        } catch (error: any) {
            AlertService.error('Erro!', String(error.response?.data?.error?.message || 'Erro desconhecido'));
            console.error('Erro ao processar a requisição:', error);
        }
    }
    const handleChangeFormAtualizacao = (field: string, value: any) => {
        setFormAtualizarBoleto((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    };

    async function emitirRemessa(id: any): Promise<void> {
        try {
            const response: any = await api.get(`${url}/api/v1/admin/financeiro/emitir-remessa/${id}`);

            if (response.status === 200) {
                const link = response.data.link;
                const nameFile = response.data.nameFile;
                downloadRemessa(link, nameFile);
                setShowModalEmissaoRemessa(false);
                listarBoletos();
                setSelectedNodeKeys(null);
                setSelectedArray([]);
                toast.current.show({ severity: 'success', summary: 'Sucesso!', detail: 'Remessa emitida!' });
            } else {
                AlertService.infomessage('Atenção!', 'Esta conta provavelmente não possui boletos para se emitir a remessa, verifique a listagem das parcelas e tente novamente!');
            }
        } catch (error: any) {
            AlertService.infomessage('Atenção!', 'Esta conta provavelmente não possui boletos para se emitir a remessa, verifique a listagem das parcelas e tente novamente!');
            console.error('Erro ao processar a requisição:', error);
        }
    }

    const removeFile = () => {
        setSelectedFile(null);
    };
    useEffect(() => {
        setFormEmissao((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                ['conta_financeira_id']: Number(formEmissao.conta_financeira_id)
            };
        });
    }, [formEmissao.conta_financeira_id])
    async function emitirBoleto(): Promise<void> {
        try {
            const response: any = await api.post(`${url}/api/v1/admin/financeiro/emitir-boleto`, JSON.stringify(formEmissao));

            if (response.status === 200) {
                const link = response.data.link;
                const nameFile = response.data.nameFile;
                downloadBoleto(link, nameFile);
                setFormEmissao({
                    lancamentos: [],
                    conta_financeira_id: null,
                    antecipadas: false,
                    juros: false,
                    data_vencimento: null
                });
                setDataVencimento(null);
                setSelectedNode([]);
                listarBoletos();
                setSelectedNodeKeys(null);
                setSelectedArray([]);
                setShowModalEmissaoBoleto(false);
                toast.current.show({ severity: 'success', summary: 'Sucesso!', detail: 'Boletos Emitidos!' });
            } else {
                AlertService.error('Erro!', String(response.error.message || 'Erro desconhecido'));
                console.error('Erro ao processar a requisição. Código de status:', response.status);
            }
        } catch (error: any) {
            AlertService.error('Erro!', String(error.response?.data?.error?.message || 'Erro desconhecido'));
            console.error('Erro ao processar a requisição:', error);
        }
    }
    function downloadBoleto(linkBoleto: string, nameFile: string): void {
        try {
            const url = new URL(url_boleto + nameFile, window.location.origin);

            if (!/^https?:$/.test(url.protocol)) {
                throw new Error('URL com protocolo inválido');
            }

            fetch(url.href)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Erro ao baixar o arquivo');
                    }
                    return response.blob();
                })
                .then(blob => {
                    const urlCreate = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = urlCreate;
                    link.setAttribute('download', nameFile);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(urlCreate);
                })
                .catch(error => {
                    console.error('Erro ao baixar o arquivo:', error);
                });
        } catch (error) {
            console.error('URL inválida:', error);
        }
    }


    function downloadRemessa(linkBoleto: string, nameFile: string): void {
        const url = new URL(url_remessa + nameFile, window.location.origin);

        if (!/^https?:$/.test(url.protocol)) {
            throw new Error('URL com protocolo inválido');
        }

        fetch(url.href)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erro ao baixar o arquivo');
                }
                return response.blob();
            })
            .then(blob => {
                const urlCreate = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = urlCreate;
                link.setAttribute('download', nameFile);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(urlCreate);
            })
            .catch(error => {
                console.error('Erro ao baixar o arquivo:', error);
            });
    }
    async function atualizarBoleto(): Promise<void> {
        try {
            const response: any = await api.post(`${url}/api/v1/admin/financeiro/atualizar-boleto`, JSON.stringify(formAtualizarBoleto));
            if (response.status === 200) {
                setFormAtualizarBoleto({
                    contrato_id: null,
                    intervaloInicial: null,
                    intervaloFinal: null,
                    data_vencimento: null,
                    conta_financeiro_id: null,
                })
                setDataVencimento(null);
                setSelectedNode([]);
                listarBoletos();
                setSelectedNodeKeys(null);
                setSelectedArray([]);
                setShowModalAtualizarBoleto(false);
                toast.current.show({ severity: 'success', summary: 'Sucesso!', detail: 'Boletos Atualizados!' });
            } else {
                AlertService.error('Erro!', String(response.error.message || 'Erro desconhecido'));
                console.error('Erro ao processar a requisição. Código de status:', response.status);
            }
        } catch (error: any) {
            AlertService.error('Erro!', String(error.response?.data?.error?.message || 'Erro desconhecido'));
            console.error('Erro ao processar a requisição:', error);
        }
    }

    async function visualizarBoleto(): Promise<void> {
        try {
            let data = {
                lancamentos: selectedArray,
                conta_financeira_id: selectedNode[0].conta_financeiro_id,
                antecipadas: false,
                juros: false,
                data_vencimento: null
            }

            const response: any = await api.post(`${url}/api/v1/admin/financeiro/visualizar-boleto`, JSON.stringify(data));

            if (response.status === 200) {
                const link = response.data.link;
                const nameFile = response.data.nameFile;
                downloadBoleto(link, nameFile);
                setSelectedNode([]);
                toast.current.show({ severity: 'success', summary: 'Sucesso!', detail: 'Boletos Atualizados!' });
            } else {
                AlertService.error('Erro!', String(response.error.message || 'Erro desconhecido'));
            }
        } catch (error: any) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: String(error.response?.data?.error?.message || 'Erro desconhecido') });
        }
    }

    async function InitContaFinanceira() {
        try {
            const response = await api.get(`${url}/api/v1/admin/financeiro/conta-financeira`);
            setListContaFinanceira(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    }

    useEffect(() => {
        setUrl_button_create(urlRedirectCreated);
        InitContaFinanceira();
    }, []);

    const onSelect = (event: any) => {
        setSelectedNode((prev) => {
            return [
                ...prev,
                event.node.data
            ];
        });
        setSelectedArray((prev) => {
            return [
                ...prev,
                event.node.data.id
            ];
        });
    };

    const onUnselect = (event: any) => {
        setSelectedNode((prev) => {
            return prev.filter((item) => item !== event.node.data);
        });
        setSelectedArray((prev) => {
            return prev.filter((item) => item !== event.node.data);
        });
        setValidar(true);
    };
    const listarBoletos = async () => {
        try {
            setLoading(true);
            const includeParam = 'config_venda,config_carteira,pessoa.telefones,pessoa.telefones,pessoa.enderecos,filhos';
            if (urlSearch) {
                const response = await api.get(`${url}${urlSearch}&include=${includeParam}&page=${currentPage + 1}&totalitems=12`);
                if (response.status === 200) {
                    const titles = response.data.headers.titles;
                    setTitles(titles);
                    const data = response.data.data;
                    const totalPages = response.data.meta.pagination.total_pages;
                    setTotalPages(totalPages);

                    const buildTreeNode = (item: any): TreeNode | null => {
                        if (item) {
                            return {
                                key: item.id.toString(),
                                data: item,
                                children: item.filhos?.data?.map((child: any) => buildTreeNode(child)),
                            };
                        }
                        return null;
                    };

                    const nodesData: TreeNode[] = data
                        ? data.reduce((acc: TreeNode[], item: any) => {
                            const treeNode = buildTreeNode(item);
                            if (treeNode) {
                                acc.push(treeNode);
                            }
                            return acc;
                        }, [])
                        : [];
                    setNodes(nodesData);
                    setLoading(false);
                }
            } else {
                setNodes([]);
            }
        } catch (error: any) {
            AlertService.error('Erro!', String(error.response?.data?.error?.message || 'Erro desconhecido'));
        }
    };
    useEffect(() => {
        listarBoletos();
    }, [currentPage, urlSearch]);

    const getStatusColorCard = (data: any) => {
        if (data.conta_financeiro_id && data.data_arq_remessa) {
            return '#289b1e';
        } else if (data.conta_financeiro_id && !data.data_arq_remessa) {
            return '#f44336';
        } else {
            return '#ff9800';
        }
    };

    const togglerStatus = (node: any) => {
        if (!node) {
            return null;
        }

        return (
            <div style={{ display: 'flex' }}>
                {node && (<div style={{ width: '5px', position: 'relative', top: '-5px', display: 'flex', flex: '1', alignItems: 'center', backgroundColor: `${getStatusColorCard(node.data)}`, margin: '3px', border: `2px solid ${getStatusColorCard(node.data)}`, borderRadius: '12px', marginTop: '12px', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)', padding: '8px' }}></div>)}
            </div>
        );
    };

    useEffect(() => {
        selectedNode.forEach((e) => {
            const dataAtual = moment().format('YYYYMMDD');
            const dataVencimento = e.data_vencimento_renegociacao != 'Não Atualizado' ? moment(e.data_vencimento_renegociacao).format('YYYYMMDD') : moment(e.data_vencimento).format('YYYYDDMM');
            if (dataVencimento < dataAtual) {
                setValidar(false);
            }
        })
    }, [selectedNode])

    const handleToggleClick = () => {
        if (selectedNode.length > 0) {
            selectedNode.forEach((e) => {
                const dataAtual = moment().format('YYYYMMDD');
                const dataVencimento = e.data_vencimento_renegociacao != 'Não Atualizado' ? moment(e.data_vencimento_renegociacao).format('YYYYMMDD') : moment(e.data_vencimento).format('YYYYDDMM');
                setFormEmissao((prevGrupoData: any) => {
                    return {
                        ...prevGrupoData,
                        conta_financeira_id: selectedNode[0].conta_financeiro_id,
                        lancamentos: [
                            ...prevGrupoData.lancamentos,
                            e.id
                        ]
                    };
                });
            });
            setShowModalConfirmarImpressao(validar);
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Nenhuma Parcela Selecionada!' });
        }
    };

    const handleToggleClickVisualizar = () => {
        if (selectedNode.length > 0) {
            visualizarBoleto();
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Nenhuma Parcela Selecionada!' });
        }
    };

    const handleToggleClickEmitirRemessa = () => {
        setShowModalEmissaoRemessa(true);
    };

    const handleToggleClickLeituraRemessa = () => {
        setShowModalLeituraRetorno(true);
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];

        for (let i = 0; i < totalPages; i++) {
            // Adicione as três primeiras páginas
            if (i < 3 || i === totalPages - 1 || (i >= currentPage - 1 && i <= currentPage + 1)) {
                pageNumbers.push(
                    <MDBPageItem key={i} active={i === currentPage}>
                        <MDBPageNav className="page-link" onClick={() => setCurrentPage(i)}>
                            <span key={i} onClick={() => setCurrentPage(i)} style={{ cursor: 'pointer', margin: '0 5px', fontWeight: i === currentPage ? 'bold' : 'normal' }}>
                                {i + 1}
                            </span>
                        </MDBPageNav>
                    </MDBPageItem>

                );
            }
        }

        return pageNumbers;
    };

    const hasValidContaId = () => {
        return selectedNode.some(node => node.conta_financeiro_id);
    };

    return (
        <div className="card">
            <Toast ref={toast} />
            <DetalhesContrato
                data={nodes?.[0]?.data?.venda_id}
            />
            <div style={{ border: '1px solid #d2d2d2' }}></div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '14px' }}>
                <div style={{ display: 'flex' }}>
                    <p style={{ margin: '0px' }}>Legenda: </p>
                    <div style={{ display: 'flex', marginLeft: '8px', }}>
                        <div style={{
                            backgroundColor: `#ff9800`,
                            margin: '3px',
                            height: '5px',
                            marginRight: '8px',
                            border: `2px solid #ff9800`,
                            borderRadius: '12px',
                            boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                            padding: '8px'
                        }}>
                        </div>
                        <p style={{ margin: '0px' }}>Sem Boleto</p>
                    </div>
                    <div style={{ display: 'flex', marginLeft: '8px', }}>
                        <div style={{
                            backgroundColor: `#289b1e`,
                            margin: '3px',
                            height: '5px',
                            marginRight: '8px',
                            border: `2px solid #289b1e`,
                            borderRadius: '12px',
                            boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                            padding: '8px'
                        }}>
                        </div>
                        <p style={{ margin: '0px' }}>Com remessa</p>
                    </div>
                    <div style={{ display: 'flex', marginLeft: '8px', }}>
                        <div style={{
                            backgroundColor: `#f44336`,
                            margin: '3px',
                            marginRight: '8px',
                            height: '5px',
                            border: `2px solid #f44336`,
                            borderRadius: '12px',
                            boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                            padding: '8px'
                        }}>
                        </div>
                        <p style={{ margin: '0px' }}>Boleto Gerado</p>
                    </div>
                    <div style={{ display: 'flex', marginLeft: '8px', }}>
                        <div style={{
                            backgroundColor: `#000`,
                            margin: '3px',
                            marginRight: '8px',
                            height: '5px',
                            border: `2px solid #000`,
                            borderRadius: '12px',
                            boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                            padding: '8px'
                        }}>
                        </div>
                        <p style={{ margin: '0px' }}>Retorno Lido</p>
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    {selectedNode.length > 0 && (
                        <>
                            {hasValidContaId() ? (
                                <Button
                                    onClick={visualizarBoleto}
                                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                                    type="button"
                                    icon="pi pi-eye"
                                    severity="warning"
                                    title='Visualizar Boleto'
                                    rounded>
                                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Visualizar Boleto</p>
                                </Button>
                            ) : (
                                <Button
                                    onClick={handleToggleClick}
                                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                                    type="button"
                                    icon="pi pi-print"
                                    severity="success"
                                    title='Gerar Boleto'
                                    rounded>
                                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Gerar Boleto</p>
                                </Button>
                            )}
                        </>)}
                    {!validar && selectedNode.length !== 0 && (<Button
                        onClick={(e) => setShowModalAtualizarBoleto(true)}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        icon="pi pi-undo"
                        severity="danger"
                        title='Atualizar Data de Vencimento'
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '13px' }}>Atualizar Data de Vencimento</p>
                    </Button>)}
                    <Button
                        onClick={(e) => handleToggleClickEmitirRemessa()}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        icon="pi pi-file-export"
                        severity="help"
                        title='Emissão de remessa'
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '13px' }}>Gerar de Remessa</p>
                    </Button>
                    <Button
                        onClick={(e) => handleToggleClickLeituraRemessa()}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        icon="pi pi-file-import"
                        severity="info"
                        title='Visualizar'
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '13px' }}>Leitura de Retorno</p>
                    </Button>
                </div>
            </div>
            <TreeTable
                stripedRows
                lazy
                selectionMode="multiple" selectionKeys={selectedNodeKeys}
                onSelectionChange={(e: any) => setSelectedNodeKeys(e.value)} metaKeySelection={false}
                onSelect={onSelect}
                onUnselect={onUnselect}
                value={nodes ? nodes : []}
                tableStyle={{ minWidth: '50rem' }}
                emptyMessage={isLoading ? (<Skeleton />) : (<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>)}
            >
                <Column
                    style={{ width: '5px', borderBottom: '1px solid #bdbdbd96' }}
                    body={(e) => togglerStatus(e)}
                    headerClassName=""
                />

                {titles && titles.map((col: any) => (
                    <Column
                        style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                        key={col.dataField}
                        field={col.dataField}
                        header={col.heading}
                        expander={col.expander}
                        body={isLoading && (<Skeleton />)}
                    />
                ))}
            </TreeTable>
            <Modal show={showModalListRetorno} style={{ padding: '0px !important' }} size='lg' onHide={() => setShowModalListRetorno(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-center'>Retorno</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: '0px !important' }}>
                    <DataTable
                        stripedRows
                        lazy
                        value={listRetorno ? listRetorno : []}
                        tableStyle={{ minWidth: '50rem' }}
                        emptyMessage={<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>}
                    >
                        <Column
                            key={'doc'} field={'doc'} header={'Num.Doc'}
                            style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                        />
                        <Column
                            key={'codigoOcorrencia'} field={'codigoOcorrencia'} header={'Dt.Ocorrencia'}
                            style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                        />
                        <Column
                            key={'dataPagamento'} field={'dataPagamento'} header={'Dt.Pgto'}
                            style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                        />
                        <Column
                            key={'dataVencimento'} field={'dataVencimento'} header={'Dt.Venc'}
                            style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                        />
                        <Column
                            key={'justificativa'} field={'justificativa'} header={'Descrição'}
                            style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                        />
                        <Column
                            key={'ocorrencia'} field={'ocorrencia'} header={'Ocorrência'}
                            style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                        />
                        <Column
                            key={'tarifa'} field={'tarifa'} header={'Tarifa'}
                            style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                        />
                        <Column
                            key={'valor'} field={'valor'} header={'Valor'}
                            style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                        />
                        <Column
                            key={'valorPg'} field={'valorPg'} header={'Valor Pago'}
                            style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                        />
                    </DataTable>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonClose variant="success" onClick={() => { setShowModalListRetorno(false) }}>Fechar</ButtonClose>
                </Modal.Footer>
            </Modal>
            <Modal show={showModalConfirmarImpressao} size='lg' style={{ padding: '0px !important' }} onHide={() => setShowModalConfirmarImpressao(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-center'>Confirmar impressão dos seguintes boletos?</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: '0px !important' }}>
                    <DataTable
                        stripedRows
                        lazy
                        value={selectedNode ? selectedNode : []}
                        tableStyle={{ minWidth: '50rem' }}
                        emptyMessage={<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>}
                    >
                        <Column
                            key={'numero_documento'} field={'numero_documento'} header={'Documento'}
                            style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                        />
                        <Column
                            key={'data_vencimento'} field={'data_vencimento'} header={'Vencimento'}
                            style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                        />
                        <Column
                            key={'situacao_pagamento_enum_name'} field={'situacao_pagamento_enum_name'} header={'Situação'}
                            style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                        />
                        <Column
                            key={'valor_original_format'} field={'valor_original_format'} header={'Valor'}
                            style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                        />
                    </DataTable>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonClose variant="success" onClick={() => { setShowModalEmissaoBoleto(true); setShowModalConfirmarImpressao(false) }}>Confirmar</ButtonClose>
                </Modal.Footer>
            </Modal>
            <Modal show={showModalEmissaoRemessa} size='lg' style={{ padding: '0px !important' }} onHide={() => setShowModalEmissaoRemessa(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-center'>Emitir Remessa</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="formulario-empreendimento">
                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <Col md="6">
                                <Form.Label>Conta Financeira</Form.Label>
                                <Form.Select value={formEmissao.conta_financeira_id} onChange={(e) => handleChange('conta_financeira_id', Number(e.target.value))}>
                                    <option>Selecione...</option>
                                    {listContaFinanceira?.map((item: any) => (
                                        <option value={item.id} key={item.id}>
                                            {item.nome}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonClose variant="success" onClick={() => { setShowModalEmissaoRemessa(true); emitirRemessa(formEmissao.conta_financeira_id) }}>Emitir</ButtonClose>
                </Modal.Footer>
            </Modal>
            <Modal show={showModalEmissaoBoleto} size='lg' style={{ padding: '0px !important' }} onHide={() => setShowModalEmissaoBoleto(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-center'>Emitir Boletos</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: '0px !important' }}>
                    <>
                        <Form.Group className="formulario-empreendimento">
                            <Row>
                                <Col>
                                    <Form.Label>Layout de Boletos</Form.Label>
                                    <Form.Select value={formEmissao.layout_boletos} onChange={(e) => handleChange('layout_boletos', Number(e.target.value))}>
                                        <option>Selecione...</option>
                                        <option value={1}>Um boleto</option>
                                        <option value={2}>Dois boletos por paginas</option>
                                        <option value={3}>Carnes</option>
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Label>Conta Financeira</Form.Label>
                                    <Form.Select value={formEmissao.conta_financeira_id} onChange={(e) => handleChange('conta_financeira_id', Number(e.target.value))}>
                                        <option>Selecione...</option>
                                        {listContaFinanceira?.map((item: any) => (
                                            <option value={item.id} key={item.id}>
                                                {item.nome}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col>
                                    <Form.Label>Tipo de Parcelas</Form.Label>
                                    <Form.Select value={formEmissao.antecipadas !== undefined ? formEmissao.antecipadas.toString() : "false"} onChange={(e) => handleChange('antecipadas', e.target.value === "true" ? true : false)}>
                                        <option>Selecione...</option>
                                        <option value="false">
                                            Parcelas Correntes
                                        </option>
                                        <option value="true">
                                            Parcelas Antecipadas
                                        </option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        </Form.Group>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonClose variant="success" onClick={() => { setShowModalEmissaoBoleto(true); emitirBoleto() }}>Imprimir</ButtonClose>
                </Modal.Footer>
            </Modal>
            <Modal show={showModalAtualizarBoleto} size='lg' style={{ padding: '0px !important' }} onHide={() => setShowModalAtualizarBoleto(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-center'>Atualizar Boletos</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: '0px !important' }}>
                    <>
                        <Form.Group className="formulario-empreendimento">
                            <Row>
                                <Col>
                                    <Form.Label>Conta Financeira</Form.Label>
                                    <Form.Select value={formAtualizarBoleto.conta_financeira_id || ''} onChange={(e) => handleChangeFormAtualizacao('conta_financeira_id', e.target.value)}>
                                        <option>Selecione...</option>
                                        {listContaFinanceira?.map((item: any) => (
                                            <option value={item.id} key={item.id}>
                                                {item.nome}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Form.Group as={Col} controlId="formGridState" style={{ display: 'grid' }}>
                                    <Form.Label>Data do Novo Vencimento</Form.Label>
                                    <DatePicker
                                        selected={data_vencimento}
                                        onChange={(date: Date) => {
                                            setDataVencimento(date);
                                            handleChangeFormAtualizacao('data_vencimento', date.toISOString());  // Adapte conforme necessário
                                        }}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        fixedHeight
                                        locale={pt}
                                        customInput={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="text"
                                                    placeholder="Data do Novo Vencimento"
                                                    className="dateFormFinanceiro"
                                                    value={data_vencimento ? data_vencimento.toLocaleDateString() : ''}
                                                    readOnly
                                                />
                                                <img
                                                    className="calendar-icon"
                                                    src={CalendarIcon}
                                                />
                                            </div>
                                        }
                                    />
                                </Form.Group>
                                <Col>
                                    <Form.Label>Opções sobre juros</Form.Label>
                                    <Form.Select value={formEmissao.juros !== undefined ? formEmissao.juros.toString() : "false"} onChange={(e) => handleChange('juros', e.target.value === "true" ? true : false)}>
                                        <option>Selecione...</option>
                                        <option value="false">
                                            Sem Juros
                                        </option>
                                        <option value="true">
                                            Com Juros
                                        </option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row>
                                <h5 style={{ marginTop: '12px' }}>Intervalo </h5>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Numero de parcela inicial</Form.Label>
                                    <Form.Control value={formAtualizarBoleto.intervaloInicial || ''} onChange={(e) => handleChangeFormAtualizacao("intervaloInicial", e.target.value)} />
                                </Col>
                                <Col>
                                    <Form.Label>Numero de parcela Final</Form.Label>
                                    <Form.Control value={formAtualizarBoleto.intervaloFinal || ''} onChange={(e) => handleChangeFormAtualizacao("intervaloFinal", e.target.value)} />
                                </Col>
                            </Row>
                        </Form.Group>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonClose variant="success" onClick={() => { setShowModalAtualizarBoleto(true); atualizarBoleto() }}>Imprimir</ButtonClose>
                </Modal.Footer>
            </Modal>
            <Modal show={showModalLeituraRetorno} size='lg' style={{ padding: '0px !important' }} onHide={() => setShowModalLeituraRetorno(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-center'>Leitura de Retorno</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="formulario-empreendimento">
                        <Row style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
                            <Col>
                                <div
                                    {...getRootProps()}
                                    style={{
                                        border: '2px dashed #cccccc',
                                        padding: '20px',
                                        textAlign: 'center',
                                    }}
                                >
                                    <input {...getInputProps()} />
                                    {!selectedFile && (
                                        <p>Arraste e solte o arquivo de memorial descritivo aqui, ou clique para selecionar.</p>
                                    )}
                                    {selectedFile && (
                                        <>
                                            <p>{selectedFile.name}</p>
                                            <ButtonClose variant="contained" className='btn btn-danger' onClick={removeFile}>
                                                Remover Arquivo
                                            </ButtonClose>
                                        </>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <Col md="6">
                                <Form.Label>Conta Financeira</Form.Label>
                                <Form.Select value={formEmissao.conta_financeira_id || ''} onChange={(e) => handleChange('conta_financeira_id', e.target.value)}>
                                    <option>Selecione...</option>
                                    {listContaFinanceira?.map((item: any) => (
                                        <option value={item.id} key={item.id}>
                                            {item.nome}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonClose variant="success" onClick={() => { setShowModalLeituraRetorno(true); save(formEmissao.conta_financeira_id) }}>Enviar</ButtonClose>
                </Modal.Footer>
            </Modal>
            <div className="pagination-footer">
                <MDBRow>
                    <MDBCol>
                        <MDBPagination circle>
                            <MDBPageItem disabled={currentPage === 0}>
                                <MDBPageNav className="page-link" aria-label="Previous" onClick={() => setCurrentPage(currentPage - 1)}>
                                    <span aria-hidden="true">&laquo;</span>
                                </MDBPageNav>
                            </MDBPageItem>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {renderPageNumbers()}
                            </div>
                            <MDBPageItem disabled={currentPage === totalPages - 1}>
                                <MDBPageNav className="page-link" aria-label="Next" onClick={() => setCurrentPage(currentPage + 1)}>
                                    <span aria-hidden="true">&raquo;</span>
                                </MDBPageNav>
                            </MDBPageItem>
                        </MDBPagination>
                    </MDBCol>
                </MDBRow>
            </div>
        </div>
    );
};

export default TableComponentsBoletos;
