import './../../styles/global.css';
import paginaEmConstrucao from '../../../assets/images/pagina-em-construcao.png';
import { Col, Form, Row } from 'react-bootstrap';
import { Card } from 'primereact/card';
import { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AlertService } from '../../components/AlertService';
import { url } from '../../environments/environments-develop';
import api from '../../services/api';
import TokenService from '../../services/Auth/token.service';
import { useSidebarContext } from '../../contexts/SidebarContext';
function Pessoas() {
  const [progressConfirmados, setConfirmados] = useState(0);
  const [progressConfirmadosValor, setConfirmadosValor] = useState(0);
  const [progressConfirmadosPercentual, setConfirmadosPercentual] = useState(0);

  const [progressAReceber, setAReceber] = useState(0);
  const [progressAReceberValor, setAReceberValor] = useState(0);
  const [progressAReceberPercentual, setAReceberPercentual] = useState(0);

  const [progressInadimplentes, setInadimplentes] = useState(0);
  const [progressInadimplentesValor, setInadimplentesValor] = useState(0);
  const [progressInadimplentesPercentual, setInadimplentesPercentual] = useState(0);

  const circleSize = 110;                             // Tamanho do círculo em pixels
  const borderWidth = 4;                               // Largura da borda em pixels
  const radius = (circleSize - borderWidth) / 2;  // Raio do círculo

  const circumference = 2 * Math.PI * radius;                                     // Circunferência do círculo
  const progressConfirmadosLength = (progressConfirmadosPercentual / 100) * circumference;    // Comprimento do progresso
  const progressAReceberLength = (progressAReceberPercentual / 100) * circumference;       // Comprimento do progresso
  const progressInadimplentesLength = (progressInadimplentesPercentual / 100) * circumference;  // Comprimento do progresso
  const [filtroQuery, setFiltroQuery] = useState(1);
  const [totalVendas, setTotalVendas] = useState<any | null>(0);
  const [totalInadimplentes, setTotalInadimplentes] = useState<any | null>(0);
  const [emissoesPeriodo, setEmissoesPeriodo] = useState<any | null>(0);
  const [qtdTotal, setQtdTotal] = useState<any | null>(0);
  const [nodesConfirmacoes, setNodesConfirmacoes] = useState<any | null>(null);
  const [nodesInadimplentes, setNodesInadimplentes] = useState<any | null>(null);
  const [titles, setTitles] = useState<any | null>(null);
  const { updateVisibility } = useSidebarContext();
  const { isVisible } = useSidebarContext();

  useEffect(() => {
    montarFiltro(1);
  }, []);
  useEffect(() => {
    updateVisibility(true);
  }, []);
  async function montarFiltro(filtroQuery: any) {
    try {
      const queryParams = {
        filtro: filtroQuery,
        empreendimento_id: TokenService.getEmpreendimentoId()?.id ?? null
      };

      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
        .join('&');
      InitRelatorioDashboard(queryString);
      initConfirmados(queryString);
      initInadimplentes(queryString);
      initDashGeral(queryString);
    } catch (error: any) {
      AlertService.error('Erro!', String(error.response?.data?.message) || 'Erro desconhecido');
    }
  }

  async function initConfirmados(queryString: any) {
    try {
      const response = await api.get(`${url}/api/v1/admin/financeiro/relatorio-dashboard-confirmados?${queryString}`);
      if (response.status === 200) {
        const titles = response.data.headers.titles;
        setTitles(titles)
        const data = response.data.data;
        setNodesConfirmacoes(data);
      }
    } catch (error: any) {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error('Erro!', String(mensagens));
    }
  };
  async function initDashGeral(queryString: any) {
    try {
      const response = await api.get(`${url}/api/v1/admin/financeiro/relatorio-dashboard-geral?${queryString}`);
      if (response.status === 200) {
        setTotalVendas(response.data.total_vendas);
        setTotalInadimplentes(response.data.total_inadimplentes);
        setEmissoesPeriodo(response.data.emissoes_periodo);
        setQtdTotal(response.data.quantidade_total);
      }
    } catch (error: any) {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error('Erro!', String(mensagens));
    }
  };
  async function initInadimplentes(queryString: any) {
    try {
      const response = await api.get(`${url}/api/v1/admin/financeiro/relatorio-dashboard-inadimplentes?${queryString}`);
      if (response.status === 200) {
        const titles = response.data.headers.titles;
        setTitles(titles)
        const data = response.data.data;
        setNodesInadimplentes(data);
      }
    } catch (error: any) {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error('Erro!', String(mensagens));
    }
  };
  async function InitRelatorioDashboard(queryString: any) {
    try {
      const response = await api.get(`${url}/api/v1/admin/financeiro/relatorio-dashboard?${queryString}`);
      setConfirmados(response.data.confirmados);
      setConfirmadosValor(response.data.valorConfirmados);
      setConfirmadosPercentual(response.data.percentConfirmados);
      setAReceber(response.data.abertos);
      setAReceberValor(response.data.valorAbertos);
      setAReceberPercentual(response.data.percentAbertos);
      setInadimplentes(response.data.inadimplentes);
      setInadimplentesValor(response.data.valorInadimplentes);
      setInadimplentesPercentual(response.data.percentInadimplentes);
    } catch (error) {
      AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
    }
  }
  const headerConfirmacoes = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">Ultimas Confirmações</span>
    </div>
  );

  const headerInadimplentes = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">Ultimas Inadimplências</span>
    </div>
  );
  return (
    <div
      className={`${isVisible ? 'container-section-home-visible' : 'container-section-home'}`}>
      <Row className="body-content-dash">
        <Col md={6} lg={7}>
          <Card className="mb-3" style={{ height: '89%', marginBottom: '0px !important', marginTop: '22px', marginLeft: '22px', display: 'flex', borderRadius: '10px', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)' }}>
            <div style={{ marginTop: '15px' }}>
              <p style={{ fontSize: '13px', fontWeight: 'bold', marginBottom: '10px' }}>Relatório do dia</p>
              <div key={`inline-radio`} style={{ marginTop: '10px' }} className="mb-3">
                <Form.Check
                  inline
                  checked={filtroQuery === 1 ? true : false}
                  onChange={(e) => setFiltroQuery(1)}
                  onClick={(e) => montarFiltro(1)}
                  label="Dia"
                  type="radio"
                  id={`inline-radio-1`}
                />
                <Form.Check
                  inline
                  checked={filtroQuery === 2 ? true : false}
                  onChange={(e) => setFiltroQuery(2)}
                  onClick={(e) => montarFiltro(2)}
                  label="Ultima Semana"
                  type="radio"
                  id={`inline-radio-2`}
                />
                <Form.Check
                  inline
                  checked={filtroQuery === 3 ? true : false}
                  onChange={(e) => setFiltroQuery(3)}
                  onClick={(e) => montarFiltro(3)}
                  label="Ultimos 30 Dias"
                  type="radio"
                  id={`inline-radio-3`}
                />
                <Form.Check
                  inline
                  checked={filtroQuery === 4 ? true : false}
                  onChange={(e) => setFiltroQuery(4)}
                  onClick={(e) => montarFiltro(4)}
                  label="Mês"
                  type="radio"
                  id={`inline-radio-4`}
                />
              </div>
            </div>
            <Row style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '32px', marginRight: '32px', marginBottom: '22px', marginTop: '12px', padding: '8px', paddingTop: '0px', paddingBottom: '0px' }}>
              <Col md={4} lg={2} style={{ display: 'flex', marginLeft: '13px', marginRight: '13px', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <h6>Confirmadas</h6>
                <div style={{ position: 'relative', width: circleSize, height: circleSize, margin: '10px' }}>
                  <svg width={circleSize} height={circleSize}>
                    <circle cx={circleSize / 2} cy={circleSize / 2} r={radius} fill="none" stroke="#f0f0f0" strokeWidth={borderWidth} />
                    <circle cx={circleSize / 2} cy={circleSize / 2} r={radius} fill="none" stroke="#4CAF50" strokeWidth={borderWidth} strokeDasharray={circumference} strokeDashoffset={circumference - progressConfirmadosLength} strokeLinecap="round" />
                  </svg>
                  <h6 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1' }}>{progressConfirmados}</h6>
                </div>
                <p style={{ width: 'max-content' }}>{progressConfirmadosValor}</p>
              </Col>
              <div style={{ width: '1px', borderRight: '1px solid rgb(73 73 73 / 17%)' }}></div>
              <Col md={4} lg={2} style={{ display: 'flex', marginLeft: '13px', marginRight: '13px', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <h6>Vigentes</h6>
                <div style={{ position: 'relative', width: circleSize, height: circleSize, margin: '10px' }}>
                  <svg width={circleSize} height={circleSize}>
                    <circle cx={circleSize / 2} cy={circleSize / 2} r={radius} fill="none" stroke="#f0f0f0" strokeWidth={borderWidth} />
                    <circle cx={circleSize / 2} cy={circleSize / 2} r={radius} fill="none" stroke="#ffbb00" strokeWidth={borderWidth} strokeDasharray={circumference} strokeDashoffset={circumference - progressAReceberLength} strokeLinecap="round" />
                  </svg>
                  <h6 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1' }}>{progressAReceber}</h6>
                </div>
                <p style={{ width: 'max-content' }}>{progressAReceberValor}</p>
              </Col>
              <div style={{ width: '1px', borderRight: '1px solid rgb(73 73 73 / 17%)' }}></div>
              <Col md={4} lg={2} style={{ display: 'flex', marginLeft: '13px', marginRight: '13px', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <h6>Inadimplentes</h6>
                <div style={{ position: 'relative', width: circleSize, height: circleSize, margin: '10px' }}>
                  <svg width={circleSize} height={circleSize}>
                    <circle cx={circleSize / 2} cy={circleSize / 2} r={radius} fill="none" stroke="#f0f0f0" strokeWidth={borderWidth} />
                    <circle cx={circleSize / 2} cy={circleSize / 2} r={radius} fill="none" stroke="#ff0000" strokeWidth={borderWidth} strokeDasharray={circumference} strokeDashoffset={circumference - progressInadimplentesLength} strokeLinecap="round" />
                  </svg>
                  <h6 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1' }}>{progressInadimplentes}</h6>
                </div>
                <p style={{ width: 'max-content' }}>{progressInadimplentesValor}</p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={6} lg={5}>
          <Card className="mb-3" style={{ marginTop: '22px', height: '89%', marginBottom: '0px !important', marginRight: '22px', display: 'flex', borderRadius: '10px', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)' }}>
            <div style={{ marginTop: '15px' }}>
              <p style={{ fontSize: '13px', fontWeight: 'bold', marginBottom: '10px' }}>Resumo Geral</p>
            </div>
            <Row style={{ display: 'flex', justifyContent: 'space-between', padding: '8px', paddingTop: '0px', paddingBottom: '0px' }}>
              <Row>
                <Col style={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px', marginBottom: '13px', padding: '10px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                  <h3>{emissoesPeriodo}</h3>
                  <p style={{ color: '#e8550f', fontSize: '11px', fontWeight: 'bold', marginBottom: '10px' }}>Emissões ocorridas por periodo</p>
                </Col>
                <div style={{ width: '1px', borderRight: '1px solid rgb(73 73 73 / 17%)' }}></div>
                <Col style={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px', marginBottom: '13px', padding: '10px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                  <h3>{totalInadimplentes}</h3>
                  <p style={{ color: '#e8550f', fontSize: '11px', fontWeight: 'bold', marginBottom: '10px' }}>Cobranças Inadimplentes</p>
                </Col>
              </Row>
              <hr style={{ width: '95%', margin: '10px' }} />
              <Row>
                <Col style={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px', marginBottom: '13px', padding: '10px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                  <h3>{qtdTotal}</h3>
                  <p style={{ color: '#e8550f', fontSize: '11px', fontWeight: 'bold', marginBottom: '10px' }}>Quantidade total</p>
                </Col>
                <div style={{ width: '1px', borderRight: '1px solid rgb(73 73 73 / 17%)' }}></div>
                <Col style={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px', marginBottom: '13px', padding: '10px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                  <h3>{totalVendas}</h3>
                  <p style={{ color: '#e8550f', fontSize: '11px', fontWeight: 'bold', marginBottom: '10px' }}>Total de vendas</p>
                </Col>
              </Row>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row className="body-content-dash">
        <Col md={6} lg={6}>
          <div className="mb-3" style={{ marginTop: '8px', marginLeft: '20px', borderRadius: '10px', paddingBottom: '12px', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)' }}>
            <Col md="12">
              <DataTable value={nodesConfirmacoes} header={headerConfirmacoes} tableStyle={{ width: '100%', display: 'revert-layer' }}>
                {titles && titles.map((col: any) => (
                  <Column style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }} key={col.dataField} field={col.dataField} header={col.heading} />
                ))}
              </DataTable>
            </Col>
          </div>
        </Col>
        <Col md={6} lg={6}>
          <div className="mb-3" style={{ marginTop: '8px', marginRight: '20px', borderRadius: '10px', paddingBottom: '12px', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)' }}>
            <Col md="12">
              <DataTable value={nodesInadimplentes} header={headerInadimplentes} tableStyle={{ width: '100%', display: 'revert-layer' }}>
                {titles && titles.map((col: any) => (
                  <Column style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }} key={col.dataField} field={col.dataField} header={col.heading} />
                ))}
              </DataTable>
            </Col>
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default Pessoas;