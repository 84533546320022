import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import { useSidebarContext } from '../../../contexts/SidebarContext';
import { Col, Form, NavLink } from 'react-bootstrap';
import 'react-autocomplete-input/dist/bundle.css';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import api from '../../../services/api';
import FileImportForm from './services/FileImportForm';
import { AlertService } from '../../../components/AlertService';

function Importador() {
  const { isVisible, updateVisibility } = useSidebarContext();
  const { showSubSidebar } = useAppContext();
  const toggleVisibility = () => {
    const newValue = !isVisible;
    updateVisibility(newValue);
  };
  async function processFile(body: any | null): Promise<void> {
    let response = await api.post(`${url}/api/v1/admin/core/import-arquivo`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.status === 200) {
      AlertService.sucess('Importado', 'Arquivo importado com sucesso!').then((e) => {
        if (e.isConfirmed) {
          localStorage.removeItem('formData');
          window.location.reload();
        }
      });
    }
  };

  return (
    <div className="body-content">
      <div
        className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}
      >
        <div className="contentSearch" style={{ marginLeft: '22px', marginBottom: '22px', marginRight: '22px' }}>
          <div className="contentSearch-header">
            <NavLink onClick={toggleVisibility}>
              <img
                className="menu-context"
                src={iconMenuTresPonto}
              />
            </NavLink>
            <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Importador</Col>
          </div>
          <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
          <Form.Group className="imovel-form" style={{ margin: '22px' }}>
            <FileImportForm onFileUpload={processFile} />
          </Form.Group>
        </div>
      </div>
    </div>
  );
}

export default Importador;