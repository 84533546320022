import iconMenuTresPonto from '../../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';
import 'react-autocomplete-input/dist/bundle.css';
import { useEffect, useState } from 'react';
import { useSidebarContext } from '../../../../contexts/SidebarContext';
import { Row, Col, Form, NavLink, Modal, Container, InputGroup } from 'react-bootstrap';
import iconFiltroAvancado from '../../../../../assets/images/icon-filtro-avancado.png';
import { url } from '../../../../environments/environments-develop';
import api from '../../../../services/api';
import { AlertService } from '../../../../components/AlertService';
import FileUploaderWithModal from '../../../../services/FileUploaderForm';
interface ContextSearchProps {
  header?: string;
  filtrosData: {
    identificador1?: string;
    identificador2?: string;
    nome_corretor?: string;
    corretor_id?: number;
    logradouro?: string;
    observacao?: string;
    construcao?: boolean;
    tipo_imovel_id?: string;
    sub_tipo_imovel_id?: string;
    status?: number;
    campo_busca?: string;
  };
  onInputClearList: () => void;
  onInputChangeList: (field: string, value: any) => void;
  onInputChange: (field: string, value: any) => void;
}

function ContentSearchImovel(props: ContextSearchProps) {
  const { identificador1, identificador2, campo_busca, nome_corretor, corretor_id, logradouro, observacao, construcao, tipo_imovel_id, sub_tipo_imovel_id, status } = props.filtrosData || {};
  const [showModal, setShowModal] = useState(false);
  const { isVisible, updateVisibility } = useSidebarContext();
  const [subTipoImovel, setSubTipoImovel] = useState<any>([]);
  const [tipoImovel, setTipoImovel] = useState<any>([]);
  const [FilteredCorretores, setFilteredCorretores] = useState<any>(null);
  const [show, setShow] = useState(false);
  const [bodyRequest, setBodyRequest] = useState<any>({});

  async function search(event: any) {
    let _FilteredPessoa: any;

    if (event.query) {
      try {
        _FilteredPessoa = await api.get(`${url}/api/v1/admin/vendas/corretores/buscarCorrretoresList/${event.query.toLowerCase()}`);
      } catch (error) {
        console.error('Erro ao buscar corretores:', error);
        _FilteredPessoa = [];
      }
    } else {
      _FilteredPessoa = [];
    }
    setFilteredCorretores(_FilteredPessoa ? _FilteredPessoa?.data?.data : []);
  }

  const handleChangeCliente = (field: string, value: any) => {
    handleChange('nome_corretor', value.nome);
    handleChange(field, value.id);
  };

  const toggleVisibility = () => {
    const newValue = !isVisible;
    updateVisibility(newValue);
  };
  const handleChange = (field: string, value: any) => {
    props.onInputChange(field, value);
  };
  const handleChangeList = (field: string, value: any) => {
    props.onInputChangeList(field, value);
  };
  const handleClearList = () => {
    setShowModal(false);
    props.onInputClearList();
  };

  const handleFilter = () => {
    setShowModal(false);
    if (identificador1) {
      handleChangeList('identificador1', identificador1);
    }
    if (identificador2) {
      handleChangeList('identificador2', identificador2);
    }
    if (corretor_id) {
      handleChangeList('corretor_id', corretor_id);
    }
    if (logradouro) {
      handleChangeList('logradouro', logradouro);
    }
    if (observacao) {
      handleChangeList('observacao', observacao);
    }
    if (construcao) {
      handleChangeList('construcao', construcao);
    }
    if (tipo_imovel_id) {
      handleChangeList('tipo_imovel_id', tipo_imovel_id);
    }
    if (sub_tipo_imovel_id) {
      handleChangeList('sub_tipo_imovel_id', sub_tipo_imovel_id);
    }
    if (status) {
      handleChangeList('status', status);
    }
    []
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [responseTiposImovel, responseSubtiposImovel] = await Promise.all([
          api.get(`${url}/api/v1/admin/vendas/tipoimovel`),
          api.get(`${url}/api/v1/admin/vendas/subtipoimovel`)
        ]);
        if (responseTiposImovel.status === 200) {
          setTipoImovel(responseTiposImovel.data.data);
        }
        if (responseSubtiposImovel.status === 200) {
          setSubTipoImovel(responseSubtiposImovel.data.data);
        }
      } catch (error) {
        AlertService.error('Erro!', String('Erro desconhecido'));
      }
    };
    fetchData();
  }, []);
  const processFile = (data: any | null) => {
    setBodyRequest(data)
  };
  const handleClose = () => {
    setShow(false)
    window.location.reload();
  };
  const handleShow = () => setShow(true);
  async function handleSaveButton(): Promise<void> {
    try {
      const response = await api.post(`${url}/api/v1/admin/vendas/import-imovel`, bodyRequest);
      localStorage.removeItem("excelProcessado");
      if (response.status === 200) {
        AlertService.sucess('Sucesso!', 'Salvo com sucesso!').then(() => {
          setShow(false)
          window.location.reload();
        });
      }
    } catch (error: any) {
      AlertService.infomessage('Erro!', String(error.message)).then(() => {
        setShow(false)
        window.location.reload();
      });
    }
  }
  return (
    <div className="contentSearch">
      <div className="contentSearch-header">
        <NavLink onClick={toggleVisibility}>
          <img
            style={{ width: '23px' }}
            src={iconMenuTresPonto}
          />
        </NavLink>
        <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>{props.header}</Col>
        <>
          <a type='button' onClick={handleShow} >
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path opacity="0.7" d="M0 0H32V32H0V0Z" fill="url(#pattern0)" />
              <defs>
                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                  <use xlinkHref="#image0_260_103" transform="scale(0.0104167)" />
                </pattern>
                <image id="image0_260_103" width="96" height="96" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAElElEQVR4nO2cS4gdRRSGv7wmJmYiGomKgrqSmAQXLoxLI0giiBpUFJEgIogSiSgSoosGN6IgiRglGwWXISIuxJUiYhaiC1+5BkUhPkMETVTwgeZIQw00h5q53XdudZ2qrh9q09Wnq+v/qrvrdS8UFRUVFRUVFRUVFRUtXpuB/cDnwB+AGEtVrpBXAi8C/xkwWYYGoTb/HQPGylAhvGTAUBkqhM2JvHYkVwj7DZgoi0xPk7COGjBQhvwk/G7APBnykzCuUrEluUPIDYCkBiFHAJIShFwBSCoQcgYgKUDIHYBYhzAEAGIZwlAAiFUIQwIgFiEMDYBYgzBEAGIJwlABiBUIQwYgGJD1G5QCoAAIqvIERJZ1ANnXz/wN5l4/8zeYe/3M32Dq9bO+11Mip2BKaa+n5AYgxb2ekhOAVPd6Sg4AUt/rKakDyGGvp6QMIJe9npIqgJz2ekqKAMYVeLcn5g3Pea97ztvZogIyYap7bbcBFwPLgHXAFuAZ4NecAHwJLFcxN3jOu16dswL4OhCAR8fU6VLg01wA1OleFbMEONbIP+qONXV/ywpIx/RKy3pdDpzOBcA3wIyK29XIf9AzsDseCMAGFX8T8BZwCNik8p7NBUCdHlBxa4HfXCubVXkPdaiAdEi/qNjLgH8a+V+o/GumbP5oCn5PbMB3wFkq9gDwvDq2CvghEIBvVey1nnPWN/LPmbL5F0zJ84kNeFjFXglcoY490vERlg7pb9UhmHGzttNs5b2a39WAn4DVC1zrbOBEQAAC3Kji6xb/ZqrmT2LAYwtc6/EW8Yst/2P3kcfzvn8NOJOS+ZMYcNK1dK01wM89ABDX4s+dpz7bge9TMX8SA75ygyxaDrxCABDgR+B2z/hj7rX0WQrmT2LAPQtca2ePAMSlT4AdHhD1QOyUdfOZ4ObqeZf5tMz1xfsEII25oQvVtZ+ybn5XA+7wfPiuVsfuDAjgPeBgI+nR9rvq2ldZN5+Oj/pSFfuqZ25m6ZiJsMUAeELFvqzyz7iB4JxmrZtPh8rf7PnQ/QX8CZyv8m4JBOCQir3VM3Pb1CXWzadlxT/0fOT2NvL3qLwlLqYNgHFqxtaLR+epcnYDR4DDnsm4u6yb3xbANhWz3M3LzOUf93yctwcAIG7+qY3qe/zIuvm0MP99T8wOz3n160DrSAAAAjw5T/9/Tivdt8m8+W0AXOeJedtzXn1Ma2sgAAJ8ANzn1gdmXdro1ilGqZg/tEX5Y8BFHbyp+vhh3lC2pYw6tvyqr78322fAHDHY8vU1gj0JddftXwMmicGWr1OwJ+GAAaPEuPlBIczM07NJOY0CmB8cwguZvI5GAc0PCgHXl37OLWik2EUd9WB+cAgLadxNpaQqxT/wyAVANcWnrlcIOQCoArz6eoOQOoAq4PenFwgpA6gCmt8bhFQBVD2Y3wuEFAFUPZofHEJqAKoI5geFEHtwFlqxyze/fhBascs3v34QWrHLN79+EFqxyze/fhBascs3v34QWrHLN79+EFqxyze/fhBascsfvKQAiKsCILIKgMgqACKrAIisAiCyCgAyBvA/T9re9EmJb+kAAAAASUVORK5CYII=" />
              </defs>
            </svg>
          </a>

          <Modal
            show={show}
            onHide={handleClose}
            style={{ width: '1200px !important' }}
            backdrop="static"
            keyboard={false}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>Importação de Memorial Descritivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FileUploaderWithModal onFileUpload={processFile} />
            </Modal.Body>
            <Modal.Footer>
              <Button severity="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button className="button-save-or-update" onClick={() => handleSaveButton()} severity="success">Confirmar e Importar</Button>
            </Modal.Footer>
          </Modal>
        </>
      </div>
      <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
      <div className="contentSearch-body">
        <div className='div-calendar'>
          <Col md="3" style={{ marginRight: "8px" }}>
            <Form.Label>Quadra</Form.Label>
            <Form.Control
              value={identificador1 ? identificador1 : ''}
              style={{ marginRight: '12px' }}
              onChange={(e) => handleChange("identificador1", e.target.value)}
            />
          </Col>
          <Col md="3" style={{ marginRight: "8px" }}>
            <Form.Label>Lote</Form.Label>
            <Form.Control
              value={identificador2 ? identificador2 : ''}
              onChange={(e) => handleChange("identificador2", e.target.value)}
            />
          </Col>
        </div>
        <div className='div-filtros-avancados' style={{ marginTop: '35px' }}>
          <Button
            style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
            type="button"
            rounded outlined severity="secondary"
            onClick={() => { setShowModal(true) }}>
            <img style={{ width: '25px', height: '35px' }} src={iconFiltroAvancado} />
          </Button>
          <Button
            onClick={handleFilter}
            style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
            type="button"
            severity="success"
            icon="pi pi-filter"
            rounded>
            <p style={{ marginLeft: '5px', marginTop: '15px' }}>Pesquisar</p>
          </Button>
          <Button
            onClick={handleClearList}
            style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
            type="button"
            severity="danger"
            icon="pi pi-delete-left"
            rounded>
            <p style={{ marginLeft: '5px', marginTop: '15px' }}>Limpar</p>
          </Button>
        </div>
      </div>
      <Modal
        show={showModal}
        size='lg'
        onHide={() => setShowModal(false)}
        className='modal-filtro-avancado'
      >
        <Modal.Header closeButton>
          <Modal.Title>Filtros Avançados</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={6} md={4}>
                <Form.Label>Corretor</Form.Label>
                <AutoComplete
                  placeholder='Pesquisar nome/cpf/cnpj'
                  field="nome_corretor"
                  value={nome_corretor ? nome_corretor : campo_busca}
                  suggestions={FilteredCorretores}
                  completeMethod={search}
                  onChange={(e) =>
                    handleChange('campo_busca', e.value)}
                  onSelect={(e) =>
                    handleChangeCliente('corretor_id', e.value)}
                />
              </Col>
              <Col xs={6} md={4}>
                <Form.Label>Logradouro</Form.Label>
                <Form.Control
                  value={logradouro ? logradouro : ''}
                  onChange={(e) => handleChange("logradouro", e.target.value)}
                />
              </Col>
              <Col xs={6} md={4}>
                <Form.Label>Observação</Form.Label>
                <Form.Control
                  value={observacao ? observacao : ''}
                  onChange={(e) => handleChange("observacao", e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={4}>
                <Form.Label>Tipo Imóvel</Form.Label>
                <Form.Select value={tipo_imovel_id || ''} onChange={(e) => handleChange('tipo_imovel_id', e.target.value)}>
                  <option>Selecione...</option>
                  {tipoImovel.map((tipoimovel: any) => (
                    <option value={tipoimovel.id}>{tipoimovel.nome}</option>
                  ))}
                </Form.Select>
              </Col>
              <Col xs={6} md={4}>
                <Form.Label>SubTipo Imóvel</Form.Label>
                <Form.Select value={sub_tipo_imovel_id || ''} onChange={(e) => handleChange('sub_tipo_imovel_id', e.target.value)}>
                  <option>Selecione...</option>
                  {subTipoImovel.map((subtipo: any) => (
                    <option value={subtipo.id}>{subtipo.nome}</option>
                  ))}
                </Form.Select>
              </Col>
              <Col xs={6} md={4}>
                <Form.Label>Status</Form.Label>
                <Form.Select value={status || ''} onChange={(e) => handleChange('status', e.target.value)}>
                  <option>Selecione...</option>
                  <option value={"1"}>Disponível</option>
                  <option value={"2"}>Reservado</option>
                  <option value={"3"}>Reserva Fixa</option>
                  <option value={"4"}>Negociado</option>
                  <option value={"5"}>Vendido</option>
                  <option value={"6"}>Reserva Técnica</option>
                  <option value={"7"}>Reserva Diretoria</option>
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={4}>
                <Form.Label>Construção</Form.Label>
                <Form.Check
                  style={{ display: "flex" }}
                  checked={construcao || false}
                  onChange={(e) => handleChange("construcao", String(e.target.checked))}
                  type="switch"
                  id="custom-switch"
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleFilter}

            style={{ marginTop: '3px', width: '100px', height: '30px', borderRadius: '5px', padding: '5px 10px', marginRight: '5px', fontSize: '14px', marginLeft: '10px' }}
            type="button"
            severity="success"
            icon="pi pi-filter"
            rounded>
            <p style={{ marginLeft: '5px', marginTop: '15px' }}>Pesquisar</p>
          </Button>
          <Button
            onClick={handleClearList}
            style={{ marginTop: '3px', width: '100px', height: '30px', borderRadius: '5px', padding: '5px 10px', marginRight: '5px ', fontSize: '14px' }}
            type="button"
            severity="danger"
            icon="pi pi-delete-left"
            rounded>
            <p style={{ marginLeft: '5px', marginTop: '15px' }}>Limpar</p>
          </Button>
        </Modal.Footer>
      </Modal>
    </div >

  );
};

export default ContentSearchImovel;