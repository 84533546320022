import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import TokenService from '../../../services/Auth/token.service';
import ProgressBarForm from '../../Formularios/progressBarForm';
import InumadoForm from './Forms/InumadoForm';
import CaracteristicaForm from './Forms/CaracteristicaForm';
import InformacoesForm from './Forms/InfoForm';
import DeclaranteForm from './Forms/DeclaranteForm';
import { AlertService } from '../../../components/AlertService';

function EditInumados() {
    const { setBody, setUrl, setTypeRequest, setShowButtonSave } = useAppContext();
    const [currentPart, setCurrentPart] = useState(1);
    const totalParts = 4;
    const [steps, setSteps] = useState<string[]>(["Inumado", "Características", "Informações", "Declarante"]);
    const [formBasico, setFormBasico] = useState<any | null>({});

    useEffect(() => {
        try {
            const storedData = localStorage.getItem('dadosEdicao');
            if (storedData) {
                const parsedData = JSON.parse(storedData);
                setFormBasico({
                    ...parsedData,
                    pessoa: {
                        ...parsedData.pessoa,
                    },
                    cliente_id: parsedData.cliente?.id,
                    empreendimento_id: TokenService.getEmpreendimentoId()?.id,
                });
                setTypeRequest('PUT');
                setUrl(`${url}/api/v1/admin/cemiterio/inumado/${parsedData.id}`);
                setShowButtonSave(true);
            }
        } catch (error) {
            AlertService.error("Error", String(error));
        }
    }, []);

    useEffect(() => {
        setBody(formBasico);
    }, [formBasico]);

    const handleBack = () => {
        if (currentPart > 1) {
            setCurrentPart((prevPart) => prevPart - 1);
        }
    };

    const handleNext = () => {
        if (currentPart < totalParts) {
            setCurrentPart((prevPart) => prevPart + 1);
        }
    };

    const handleChange = (field: string, value: any) => {
        if (field.startsWith('pessoa.')) {
            const pessoaField = field.replace('pessoa.', '');
            setFormBasico((prevForm: any) => ({
                ...prevForm,
                pessoa: {
                    ...prevForm.pessoa,
                    [pessoaField]: value,
                },
            }));
        } else {
            setFormBasico((prevForm: any) => ({
                ...prevForm,
                [field]: value,
            }));
        }
    };

    const handleChangeNaturalidade = (field: string, value: any) => {
        setFormBasico((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                pessoa: {
                    ...prevGrupoData.pessoa,
                    ['naturalidade_id']: value?.id ? value?.id : value,
                    ['naturalidade_label']: value?.nome ? value?.nome : value,
                },
            };
        });
    };

    const handleChangeProfissao = (field: string, value: any) => {
        setFormBasico((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                ['profissao_id']: value?.id ? value?.id : undefined,
                profissao: {
                    ...prevGrupoData.profissao,
                    [field]: value.descricao ? value.descricao : value
                }
            };
        });
    };

    const handleChangeReligiao = (field: string, value: any) => {
        setFormBasico((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                ['religiao_id']: value?.id ? value?.id : undefined,
                religiao: {
                    ...prevGrupoData.religiao,
                    [field]: value.descricao ? value.descricao : value
                }
            };
        });
    };

    const handleChangeCliente = (field: string, value: any) => {
        setFormBasico((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                ['cliente_id']: value?.id ? value?.id : undefined,
                cliente: {
                    ...prevGrupoData.cliente,
                    [field]: value.nome ? value.nome : value
                }
            };
        });
    };

    return (
        <div className="body-content">
            <div className="form-user-create">
                <Container>
                    <ProgressBarForm
                        currentPart={currentPart}
                        totalParts={totalParts}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        steps={steps}
                        body={formBasico}
                    >
                        {currentPart === 1 && (
                            <InumadoForm
                                inumado={formBasico}
                                onInputChange={(field: string, value: any) => handleChange(field, value)}
                            />
                        )}
                        {currentPart === 2 && (
                            <CaracteristicaForm
                                caracteristicaData={formBasico}
                                onInputChange={(field: string, value: any) => handleChange(field, value)}
                                onInputChangeNaturalidade={(field: string, value: any) => handleChangeNaturalidade(field, value)}
                                onInputChangeProfissao={(field: string, value: any) => handleChangeProfissao(field, value)}
                                onInputChangeReligiao={(field: string, value: any) => handleChangeReligiao(field, value)}
                                onInputChangeCliente={(field: string, value: any) => handleChangeCliente(field, value)}
                            />
                        )}
                        {currentPart === 3 && (
                            <InformacoesForm
                                infoData={formBasico}
                                onInputChange={(field: string, value: any) => handleChange(field, value)}
                            />
                        )}
                        {currentPart === 4 && (
                            <DeclaranteForm
                                declaranteData={formBasico}
                                onInputChange={(field: string, value: any) => handleChange(field, value)}
                            />
                        )}
                    </ProgressBarForm>
                </Container>
            </div>
        </div>
    );
}

export default EditInumados;
