import React, { useState, useEffect, useRef } from 'react';
import { Column } from 'primereact/column';
import { MDBCol, MDBPageItem, MDBPageNav, MDBPagination, MDBRow } from 'mdbreact';
import { DataTable } from 'primereact/datatable';
import { useAppContext } from '../../../../contexts/AppContext';
import { Toast } from 'primereact/toast';
import { fetchData } from './GestaoContratosServices';
import ModalReativarContrato from './ModalReativarContrato';
import ModalConfirmarReativarContrato from './ModalConfirmarReativarContrato';
import Legenda from './Legenda';
import { actionTemplate } from './actionTemplate';
import { togglerStatus } from './TogglerStatus';
import Paginacao from './Paginacao';
import ModalConfirmarCancelarContrato from './ModalConfirmarCancelarContrato';
import ModalCancelarContrato from './ModalCancelarContrato';
import ModalEmitirContrato from './ModalEmitirContrato';
import ModalAnexo from './ModalAnexo';
import { Skeleton } from 'primereact/skeleton';

interface TableComponentsContratoArvoreListProps {
    urlSearch: string;
    urlSearchDataPut: string;
    url_redirect_edit: string;
    urlRedirectCreated: string;
}

const TableComponentsContrato: React.FC<TableComponentsContratoArvoreListProps> = ({ urlRedirectCreated, urlSearch, urlSearchDataPut, url_redirect_edit }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [nodes, setNodes] = useState<any | null>(null);
    const [titles, setTitles] = useState<any | null>(null);
    const [totalPages, setTotalPages] = useState(0);
    const { setUrl_button_create } = useAppContext();
    const [formCancelamento, setFormCancelamento] = useState<any>({});
    const [formEmissao, setFormEmissao] = useState<any>({});
    const [selectedNodeKeys, setSelectedNodeKeys] = useState<any>(null);
    const [showModalCancelarContrato, setShowModalCancelarContrato] = useState<boolean>(false);
    const [showConfirmarModalCancelarContrato, setShowConfirmarModalCancelarContrato] = useState<boolean>(false);
    const [showConfirmarModalReativarContrato, setShowConfirmarModalReativarContrato] = useState<boolean>(false);
    const [showModalReativarContrato, setShowModalReativarContrato] = useState<boolean>(false);
    const [showModalEmitirContrato, setShowModalEmitirContrato] = useState(false);
    const [dataCancelamento, setDataCancelamento] = useState<Date | null>(null);
    const [dataPrimeiraParcelaPaga, setDataPrimeiraParcelaPaga] = useState<Date | null>(null);
    const [showModalAnexo, setShowModalAnexo] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const toast = useRef<any>(null);
    const [dados, setDados] = useState({});
    useEffect(() => {
        setUrl_button_create(urlRedirectCreated);
    }, [])
    useEffect(() => {
        fetchData(urlSearch, setLoading, currentPage, setTitles, setTotalPages, setNodes);
    }, [currentPage, urlSearch]);

    const handleData = (newData: any) => {
        setDados(newData);
    };

    const handleChange = (field: string, value: any) => {
        setFormCancelamento((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    };

    const handleChangeEmitirContrato = (field: string, value: any) => {
        setFormEmissao((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    };
    return (
        <div className="card">
            <Toast ref={toast} />
            <Legenda />
            <DataTable
                stripedRows
                size="small"
                lazy
                value={nodes ? nodes : []}
                tableStyle={{ minWidth: '50rem' }}
                emptyMessage={<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>}
            >
                <Column
                    style={{ width: '5px' }}
                    body={(e) => togglerStatus(e)}
                    headerClassName=""
                />
                <Column
                    style={{ textAlign: 'right' }}
                    key={'id'}
                    field={'id'}
                    headerClassName="righted-header"
                    header={<div className="lefted-content">Id</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'right' }}
                    key={'numero'}
                    field={'numero'}
                    headerClassName="righted-header"
                    header={<div className="righted-content">Nº.Contrato</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'cliente_nome'}
                    field={'cliente_nome'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content">Cliente</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'data_venda_format'}
                    field={'data_venda_format'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content">Dt.Venda</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'right' }}
                    key={'unidade'}
                    field={'unidade'}
                    headerClassName="righted-header"
                    header={<div className="righted-content">Unidade</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'status_name'}
                    field={'status_name'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content">Status</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'data_ult_reajuste_format'}
                    field={'data_ult_reajuste_format'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content">Ult.Reajuste</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'prox_reajuste_format'}
                    field={'prox_reajuste_format'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content">Prox.Reajuste</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'right' }}
                    key={'qtd_parcelas'}
                    field={'qtd_parcelas'}
                    headerClassName="righted-header"
                    header={<div className="righted-content">Qtd.Parcelas</div>}
                    body={isLoading ? <Skeleton /> : null}
                />

                <Column
                    style={{ textAlign: 'right' }}
                    key={'valor_total_format'}
                    field={'valor_total_format'}
                    headerClassName="righted-header"
                    header={<div className="righted-content">Vlr.Total</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    className='menu-context-grid-contrato'
                    body={(e) => actionTemplate(
                        e,
                        urlSearchDataPut,
                        url_redirect_edit,
                        setSelectedNodeKeys,
                        (field: string, value: any) => handleChangeEmitirContrato(field, value),
                        setShowConfirmarModalCancelarContrato,
                        setShowConfirmarModalReativarContrato,
                        setShowModalEmitirContrato,
                        setShowModalAnexo
                    )}
                    header="Menu"
                    headerClassName=""
                />
            </DataTable>
            <ModalEmitirContrato
                showModalEmitirContrato={showModalEmitirContrato}
                selectedNodeKeys={selectedNodeKeys}
                formEmissao={formEmissao}
                onInputChange={(field: string, value: any) => handleChangeEmitirContrato(field, value)}
                handleShowModalEmitirContrato={setShowModalEmitirContrato}
            />

            <ModalAnexo
                showModalAnexo={showModalAnexo}
                selectedNodeKeys={selectedNodeKeys}
                handleShowModalAnexo={setShowModalAnexo}
            />

            <ModalConfirmarCancelarContrato
                showConfirmarModalCancelarContrato={showConfirmarModalCancelarContrato}
                selectedNodeKeys={selectedNodeKeys}
                handleShowConfirmarModalCancelarContrato={setShowConfirmarModalCancelarContrato}
                handleShowModalCancelarContrato={setShowModalCancelarContrato}
                handleData={handleData}
            />

            <ModalCancelarContrato
                showModalCancelarContrato={showModalCancelarContrato}
                selectedNodeKeys={selectedNodeKeys}
                dados={dados}
                formCancelamento={formCancelamento}
                dataCancelamento={dataCancelamento}
                dataPrimeiraParcelaPaga={dataPrimeiraParcelaPaga}
                handleDataPrimeiraParcelaPaga={setDataPrimeiraParcelaPaga}
                handleDataCancelamento={setDataCancelamento}
                onInputChange={(field: string, value: any) => handleChange(field, value)}
                handleShowModalCancelarContrato={setShowModalCancelarContrato}
            />

            <ModalReativarContrato
                showModalReativarContrato={showModalReativarContrato}
                handleShowModalReativarContrato={setShowModalReativarContrato}
                selectedNodeKeys={selectedNodeKeys}
                formCancelamento={formCancelamento}
                dataCancelamento={dataCancelamento}
                handleDataCancelamento={setDataCancelamento}
                dataPrimeiraParcelaPaga={dataPrimeiraParcelaPaga}
                handleDataPrimeiraParcelaPaga={setDataPrimeiraParcelaPaga}
                onInputChange={(field: string, value: any) => handleChange(field, value)}
            />

            <ModalConfirmarReativarContrato
                showConfirmarModalReativarContrato={showConfirmarModalReativarContrato}
                selectedNodeKeys={selectedNodeKeys}
                handleShowConfirmarModalReativarContrato={setShowConfirmarModalReativarContrato}
                handleShowModalReativarContrato={setShowModalReativarContrato}
            />
            <Paginacao
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </div >
    );
};

export default TableComponentsContrato;
