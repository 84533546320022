import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import PessoaForm from '../../Formularios/pessoa';
import UsuarioForm from '../../Configuracao/Usuario/Formularios/usuario';
import EnderecoForm from '../../Formularios/endereco';
import TelefoneForm from '../../Formularios/telefone';
import { showToast } from '../../../components/ToastComponent';
import { addTelefone, changeTelefone, removeTelefone } from '../../../utils/TelefoneUtils';
import { addEndereco, changeEndereco, removeEndereco } from '../../../utils/EnderecoUtils';
import { changeUsuario } from '../../../utils/UsuarioUtils';
import ProgressBarForm from '../../Formularios/progressBarForm';
import { AlertService } from '../../../components/AlertService';
import ObraForm from '../../Empreendimento/ObraEmpreendimento/Fomularios/obraForm';
import CorretoresForm from './Formularios/CorretoresForm';
import TokenService from '../../../services/Auth/token.service';

interface PropsData {
    nome?: string;
    tipo_empreendimento?: string;
    pessoa: {
        id: string,
        nome: string,
        email: string,
        cpf_cnpj: string,
        sexo: string,
        razao_social: string,
        inscricao_municipal: string,
        inscricao_estadual: string,
        filiacao_mae: string,
        filiacao_pai: string,
        rg: string,
        estado_civil: string,
        regime_uniao: string,
        data_nascimento: string,
        data_fundacao: string,
        enderecos: [],
        telefones: [],
    },
}

function EditCorretores() {
    const { setUrl, setTypeRequest, setBody } = useAppContext();
    const [currentPart, setCurrentPart] = useState(1);
    const totalParts = 4;
    const [formBasico, setFormBasico] = useState<any>({
        nome: '',
        tipo_empreendimento: '',
        pessoa: {
            id: '',
            nome: '',
            email: '',
            cpf_cnpj: '',
            sexo: '',
            razao_social: '',
            inscricao_municipal: '',
            inscricao_estadual: '',
            filiacao_mae: '',
            filiacao_pai: '',
            rg: '',
            estado_civil: '',
            regime_uniao: '',
            data_nascimento: '',
            data_fundacao: '',
            enderecos: [],
            telefones: [],
        },
    });
    useEffect(() => {
        setBody(formBasico);
    }, [formBasico]);
    useEffect(() => {
        try {
            const storedData = localStorage.getItem('dadosEdicao');
            if (storedData) {
                setFormBasico((data: PropsData) => {
                    return {
                        ...data,
                        ...JSON.parse(storedData),
                        pessoa: {
                            ...data.pessoa,
                            ...JSON.parse(storedData).pessoa,
                            enderecos: [...JSON.parse(storedData).pessoa.enderecos.data],
                            telefones: [...JSON.parse(storedData).pessoa.telefones.data],
                        },
                        ['empreendimento_id']: TokenService.getEmpreendimentoId()?.id
                    }
                });
                setTypeRequest('PUT');
                setUrl(`${url}/api/v1/admin/vendas/corretores/${storedData && JSON.parse(storedData).id}`);
            }
            return () => {
                localStorage.removeItem('dadosEdicao');
            };
        } catch (error) {
            AlertService.error("error", String(error))
        }
    }, []);

    const handleNext = () => {
        if (!formBasico) {
            showToast("error", "Os campos de Nome e CPF ou CNPJ é obrigatório!")
            return;
        }
        if (!formBasico?.pessoa?.cpf_cnpj) {
            showToast("error", "O campo CPF ou CNPJ é obrigatório!")
            return;
        }
        if (!formBasico.pessoa.nome) {
            showToast("error", "O campo Nome é obrigatório!")
            return;
        }
        if (!formBasico.pessoa.data_fundacao && formBasico?.pessoa?.cpf_cnpj.length > 14) {
            showToast("error", "O campo de Data de Fundação é obrigatório!")
            return;
        }
        if (currentPart < totalParts) {
            setCurrentPart((prevPart) => prevPart + 1);
        }
    };

    const handleBack = () => {
        if (currentPart > 1) {
            setCurrentPart((prevPart) => prevPart - 1);
        }
    };


    //usuario formulario utils + pessoa formulario utils
    const handleChange = (field: string, value: string) => {
        changeUsuario(field, value, formBasico, setFormBasico)
    };

    const handleEnderecoInputChange = (index: number, field: string, value: any) => {
        changeEndereco(formBasico, setFormBasico, index, field, value)
    };
    const handleAddEndereco = () => {
        addEndereco(formBasico, setFormBasico);
    };
    const handleRemoveEndereco = (index: number) => {
        removeEndereco(formBasico, setFormBasico, index);
    };

    // Telefone Formulario Utils
    const handleTelefoneInputChange = (index: number, field: string, value: string) => {
        changeTelefone(formBasico, setFormBasico, index, field, value);
    };
    const handleAddTelefone = () => {
        addTelefone(formBasico, setFormBasico);
    };
    const handleRemoveTelefone = (index: number) => {
        removeTelefone(formBasico, setFormBasico, index);
    };

    const saveNewPerfil = () => {
    }
    return (
        <div className="body-content">
            <div className="form-user-create">
                <Container>
                    <ProgressBarForm
                        currentPart={currentPart}
                        totalParts={totalParts}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        steps={["Pessoa", "Corretor", "Endereço", "Telefone"]}
                        body={formBasico}
                    >
                        {currentPart === 1 && (
                            <PessoaForm
                                isEnablePerfil={false}
                                tipoPessoa={2}
                                tipoAcao={"editar"}
                                pessoaData={formBasico?.pessoa}
                                onInputChange={(field: string, value: string) => handleChange(`pessoa.${field}`, value)}
                            />
                        )}
                        {currentPart === 2 && (
                            <CorretoresForm
                                obraData={formBasico}
                                onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                            />
                        )}
                        {currentPart === 3 && (
                            <EnderecoForm
                                enderecosData={formBasico?.pessoa?.enderecos || []}
                                onInputChange={(index: number, field: string, value: string) => handleEnderecoInputChange(index, field, value)}
                                onAddEndereco={handleAddEndereco}
                                onRemoveEndereco={handleRemoveEndereco}
                            />
                        )}

                        {currentPart === 4 && (
                            <TelefoneForm
                                telefonesData={formBasico?.pessoa?.telefones || []}
                                onInputChange={(index: number, field: string, value: string) => {
                                    if (formBasico?.pessoa?.telefones && formBasico?.pessoa?.telefones?.length > 0) {
                                        handleTelefoneInputChange(index, field, value);
                                    } else {
                                        handleAddTelefone();
                                    }
                                }}
                                onAddTelefone={() => handleAddTelefone()}
                                onRemoveTelefone={(index: number) => handleRemoveTelefone(index)}
                            />
                        )}
                    </ProgressBarForm>

                </Container>
            </div>
        </div>
    );
}

export default EditCorretores;
