import { Col, Form, Row } from "react-bootstrap";

function FornecedoresForm(props: any) {
    const {
        nome_representante_vendedor,
        telefone_representante,
        obs,
        site,
        status,
        portfolio_atividade,
        condicao_venda
    } = props.fornecedorData || {};

    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };

    return (
        <>
            <Form.Group className="fornecedor-form">
                <Row mb="3">
                    <Col>
                        <Form.Label>Nome do Representante</Form.Label>
                        <Form.Control
                            value={nome_representante_vendedor}
                            onChange={(e) => handleChange('nome_representante_vendedor', e.target.value)}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Telefone do Representante</Form.Label>
                        <Form.Control
                            value={telefone_representante}
                            onChange={(e) => handleChange('telefone_representante', e.target.value)}
                        />
                    </Col>
                </Row>
                <Row mb="3">
                    <Col>
                        <Form.Label>Site</Form.Label>
                        <Form.Control
                            value={site}
                            onChange={(e) => handleChange('site', e.target.value)}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Condição de Venda</Form.Label>
                        <Form.Control
                            value={condicao_venda}
                            onChange={(e) => handleChange('condicao_venda', e.target.value)}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Status</Form.Label>
                        <Form.Select
                            value={status}
                            onChange={(e) => handleChange('status', e.target.value)}
                        >
                            <option>Selecione</option>
                            <option value="0">Desativado</option>
                            <option value="1">Ativado</option>
                        </Form.Select>
                    </Col>
                </Row>
                <Row mb="3">
                    <Col>
                        <Form.Label>Portfolio de Atividade</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={portfolio_atividade}
                            onChange={(e) => handleChange('portfolio_atividade', e.target.value)}
                        />
                    </Col>
                </Row>
                <Row mb="3">
                    <Col>
                        <Form.Label>Observação</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={obs}
                            onChange={(e) => handleChange('obs', e.target.value)}
                        />
                    </Col>
                </Row>
            </Form.Group>
        </>
    );
}

export default FornecedoresForm;
