import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import PessoaForm from '../../Formularios/pessoa';
import EnderecoForm from '../../Formularios/endereco';
import TelefoneForm from '../../Formularios/telefone';
import { useUsuarioContext } from '../../../contexts/UsuarioContext';
import { addTelefone, changeTelefone, removeTelefone } from '../../../utils/TelefoneUtils';
import { addEndereco, changeEndereco, removeEndereco } from '../../../utils/EnderecoUtils';
import ProgressBarForm from '../../Formularios/progressBarForm';
import CorretoresForm from './Formularios/CorretoresForm';
import TokenService from '../../../services/Auth/token.service';
import { showToast } from '../../../components/ToastComponent';
interface PropsData {
    nome?: string,
    tipo_empreendimento?: string,
    empreendimento_id?: string,
    status?: string,
    comissao?: string,
    qtd_lt_reservado?: string,
    creci?: string,
    observacao?: string,
    pessoa: {
        id: string,
        nome: string,
        email: string,
        cpf_cnpj: string,
        sexo: string,
        razao_social: string,
        inscricao_municipal: string,
        inscricao_estadual: string,
        filiacao_mae: string,
        filiacao_pai: string,
        rg: string,
        estado_civil: string,
        regime_uniao: string,
        data_nascimento: string,
        data_fundacao: string,
        enderecos: [],
        telefones: [],
    },
}
function CreateCorretores() {
    const { setUrl, setBody, setTypeRequest } = useAppContext();
    const [currentPart, setCurrentPart] = useState(1);
    const totalParts = 4;
    const [formBasico, setFormBasico] = useState<PropsData>({
        nome: '',
        tipo_empreendimento: '',
        pessoa: {
            id: '',
            nome: '',
            email: '',
            cpf_cnpj: '',
            sexo: '',
            razao_social: '',
            inscricao_municipal: '',
            inscricao_estadual: '',
            filiacao_mae: '',
            filiacao_pai: '',
            rg: '',
            estado_civil: '',
            regime_uniao: '',
            data_nascimento: '',
            data_fundacao: '',
            enderecos: [],
            telefones: [],
        },
    });

    useEffect(() => {
        setUrl(`${url}/api/v1/admin/vendas/corretores`);
        setTypeRequest('POST')
        setFormBasico((prevImovelData) => {
            return {
                ...prevImovelData,
                ['empreendimento_id']: TokenService.getEmpreendimentoId()?.id
            };
        });
    }, []);
    useEffect(() => {
        setBody(formBasico);
    }, [formBasico]);
    const handleNext = () => {
        if (!formBasico) {
            showToast("error", "Os campos de Nome e CPF ou CNPJ é obrigatório!")
            return;
        }
        if (!formBasico?.pessoa?.cpf_cnpj) {
            showToast("error", "O campo CPF ou CNPJ é obrigatório!")
            return;
        }
        if (!formBasico.pessoa.nome) {
            showToast("error", "O campo Nome é obrigatório!")
            return;
        }
        if (!formBasico.pessoa.data_fundacao && formBasico?.pessoa?.cpf_cnpj.length > 14) {
            showToast("error", "O campo de Data de Fundação é obrigatório!")
            return;
        }
        if (formBasico.pessoa.enderecos.length === 0 && currentPart === 3) {
            showToast("error", "O corretor deve possuir ao menos um endereço!")
            return;
        }
        if (currentPart < totalParts) {
            setCurrentPart((prevPart) => prevPart + 1);
        }
    };

    const handleBack = () => {
        if (currentPart > 1) {
            setCurrentPart((prevPart) => prevPart - 1);
        }
    };


    const handleChange = (field: string, value: any) => {
        if (setFormBasico) {
            let newForm: PropsData = { ...formBasico } || {
                pessoa: { enderecos: [], telefones: [] },
                configVendas: {},
                configCarteira: {},
            };

            if (formBasico) {
                if (field.startsWith('pessoa.')) {
                    const pessoaField = field.replace('pessoa.', '');
                    setFormBasico((prevGrupoData: any) => {
                        return {
                            ...prevGrupoData,
                            pessoa: {
                                ...newForm.pessoa,
                                [pessoaField]: value,
                            },
                        };
                    });
                } else {
                    setFormBasico((prevGrupoData: any) => {
                        return {
                            ...prevGrupoData,
                            [field]: value,
                        };
                    });
                }
            }
        }
    };
    const handleChangePessoa = (field: string, value: any) => {
        if (formBasico) {
            setFormBasico((prevGrupoData: any) => {
                return {
                    ...prevGrupoData,
                    pessoa: {
                        ...prevGrupoData.pessoa,
                        [field]: value,
                    },
                };
            });
        }
    };

    const handleEnderecoInputChange = (index: number, field: string, value: any) => {
        changeEndereco(formBasico, setFormBasico, index, field, value)
    };

    const handleAddEndereco = () => {
        addEndereco(formBasico, setFormBasico);
    };
    const handleRemoveEndereco = (index: number) => {
        removeEndereco(formBasico, setFormBasico, index);
    };

    const handleTelefoneInputChange = (index: number, field: string, value: string) => {
        changeTelefone(formBasico, setFormBasico, index, field, value);
    };
    const handleAddTelefone = () => {
        addTelefone(formBasico, setFormBasico);
    };
    const handleRemoveTelefone = (index: number) => {
        removeTelefone(formBasico, setFormBasico, index);
    };

    return (
        <div className="body-content">
            <div className="form-user-create">
                <Container>
                    <ProgressBarForm
                        currentPart={currentPart}
                        totalParts={totalParts}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        steps={["Pessoa", "Corretor", "Endereço", "Telefone"]}
                        body={formBasico}
                    >
                        {currentPart === 1 && (
                            <PessoaForm
                                isEnablePerfil={false}
                                tipoPessoa={2}
                                tipoAcao={"criar"}
                                pessoaData={formBasico?.pessoa}
                                onInputChange={(field: string, value: any) => handleChangePessoa(`${field}`, value)}
                            />
                        )}
                        {currentPart === 2 && (
                            <CorretoresForm
                                obraData={formBasico}
                                onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                            />
                        )}
                        {currentPart === 3 && (
                            <EnderecoForm
                                enderecosData={formBasico?.pessoa?.enderecos || []}
                                onInputChange={(index: number, field: string, value: string) => handleEnderecoInputChange(index, field, value)}
                                onAddEndereco={handleAddEndereco}
                                onRemoveEndereco={handleRemoveEndereco}
                            />
                        )}
                        {currentPart === 4 && (
                            <TelefoneForm
                                telefonesData={formBasico?.pessoa?.telefones || []}
                                onInputChange={(index: number, field: string, value: string) => {
                                    if (formBasico?.pessoa?.telefones && formBasico?.pessoa?.telefones?.length > 0) {
                                        handleTelefoneInputChange(index, field, value);
                                    } else {
                                        handleAddTelefone();
                                    }
                                }}
                                onAddTelefone={() => handleAddTelefone()}
                                onRemoveTelefone={(index: number) => handleRemoveTelefone(index)}
                            />
                        )}
                    </ProgressBarForm>

                </Container>
            </div>
        </div>
    );
}

export default CreateCorretores;
