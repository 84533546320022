import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { AlertService } from './AlertService';
import api from '../services/api';
import ModalBaixaManualForm from './ModalBaixaManualForm';
import { url, url_financeiro } from '../environments/environments-develop';
import { abrirPDF } from '../utils/PdfUtils';
import TokenService from '../services/Auth/token.service';

interface formBasicoProps {
    lancamento_id?: string | null;
    descricao_caixa: string | null;
    justificativa: string | null;
    pagamentos: {
        conta_financeiro_id: string | null;
        valor: string | null;
        meio_pagamento: string | null;
    }[];
    data_baixa: string | null;
    tipo: string | null;
    tipo_movimentacao: number | null;
    lancamento: any;
    data_vencimento_recorrencia: string | null;
    mudar_data_recorrencia: string | null;
    empreendimento_id: any | null;
}

interface ModalBaixaManualProps {
    showModal: boolean;
    handleClose: () => void;
    lancamentSelected: any;
    typeLancamento: any;
    urlSearchDataPut: any;
}

const ModalBaixaManual: React.FC<ModalBaixaManualProps> = ({ showModal, urlSearchDataPut, handleClose, lancamentSelected, typeLancamento }) => {
    const [show, setShow] = useState(showModal);
    const [valorDocumento, setValorDocumento] = useState(showModal);
    const [saldoDocumento, setSaldoDocumento] = useState(showModal);
    const [lancamento_id, setLancamentoId] = useState(lancamentSelected);
    const [valorLancamentoAtualizado, setValorLancamentoAtualizado] = useState<any>("0,00");
    const [valorTaxasJuros, setValorTaxasJuros] = useState<any>("0,00");
    const [formBasico, setFormBasico] = useState<formBasicoProps>({
        pagamentos: [{
            conta_financeiro_id: null,
            valor: null,
            meio_pagamento: null
        }],
        lancamento_id: lancamento_id,
        descricao_caixa: null,
        justificativa: null,
        data_baixa: null,
        tipo: typeLancamento,
        lancamento: null,
        tipo_movimentacao: null,
        data_vencimento_recorrencia: null,
        mudar_data_recorrencia: null,
        empreendimento_id: TokenService.getEmpreendimentoId()?.id
    });

    async function setSessionEditData(value: any): Promise<void> {
        try {
            const includeParam = 'config_venda,config_carteira,pessoa.telefones,pessoa.enderecos,grupo_permissoes';
            const response = await api.get(`${url_financeiro}${urlSearchDataPut}${value}?include=${includeParam}`);
            if (response.status === 200) {
                setValorDocumento(response.data.valor_original)
                setSaldoDocumento(response.data.saldo)
                setFormBasico((prevGrupoData) => {
                    const dadosAtualizados = {
                        ...prevGrupoData,
                        pagamentos: [{
                            conta_financeiro_id: response.data.conta_financeiro_id,
                            valor: response.data.saldo,
                            meio_pagamento: null
                        }],
                        lancamento_id: response.data.id,
                        tipo_movimentacao: 0,
                        mudar_data_recorrencia: "false",
                        lancamento: response.data,
                        empreendimento_id: TokenService.getEmpreendimentoId()?.id
                    };
                    return dadosAtualizados;
                });
            }
        } catch (error: any) {
            let mensagens: string[] = [];
            mensagens.push(error?.response?.data?.error?.message);
            AlertService.error('Erro!', String(mensagens));
        }
    }
    useEffect(() => {
        if (lancamentSelected) {
            setSessionEditData(lancamentSelected);
        }
    }, [lancamentSelected]);

    useEffect(() => {
        setShow(showModal);
    }, [showModal]);
    console.log('formBasico', formBasico);

    const handleSaveButton = async (): Promise<void> => {
        try {
            const response = await api.post(`${url_financeiro}/api/v1/admin/financeiro/contasPagar/baixa`, formBasico);
            localStorage.removeItem("excelProcessado");
            if (response.status === 200) {
                AlertService.confirm({
                    title: 'Confirmação!',
                    text: 'Deseja emitir o recibo?',
                    cancelButtonText: 'Não',
                    confirmButtonText: 'Sim',
                }).then((e: any) => {
                    if (e.isConfirmed) {
                        EmitirRecibo().then(() => {
                            AlertService.sucess('Sucesso!', 'Recibo emitido!').then(() => {
                                setShow(false);
                                window.location.reload();
                            });
                        });
                    } else if (e.isDismissed) {
                        AlertService.sucess('Sucesso!', 'Salvo com sucesso!').then(() => {
                            setShow(false);
                            window.location.reload();
                        });
                    }
                });

            }
        } catch (error: any) {
            AlertService.infomessage('Erro!', String(error.message)).then(() => {
                setShow(false);
                window.location.reload();
            });
        }
    };
    const EmitirRecibo = async (): Promise<void> => {
        try {
            const response = await api.get(`${url_financeiro}/api/v1/admin/financeiro/lancamentofinanceiro/emitir-recibo/` + formBasico.lancamento_id, {
                responseType: 'arraybuffer',
            });
            if (response.status === 200) {
                handleOpenPDF(response.data, 1);
            }
        } catch (error: any) {
            AlertService.infomessage('Erro!', String(error.message)).then(() => {
                setShow(false);
            });
        }
    };

    const handleOpenPDF = (pdfBase64: string, listFiltros: any) => {
        abrirPDF(pdfBase64, listFiltros);
    }
    const handleChange = (field: string, value: any, paymentIndex?: number, paymentField?: string) => {
        setFormBasico((prevForm) => {
            if (paymentIndex !== undefined && paymentField) {
                const updatedPayments = [...prevForm.pagamentos];
                const updatedPayment = { ...updatedPayments[paymentIndex], [paymentField]: value };
                updatedPayments[paymentIndex] = updatedPayment;
                return {
                    ...prevForm,
                    pagamentos: updatedPayments,
                };
            }

            return {
                ...prevForm,
                [field]: value,
            };
        });
    };


    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size='xl'
        >
            <Modal.Header closeButton>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Modal.Title>Baixa Manual - {formBasico?.lancamento?.cliente_nome}</Modal.Title>
                    <p>N° Documento: {formBasico?.lancamento?.numero_documento}<br />
                        Emissão: {formBasico?.lancamento?.data_vencimento_renegociacao_format ? formBasico?.lancamento?.data_vencimento_renegociacao_format : formBasico?.lancamento?.data_emissao_format}<br />
                        Vencimento: {formBasico?.lancamento?.data_vencimento_format}</p>
                </div>
            </Modal.Header>
            <Modal.Body>
                <ModalBaixaManualForm
                    formBasico={formBasico}
                    handleChange={handleChange}
                    handleSaveButton={handleSaveButton}
                    handlePagamentoAtualizado={(value: any) => setValorLancamentoAtualizado(value)}
                    handleTaxasJuros={(value: any) => setValorTaxasJuros(value)}
                />
            </Modal.Body>
            <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#7b7b7b', padding: '10px', margin: '10px', border: '2px solid #7b7b7b', borderRadius: '4px' }}>
                <Row>
                    <Col>
                        <Form.Label style={{ marginRight: '25px', color: '#fff' }}><strong>Valor do Documento:</strong> R$ {valorDocumento}</Form.Label>
                        <Form.Label style={{ color: '#fff' }}><strong>Valor Atualizado:</strong> R$ {valorLancamentoAtualizado}</Form.Label>
                        <Form.Label style={{ marginLeft: '25px', color: '#fff' }}><strong>Taxas e Juros:</strong> R$ {valorTaxasJuros}</Form.Label>
                    </Col>
                </Row>
                <div style={{ display: 'flex' }}>
                    <Button className='button-save-or-update' style={{ marginRight: '27px' }} onClick={handleSaveButton} variant="success">Confirmar</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalBaixaManual;
