import { AlertService } from "../../../../components/AlertService";
import TokenService from "../../../../services/Auth/token.service";
import PessoaService from "../../../Pessoas/Services/PessoaService";
import ContasPagarService from "../../Services/ContasPagarService";

interface Consulta {
  order?: string;
  filtro?: any;
}
const consulta: Consulta = {
  order: "id;desc",
};

export const loadData = (
  listFiltros: any,
  currentPage: any,
  setLoading: (value: any) => void,
  setNodes: (value: any) => void,
  updateTitles: (titles: string[]) => void,
  updatePagination: (totalPages: number) => void,
  setShowModalFiltro: (value: any) => void
) => {
  setLoading(true);
  const form = {
    conta_financeiro_id: null,
    plano_conta_id: listFiltros?.plano_conta_id,
    centro_custo_id: listFiltros?.centro_custo_id,
    cliente_id: listFiltros?.cliente_id,
    tipo: 1,
    situacao_pagamento: listFiltros?.situacao_pagamento,
    tipo_parcela: listFiltros?.tipo_parcela,
    tipo_consulta: listFiltros?.tipo_consulta,
    data_inicial: listFiltros?.data_inicial,
    data_final: listFiltros?.data_final,
    justificativa: listFiltros?.justificativa,
    numero_documento: listFiltros?.numero_documento,
    numero_contrato: listFiltros.numero_contrato,
    venda_id: listFiltros.venda_id,
    nome: listFiltros?.nome,
    empreendimento_id: TokenService.getEmpreendimentoId()
      ? TokenService.getEmpreendimentoId()?.id
      : null,
  };

  const filtro = Object.fromEntries(
    Object.entries(form).filter(
      ([_, value]) => value !== null && value !== "" && value !== undefined
    )
  );

  if (consulta) {
    consulta.filtro = filtro;
  }
  ContasPagarList(
    consulta,
    currentPage,
    setLoading,
    setNodes,
    updateTitles,
    updatePagination,
    setShowModalFiltro
  );
};

export const ContasPagarList = (
  filtros: any,
  currentPage: any,
  setLoading: (value: any) => void,
  setNodes: (value: any) => void,
  updateTitles: (titles: string[]) => void,
  updatePagination: (totalPages: number) => void,
  setShowModalFiltro: any | null
) => {
  ContasPagarService.list(
    {
      include:
        "anexo,fornecedor.pessoa,plano_conta,conta_financeiro,centro_custo",
      totalitems: 8,
      consulta: JSON.stringify(filtros),
      page: currentPage + 1,
    },
    null,
    updateTitles,
    updatePagination
  ).subscribe(
    (response) => {
      setNodes(response);
      setShowModalFiltro(false);
      setLoading(false);
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const setSessionEditData = (value: any, url_redirect_edit: any) => {
  ContasPagarService.get(value, {
    include:
      "anexo,fornecedor.pessoa,plano_conta,conta_financeiro,centro_custo",
  }).subscribe(
    (response) => {
      localStorage.setItem("dadosEdicao", JSON.stringify(response));
      window.location.href = url_redirect_edit;
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const estorno = (value: any) => {
  ContasPagarService.estorno(value).subscribe(
    (response) => {
      AlertService.sucess("Sucesso", "Lançamento Estornado com Sucesso!").then(
        (e) => {
          if (e.isConfirmed) {
            window.location.reload();
          }
        }
      );
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const getStatusColorCard = (status: any) => {
  switch (status) {
    case 0:
      return "#ff9800";
    case 2:
      return "#f44336";
    case 1:
      return "#289b1e";
    case 6:
      return "#000";
    default:
      return "#289b1e";
  }
};

export const handleOpenModal = (
  row: any,
  setSelectedRow: (value: any) => void,
  setShowModal: (value: any) => void
) => {
  setSelectedRow(row);
  setShowModal(true);
};

export const handleOpenModalAniversario = (
  row: any,
  setSelectedRow: (value: any) => void,
  setShowModalAniversario: (value: any) => void
) => {
  setSelectedRow(row);
  setShowModalAniversario(true);
};
export const handleOpenModalAltaDataProgramada = (
  row: any,
  setSelectedRow: (value: any) => void,
  setShowModalAltDataProgramada: (value: any) => void
) => {
  setSelectedRow(row.id);
  setShowModalAltDataProgramada(true);
};
export const searchFornecedor = (
  event: any,
  setFilteredPessoa: (value: any) => void
) => {
  const query = event.query;
  setTimeout(() => {
    ContasPagarService.buscarFornecedorList({
      query: query,
    }).subscribe(
      (response) => {
        setFilteredPessoa(response);
      },
      (error) => {
        let mensagens: string[] = [];
        mensagens.push(error?.response?.data?.error?.message);
        AlertService.error("Erro!", String(mensagens));
      }
    );
  }, 600);
};
export const handleOpenModalAnexo = (
  row: any,
  setSelectedRow: (value: any) => void,
  setShowModalAnexo: (value: any) => void
) => {
  setSelectedRow(row.id);
  setShowModalAnexo(true);
};
export const searchPessoa = (
  event: any,
  setFilteredPessoa: (value: any) => void
) => {
  if (event.query.length > 2) {
    const query = event.query;
    setTimeout(() => {
      PessoaService.encontrar_pessoa({
        search: query,
      }).subscribe(
        (response) => {
          setFilteredPessoa(response);
        },
        (error) => {
          let mensagens: string[] = [];
          mensagens.push(error?.response?.data?.error?.message);
          AlertService.error("Erro!", String(mensagens));
        }
      );
    }, 600);
  } else {
    setFilteredPessoa([]);
  }
};
