import React, { useState, useEffect } from 'react';
import { AlertService } from './../../../../components/AlertService';
import { url } from './../../../../environments/environments-develop';
import api from './../../../../services/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Column } from 'primereact/column';
import { MDBCol, MDBPageItem, MDBPageNav, MDBPagination, MDBRow } from 'mdbreact';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/datatable';
import { useAppContext } from './../../../../contexts/AppContext';
import { actionTemplate } from './actionTemplate';
import ModalClonar from './ModalClonar';
import { Skeleton } from 'primereact/skeleton';

const TableProduto: React.FC<any> = ({ include, urlRedirectCreated, urlSearch, urlSearchDataPut, url_redirect_edit }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [nodes, setNodes] = useState<any | null>(null);
    const [titles, setTitles] = useState<any | null>(null);
    const [totalPages, setTotalPages] = useState(0);
    const { setUrl_button_create } = useAppContext();
    const [showModal, setShowModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any | null>(null);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setUrl_button_create(urlRedirectCreated);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await api.get(`${url}${urlSearch}&include=${include}&page=${currentPage + 1}`);
                if (response.status === 200) {
                    const titles = response.data.headers.titles;
                    setTitles(titles)
                    const data = response.data.data;
                    const totalPages = response.data.meta.pagination.total_pages;
                    setTotalPages(totalPages);
                    setNodes(data);
                    setLoading(false)
                }
            } catch (error: any) {
                setLoading(false);
                let mensagens: string[] = [];
                mensagens.push(error?.response?.data?.error?.message);
                AlertService.error('Erro!', String(mensagens));
            }
        };
        fetchData();
    }, [currentPage, urlSearch]);

    const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 0; i < totalPages; i++) {
            if (i < 3 || i === totalPages - 1 || (i >= currentPage - 1 && i <= currentPage + 1)) {
                pageNumbers.push(
                    <MDBPageItem key={i} active={i === currentPage}>
                        <MDBPageNav className="page-link" onClick={() => setCurrentPage(i)}>
                            <span key={i} onClick={() => setCurrentPage(i)} style={{ cursor: 'pointer', margin: '0 5px', fontWeight: i === currentPage ? 'bold' : 'normal' }}>
                                {i + 1}
                            </span>
                        </MDBPageNav>
                    </MDBPageItem>
                );
            }
        }
        return pageNumbers;
    };

    const setSessionEditData = async (value: any) => {
        try {
            const response = await api.get(`${url}${urlSearchDataPut}${value}`);
            if (response.status === 200) {
                await localStorage.setItem('dadosEdicao', JSON.stringify(response.data));
                window.location.href = url_redirect_edit;
            }
        } catch (error: any) {
            let mensagens: string[] = [];
            mensagens.push(error?.response?.data?.error?.message);
            AlertService.error('Erro!', String(mensagens));
        }
    };

    return (
        <div className="card">
            <DataTable
                stripedRows
                value={nodes ? nodes : []}
                tableStyle={{ minWidth: '50rem' }}
                emptyMessage={<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>}
            >
                <Column
                    style={{ textAlign: 'right', width: '7%' }}
                    key={'id'}
                    field={'id'}
                    headerClassName="righted-header"
                    header={<div className="righted-content">Codigo</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'descricao_resumida_label'}
                    field={'descricao_resumida_label'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content">Descrição</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'identificador'}
                    field={'identificador'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content">Identificador</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'tipo_label'}
                    field={'tipo_label'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content">Tipo Serviço</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'right' }}
                    key={'valor_format'}
                    field={'valor_format'}
                    headerClassName="righted-header"
                    header={<div className="righted-content">Valor</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'status_label'}
                    field={'status_label'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content">Status</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'acessorios_label'}
                    field={'acessorios_label'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content">Acessorios</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'n_serie'}
                    field={'n_serie'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content">N° Série</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'patrimonio'}
                    field={'patrimonio'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content">Patrimônio</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    className='menu-context-grid-contrato'
                    body={(e) => actionTemplate(e
                        , setSessionEditData
                        , setShowModal
                        , setSelectedRow
                    )}
                    header="Menu"
                    style={{ width: '9%', textAlign: 'center' }}
                />
            </DataTable>
            <ModalClonar
                data={selectedRow}
                showModal={showModal}
                onHide={() => setShowModal(false)}
                onInputChange={() => setCurrentPage(currentPage)}
            />
            <div className="pagination-footer">
                <MDBRow>
                    <MDBCol>
                        <MDBPagination circle>
                            <MDBPageItem disabled={currentPage === 0}>
                                <MDBPageNav className="page-link" aria-label="Previous" onClick={() => setCurrentPage(currentPage - 1)}>
                                    <span aria-hidden="true">&laquo;</span>
                                </MDBPageNav>
                            </MDBPageItem>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {renderPageNumbers()}
                            </div>
                            <MDBPageItem disabled={currentPage === totalPages - 1}>
                                <MDBPageNav className="page-link" aria-label="Next" onClick={() => setCurrentPage(currentPage + 1)}>
                                    <span aria-hidden="true">&raquo;</span>
                                </MDBPageNav>
                            </MDBPageItem>
                        </MDBPagination>
                    </MDBCol>
                </MDBRow>
            </div>
        </div>
    );
};

export default TableProduto;
