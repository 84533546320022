import { useAppContext } from '../../../contexts/AppContext';
import TableComponentsImovel from "./Components/TableComponentsImovel";
import { useEffect, useState } from 'react';
import { AlertService } from '../../../components/AlertService';
import TokenService from '../../../services/Auth/token.service';
import ContentSearchImovel from './Components/ContentSearchImovel';

function Imoveis() {
  const { showSubSidebar } = useAppContext();
  const [listFiltros, setListFiltros] = useState<any>({});
  const [filtros, setFiltros] = useState<string>();
  const [formBasico, setFormBasico] = useState<any>({});

  async function montarFiltro(): Promise<void> {
    try {
      const consulta: any = {
        consulta: {
          order: "id;desc",
          filtro: {
            "vendas.imoveis.identificador1": listFiltros ? listFiltros?.identificador1 : null,
            "vendas.imoveis.identificador2": listFiltros ? listFiltros?.identificador2 : null,
            "vendas.imoveis.corretor_id": listFiltros ? listFiltros?.corretor_id : null,
            "vendas.imoveis.logradouro": listFiltros ? listFiltros?.logradouro : null,
            "vendas.imoveis.observacao": listFiltros ? listFiltros?.observacao : null,
            "vendas.imoveis.construcao": listFiltros ? listFiltros?.construcao : null,
            "vendas.imoveis.tipo_imovel_id": listFiltros ? listFiltros?.tipo_imovel_id : null,
            "vendas.imoveis.sub_tipo_imovel_id": listFiltros ? listFiltros?.sub_tipo_imovel_id : null,
            "vendas.imoveis.status": listFiltros ? listFiltros?.status : null,
            "vendas.imoveis.empreendimento_id": TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().id : null
          }
        }
      };

      const queryParams: any = {};

      const camposEnviar = ['consulta'];

      camposEnviar.forEach((key: any) => {
        const value = consulta[key];
        if (value !== undefined && value !== null && value !== '') {
          if (key === 'consulta') {
            // Converte o objeto consulta em uma string JSON e codifica para URI
            queryParams[key] = JSON.stringify(value);
          } else {
            queryParams[key] = value;
          }
        }
      });

      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
        .join('&');

      setFiltros(queryString);
    } catch (error: any) {
      AlertService.error('Erro!', String(error.response?.data?.message) || 'Erro desconhecido');
    }
  }

  useEffect(() => {
    montarFiltro();
  }, [listFiltros]);
  const handleChange = (field: string, value: string) => {
    setFormBasico((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };

  const handleChangeList = (field: string, value: string) => {
    setListFiltros((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };
  const handleClearList = () => {
    setListFiltros(null);
    setFormBasico(null);
    montarFiltro();
  };
  return (
    <div className="body-content">
      <div
        className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}
      >
        <ContentSearchImovel
          onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
          onInputChangeList={(field: string, value: string) => handleChangeList(`${field}`, value)}
          onInputClearList={() => handleClearList()}
          filtrosData={formBasico}
          header={"Imóveis"}
        />
      </div>
      <div
        className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}
      >
        <TableComponentsImovel
          key={filtros}
          urlRedirectCreated="/imovel/store"
          urlSearch={`/api/v1/admin/vendas/imovel`}
          filtros={filtros}
          urlSearchDataPut="/api/v1/admin/vendas/imovel/"
          url_redirect_edit="/imovel/update"
        />
      </div>
    </div>
  );
}

export default Imoveis;
