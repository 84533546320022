import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../../contexts/AppContext';
import { url } from '../../../../environments/environments-develop';
import { AlertService } from '../../../../components/AlertService';
import FormJazigo from './forms';

interface PropsData {
    identificador1?: string;
    identificador2?: string;
    tipo_jazigo_id?: number;
    tipo_imovel_id?: number;
    sub_tipo_imovel_id?: number;
    logradouro?: string;
    tipo_particular_coletivo?: number;
    imo_preco?: number;
    construcao?: boolean;
}
function EditJazigo() {
    const { setUrl, setBody, setShowButtonSave, setTypeRequest, typeRequest } = useAppContext();
    const [Data, setData] = useState<PropsData>({});

    useEffect(() => {
        try {
            const storedData = localStorage.getItem('dadosEdicao');
            if (storedData) {
                setData((prevGrupoData) => {
                    const dadosAtualizados = {
                        ...prevGrupoData,
                        ...JSON.parse(storedData),
                    };
                    return dadosAtualizados;
                });
                setTypeRequest('PUT');
                setUrl(`${url}/api/v1/admin/cemiterio/jazigo/${storedData && JSON.parse(storedData).id}`);
                setShowButtonSave(true);
            }
            return () => {
                localStorage.removeItem('dadosEdicao');
            };
        } catch (error) {
            AlertService.error("error", String(error))
        }
    }, []);

    useEffect(() => {
        setBody(Data)
    }, [Data]);

    const handleChange = (field: string, value: string) => {
        setData((prevGrupoData) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
        setTypeRequest('PUT');
    }
    return (
        <>
            <div className="body-content">
                <div className="form-create-group">
                    <div className="form-create-group-header">
                        <h3>Alterar Jazigo</h3>
                    </div>
                </div>
                <div className="form-group-create">
                    <Container>
                        <FormJazigo
                            initialRightValues={Data}
                            Data={Data}
                            onInputChange={(field: string, value: any) => handleChange(field, value)}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default EditJazigo;
