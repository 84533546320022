import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Button as BootstrapButton, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { Button as PrimeButton } from 'primereact/button';
import api from '../../../../services/api';
import { url_financeiro } from '../../../../environments/environments-develop';
import { toast } from 'react-toastify';
import TokenService from '../../../../services/Auth/token.service';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { ToggleButton } from 'primereact/togglebutton';
import { addLocale } from "primereact/api";

interface SimularRenegociacaoProps {
    handleClose: () => void;
    showSimularRenegociacao: any;
    handleBack: () => void;
    data: any;
    handleData: (newData: any) => void;
}

const SimularRenegociacao: React.FC<SimularRenegociacaoProps> = ({ data, handleData, handleBack, handleClose, showSimularRenegociacao }) => {
    const [show, setShow] = useState(showSimularRenegociacao);
    const parcelas = data?.parcelasSelecionadas?.map((parcela: any) => ({ id: parcela.id }));
    const [dadosSimulado, setDadosSimulado] = useState<any>();
    const [formBasico, setFormBasico] = useState<any>({
        contrato_id: data?.contrato?.id,
        empreendimento_id: TokenService.getEmpreendimentoId()?.id,
        qtd_parcelas: null,
        vincular_homologacao: false,
        valor_desconto: null,
        valor_primeira_parcela: '',
        data_primeiro_vencimento: null,
        prox_reajuste: data?.contrato?.prox_reajuste,
        data_novo_reajuste: null,
        data_assinatura_contrato: new Date(),
        data_renegociacao: new Date(),
        parcelas: parcelas,
        data_calculo: new Date(),
        data_anterior_reajuste: data?.contrato?.prox_reajuste,
    });

    useEffect(() => {
        formBasico.parcelas = data?.parcelasSelecionadas?.map((parcela: any) => ({ id: parcela.id }));
        formBasico.contrato_id = data?.contrato?.id;
        formBasico.prox_reajuste = data?.contrato?.prox_reajuste;
        formBasico.data_anterior_reajuste = data?.contrato?.prox_reajuste;
        setFormBasico(formBasico);
    }, [data]);

    const totalValor = data?.parcelasSelecionadas?.reduce((acc: number, parcela: { valor_original: any; }) => {
        return acc + parseFloat(parcela.valor_original || 0);
    }, 0);

    useEffect(() => {
        setShow(showSimularRenegociacao);
    }, [showSimularRenegociacao]);

    const MoneyFormatter = (saldoDevedor: any) => {
        const formatMoney = (value: number): string => {
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(value);
        };
        return formatMoney(saldoDevedor);
    };

    const simularRenegociacao = async () => {
        if (!isFormValid()) {
            return;
        }
        try {
            const response = await api.post(
                `${url_financeiro}/api/v1/admin/financeiro/renegociacao/simular-parcela`,
                formBasico
            );
            toast.success('Simulação realizada com sucesso.');
            setDadosSimulado(response.data);
        } catch (error) {
            const errorMessage =
                (error as any)?.response?.data?.message ||
                (error as any)?.error.message ||
                'Erro desconhecido ao simular renegociação';
            toast.error(`Erro ao Simular: ${errorMessage}`);
        }
    };

    const renegociar = async () => {
        if (!dadosSimulado) {
            toast.error('Realize a simulação antes de renegociar.');
            return;
        }
        if (
            formBasico.qtd_parcelas === 1 &&
            Math.abs(formBasico.valor_primeira_parcela - dadosSimulado?.valorParcela) > 0.01
        ) {
            toast.error(
                'Quando a quantidade de parcelas é 1, o valor da primeira parcela deve ser igual ao valor da parcela simulada.'
            );
            return;
        }
        try {
            const response = await api.post(
                `${url_financeiro}/api/v1/admin/financeiro/renegociacao/renegociar`,
                formBasico
            );
            toast.success('Renegociação realizada com sucesso.');
            handleClose();
            window.location.reload();
        } catch (error) {
            const errorMessage =
                (error as any)?.response?.data?.message ||
                (error as any)?.error.message ||
                'Erro desconhecido ao renegociar';
            toast.error(`Erro ao Renegociar: ${errorMessage}`);
        }
    };

    const removeParcela = (parcelaId: any) => {
        const newParcelasSelecionadas = data?.parcelasSelecionadas.filter(
            (parcela: { id: any }) => parcela?.id !== parcelaId
        );
        handleData({
            ...data,
            parcelasSelecionadas: newParcelasSelecionadas,
            totalQuantidadeParcelas: newParcelasSelecionadas.length,
        });
        setFormBasico((prevFormBasico: any) => ({
            ...prevFormBasico,
            parcelas: newParcelasSelecionadas.map((parcela: any) => ({ id: parcela.id })),
        }));
    };

    const actionBodyTemplate = (rowData: { id: any; }) => {
        return (
            <PrimeButton
                icon="pi pi-trash"
                className="p-button-rounded p-button-danger p-button-text"
                onClick={() => removeParcela(rowData.id)}
                tooltip="Remover Parcela"
                tooltipOptions={{ position: 'top' }}
            />
        );
    };

    const isFormValid = () => {
        if (!formBasico.valor_primeira_parcela) {
            toast.error('Preencha o Valor da 1ª Parcela.');
            return false;
        }
        if (!formBasico.data_primeiro_vencimento) {
            toast.error('Selecione a Data do 1º Vencimento.');
            return false;
        }
        if (!formBasico.qtd_parcelas) {
            toast.error('Selecione a Quantidade de Parcelas.');
            return false;
        }
        if (formBasico.qtd_parcelas === 1) {
            if (formBasico.valor_primeira_parcela < totalValor) {
                toast.error(
                    'Quando a quantidade de parcelas é 1, o valor da primeira parcela deve ser pelo menos o valor total.'
                );
                return false;
            }
            if (dadosSimulado && Math.abs(formBasico.valor_primeira_parcela - dadosSimulado?.valorParcela) > 0.01) {
                toast.error(
                    'Quando a quantidade de parcelas é 1, o valor da primeira parcela deve ser igual ao valor da parcela simulada.'
                );
                return false;
            }
        }
        
        return true;
    };

    const colSize = dadosSimulado ? 6 : { span: 6, offset: 3 };

    addLocale('pt', {
        firstDayOfWeek: 0,
        dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size='xl'
        >
            <Modal.Header closeButton>
                <Modal.Title>Renegociação</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Card style={{ display: 'flex', borderRadius: '10px', justifyContent: 'center', margin: '12px', padding: '6px', paddingTop: '12px', backgroundColor: '#282c34' }}>
                        <Form.Label style={{ color: '#e8550f', display: 'flex', justifyContent: 'center' }}>Qtd Total Parcelas: {data?.contrato?.qtd_parcelas}</Form.Label>
                        <h6 style={{ display: 'flex', color: '#fff', justifyContent: 'center' }}>{MoneyFormatter(data?.contrato?.valor_total ?? 0)}</h6>
                        <Form.Label style={{ color: '#e8550f', display: 'flex', justifyContent: 'center' }}>Valor Total</Form.Label>
                    </Card>
                    <Card style={{ display: 'flex', borderRadius: '10px', justifyContent: 'center', margin: '12px', padding: '6px', paddingTop: '12px', backgroundColor: '#282c34' }}>
                        <Form.Label style={{ color: '#e8550f', display: 'flex', justifyContent: 'center' }}>Qtd Parcelas Venc: {data?.qtd_total_vencidas}</Form.Label>
                        <h6 style={{ display: 'flex', color: '#fff', justifyContent: 'center' }}>{MoneyFormatter(data?.valor_total_vencidas ?? 0)}</h6>
                        <Form.Label style={{ color: '#e8550f', display: 'flex', justifyContent: 'center' }}>Valor Vencido</Form.Label>
                    </Card>
                    <Card style={{ display: 'flex', borderRadius: '10px', justifyContent: 'center', margin: '12px', padding: '6px', paddingTop: '12px', backgroundColor: '#282c34' }}>
                        <Form.Label style={{ color: '#e8550f', display: 'flex', justifyContent: 'center' }}>Qtd Parcelas Pagas: {data?.qtd_total_pagas}</Form.Label>
                        <h6 style={{ display: 'flex', color: '#fff', justifyContent: 'center' }}>{MoneyFormatter(data?.valor_total_pagas ?? 0)}</h6>
                        <Form.Label style={{ color: '#e8550f', display: 'flex', justifyContent: 'center' }}>Valor Pago</Form.Label>
                    </Card>
                    <Card style={{ display: 'flex', borderRadius: '10px', justifyContent: 'center', margin: '12px', padding: '6px', paddingTop: '12px', backgroundColor: '#282c34' }}>
                        <Form.Label style={{ color: '#e8550f', display: 'flex', justifyContent: 'center' }}>Qtd Parcelas a Vencer: {data?.qtd_total_abertas}</Form.Label>
                        <h6 style={{ display: 'flex', color: '#fff', justifyContent: 'center' }}>{MoneyFormatter(data?.valor_total_abertas ?? 0)}</h6>
                        <Form.Label style={{ color: '#e8550f', display: 'flex', justifyContent: 'center' }}>Total a Pagar</Form.Label>
                    </Card>
                </Row>
                <Row className="body-content-dash">
                    <Col md={colSize}>
                        <div
                            className="mb-3"
                            style={{
                                borderRadius: '10px',
                                backgroundColor: '#d9d9d9',
                                height: '450px',
                                width: '100%'
                            }}
                        >
                            <Card
                                className="mb-3"
                                style={{
                                    height: '100%',
                                    padding: '10px',
                                    borderRadius: '10px',
                                    backgroundColor: '#f7f7f7',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <h5 style={{ textAlign: 'center' }}>
                                    Parcelas Selecionadas
                                </h5>
                                {data?.parcelasSelecionadas && (
                                    <DataTable
                                        value={data.parcelasSelecionadas}
                                        paginator
                                        rows={5}
                                        dataKey="id"
                                        style={{
                                            borderTop: '1px solid #bdbdbd96',
                                            flexGrow: 1,
                                            overflowY: 'auto',
                                            maxHeight: '400px',
                                        }}

                                        emptyMessage={
                                            <span
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    fontStyle: 'italic',
                                                    color: '#888',
                                                }}
                                            >
                                                Nenhum item encontrado.
                                            </span>
                                        }
                                    >
                                        <Column
                                            field="numero_documento"
                                            header="Doc"
                                            style={{
                                                textAlign: 'center',
                                                color: 'black',
                                                padding: '9px',
                                            }}
                                        />
                                        <Column
                                            field="data_vencimento"
                                            header="Venc"
                                            body={(rowData) =>
                                                new Date(
                                                    rowData.data_vencimento
                                                ).toLocaleDateString('pt-BR')
                                            }
                                            style={{
                                                textAlign: 'center',
                                                color: 'black',
                                                padding: '9px',
                                            }}
                                        />
                                        <Column
                                            field="valor_original"
                                            header="Valor"
                                            body={(rowData) =>
                                                MoneyFormatter(
                                                    rowData.valor_original
                                                )
                                            }
                                            style={{
                                                textAlign: 'center',
                                                color: 'black',
                                                padding: '9px',
                                            }}
                                        />
                                        <Column
                                            field="valor_pago"
                                            header="Valor-Atu"
                                            body={(rowData) =>
                                                MoneyFormatter(
                                                    rowData.valor_pago
                                                )
                                            }
                                            style={{
                                                textAlign: 'center',
                                                color: 'black',
                                                padding: '9px',
                                            }}
                                        />
                                        <Column
                                            field="justificativa"
                                            header="OBS"
                                            style={{
                                                textAlign: 'center',
                                                color: 'black',
                                                padding: '9px',
                                            }}
                                        />
                                        <Column
                                            header="Ações"
                                            body={actionBodyTemplate}
                                            style={{
                                                textAlign: 'center',
                                                color: 'black',
                                                width: '52px',
                                                padding: '9px',
                                            }}
                                        />
                                    </DataTable>
                                )}
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <span
                                        style={{
                                            color: '#e8550f', 
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                        }}
                                    >
                                        Qtd de Parcelas: {data?.totalQuantidadeParcelas}
                                    </span>
                                    <span
                                        style={{
                                            color: '#e8550f',
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                        }}
                                    >
                                        Valor Total: {MoneyFormatter(totalValor)}
                                    </span>
                                </div>
                            </Card>
                        </div>
                    </Col>
                    {dadosSimulado && (
                        <Col md={6}>
                            <div className="mb-3" style={{ borderRadius: '10px', height: '450px', backgroundColor: '#d9d9d9' }}>
                                <Card className="mb-3" style={{ padding: '10px', height: '450px', borderRadius: '10px', backgroundColor: '#f7f7f7' }}>
                                    <h5 style={{ textAlign: 'center' }}>
                                        Simulação
                                    </h5>
                                    <Form style={{ borderTop: '1px solid #bdbdbd96', height: '265px' }}>
                                        <Form.Group as={Row} className="mb-3" controlId="valordasParcelas">
                                            <Form.Label column sm="9">
                                                Valor das Parcelas:
                                            </Form.Label>
                                            <Col sm="3">
                                                <Form.Label>{MoneyFormatter(dadosSimulado?.valorParcela || 0)}</Form.Label>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="valorTotalJuros">
                                            <Form.Label column sm="9">
                                                Valor Total dos Juros:
                                            </Form.Label>
                                            <Col sm="3">
                                                <Form.Label>{MoneyFormatter(dadosSimulado?.valorTotalJuros || 0)}</Form.Label>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="valorTotalMultas">
                                            <Form.Label column sm="9">
                                                Valor Total das Multas:
                                            </Form.Label>
                                            <Col sm="3">
                                                <Form.Label>{MoneyFormatter(dadosSimulado?.valorTotalMulta || 0)}</Form.Label>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="valorTotalParcelasSDesconto">
                                            <Form.Label column sm="9">
                                                Valor Total das Pacelas Sem Juros e Multa:
                                            </Form.Label>
                                            <Col sm="3">
                                                <Form.Label>{MoneyFormatter(dadosSimulado?.valorTotalParcelasSemJurosMulta || 0)}</Form.Label>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="valorTotalParcelasCDesconto">
                                            <Form.Label column sm="9">
                                                Valor Total das Pacelas Com Juros e Multa:
                                            </Form.Label>
                                            <Col sm="3">
                                                <Form.Label>{MoneyFormatter(dadosSimulado?.valorTotalParcelasComJurosMulta || 0)}</Form.Label>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="valorTotalLiquidoParcelas">
                                            <Form.Label column sm="9">
                                                Valor Total Liquido das Pacelas:
                                            </Form.Label>
                                            <Col sm="3">
                                                <Form.Label>{MoneyFormatter(dadosSimulado?.valorTotalLiquidoParcelas || 0)}</Form.Label>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </Card>
                            </div>
                        </Col>
                    )}
                </Row>
                <Row className="body-content-dash">
                    <Col md={12}>
                        <div className="mb-3" style={{ padding: '12px', borderRadius: '10px', backgroundColor: '#d9d9d9' }}>
                            <Row className="d-flex justify-content-center align-items-center">
                                <Col md={2} className="d-flex flex-column align-items-center">
                                    <Form.Label>Qtd Parcelas</Form.Label>
                                    <InputNumber
                                        value={formBasico?.qtd_parcelas}
                                        onValueChange={(e) =>
                                            setFormBasico({ ...formBasico, qtd_parcelas: e.value })
                                        }
                                        invalid={!formBasico?.qtd_parcelas}
                                        style={{ textAlign: 'center' }}
                                    />
                                </Col>
                                <Col md={2} className="d-flex flex-column align-items-center">
                                    <Form.Label>Valor Desconto</Form.Label>
                                    <InputNumber
                                        value={formBasico?.valor_desconto}
                                        onValueChange={(e) =>
                                            setFormBasico({ ...formBasico, valor_desconto: e.value })
                                        }
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        style={{ textAlign: 'center' }}
                                    />
                                </Col>
                                <Col md={2} className="d-flex flex-column align-items-center">
                                    <Form.Label>
                                        Valor 1ª Parcela
                                    </Form.Label>
                                    <InputNumber
                                        value={formBasico?.valor_primeira_parcela}
                                        onValueChange={(e) =>
                                            setFormBasico({
                                                ...formBasico,
                                                valor_primeira_parcela: e.value,
                                            })
                                        }
                                        invalid={!formBasico?.valor_primeira_parcela}
                                        mode="currency"
                                        currency="BRL"
                                        style={{ textAlign: 'center', borderColor: formBasico?.valor_primeira_parcela ? '#ced4da' : 'red' }}
                                        locale="pt-BR"
                                        required
                                    />
                                </Col>
                                <Col md={2} className="d-flex flex-column align-items-center">
                                    <Form.Label>
                                        Dt 1ª Parcela
                                    </Form.Label>
                                    <div className="card flex justify-center">
                                        <Calendar
                                            value={formBasico?.data_primeiro_vencimento || null}
                                            onChange={(e) =>
                                                setFormBasico({
                                                    ...formBasico,
                                                    data_primeiro_vencimento: e.value,
                                                })
                                            }
                                            invalid={!formBasico?.data_primeiro_vencimento}
                                            readOnlyInput
                                            showButtonBar
                                            dateFormat="dd/mm/yy"
                                            locale="pt"
                                            panelClassName="custom-calendar-panel"
                                            style={{ textAlign: 'center' }}
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={2} className="d-flex flex-column align-items-center">
                                    <Form.Label>Data Atual Reajuste</Form.Label>
                                    <div className="card flex justify-center">
                                        <Calendar
                                            value={formBasico?.data_anterior_reajuste ? new Date(formBasico?.data_anterior_reajuste) : formBasico?.prox_reajuste ? new Date(formBasico?.prox_reajuste) : null}
                                            disabled
                                            showButtonBar
                                            dateFormat="dd/mm/yy"
                                            panelClassName="custom-calendar-panel"
                                            style={{ textAlign: 'center' }}
                                        />
                                    </div>
                                </Col>
                                <Col md={2} className="d-flex flex-column align-items-center">
                                    <Form.Label>Nova Dt Reajuste</Form.Label>
                                    <div className="card flex justify-center">
                                        <Calendar
                                            value={formBasico?.data_novo_reajuste || null}
                                            onChange={(e) =>
                                                setFormBasico({
                                                    ...formBasico,
                                                    data_novo_reajuste: e.value,
                                                })
                                            }
                                            dateFormat="dd/mm/yy"
                                            locale="pt"
                                            showButtonBar
                                            readOnlyInput
                                            panelClassName="custom-calendar-panel"
                                            style={{ textAlign: 'center' }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="mb-3" style={{ padding: '12px', borderRadius: '10px', backgroundColor: '#d9d9d9' }}>
                            <Row className="d-flex justify-content-center align-items-center">

                                <Col md={2} className="d-flex flex-column align-items-center">
                                    <Form.Label>Data Renegociação</Form.Label>
                                    <div className="card flex justify-center">
                                        <Calendar
                                            value={formBasico?.data_renegociacao || new Date()}
                                            onChange={(e) =>
                                                setFormBasico({
                                                    ...formBasico,
                                                    data_renegociacao: e.value,
                                                })
                                            }
                                            dateFormat="dd/mm/yy"
                                            locale="pt"
                                            showButtonBar
                                            panelClassName="custom-calendar-panel"
                                            style={{ textAlign: 'center' }}
                                        />
                                    </div>
                                </Col>
                                <Col md={2} className="d-flex flex-column align-items-center">
                                    <Form.Label>Data Assinatura</Form.Label>
                                    <div className="card flex justify-center">
                                        <Calendar
                                            value={formBasico?.data_assinatura_contrato || new Date()}
                                            onChange={(e) =>
                                                setFormBasico({
                                                    ...formBasico,
                                                    data_assinatura_contrato: e.value,
                                                })
                                            }
                                            dateFormat="dd/mm/yy"
                                            locale="pt"
                                            showButtonBar
                                            panelClassName="custom-calendar-panel"
                                            style={{ textAlign: 'center' }}
                                        />
                                    </div>
                                </Col>
                                <Col md={2} className="d-flex flex-column align-items-center">
                                    <Form.Label>Data de Calculo</Form.Label>
                                    <div className="card flex justify-center">
                                        <Calendar
                                            value={formBasico?.data_calculo || new Date()}
                                            disabled
                                            dateFormat="dd/mm/yy"
                                            locale="pt"
                                            showButtonBar
                                            panelClassName="custom-calendar-panel"
                                            style={{ textAlign: 'center' }}
                                        />
                                    </div>
                                </Col>
                                <Col md={2} className="d-flex flex-column align-items-center">
                                    <Form.Label>Vincular Homologação</Form.Label>
                                    <ToggleButton
                                        checked={formBasico?.vincular_homologacao}
                                        onChange={(e) =>
                                            setFormBasico({
                                                ...formBasico,
                                                vincular_homologacao: e.value,
                                            })
                                        }
                                        style={{ height: '32px', lineHeight: '32px' }}
                                        onLabel="Sim"
                                        offLabel="Não"
                                        onIcon="pi pi-check"
                                        offIcon="pi pi-times"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <BootstrapButton onClick={() => { simularRenegociacao() }} className="button-next-form" variant="primary">
                        Simular
                    </BootstrapButton>
                    <BootstrapButton onClick={() => { handleBack() }} className="button-back-form" variant="primary">
                        Voltar
                    </BootstrapButton>
                    <BootstrapButton onClick={() => { renegociar() }} className="button-next-form">
                        Renegociar
                    </BootstrapButton>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SimularRenegociacao;
