import iconMenuTresPonto from '../../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { Col, Form, InputGroup, NavLink, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import pt from 'date-fns/locale/pt';
import 'react-autocomplete-input/dist/bundle.css';
import { AlertService } from '../../../../components/AlertService';
import { url } from '../../../../environments/environments-develop';
import api from '../../../../services/api';
import { abrirPDF } from '../../../../utils/PdfUtils';
import { useAppContext } from '../../../../contexts/AppContext';
import { useSidebarContext } from '../../../../contexts/SidebarContext';
import TokenService from '../../../../services/Auth/token.service';


function RelatorioDisponibilidade() {
  const [listFiltros, setListFiltros] = useState<any>({});
  const { isVisible, updateVisibility } = useSidebarContext();
  const { showSubSidebar } = useAppContext();

  const toggleVisibility = () => {
    const newValue = !isVisible;
    updateVisibility(newValue);
  };

  async function gerarPDF(): Promise<void> {
    try {
      const consulta: any = {
        consulta: {
          order: "id;asc",
          filtro: {
            corretor: listFiltros.corretor,
            identificador1: listFiltros.identificador1,
            observacao: listFiltros.observacao,
            status: listFiltros.status,
            qtd_parcelas: listFiltros.qtd_parcelas,
            tipo_relatorio: listFiltros.tipo_relatorio || 1,
            empreendimento_id: TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().id : null
          }
        }
      };

      const queryParams: any = {};

      const camposEnviar = ['consulta'];

      camposEnviar.forEach((key: any) => {
        const value = consulta[key];
        if (value !== undefined && value !== null && value !== '') {
          if (key === 'consulta') {
            queryParams[key] = JSON.stringify(value);
          } else {
            queryParams[key] = value;
          }
        }
      });

      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
        .join('&');
      const response = await api.get(`${url}/api/v1/admin/vendas/imoveis-relatorio-disponibilidade?${queryString}`, {
        responseType: 'arraybuffer',
      });
      if (response.status === 200) {
        handleOpenPDF(response.data, listFiltros);
      }

    } catch (error: any) {
      console.log('error', error)
      AlertService.error('Erro!', 'Nenhum Imovel disponivel');
    }
  }

  const handleChange = (field: string, value: any) => {
    setListFiltros((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };

  const handleOpenPDF = (pdfBase64: string, listFiltros: any) => {
    abrirPDF(pdfBase64, listFiltros);
  }

  return (
    <div className="body-content">
      <div
        className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}
      >
        <div className="contentSearch">
          <div className="contentSearch-header">
            <NavLink onClick={toggleVisibility}>
              <img
                className="menu-context"
                src={iconMenuTresPonto}
              />
            </NavLink>
            <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Relatórios de Disponibilidade</Col>
          </div>
          <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
          <Form.Group className="imovel-form" style={{ marginLeft: '22px' }}>
            <Row className="mb-3">
              <Col md="4">
                <Form.Label>Corretor</Form.Label>
                <Form.Control
                  value={listFiltros.corretor || ''}
                  onChange={(e) => handleChange("corretor", e.target.value)}
                />
              </Col>
              <Col md="3">
                <Form.Label>Quadra</Form.Label>
                <Form.Control
                  value={listFiltros.identificador1 || ''}
                  onChange={(e) => handleChange("identificador1", e.target.value)}
                />
              </Col>
              <Col md="5">
                <Form.Label>Observação</Form.Label>
                <Form.Control
                  value={listFiltros.observacao || ''}
                  onChange={(e) => handleChange("observacao", e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="3">
                <Form.Label>Status</Form.Label>
                <Form.Select value={listFiltros ? listFiltros.status : 1} onChange={(e) => handleChange('status', Number(e.target.value))}>
                  <option>Selecione...</option>
                  <option value={1}> Disponivel</option>
                  <option value={2}> Reservado</option>
                  <option value={3}> Reserva fixa</option>
                  <option value={4}> Negociado</option>
                  <option value={5}> Vendido</option>
                  <option value={6}> Reserva Técnica</option>
                  <option value={7}> Reserva Técnica</option>
                </Form.Select>
              </Col>
              <Col md="3">
                <Form.Label>Qtd Parcela</Form.Label>
                <Form.Control
                  value={listFiltros.qtd_parcelas || 0}
                  onChange={(e) => handleChange("qtd_parcelas", Number(e.target.value))}
                />
              </Col>
              <Col md="3">
                <Form.Label>Tipo de Relatório</Form.Label>
                <Form.Select value={listFiltros.tipo_relatorio || 1} onChange={(e) => handleChange('tipo_relatorio', Number(e.target.value))}>
                  <option>Selecione...</option>
                  <option value={1} key={1}>PDF</option>
                  <option value={2} key={2}>EXCEL</option>
                </Form.Select>
              </Col>
            </Row>
          </Form.Group>
          <div className='div-filtros-avancados'>
            <Button
              onClick={() => gerarPDF()}
              style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '25px', fontSize: '14px' }}
              type="button"
              severity="success"
              icon="pi pi-search"
              rounded>
              <p style={{ marginLeft: '5px', marginTop: '13px' }}>Buscar</p>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RelatorioDisponibilidade;