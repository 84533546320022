import React from 'react';
import { SpeedDial } from 'primereact/speeddial';
import { Tooltip } from 'primereact/tooltip';
import { useAppContext } from '../contexts/AppContext';

function ButtonMenuCrumbsComponent() {
  const { contextItemsButtonMenu } = useAppContext();

  return (
    <>
      {contextItemsButtonMenu.length > 0 && (
        <div className="nav-item" style={{ width: '20px' }}>
          {contextItemsButtonMenu.map((item, index) => (
            <Tooltip
              key={index}
              target={`.p-speeddial-action:nth-child(${index + 1})`}
              content={item.tooltip}
              position="left"
            />
          ))}
          <SpeedDial
            className="button-menu-speedial"
            model={contextItemsButtonMenu}
            style={{ width: '25px' }}
            direction="down"
            transitionDelay={80}
            radius={45}
            showIcon="pi pi-bars"
            hideIcon="pi pi-times"
          />
        </div>
      )}
    </>
  );
}

export default ButtonMenuCrumbsComponent;
