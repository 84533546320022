import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url, url_financeiro } from '../../../environments/environments-develop';
import TokenService from '../../../services/Auth/token.service';
import ProgressBarForm from '../../Formularios/progressBarForm';
import { AlertService } from '../../../components/AlertService';
import ContasPagarForm from './Formularios/ContasPagarForm';
import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
import { useSidebarContext } from '../../../contexts/SidebarContext';
import { Col, NavLink } from 'react-bootstrap';
import TaxasJurosForm from './Formularios/TaxasJurosForm';
import { LANCAMENTO_SAIDA } from '../Models/LancamentoFinanceiroModel';
import AnexoForm from './Formularios/AnexoForm';

function CreateContasPagar() {
    const { setUrl, setBody, setShowButtonSave } = useAppContext();
    const [formBasico, setFormBasico] = useState<any | null>(null);
    const [currentPart, setCurrentPart] = useState(1);
    const totalParts = 3;
    const { isVisible, updateVisibility } = useSidebarContext();

    useEffect(() => {
        setUrl(`${url_financeiro}/api/v1/admin/financeiro/contasPagar`);
        setFormBasico((prevImovelData: any) => {
            return {
                ...prevImovelData,
                ['tipo']: LANCAMENTO_SAIDA,
                ['empreendimento_id']: TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId()?.id : null
            };
        });
    }, []);
    const handleNext = () => {
        if (currentPart === 1 && formBasico.fornecedor_id === undefined) {
            AlertService.infomessage('Atenção', 'Favorecido não informado!')
            return;
        }
        if (currentPart === 1 && formBasico.valor_original === undefined) {
            AlertService.infomessage('Atenção', 'Valor Original não informado!')
            return;
        }
        if (currentPart === 1 && formBasico.num_parcela > 1 && !formBasico?.parcelas) {
            AlertService.infomessage('Atenção', 'Parcelas não configuradas!')
            return;
        }
        if (currentPart === 1 && formBasico.data_vencimento === undefined) {
            AlertService.infomessage('Atenção', 'Data de vencimento não informado!')
            return;
        }

        if (currentPart < totalParts) {
            setCurrentPart((prevPart) => prevPart + 1);
        }
    };

    const handleBack = () => {
        if (currentPart > 1) {
            setCurrentPart((prevPart) => prevPart - 1);
        }
    };
    const handleChange = (field: string, value: string) => {
        setFormBasico((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    };
    useEffect(() => {
        setBody(formBasico)
    }, [formBasico])
    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };
    return (
        <div className="body-content">
            <div className="form-user-create">
                <div className="contentSearch-header">
                    <NavLink onClick={toggleVisibility}>
                        <img
                            className="menu-context"
                            src={iconMenuTresPonto}
                        />
                    </NavLink>
                    <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Contas à Pagar</Col>
                </div>
                <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
                <Container>
                    <ProgressBarForm
                        currentPart={currentPart}
                        totalParts={totalParts}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        steps={["Identificação", "Taxas e Juros", "Anexo"]}
                        body={formBasico}
                    >
                        {currentPart === 1 && (
                            <ContasPagarForm
                                Data={formBasico || null}
                                onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                            />
                        )}
                        {currentPart === 2 && (
                            <TaxasJurosForm
                                Data={formBasico}
                                onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                            />
                        )}
                        {currentPart === 3 && (
                            <AnexoForm
                                Data={formBasico}
                                onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                            />
                        )}
                    </ProgressBarForm>
                </Container>
            </div>
        </div>
    );
}

export default CreateContasPagar;
