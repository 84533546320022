import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useSidebarContext } from '../../../contexts/SidebarContext';
import { Col, Form, InputGroup, Modal, NavLink, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../assets/images/icon-calendar.png';
import pt from 'date-fns/locale/pt';
import 'react-autocomplete-input/dist/bundle.css';
import { AlertService } from '../../../components/AlertService';
import TokenService from '../../../services/Auth/token.service';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import api from '../../../services/api';
import { abrirPDF } from '../../../utils/PdfUtils';
import { AutoComplete } from 'primereact/autocomplete';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import ButtonClose from 'react-bootstrap/Button';
import { MDBCol, MDBPageItem, MDBPageNav, MDBPagination, MDBRow } from 'mdbreact';
import { showToast } from '../../../components/ToastComponent';
import { searchCliente } from '../../Financeiro/MovCaixa/Service/MovCaixaService';

function ContaCorrenteClienteForm() {
  const [listFiltros, setListFiltros] = useState<any>({});
  const [nomeCliente, setNomeCliente] = useState<string>();
  const [nodes, setNodes] = useState<any | null>(null);
  const [totalPages, setTotalPages] = useState(0);
  const { isVisible, updateVisibility } = useSidebarContext();
  const [dataInicio, setDataInicio] = useState<string | null>(null);
  const [dataFim, setDataFim] = useState<string | null>(null);
  const { showSubSidebar } = useAppContext();
  const [FilteredCliente, setFilteredCliente] = useState<any>(null);
  const [nome, setNome] = useState<string>('');
  const [showModalListLancamento, setShowModalListLancamento] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const consultaPorPeriodo = [
    { label: 'Vencimento', value: 'data_vencimento' },
    { label: 'Pagamento', value: 'data_pagamento' },
    { label: 'Baixa', value: 'data_baixa' },
    { label: 'Emissão', value: 'data_emissao' },
    { label: 'Credito', value: 'data_credito' },
    { label: 'Criação', value: 'created_at' },
    { label: 'Alteração', value: 'updated_at' },
  ];
  const tipoLancamento = [
    { label: 'Contas à Receber', value: 0 },
    { label: 'Contas à Pagar', value: 1 },
  ];
  const getTipoParcela = [
    { label: 'Ordinaria', value: 0 },
    { label: 'Avulsa', value: 1 },
    { label: 'Agrupada', value: 2 },
  ];

  const getSituacaoPagamento = [
    { label: 'Aberto', value: 0 },
    { label: 'Baixado', value: 1 },
    { label: 'Inadimplente', value: 2 },
  ];

  const toggleVisibility = () => {
    const newValue = !isVisible;
    updateVisibility(newValue);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 0; i < totalPages; i++) {
      // Adicione as três primeiras páginas
      if (i < 3 || i === totalPages - 1 || (i >= currentPage - 1 && i <= currentPage + 1)) {
        pageNumbers.push(
          <MDBPageItem key={i} active={i === currentPage}>
            <MDBPageNav className="page-link" onClick={() => setCurrentPage(i)}>
              <span key={i} onClick={() => setCurrentPage(i)} style={{ cursor: 'pointer', margin: '0 5px', fontWeight: i === currentPage ? 'bold' : 'normal' }}>
                {i + 1}
              </span>
            </MDBPageNav>
          </MDBPageItem>

        );
      }
    }

    return pageNumbers;
  };
  async function gerarPDF(): Promise<void> {
    try {
      const consulta: any = {
        consulta: {
          order: "id;desc",
          filtro: {
            numero_contrato: listFiltros?.numero_contrato || null,
            situacao_pagamento: 1,
            unidade: listFiltros?.unidade,
            cliente_id: listFiltros?.cliente_id,
            fornecedor_id: listFiltros?.fornecedor_id,
            tipo_consulta: listFiltros?.tipo_consulta,
            data_inicial: listFiltros?.data_inicial,
            data_final: listFiltros?.data_final,
            tipo_lancamento: 3,
            campos: [
              "venda_id",
              "numero_documento",
              listFiltros?.cliente_id ? null : "cliente_id",
              "tipo",
              "data_vencimento",
              "data_pagamento",
              "valor_original",
              "valor_pago",
              "justificativa"
            ].filter(campo => campo !== null),
            tipo_relatorio: 1,
            empreendimento_id: TokenService.getEmpreendimentoId()?.id || null
          }
        }
      };


      const queryParams: any = {};

      const camposEnviar = ['consulta'];

      camposEnviar.forEach((key: any) => {
        const value = consulta[key];
        if (value !== undefined && value !== null && value !== '') {
          if (key === 'consulta') {
            queryParams[key] = JSON.stringify(value);
          } else {
            queryParams[key] = value;
          }
        }
      });

      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
        .join('&');
      const response = await api.get(`${url}/api/v1/admin/financeiro/gerador-arquivo-relatorio?${queryString}`, {
        responseType: 'arraybuffer',
      });
      if (response.status === 200) {
        handleOpenPDF(response.data, listFiltros);
      }

    } catch (error: any) {
      AlertService.error('Erro!', String(error.response?.data?.message) || 'Erro desconhecido');
    }
  }
  async function filtrar(): Promise<void> {
    try {
      if (!listFiltros?.numero_contrato && !listFiltros?.cliente_id && !listFiltros?.fornecedor_id && !listFiltros?.unidade) {
        showToast('error', 'Selecione um contrato ou cliente ou unidade');
        return;
      }
      const consulta: any = {
        consulta: {
          order: "id;desc",
          filtro: {
            numero_contrato: listFiltros && listFiltros?.numero_contrato ? listFiltros?.numero_contrato : null,
            cliente_id: listFiltros && listFiltros?.cliente_id,
            fornecedor_id: listFiltros && listFiltros?.fornecedor_id,
            situacao_pagamento: 1,
            tipo_consulta: listFiltros && listFiltros?.tipo_consulta,
            data_inicial: listFiltros && listFiltros?.data_inicial,
            data_final: listFiltros && listFiltros?.data_final,
            tipo_relatorio: 1,
            unidade: listFiltros?.unidade && listFiltros?.unidade,
            empreendimento_id: TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().id : null
          }
        }
      };

      const queryParams: any = {};

      const camposEnviar = ['consulta'];

      camposEnviar.forEach((key: any) => {
        const value = consulta[key];
        if (value !== undefined && value !== null && value !== '') {
          if (key === 'consulta') {
            queryParams[key] = JSON.stringify(value);
          } else {
            queryParams[key] = value;
          }
        }
      });

      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
        .join('&');
      const response = await api.get(`${url}/api/v1/admin/financeiro/movimentacaofinanceira?${queryString}&page=${currentPage + 1}&totalitems=12`);
      if (response.status === 200) {
        if (response.data.data.length > 0) {
          setNomeCliente(response.data.data[0].cliente_nome)
          setNodes(response.data.data);
          const totalPages = response.data.meta.pagination.total_pages;
          setTotalPages(totalPages);
          setShowModalListLancamento(true);
        }
      } else {
        showToast('error', 'Nenhum Registro encontrado!')
      }

    } catch (error: any) {
      AlertService.error('Erro!', String(error.response?.data?.message) || 'Erro desconhecido');
    }
  }
  const handleOpenPDF = (pdfBase64: string, listFiltros: any) => {
    abrirPDF(pdfBase64, listFiltros);
  }

  async function search(event: any) {
    // Timeout to emulate a network connection
    let _FilteredPessoa: any;

    if (event.query) {
      try {
        _FilteredPessoa = await api.get(`${url}/api/v1/admin/financeiro/cliente/buscarClienteList/${event.query.toLowerCase()}`);
      } catch (error) {
        console.error('Erro ao buscar cliente:', error);
        _FilteredPessoa = [];
      }
    } else {
      _FilteredPessoa = [];
    }
    setFilteredCliente(_FilteredPessoa?.data?.data);
  }

  const handleChangeCliente = (field: string, value: any) => {
    handleChange('cliente_nome', value.nome);
    handleChange(field, value.id);
  }
  const handleChange = (field: string, value: any) => {
    setListFiltros((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };
  const handleClearList = () => {
    setListFiltros({});
  }

  return (
    <div className="body-content">
      <div
        className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}
      >
        <div className="contentSearch">
          <div className="contentSearch-header">
            <NavLink onClick={toggleVisibility}>
              <img
                className="menu-context"
                src={iconMenuTresPonto}
              />
            </NavLink>
            <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Relatório Conta Corrente</Col>
          </div>
          <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
          <Form.Group className="imovel-form" style={{ marginLeft: '22px' }}>
            <Row className="mb-3">
              <Col md={2} style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Número do Contrato</Form.Label>
                <Form.Control placeholder='Número do contrato' value={listFiltros.numero_contrato || ''} onChange={(e) => handleChange("numero_contrato", e.target.value)} />
              </Col>
              <Col md={2} style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Cliente|Favorecido</Form.Label>
                <AutoComplete
                  field="nome"
                  style={{ height: "37px" }}
                  value={listFiltros.nome_cliente ? listFiltros.nome_cliente : listFiltros.nome}
                  suggestions={FilteredCliente}
                  completeMethod={(e) => searchCliente(e, setFilteredCliente)}
                  onChange={(e) => {
                    setListFiltros({ ...listFiltros, nome: e.value })
                    handleChange('nome', e.value)
                  }}
                  onSelect={(e) => {
                    console.log('e', e)
                    if (e?.value?.cliente_id) {
                      handleChange('cliente_id', e.value.cliente_id)
                      setListFiltros({ ...listFiltros, cliente_id: e.value.cliente_id, nome: e.value })
                    } else if (e?.value?.fornecedor_id) {
                      handleChange('fornecedor_id', e.value.fornecedor_id)
                      setListFiltros({ ...listFiltros, fornecedor_id: e.value.fornecedor_id, nome: e.value })
                    } else {
                      handleChange('pessoa_id', e.value.id)
                      setListFiltros({ ...listFiltros, cliente_id: e.value.id, nome: e.value })
                    }
                  }}
                  dropdown
                />
              </Col>
              <Col md={2} style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Unidade</Form.Label>
                <Form.Control value={listFiltros.unidade || ''} onChange={(e) => handleChange("unidade", e.target.value)} />
              </Col>
              <Col md={2} style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Tipo de data</Form.Label>
                <Form.Select value={listFiltros.tipo_consulta || ''} onChange={(e) => handleChange('tipo_consulta', e.target.value)}>
                  <option>Selecione...</option>
                  {consultaPorPeriodo.map((item: any) => (
                    <option key={item.value} value={item.value}>{item.label}</option>
                  ))}
                </Form.Select>
              </Col>
              <Col md={2} style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Data de Inicio</Form.Label>
                <Form.Group controlId="formGridState" style={{ display: 'grid' }}>
                  <DatePicker
                    selected={dataInicio ? new Date(dataInicio) : null}
                    onChange={(date: Date) => {
                      const formattedDate = date.toISOString().split('T')[0]; // Extrai apenas a parte da data YYYY-MM-DD
                      setDataInicio(formattedDate);
                      handleChange('dataInicio', formattedDate); // Adapte conforme necessário
                    }}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    fixedHeight
                    locale={pt}
                    customInput={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          type="date"
                          placeholder="Data Inicio"
                          className="dateFormFinanceiro"
                          value={dataInicio ? dataInicio : ''}
                          readOnly
                        />
                        <img
                          className="calendar-icon"
                          src={CalendarIcon}
                        />
                      </div>
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={2} style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Data de Fim</Form.Label>
                <Form.Group as={Col} controlId="formGridState" style={{ display: 'grid' }}>
                  <DatePicker
                    selected={dataFim ? new Date(dataFim) : null}
                    onChange={(date: Date) => {
                      const formattedDate = date.toISOString().split('T')[0]; // Extrai apenas a parte da data YYYY-MM-DD
                      setDataFim(formattedDate);
                      handleChange('dataFim', formattedDate); // Adapte conforme necessário
                    }}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    fixedHeight
                    locale={pt}
                    customInput={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          type="date"
                          placeholder="Data Fim"
                          className="dateFormFinanceiro"
                          value={dataFim ? String(dataFim) : ''}
                          readOnly
                        />
                        <img
                          className="calendar-icon"
                          src={CalendarIcon}
                        />
                      </div>
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form.Group>
          <div className='div-filtros-avancados'>
            <Button
              onClick={() => filtrar()}
              style={{ height: '30px', marginBottom: '13px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
              type="button"
              severity="success"
              icon="pi pi-filter"
              rounded>
              <p style={{ marginLeft: '5px', marginTop: '13px' }}>Buscar</p>
            </Button>
            <Button
              onClick={() => handleClearList()}
              style={{ height: '30px', marginBottom: '13px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
              type="button"
              severity="danger"
              icon="pi pi-delete-left"
              rounded>
              <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
            </Button>
          </div>
        </div>
      </div>
      <Modal show={showModalListLancamento} size='xl' style={{ padding: '0px !important' }} onHide={() => setShowModalListLancamento(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Conta Corrente</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '0px !important', display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', justifyItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h6>{nomeCliente}</h6>
            <p>
              N° Contrato: {listFiltros?.numero_contrato ? listFiltros?.numero_contrato : 'Todos'} <br />
              Data: {listFiltros?.data_inicial && listFiltros?.data_final ? `${listFiltros.data_inicial} à ${listFiltros.data_final}` : 'Todo o Período'}
            </p>
          </div>
          <DataTable
            stripedRows
            lazy
            value={nodes ? nodes : []}
            tableStyle={{ minWidth: '50rem' }}
            emptyMessage={<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>}
          >
            {!listFiltros?.numero_contrato && <Column
              key={'venda_id'} field={'venda_id'} header={'N° Contrato'}
              style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
            />}
            <Column
              key={'numero_documento'} field={'numero_documento'} header={'Documento'}
              style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
            />
            <Column
              key={'data_vencimento_format'} field={'data_vencimento_format'} header={'Vencimento'}
              style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
            />
            <Column
              key={'data_pagamento_format'} field={'data_pagamento_format'} header={'Dt.Pgto'}
              style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
            />
            <Column
              key={'valor_original_format'} field={'valor_original_format'} header={'Valor'}
              style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
            />
            <Column
              key={'valor_pago_format'} field={'valor_pago_format'} header={'Valor Pago'}
              style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
            />
            <Column
              key={'justificativa'} field={'justificativa'} header={'Descrição'}
              style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
            />
          </DataTable>
          <div className="pagination-footer" style={{ marginTop: '10px' }}>
            <MDBRow>
              <MDBCol>
                <MDBPagination circle>
                  <MDBPageItem disabled={currentPage === 0}>
                    <MDBPageNav className="page-link" aria-label="Previous" onClick={() => setCurrentPage(currentPage - 1)}>
                      <span aria-hidden="true">&laquo;</span>
                    </MDBPageNav>
                  </MDBPageItem>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {renderPageNumbers()}
                  </div>
                  <MDBPageItem disabled={currentPage === totalPages - 1}>
                    <MDBPageNav className="page-link" aria-label="Next" onClick={() => setCurrentPage(currentPage + 1)}>
                      <span aria-hidden="true">&raquo;</span>
                    </MDBPageNav>
                  </MDBPageItem>
                </MDBPagination>
              </MDBCol>
            </MDBRow>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonClose variant="success" onClick={() => { setShowModalListLancamento(false) }}>Fechar</ButtonClose>
          <ButtonClose variant="success" onClick={() => gerarPDF()}> Gerar PDF </ButtonClose>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default ContaCorrenteClienteForm;