import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import PessoaForm from '../../Formularios/pessoa';
import UsuarioForm from '../Usuario/Formularios/usuario';
import EnderecoForm from '../../Formularios/endereco';
import TelefoneForm from '../../Formularios/telefone';
import { useUsuarioContext } from '../../../contexts/UsuarioContext';
import { showToast } from '../../../components/ToastComponent';
import { addTelefone, changeTelefone, removeTelefone } from '../../../utils/TelefoneUtils';
import { addEndereco, changeEndereco, removeEndereco } from '../../../utils/EnderecoUtils';
import { changeUsuario } from '../../../utils/UsuarioUtils';
import ProgressBarForm from '../../Formularios/progressBarForm';
import { AlertService } from '../../../components/AlertService';
import FilailForm from './Formularios/filialForm';

function EditUsuario() {
    const { setUrl, setTypeRequest, setShowButtonSave, setBody, retorno } = useAppContext();
    const usuarioContext = useUsuarioContext();
    const [currentPart, setCurrentPart] = useState(1);
    const totalParts = 4;
    const [formData, setFormData] = useState<any>();
    useEffect(() => {
        try {
            const storedData = localStorage.getItem('dadosEdicao');
            if (storedData) {
                setFormData((data: any) => {
                    return {
                        ...data,
                        ...JSON.parse(storedData),
                        pessoa: {
                            ...JSON.parse(storedData).pessoa,
                            enderecos: [...JSON.parse(storedData).pessoa.enderecos.data],
                            telefones: [...JSON.parse(storedData).pessoa.telefones.data],
                        }
                    }
                });
                setTypeRequest('PUT');
                setUrl(`${url}/api/v1/admin/core/filial/${storedData && JSON.parse(storedData).id}`);
                setShowButtonSave(true);
            }
            return () => {
                localStorage.removeItem('dadosEdicao');
            };
        } catch (error) {
            AlertService.error("error", String(error))
        }
    }, []);

    const handleNext = () => {
        if (!formData) {
            showToast("error", "Os campos de Nome e CPF ou CNPJ é obrigatório!")
            return;
        }
        if (!formData?.pessoa?.cpf_cnpj && currentPart === 2) {
            showToast("error", "O campo CPF ou CNPJ é obrigatório!")
            return;
        }
        if (!formData.pessoa.nome && formData?.pessoa?.cpf_cnpj.length < 15 && currentPart === 2) {
            showToast("error", "O campo Nome é obrigatório!")
            return;
        }
        if (!formData.pessoa.data_fundacao && formData?.pessoa?.cpf_cnpj.length > 14 && currentPart === 2) {
            showToast("error", "O campo de Data de Fundação é obrigatório!")
            return;
        }
        if (currentPart < totalParts) {
            setCurrentPart((prevPart) => prevPart + 1);
        }
    };

    const handleBack = () => {
        if (currentPart > 1) {
            setCurrentPart((prevPart) => prevPart - 1);
        }
    };


    //usuario formulario utils + pessoa formulario utils
    const handleChange = (field: string, value: any) => {
        changeUsuario(field, value, formData, setFormData)
    };

    // Endereco Formulario Utils
    const handleEnderecoInputChange = (index: number, field: string, value: any) => {
        changeEndereco(formData, setFormData, index, field, value)
    };
    const handleAddEndereco = () => {
        addEndereco(formData, setFormData);
    };
    const handleRemoveEndereco = (index: number) => {
        removeEndereco(formData, setFormData, index);
    };

    // Telefone Formulario Utils
    const handleTelefoneInputChange = (index: number, field: string, value: any) => {
        changeTelefone(formData, setFormData, index, field, value);
    };
    const handleAddTelefone = () => {
        addTelefone(formData, setFormData);
    };
    const handleRemoveTelefone = (index: number) => {
        removeTelefone(formData, setFormData, index);
    };
    useEffect(() => {
        setBody(formData);
    }, [formData]);
    return (
        <div className="body-content">
            <div className="form-user-create">
                <Container>
                    <ProgressBarForm
                        currentPart={currentPart}
                        totalParts={totalParts}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        steps={["Filial", "Pessoa", "Endereço", "Telefone"]}
                        body={formData}
                    >
                        {currentPart === 1 && (
                            <FilailForm
                                filialData={formData}
                                onInputChange={(field: string, value: any) => handleChange(`${field}`, value)}
                            />
                        )}
                        {currentPart === 2 && (
                            <PessoaForm
                                isEnablePerfil={false}
                                tipoPessoa={4}
                                tipoAcao={"editar"}
                                pessoaData={formData?.pessoa}
                                onInputChange={(field: string, value: string) => handleChange(`pessoa.${field}`, value)}
                            />
                        )}
                        {currentPart === 3 && (
                            <EnderecoForm
                                enderecosData={formData?.pessoa?.enderecos || []}
                                onInputChange={(index: number, field: string, value: any) => handleEnderecoInputChange(index, field, value)}
                                onAddEndereco={handleAddEndereco}
                                onRemoveEndereco={handleRemoveEndereco}
                            />
                        )}

                        {currentPart === 4 && (
                            <TelefoneForm
                                telefonesData={formData?.pessoa?.telefones || []}
                                onInputChange={(index: number, field: string, value: string) => {
                                    if (formData?.pessoa?.telefones && formData?.pessoa?.telefones?.length > 0) {
                                        handleTelefoneInputChange(index, field, value);
                                    } else {
                                        handleAddTelefone();
                                    }
                                }}
                                onAddTelefone={() => handleAddTelefone()}
                                onRemoveTelefone={(index: number) => handleRemoveTelefone(index)}
                            />
                        )}
                    </ProgressBarForm>

                </Container>
            </div>
        </div>
    );
}

export default EditUsuario;
