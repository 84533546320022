import React, { useState, useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useAppContext } from '../../../../../contexts/AppContext';
import SecaoImovel from '../../Formularios/SecaoImovel';
import ProgressBarFormVenda from '../../Components/progressBarFormVenda';
import SecaoIntermediacao from '../../Formularios/SecaoIntermediacao';
import SecaoEntrada from '../../Formularios/SecaoEntrada';
import SecaoParcelamento from '../../Formularios/SecaoParcelamento';
import api from "../../../../../services/api";
import { AlertService } from '../../../../../components/AlertService';
import { addCompradores, changeCompradores, removeCompradores } from '../../../../../utils/CompradorUtils';
import { Card } from 'primereact/card';
import { showToast } from '../../../../../components/ToastComponent';
import CompradorForm from '../../Formularios/Compradores';
import TokenService from '../../../../../services/Auth/token.service';
import { url } from '../../../../../environments/environments-develop';

function Imobiliaria() {
    const { setUrl, setBody, setTypeRequest } = useAppContext();
    const [saldoDevedor, setSaldoDevedor] = useState<number | undefined>();
    const [currentPart, setCurrentPart] = useState(1);
    const totalParts = 5;
    const [formBasico, setFormBasico] = useState<any>({
        imovel_id: null,
        entrada: null,
        tabela_preco_id: null,
        qtd_parcelas: null,
        num_parcela: null,
        desconto: null,
        premiacao: null,
        comiss_corretor: null,
        tipo_reaj: null,
        identificador1: null,
        data_venda: null,
        identificador2: null,
        justificativa_desc: null,
        numero: null,
        imo_preco: null,
        anotacoes: null,
        imo_intermed: null,
        qtd_parcelas_entrada: null,
        primeira_parcela_entrada: null,
        primeiro_vencimento_entrada: null,
        qtd_parc_intermed: null,
        corretor_id: null,
        descontoImovel: null,
        descontoIntermed: null,
        saldoDevedor: null,
        valor_parcelas: null,
        data_prim_parcela: null,
        primeiro_vencimento_imovel: null,
        valor_venda: null,
        qtd_parcelas_intermed: null,
        primeira_parcela_intermed: null,
        primeiro_vencimento_intermed: null,
        valor_desconto: null,
        valor_parcelas_calc: null,
        cliente_compradores: []
    });
    useEffect(() => {
        if (formBasico?.qtd_parcelas && formBasico?.valor_parcelas) {
            let qtdParcela = Number(formBasico?.qtd_parcelas) || 1;
            let valorParcela = Number(formBasico?.valor_parcelas) || 0;

            let resultado = qtdParcela * valorParcela;
            setSaldoDevedor(resultado);
        }
    }, [formBasico?.qtd_parcelas, formBasico?.valor_parcelas]);
    useEffect(() => {
        async function actionButton(): Promise<void> {
            try {
                const queryParams: any = {};

                const camposEnviar = [
                    'imovel_id',
                    'tabela_preco_id',
                    'entrada',
                    'valor_desconto',
                    'intermed_valor_desc',
                    'valor_parc_intermed',
                    'qtd_parc_intermed',
                    'qtd_parc_entrada',
                    'valor_prim_parc_entrada'
                ];

                camposEnviar.forEach(key => {
                    const value = formBasico[key];
                    if (value !== undefined && value !== null && value !== '') {
                        queryParams[key] = value;
                    }
                });

                const queryString = Object.entries(queryParams)
                    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
                    .join('&');

                const response = await api.get(`${url}/api/v1/admin/vendas/contrato-simular-valor-venda?${queryString}`);

                if (response.status === 200) {
                    Object.keys(response.data.data).forEach(key => {
                        handleChange(key, response.data.data[key]);
                    });
                }
            } catch (error: any) {
                AlertService.error('Erro!', String(error.response?.data?.message) || 'Erro desconhecido');
            }
        }
        if (
            formBasico?.imovel_id ||
            formBasico?.qtd_parcelas ||
            formBasico?.entrada ||
            formBasico?.valor_desconto ||
            formBasico?.intermed_valor_desc ||
            formBasico?.valor_parc_intermed ||
            formBasico?.qtd_parc_intermed ||
            formBasico?.qtd_parc_entrada ||
            formBasico?.valor_prim_parc_entrada
        ) {
            actionButton();
        }
    }, [
        formBasico?.imovel_id,
        formBasico?.qtd_parcelas,
        formBasico?.entrada,
        formBasico?.valor_desconto,
        formBasico?.intermed_valor_desc,
        formBasico?.valor_parc_intermed,
        formBasico?.qtd_parc_intermed,
        formBasico?.qtd_parc_entrada,
        formBasico?.valor_prim_parc_entrada
    ]);
    useEffect(() => {
        const storedData = localStorage.getItem('dadosEdicao');
        let data = {
            "data_venda": formBasico?.data_venda ? new Date(formBasico?.data_venda) : new Date().toUTCString(),
            "numero": formBasico?.numero ?? null,
            "imovel_id": formBasico?.imovel_id ?? null,
            "entrada": formBasico?.entrada ?? null,
            "imo_intermed": formBasico?.imo_intermed ?? null,
            "tabela_preco_id": formBasico?.tabela_preco_id ?? null,
            "anotacoes": formBasico?.anotacoes ?? null,
            "valor_desconto": Number(formBasico?.valor_desconto) ?? null,
            "intermed_valor_desc": Number(formBasico?.descontoIntermed) ?? null,
            "justificativa_desc": formBasico?.justificativa_desc ?? null,
            "status": 1,
            "tipo_contrato": 1,
            "data_prim_parcela": formBasico?.data_prim_parcela ? new Date(formBasico?.data_prim_parcela) : null,
            "qtd_parc_intermed": formBasico?.qtd_parc_intermed ?? 0,
            "venc_prim_parc_intermed": formBasico?.primeiro_vencimento_intermed ? new Date(formBasico?.primeiro_vencimento_intermed) : null,
            "venc_prim_parc_entrada": formBasico?.venc_prim_parc_entrada ? new Date(formBasico?.primeiro_vencimento_entrada) : null,
            "valor_prim_parc_entrada": formBasico?.valor_prim_parc_entrada ?? null,
            "valor_parc_intermed": formBasico?.primeira_parcela_intermed ?? null,
            "qtd_parc_entrada": formBasico?.qtd_parc_entrada ?? 0,
            "coordenador_id": formBasico?.coordenador_id ?? null,
            "corretor_id": formBasico?.corretor_id ?? null,
            "tipo_garantia": formBasico?.tipo_garantia ?? null,
            "clausula_especial": null,
            "gerou_lancamento": TokenService.getEmpreendimentoId()?.gerar_parcelas_contas_receber ?? false,
            "indice_reajuste_id": null,
            "periodicidade_reajuste": 12,
            "empreendimento_id": TokenService.getEmpreendimentoId()?.id,
            "cliente_compradores": formBasico?.cliente_compradores ?? []
        }

        setBody(data);
    }, [formBasico])
    useEffect(() => {
        if (formBasico?.imovel_id && formBasico?.tabela_preco_id) {
            simularSeNecessario();
        }
    }, [
        formBasico?.tabela_preco_id,
        formBasico?.entrada,
        formBasico?.valor_desconto,
        formBasico?.descontoIntermed,
        formBasico?.primeira_parcela_intermed,
        formBasico?.qtd_parc_intermed,
        formBasico?.qtd_parcelas_entrada,
        formBasico?.primeira_parcela_entrada
    ]);
    const simularSeNecessario = async () => {
        try {
            const imovelId = formBasico?.imovel_id;
            const tabelaPrecoId = formBasico?.tabela_preco_id;
            const entrada = formBasico?.entrada;
            const valorDesconto = formBasico?.valor_desconto;
            const descontoIntermed = formBasico?.descontoIntermed;
            const primeiraParcelaIntermed = formBasico?.primeira_parcela_intermed;
            const qtdParcelasIntermed = formBasico?.qtd_parc_intermed;
            const qtdParcelasEntrada = formBasico?.qtd_parcelas_entrada;
            const primeiraParcelaEntrada = formBasico?.primeira_parcela_entrada;

            const paramsObj: Record<string, string | number> = {};

            if (imovelId) paramsObj['imovel_id'] = imovelId.toString();
            if (tabelaPrecoId) paramsObj['tabela_preco_id'] = tabelaPrecoId.toString();
            if (entrada) paramsObj['entrada'] = entrada.toString();
            if (valorDesconto) paramsObj['valor_desconto'] = valorDesconto.toString();
            if (descontoIntermed) paramsObj['intermed_valor_desc'] = descontoIntermed.toString();
            if (primeiraParcelaIntermed) paramsObj['valor_parc_intermed'] = primeiraParcelaIntermed.toString();
            if (qtdParcelasIntermed) paramsObj['qtd_parc_intermed'] = qtdParcelasIntermed.toString();
            if (qtdParcelasEntrada) paramsObj['qtd_parc_entrada'] = qtdParcelasEntrada.toString();
            if (primeiraParcelaEntrada) paramsObj['valor_prim_parc_entrada'] = primeiraParcelaEntrada.toString();

            const urlParams = new URLSearchParams(paramsObj as Record<string, string>);

            const busca = `${url}/api/v1/admin/vendas/contrato-simular-parcela-imovel?${urlParams}`;
            const response = await api.get(busca);

            if (response.status === 200) {
                Object.keys(response.data.data).forEach(key => {
                    handleChange(key, response.data.data[key]);
                });
            }
        } catch (error) {
            console.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    };
    useEffect(() => {
        setTypeRequest('POST');
        setUrl(`${url}/api/v1/admin/vendas/contrato`);
    }, []);
    const handleNext = () => {
        if (!formBasico) {
            showToast("error", "É necessario buscar um lote para prosseguir com a venda!")
            return;
        }
        if (!formBasico?.imovel_id) {
            showToast("error", "O imóvel não foi encontrado ou não está disponível, Efetue a busca do imóvel corretamente!")
            return;
        }
        if (!formBasico?.data_venda) {
            showToast("error", "O campo de data da venda é obrigatório!")
            return;
        }
        if (!formBasico?.corretor_id) {
            showToast("error", "É necessario incluir um corretor para prosseguir, corrija o formulário e tente novamente!")
            return;
        }
        if (formBasico?.cliente_compradores && formBasico?.cliente_compradores.length === 0 && currentPart === 2) {
            showToast("error", "É necessario incluir um comprador para prosseguir, corrija o formulário e tente novamente!")
            return;
        }
        if (!formBasico?.imo_intermed && currentPart === 3) {
            showToast("error", "É necessario incluir o valor da intermediação para prosseguir, corrija o formulário e tente novamente!")
            return;
        }
        if (!formBasico?.qtd_parc_intermed && currentPart === 3) {
            showToast("error", "É necessario incluir a quantidade de parcelas da intermediação para prosseguir, corrija o formulário e tente novamente!")
            return;
        }
        if (formBasico?.qtd_parc_intermed > TokenService.getEmpreendimentoId()?.config_venda?.qtd_parc_intermed && currentPart === 3) {
            showToast('error', 'Quantidade de parcelas da intermediação não pode ser maior que a configurada no empreendimento!');
            return;
        }
        if (formBasico?.qtd_parc_entrada > TokenService.getEmpreendimentoId()?.config_venda?.qtd_parc_entrada && currentPart === 3) {
            showToast('error', 'Quantidade de parcelas da entrada não pode ser maior que a configurada no empreendimento!');
            return;
        }
        if (!formBasico?.primeira_parcela_intermed && currentPart === 3) {
            showToast("error", "É necessario incluir o valor da primeira parcela da intermediação para prosseguir, corrija o formulário e tente novamente!")
            return;
        }
        if (!formBasico?.primeiro_vencimento_intermed && currentPart === 3) {
            showToast("error", "É necessario incluir a data da primeira parcela da intermediação para prosseguir, corrija o formulário e tente novamente!")
            return;
        }
        if (currentPart < totalParts) {
            setCurrentPart((prevPart) => prevPart + 1);
        }
    };

    const handleBack = () => {
        if (currentPart > 1) {
            setCurrentPart((prevPart) => prevPart - 1);
        }
    };

    const handleCompradoresInputChange = (index: number, field: string, value: any) => {
        const clientes = formBasico?.cliente_compradores;
        if (formBasico && clientes && index >= 0 && index < clientes.length) {
            setFormBasico((prevFormBasico: any) => {
                const updatedClientes = [...prevFormBasico.cliente_compradores];

                updatedClientes[index] = {
                    ...updatedClientes[index],
                    [field]: value,
                };
                return {
                    ...prevFormBasico,
                    cliente_compradores: updatedClientes,
                };
            });
        }
    };
    const handleAddCompradores = () => {
        addCompradores(formBasico, setFormBasico);
    };
    const handleRemoveCompradores = (index: number) => {
        removeCompradores(formBasico, setFormBasico, index);
    };

    const MoneyFormatter = (saldoDevedor: any) => {
        const formatMoney = (value: number): string => {
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }).format(value);
        };
        return formatMoney(saldoDevedor);
    };
    const handleChange = (field: string, value: any) => {
        setFormBasico((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    }
    return (
        <>
            <div className="body-content">
                <div className="form-venda-create">
                    <Col md="2" style={{ background: '#d9d9d9 ', padding: '8px', paddingTop: '0px', paddingBottom: '0px', marginRight: '40px', flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
                        <Card style={{ display: 'flex', borderRadius: '10px', justifyContent: 'center', marginBottom: '12px', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)' }}>
                            <Form.Label style={{ color: '#a0a0a0', display: 'flex', justifyContent: 'center' }}>Valor de Tabela</Form.Label>
                            <h6 style={{ display: 'flex', justifyContent: 'center' }}>R$ {formBasico?.imo_preco_format ? formBasico?.imo_preco_format : '0,00'}</h6>
                        </Card>
                        <Card style={{ borderRadius: '10px', display: 'flex', justifyContent: 'center', marginBottom: '12px', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)' }}>
                            <Col>
                                <Form.Label style={{ color: '#a0a0a0', display: 'flex', justifyContent: 'center' }}>Valor Intermediação</Form.Label>
                                <h6 style={{ display: 'flex', justifyContent: 'center' }}>{formBasico?.imo_intermed ? MoneyFormatter(formBasico?.imo_intermed) : 'R$ 0,00'}</h6>
                            </Col>
                        </Card>
                        <Card style={{ borderRadius: '10px', display: 'flex', justifyContent: 'center', marginBottom: '12px', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)' }}>
                            <Col>
                                <Form.Label style={{ color: '#a0a0a0', display: 'flex', justifyContent: 'center' }}>Entrada</Form.Label>
                                <h6 style={{ display: 'flex', justifyContent: 'center' }}>{formBasico?.entrada ? MoneyFormatter(formBasico?.entrada) : 'R$ 0,00'}</h6>
                            </Col>
                        </Card>
                        <Card style={{ borderRadius: '10px', display: 'flex', justifyContent: 'center', marginBottom: '12px', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)' }}>
                            <Col>
                                <Form.Label style={{ color: '#a0a0a0', display: 'flex', justifyContent: 'center' }}>Desconto Imovel</Form.Label>
                                <h6 style={{ display: 'flex', justifyContent: 'center' }}>{formBasico?.valor_desconto ? MoneyFormatter(formBasico?.valor_desconto) : 'R$ 0,00'}</h6>
                            </Col>
                        </Card>
                        <Card style={{ borderRadius: '10px', display: 'flex', justifyContent: 'center', marginBottom: '12px', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)' }}>
                            <Col>
                                <Form.Label style={{ color: '#a0a0a0', display: 'flex', justifyContent: 'center' }}>Saldo Devedor</Form.Label>
                                <h6 style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>
                                    {saldoDevedor ? MoneyFormatter(Number(saldoDevedor)) : 'R$ 0,00'}
                                </h6>

                            </Col>
                        </Card>
                        <Card style={{ borderRadius: '10px', display: 'flex', justifyContent: 'center', marginBottom: '12px', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)' }}>
                            <Col>
                                <Form.Label style={{ color: '#a0a0a0', display: 'flex', justifyContent: 'center' }}>Parcelamento</Form.Label>
                                <div style={{ display: 'flex' }}>
                                    <h6 style={{ display: 'flex', justifyContent: 'center' }}>
                                        <strong>{formBasico?.qtd_parcelas ?? '1'}</strong>
                                    </h6>
                                    <h6 style={{ display: 'flex', justifyContent: 'center' }}>
                                        x {formBasico?.valor_parcelas ? MoneyFormatter(formBasico?.valor_parcelas) : 'R$ 0,00'}
                                    </h6>
                                </div>
                            </Col>
                        </Card>
                        <Card style={{ borderRadius: '10px', display: 'flex', justifyContent: 'center', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)' }}>
                            <Col>
                                <Form.Label style={{ color: '#a0a0a0', display: 'flex', justifyContent: 'center' }}>Valor de Venda</Form.Label>
                                <h6 style={{ display: 'flex', justifyContent: 'center', color: 'green' }}>{formBasico?.valor_venda_format ? formBasico?.valor_venda_format : 'R$ 0,00'}</h6>
                            </Col>
                        </Card>
                    </Col>
                    <Form.Group className="pessoa-form" style={{ width: '-webkit-fill-available', height: '-webkit-fill-available' }}>
                        <Col md="12">
                            <ProgressBarFormVenda
                                currentPart={currentPart}
                                totalParts={totalParts}
                                handleBack={handleBack}
                                handleNext={handleNext}
                                steps={["Dísponibilidade", "Compradores", "Intermediação", "Entrada", "Parcelamento"]}
                            >
                                {currentPart === 1 && (
                                    <SecaoImovel
                                        secaoImovelData={formBasico}
                                        onInputChange={(field: string, value: string) => handleChange(`${field}`, value)} />
                                )}
                                {currentPart === 2 && (
                                    <CompradorForm
                                        compradorData={formBasico?.cliente_compradores || []}
                                        onInputChange={(index: number, field: string, value: any) => handleCompradoresInputChange(index, field, value)}
                                        onAddCompradores={handleAddCompradores}
                                        onRemoveCompradores={handleRemoveCompradores}
                                    />
                                )}
                                {currentPart === 3 && (
                                    <SecaoIntermediacao
                                        secaoIntermediacaoData={formBasico}
                                        onInputChange={(field: string, value: string) => handleChange(`${field}`, value)} />
                                )}
                                {currentPart === 4 && (
                                    <SecaoEntrada
                                        secaoEntradaData={formBasico}
                                        onInputChange={(field: string, value: string) => handleChange(`${field}`, value)} />
                                )}
                                {currentPart === 5 && (
                                    <SecaoParcelamento
                                        secaoParcelamentoData={formBasico}
                                        onInputChange={(field: string, value: string) => handleChange(`${field}`, value)} />
                                )}
                            </ProgressBarFormVenda>
                        </Col>
                    </Form.Group>
                </div>
            </div>
        </>
    );
}

export default Imobiliaria;
