
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from './../../../../../assets/images/icon-calendar.png';
import { useEffect, useState } from "react";
import { mask } from "../../../../contexts/CpfCnpj";
import pt from "date-fns/locale/pt";
import { AutoComplete } from "primereact/autocomplete";
import api from "../../../../services/api";
import { url } from "../../../../environments/environments-develop";
import { AlertService } from "../../../../components/AlertService";
import { searchCliente } from "../Service/InumadoService";

interface FormData {
    [key: string]: any;
}

function InumadoForm(props: any) {
    const [formData, setFormData] = useState<FormData>(props.inumado || {});
    const [dataFalecimento, setDataFalecimento] = useState<Date | null>(null);
    const [dataSepultamento, setDataSepultamento] = useState<Date | null>(null);
    const [listJazigo, setListJazigo] = useState([]);
    const [FilteredPessoa, setFilteredPessoa] = useState<any>(null);
    const [FilteredJazigo, setFilteredJazigo] = useState<any>(null);
    const [listFiltros, setListFiltros] = useState<any>({});

    const isValidDate = (date: any): date is Date => {
        return date instanceof Date && !isNaN(date.getTime());
    };

    const handleChange = (field: string, value: any) => {
        if (field === 'numero_interno' || field === 'numero_exumacao' || field === 'local_falecimento' || field === 'hora_falecimento' || field === 'hora_sepultamento') {
            if (value.length <= 50) {
                props.onInputChange(field, value);
            }
        } else if (field === 'pessoa.nome') {
            if (value.length <= 200) {
                props.onInputChange(field, value);
            }
        } else if (field === 'pessoa.cpf_cnpj') {
            if (value.length <= 18) {
                props.onInputChange(field, value);
            }
        } else if (field === 'causa_mortis' || field === 'classificacao') {
            if (value.length <= 100) {
                props.onInputChange(field, value);
            }
        } else {
            props.onInputChange(field, value);
        }
    };

    async function InitJazigos() {
        try {
            const response = await api.get(`${url}/api/v1/admin/cemiterio/jazigo`);
            setListJazigo(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de jazigos. Erro:' + String(error));
        }
    }

    const searchJazigo = (event: any) => {
        setTimeout(() => {
            let _FilteredJazigo: any;
            if (!event.query.trim().length) {
                _FilteredJazigo = [...listJazigo];
            } else {
                _FilteredJazigo = listJazigo.filter((item: any) => {
                    return item?.logradouro?.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            setFilteredJazigo(_FilteredJazigo);
        }, 250);
    }

    const handleChangeJazigo = (field: string, value: any) => {
        props.onInputChangeJazigo(field, value);
    }

    useEffect(() => {
        InitJazigos();
    }, []);

    useEffect(() => {
        if (props.inumado) {
            setFormData(props.inumado);
        }
        if (props.inumado?.data_falecimento) {
            const falecimentoDate = new Date(props.inumado.data_falecimento);
            if (isValidDate(falecimentoDate)) {
                setDataFalecimento(falecimentoDate);
            }
        }
        if (props.inumado?.data_sepultamento) {
            const sepultamentoDate = new Date(props.inumado.data_sepultamento);
            if (isValidDate(sepultamentoDate)) {
                setDataSepultamento(sepultamentoDate);
            }
        }
    }, [props.inumado]);

    const formatarRG = (rg: string): string => {
        return rg.replace(/[\.-]/g, '');
    };
    

    return (
        <>
            <Form className="inumado-form">
                <Col>
                    <Row className="mb-3">
                        <Col md="2">
                            <Form.Label>Nº Interno</Form.Label>
                            <Form.Control
                                value={formData.numero_interno}
                                isInvalid={!formData.numero_interno}
                                onChange={(e) => handleChange("numero_interno", e.target.value)}
                            />
                        </Col>
                        <Col md="2">
                            <Form.Label>Nº Exumação</Form.Label>
                            <Form.Control
                                value={formData.numero_exumacao}
                                onChange={(e) => handleChange("numero_exumacao", e.target.value)}
                            />
                        </Col>
                        <Col md="2">
                            <Form.Label>Corpo será</Form.Label>
                            <Form.Select
                                value={formData.tipo_servico}
                                isInvalid={!formData.tipo_servico}
                                onChange={(e) => handleChange('tipo_servico', e.target.value)}>
                                <option>Selecione...</option>
                                <option value="1">Sepultado</option>
                                <option value="2">Cremado</option>
                            </Form.Select>
                        </Col>
                        <Col md="2">
                            <Form.Label>Situação</Form.Label>
                            <Form.Select value={formData.situacao} isInvalid={!formData.situacao}
                                onChange={(e) => handleChange('situacao', e.target.value)}>
                                <option>Selecione...</option>
                                <option value="1">Decomposição</option>
                                <option value="2">Restos Mortais</option>
                            </Form.Select>
                        </Col>
                        <Col md="2">
                            <Form.Label>Sexo</Form.Label>
                            <Form.Select value={formData?.pessoa?.sexo}
                                isInvalid={!formData?.pessoa?.sexo}
                                onChange={(e) => handleChange('pessoa.sexo', e.target.value)}>
                                <option>Selecione...</option>
                                <option value="1">Feminino</option>
                                <option value="2">Masculino</option>
                            </Form.Select>
                        </Col>
                        <Col md="2">
                            <Form.Label>RG</Form.Label>
                            <Form.Control
                                value={formData?.pessoa?.rg}
                                onChange={(e) => handleChange("pessoa.rg", formatarRG(e.target.value))}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                                required
                                value={formData?.pessoa?.nome}
                                isInvalid={!formData?.pessoa?.nome}
                                onChange={(e) => handleChange("pessoa.nome", e.target.value)}
                            />
                        </Col>
                        <Col>
                            <Form.Label>CPF</Form.Label>
                            <Form.Control
                                value={mask(formData?.pessoa?.cpf_cnpj)}
                                isInvalid={!formData?.pessoa?.cpf_cnpj}
                                onChange={(e) => handleChange("pessoa.cpf_cnpj", e.target.value)}
                                required
                            />
                        </Col>
                        <Col>
                            <Form.Label>Classificação</Form.Label>
                            <Form.Control
                                value={formData.classificacao}
                                onChange={(e) => handleChange("classificacao", e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md="4">
                            <Form.Label>Causa Mortis</Form.Label>
                            <Form.Control
                                value={formData.causa_mortis}
                                isInvalid={!formData.causa_mortis}
                                onChange={(e) => handleChange("causa_mortis", e.target.value)}
                            />
                        </Col>
                        <Col md="4">
                            <Form.Label>Local do Falecimento</Form.Label>
                            <Form.Control
                                value={formData.local_falecimento}
                                isInvalid={!formData.local_falecimento}
                                onChange={(e) => handleChange("local_falecimento", e.target.value)}
                            />
                        </Col>
                        <Col md="2" style={{ display: 'flex', flexDirection: "column" }}>
                            <Form.Group>
                                <Form.Label>Data do Falecimento</Form.Label>
                                <DatePicker
                                    selected={dataFalecimento}
                                    onChange={(date: Date) => {
                                        setDataFalecimento(date);
                                        handleChange('data_falecimento', date.toISOString());
                                    }}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    fixedHeight
                                    locale={pt}
                                    customInput={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="text"
                                                className="dateFormFinanceiro"
                                                value={dataFalecimento ? dataFalecimento.toLocaleDateString() : ''}
                                                readOnly
                                            />
                                            <img
                                                className="calendar-icon"
                                                src={CalendarIcon}
                                            />
                                        </div>
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md="2">
                            <Form.Label>Hora do Falecimento</Form.Label>
                            <Form.Control
                                value={formData.hora_falecimento}
                                isInvalid={!formData.hora_falecimento}
                                onChange={(e) => handleChange("hora_falecimento", e.target.value + ':00')}
                                type="time"
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md="2" style={{ display: 'flex', flexDirection: "column" }}>
                            <Form.Group >
                                <Form.Label>Data do Sepultamento</Form.Label>
                                <DatePicker
                                    selected={dataSepultamento}
                                    onChange={(date: Date) => {
                                        setDataSepultamento(date);
                                        handleChange('data_sepultamento', date.toISOString());
                                    }}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    locale={pt}
                                    customInput={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="text"
                                                className="dateFormFinanceiro"
                                                value={dataSepultamento ? dataSepultamento.toLocaleDateString() : ''}
                                                readOnly
                                            />
                                            <img
                                                className="calendar-icon"
                                                src={CalendarIcon}
                                            />
                                        </div>
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md="2" style={{ display: 'grid' }}>
                            <Form.Group>
                                <Form.Label>Hora do Sepultamento</Form.Label>
                                <Form.Control
                                    value={formData?.hora_sepultamento}
                                    type="time"
                                    onChange={(e) => handleChange("hora_sepultamento", e.target.value + ':00')}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="3" style={{ display: 'grid' }}>
                            <Form.Label>Cliente Ligado</Form.Label>
                            <AutoComplete
                                field="nome_cliente"
                                style={{ height: "37px" }}
                                value={formData?.nome_cliente ? formData?.nome_cliente : (formData?.cliente ? formData?.cliente?.nome_cliente : '')}
                                suggestions={FilteredPessoa}
                                completeMethod={(e) => searchCliente(e.query, setFilteredPessoa)}
                                onChange={(e) => {
                                    handleChange('nome_cliente', e.value);
                                    setListFiltros({ ...listFiltros, nome_cliente: e.value });
                                }}
                                onSelect={(e) => {
                                    handleChange('cliente_id', e.value.id);
                                    setListFiltros({ ...listFiltros, nome_cliente: e.value.nome_cliente });
                                }}
                            />
                        </Col>
                        <Col md="3" style={{ display: 'grid' }}>
                            <Form.Label>Jazigo associado</Form.Label>
                            <AutoComplete
                                disabled
                                field="nome"
                                style={{ height: "37px" }}
                                value={formData?.logradouro ?? ''}
                                suggestions={FilteredJazigo}
                                completeMethod={searchJazigo}
                                onChange={(e) => handleChangeJazigo('logradouro', e.target.value)}
                            />
                        </Col>
                        <Col md="2" style={{ display: 'grid' }}>
                            <Form.Label style={{ alignItems: "center", justifyContent: "center" }}>Corpo Embalsamado</Form.Label>
                            <Form.Check
                                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                checked={formData.corpo_embalsamado || false}
                                onChange={(e) => handleChange("corpo_embalsamado", e.target.checked)}
                                type="switch"
                                id="custom-switch"
                            />
                        </Col>
                    </Row>
                </Col>
            </Form>
        </>
    );
};

export default InumadoForm;
