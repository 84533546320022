import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { mask } from "../../../../contexts/CpfCnpj";
import InputValidationMiddleware from "./../../../../validators/InputValidationMiddleware";

interface FormData {
    [key: string]: any;
}

function DeclaranteForm(props: any) {
    const [formData, setFormData] = useState<FormData>(props.declaranteData || {});

    const handleChange = (field: string, value: any) => {
        if (field === 'declarante_nome') {
            if (value.length <= 100) {
                setFormData(prev => ({
                    ...prev,
                    [field]: value
                }));
                props.onInputChange(field, value);
            }
        } else if (field === 'declarante_cpf') {
            const cleanValue = value.replace(/[^\d]/g, '');
            if (cleanValue.length <= 11) {
                setFormData(prev => ({
                    ...prev,
                    [field]: mask(cleanValue)
                }));
                props.onInputChange(field, mask(cleanValue));
            }
        } else if (field === 'declarante_rg') {
            if (value.length <= 20) {
                setFormData(prev => ({
                    ...prev,
                    [field]: value
                }));
                props.onInputChange(field, value);
            }
        } else {
            setFormData(prev => ({
                ...prev,
                [field]: value
            }));
            props.onInputChange(field, value);
        }
    };

    useEffect(() => {
        if (props.declaranteData) {
            setFormData(props.declaranteData);
        }
    }, [props.declaranteData]);

    return (
        <InputValidationMiddleware>
            <Form.Group className="pessoa-form">
                <Col>
                    <Row className="mb-3">
                        <Col>
                            <Form.Label>Nome</Form.Label>
                            <Form.Control value={formData.declarante_nome}
                                onChange={(e) => handleChange('declarante_nome', e.target.value)}
                            />
                        </Col>
                        <Col>
                            <Form.Label>CPF</Form.Label>
                            <Form.Control value={mask(formData.declarante_cpf)}
                                onChange={(e) => handleChange('declarante_cpf', e.target.value)}
                            />
                        </Col>
                        <Col>
                            <Form.Label>RG</Form.Label>
                            <Form.Control value={formData.declarante_rg}
                                onChange={(e) => handleChange('declarante_rg', e.target.value)}
                            />
                        </Col>
                    </Row>
                </Col>
            </Form.Group>
        </InputValidationMiddleware>
    );
};

export default DeclaranteForm;
