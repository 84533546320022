import axios from "../../../services/api";
import { from, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { url } from "../../../environments/environments-develop";

interface IApiResponse<T> {
  data: T;
  headers: {
    titles: string[];
  };
  meta: any;
}

class imovelGavetaService {
  private resourceUrl = url + "/api/v1/admin/cemiterio";

  listQuadras(params: any = {}, headers: any = null) {
    return from(
      axios
        .get<IApiResponse<any[]>>(
          `${this.resourceUrl}/imovel-gaveta/lista-quadras`,
          {
            params,
            headers,
          }
        )
        .then((response) => {
          return response.data.data;
        })
    ).pipe(catchError((error) => throwError(error)));
  }

  listLotes(params: any = {}, headers: any = null) {
    return from(
      axios
        .get<IApiResponse<any[]>>(`${this.resourceUrl}/lista-jazigos-tipo`, {
          params,
          headers,
        })
        .then((response) => {
          return response.data.data;
        })
    ).pipe(catchError((error) => throwError(error)));
  }

  listGavetas(
    id: any,
    params: any = {},
    headers: any = null,
    updateTitles: (titles: string[]) => void,
    updatePagination: (totalPages: number) => void
  ): Observable<any[]> {
    return from(
      axios
        .get<IApiResponse<any[]>>(`${this.resourceUrl}/gavetas-jazigo/${id}`, {
          params,
          headers,
        })
        .then((response) => {
          if (response?.data?.headers) {
            updateTitles(response.data.headers.titles);
            updatePagination(response.data.meta.pagination.total_pages);
          }
          return response.data.data;
        })
    ).pipe(catchError((error) => throwError(error)));
  }
}

export default new imovelGavetaService();
