import { AlertService } from "../../../../components/AlertService";
import TokenService from "../../../../services/Auth/token.service";
import { ContratoModel } from "../../../Carteira/Models/ContratoModel";
import ContratoService from "../../../Carteira/Services/ContratoService";
import imovelGavetaService from "../../../Cemiterio/Services/imovelGavetaService";
import ProdutoService from "../../../Cemiterio/Services/ProdutoService";
import TipoJazigoService from "../../../Cemiterio/Services/TipoJazigoService";
import CorretorSevice from "../../../Pessoas/Services/CorretorSevice";
import PessoaService from "../../../Pessoas/Services/PessoaService";

interface Consulta {
  order?: string;
  filtro?: any;
}
const consulta: Consulta = {
  order: "id;desc",
};

export const searchProduto = (
  event: any,
  currentPage: any,
  setFilteredProduto: (value: any) => void
) => {
  const query = event.query;
  const filtro = {
    nome: query,
  };

  if (consulta) {
    consulta.filtro = filtro;
  }

  ProdutoService.list({
    totalitems: 8,
    consulta: JSON.stringify(consulta),
    page: currentPage + 1,
  }).subscribe(
    (response) => {
      setFilteredProduto(response);
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const searchJazigo = (
  event: any,
  currentPage: any,
  setFilteredClient: (value: any) => void
) => {
  const query = event.query;
  const filtro = {
    nome: query,
  };

  if (consulta) {
    consulta.filtro = filtro;
  }

  TipoJazigoService.list({
    totalitems: 8,
    consulta: JSON.stringify(consulta),
    page: currentPage + 1,
  }).subscribe(
    (response) => {
      setFilteredClient(response);
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const searchQuadra = (
  event: any,
  currentPage: any,
  setFilteredClient: (value: any) => void,
  tipo_jazigo_id: any
) => {
  const query = event.query;
  const filtro = {
    nome: query,
  };

  if (consulta) {
    consulta.filtro = filtro;
  }

  imovelGavetaService
    .listQuadras({
      tipo_jazigo_id: tipo_jazigo_id,
      empreendimento_id: TokenService.getEmpreendimentoId().id,
    })
    .subscribe(
      (response) => {
        setFilteredClient(response);
      },
      (error) => {
        let mensagens: string[] = [];
        mensagens.push(error?.response?.data?.error?.message);
        AlertService.error("Erro!", String(mensagens));
      }
    );
};

export const searchLotes = (
  event: any,
  currentPage: any,
  setFilteredClient: (value: any) => void,
  tipo_jazigo_id: any,
  identificador1: any
) => {
  const query = event.query;
  const filtro = {
    nome: query,
  };

  if (consulta) {
    consulta.filtro = filtro;
  }

  imovelGavetaService
    .listLotes({
      tipo_jazigo_id: tipo_jazigo_id,
      identificador1: identificador1,
      empreendimento_id: TokenService.getEmpreendimentoId().id,
    })
    .subscribe(
      (response) => {
        setFilteredClient(response);
      },
      (error) => {
        let mensagens: string[] = [];
        mensagens.push(error?.response?.data?.error?.message);
        AlertService.error("Erro!", String(mensagens));
      }
    );
};

export const searchCorretor = (
  event: any,
  currentPage: any,
  setFilteredClient: (value: any) => void,
  tipo_jazigo_id: any
) => {
  const query = event.query;
  const filtro = {
    nome: query,
  };

  if (consulta) {
    consulta.filtro = filtro;
  }

  CorretorSevice.list({
    tipo_jazigo_id: tipo_jazigo_id,
    empreendimento_id: TokenService.getEmpreendimentoId().id,
  }).subscribe(
    (response) => {
      setFilteredClient(response);
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const searchContrato = (
  event: any,
  setFilteredClient: (value: any) => void
) => {
  const query = event.query;
  const filtro = {
    nome: query,
  };

  if (consulta) {
    consulta.filtro = filtro;
  }

  CorretorSevice.list({
    empreendimento_id: TokenService.getEmpreendimentoId().id,
  }).subscribe(
    (response) => {
      setFilteredClient(response);
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const searchPessoa = (
  event: any,
  setFilteredPessoa: (value: any) => void
) => {
  const query = event.query;
  setTimeout(() => {
    PessoaService.encontrar_pessoa({
      search: query ?? null,
    }).subscribe(
      (response) => {
        setFilteredPessoa(response);
      },
      (error) => {
        let mensagens: string[] = [];
        Object.keys(error?.response?.data?.error?.message).forEach((key) => {
          mensagens.push("\n" + error?.response?.data?.error?.message[key][0]);
        });
        AlertService.error("Erro!", String(mensagens));
      }
    );
  }, 600);
};

export const searchContratoS = (
  event: any,
  currentPage: number,
  setFilteredClient: (value: any) => void,
  updateTitles: (titles: string[]) => void,
  updatePagination: (totalPages: number) => void
) => {
  const query = event.query;
  const filtro = {
    nome: query,
  };
  const consulta: Consulta = {
    order: "id;desc",
    filtro: filtro,
  };

  ContratoService.list(
    {
      consulta: JSON.stringify(consulta),
      page: currentPage,
    },
    null,
    updateTitles,
    updatePagination
  ).subscribe(
    (response) => {
      setFilteredClient(response);
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};
