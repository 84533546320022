import TokenService from '../../../services/Auth/token.service';
import Cemiterio from './Pages/Cemiterio';
import Imobiliaria from './Pages/Imobiliaria';

function NovaVenda() {
    const filial = TokenService.getFilialId();

    if (filial?.tipo_empresa === 1) {
        return <Imobiliaria />;
    } else if (filial?.tipo_empresa === 2) {
        return <Cemiterio />;
    }

    return null;
}

export default NovaVenda;
