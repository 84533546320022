import React, { useRef, useState } from 'react';
import { Col, Modal } from 'react-bootstrap';
import { useAppContext } from '../../../contexts/AppContext';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Controller, useForm } from 'react-hook-form';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { AlertService } from '../../../components/AlertService';
import api from "../../../services/api";
import { url } from '../../../environments/environments-develop';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import ButtonClose from 'react-bootstrap/Button';
import * as xlsx from 'xlsx';
import * as fileSaver from 'file-saver';
import AceEditor from './AceEditor';

import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-monokai';

function RotinasEspeciais() {
    const [formBasico, setFormBasico] = useState<any>([]);
    const [titles, setTitles] = useState<any>([]);
    const [showModalChildren, setShowModalChildren] = useState(false);
    const toast: any = useRef(null);
    const EXCEL_EXTENSION = '.xlsx';
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const show = () => {
        if (toast.current != null)
            toast.current.show({ severity: 'success', summary: 'Sucesso!', detail: form.getValues('description') });
    };

    const defaultValues = { description: formBasico.consulta };
    const form = useForm({ defaultValues });
    const errors: any = form.formState.errors;

    const onSubmit = (data: any) => {
        data.description && show();
        let busca = {
            "consulta": data.description,
        }
        actionButton(busca)
    };
    async function actionButton(busca: any): Promise<void> {
        try {
            const response = await api.post(`${url}/api/v1/admin/core/rotinas-especiais`, JSON.stringify(busca));
            if (response.status === 200) {
                setFormBasico(response.data);
                setColumnsTable(response.data)
                setShowModalChildren(true);
            }
        } catch (error: any) {
            AlertService.error('Erro - ' + String(error.response?.data?.error.status_code), String(error.response?.data?.error.description) || 'Erro desconhecido');
        }
    }
    const generateTableExcel = () => {
        const time = new Date();
        const fileName = `relatório-${time.getTime()}`;
        const worksheet = xlsx.utils.json_to_sheet(formBasico);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        saveExcelFile(excelBuffer, fileName);
    }

    const saveExcelFile = (buffer: any, fileName: string): void => {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE,
        });

        fileSaver.saveAs(data, fileName + '_' + EXCEL_EXTENSION, {
            autoBom: false,
        });
    }


    const setColumnsTable = (result: any) => {
        if (result.length === 0) return;

        const columns = Object.keys(result[0]);
        let columnsTable = columns.map((column): any => {
            // Remove underscores and '_id', and capitalize initial letters
            const formattedHeader = column.replace(/_id/g, '').replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase());

            return {
                field: column,
                header: formattedHeader,
            };
        });
        setTitles(columnsTable);
    }

    const getFormErrorMessage = (name: any) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const handleClick = () => {
        window.location.href = '/licenca';
    };

    return (
        <>
            <div className="body-content">
                <div className="form-group-create">
                    <div className="contentSearch">
                        <div className="contentSearch-header">
                            <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Rotinas Especiais</Col>
                        </div>
                    </div>
                    <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
                    <form onSubmit={form.handleSubmit(onSubmit)} style={{ display: 'flex', margin: '12px', flexDirection: 'column' }}>
                        <div className="card flex justify-content-center" style={{ padding: '22px', border: 'none' }} >
                            <Toast ref={toast} />
                            <Controller
                                name="description"
                                control={form.control}
                                rules={{ required: 'Preencha o campo acima para efetuar a busca..' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <AceEditor
                                            mode="pgsql"
                                            theme="textmate"
                                            onChange={(value) => field.onChange(value)}
                                            value={field.value}
                                        />
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                            <div style={{ display: 'flex', justifyContent: 'left' }}>
                                <Button
                                    icon="pi pi-exclamation-triangle"
                                    rounded outlined severity="help"
                                    style={{
                                        borderRadius: '50px',
                                        backgroundColor: isHovering ? '#ad9200' : 'white',
                                        borderColor: isHovering ? '#ad9200' : 'white',
                                        color: 'white',
                                        cursor: 'pointer',
                                        transition: 'all 0.5s'
                                    }}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    onClick={handleClick}
                                />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'right' }}>
                                <Button severity='success' style={{ height: '40px', width: '150px', borderRadius: '9px' }} label="Submit" type="submit" icon="pi pi-check" />
                            </div>
                        </div>
                    </form>
                    <Modal show={showModalChildren} size="xl" style={{ padding: '0px !important' }} onHide={() => setShowModalChildren(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title className='text-center'>Resultado</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ padding: '0px !important' }}>
                            <DataTable
                                stripedRows
                                paginator rows={5}
                                paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                                value={formBasico ? formBasico : []}
                                tableStyle={{ minWidth: '50rem' }}>
                                {titles && titles.map((col: any) => (
                                    <Column
                                        style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                                        key={col.field}
                                        field={col.field}
                                        header={col.header}
                                    />
                                ))}
                            </DataTable>
                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonClose variant="success" onClick={() => generateTableExcel()}>Imprimir excel</ButtonClose>
                            <ButtonClose variant="secondary" onClick={() => setShowModalChildren(false)}>Fechar</ButtonClose>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </>
    );
}

export default RotinasEspeciais;
