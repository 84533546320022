import * as React from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

interface BreadcrumbItem {
  label?: string; // Alterei para opcional
  to?: string;
  svg?: React.ReactNode; // Adicionado para suportar o SVG
}

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, to?: string) {
  event.preventDefault();
  var adjustedPath: any = to;

  if (to && to.split('/').length >= 3) {
    adjustedPath = `${to}/index`;
  }
  window.location.href = adjustedPath;
}


export default function CustomSeparator() {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const breadcrumbs: BreadcrumbItem[] = [
    {
      svg: (
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="25" viewBox="0 0 27 25" fill="none">
          <g filter="url(#filter0_d_117_2143)">
            <path d="M22.511 10.7157L14.4901 4.31457C14.2293 4.10648 13.8859 3.99386 13.5317 4.00026C13.1775 4.00666 12.8398 4.13158 12.5892 4.34891L5.21231 10.748L4.83374 11.0764V20.51H11.9424V14.624H15.8198V20.51H22.9284V11.0488L22.511 10.7157ZM13.5595 5.12117C13.5709 5.12117 13.5639 5.12331 13.559 5.12748C13.5539 5.12331 13.5481 5.12117 13.5595 5.12117ZM21.6359 19.3888H17.1123V14.624C17.1123 14.3266 16.9761 14.0414 16.7337 13.8312C16.4913 13.6209 16.1626 13.5028 15.8198 13.5028H11.9424C11.5996 13.5028 11.2708 13.6209 11.0284 13.8312C10.7861 14.0414 10.6499 14.3266 10.6499 14.624V19.3888H6.12622V11.5408L13.5595 5.14167C13.56 5.14125 13.5601 5.14086 13.5605 5.14044L21.6359 11.5408V19.3888Z" fill="white" />
          </g>
          <defs>
            <filter id="filter0_d_117_2143" x="0.83374" y="0" width="26.0947" height="24.5098" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feOffset />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_117_2143" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_117_2143" result="shape" />
            </filter>
          </defs>
        </svg>
      )
    },
    ...pathnames.map((_, index, array) => ({
      label: _.charAt(0).toUpperCase() + _.slice(1),
      to: `/${array.slice(0, index + 1).join('/')}`,
    })),
  ];

  return (
    <Stack spacing={2}>
      <Breadcrumbs separator={<svg xmlns="http://www.w3.org/2000/svg" width="14" height="27" viewBox="0 0 14 27" fill="none">
        <g filter="url(#filter0_d_227_2)">
          <path d="M4 6.014L7.90099 13.5L4 20.986L5.0495 23L10 13.5L5.0495 4L4 6.014Z" fill="white" />
        </g>
        <defs>
          <filter id="filter0_d_227_2" x="0" y="0" width="14" height="27" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_227_2" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_227_2" result="shape" />
          </filter>
        </defs>
      </svg>} aria-label="breadcrumb">
        {breadcrumbs.map((breadcrumb, index) => (
          <Link
            underline="hover"
            key={index}
            color="white"
            component={RouterLink}
            to={breadcrumb.to || ''}
            onClick={(event) => handleClick(event, breadcrumb.to)}
          >
            {breadcrumb.label !== 'Index'
              && breadcrumb.label !== 'Store'
              && breadcrumb.label !== 'Update'
              && (breadcrumb.svg || breadcrumb.label)}
          </Link>
        ))}
      </Breadcrumbs>
    </Stack>
  );
}
