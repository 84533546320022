import { useAppContext } from '../../../contexts/AppContext';
import { useEffect, useState } from 'react';
import { AlertService } from '../../../components/AlertService';
import TokenService from '../../../services/Auth/token.service';
import FiltroGestaoContratos from './Components/filtro';
import TableComponentsCessao from './Components/TableComponentsCessao';

function CessaoDeDireito() {
  const { showSubSidebar } = useAppContext();
  const [listFiltros, setListFiltros] = useState<any>({});
  const [filtros, setFiltros] = useState<string>();
  const [formBasico, setFormBasico] = useState<any>({});

  async function montarFiltro(): Promise<void> {
    try {
      const consulta: any = {
        consulta: {
          order: "id;desc",
          filtro: {
            "numero": listFiltros ? listFiltros?.numero : null,
            "status": listFiltros ? listFiltros?.status : null,
            "data_venda_format": listFiltros ? listFiltros?.data_venda_format : null,
            "qtd_parcelas": listFiltros ? listFiltros?.qtd_parcelas : null,
            "data_Inicio": listFiltros ? listFiltros?.data_Inicio : null,
            "data_Fim": listFiltros ? listFiltros?.data_Fim : null,
            "empreendimento_id": TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().id : null
          }
        }
      };
      const queryParams: any = {};

      const camposEnviar = ['consulta'];
      camposEnviar.forEach((key: any) => {
        const value = consulta[key];
        if (value !== undefined && value !== null && value !== '') {
          if (key === 'consulta') {
            queryParams[key] = JSON.stringify(value);
          } else {
            queryParams[key] = value;
          }
        }
      });
      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
        .join('&');
      setFiltros(queryString);
    } catch (error: any) {
      AlertService.error('Erro!', String(error.response?.data?.message) || 'Erro desconhecido');
    }
  }

  useEffect(() => {
    montarFiltro();
  }, [listFiltros]);

  const handleChange = (field: string, value: string) => {
    setFormBasico((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };

  const handleChangeList = (field: string, value: string) => {
    setListFiltros((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };

  const handleClearList = () => {
    setListFiltros(null);
    setFormBasico(null);
    montarFiltro();
  };

  return (
    <div className="body-content">
      <div
        className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}
      >
        <FiltroGestaoContratos
          onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
          onInputChangeList={(field: string, value: string) => handleChangeList(`${field}`, value)}
          onInputClearList={() => handleClearList()}
          filtrosData={formBasico}
          header={'Gestão Cessão de Direitos'}
        />
      </div>
      <div
        className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}
      >
        <TableComponentsCessao
          key={filtros}
          urlRedirectCreated=""
          urlSearch={`/api/v1/admin/carteira/contrato-transf?${filtros}`}
          urlSearchDataPut="/api/v1/admin/carteira/contrato-transf/"
          url_redirect_edit="/contrato/update"
          url_redirect_show="/carteira/contrato-transferencia/show"
        />
      </div>
    </div>
  );
}
export default CessaoDeDireito;