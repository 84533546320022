import Swal from "sweetalert2";

export class AlertService {
  static sucess(title: string, text: string) {
    return Swal.fire(title, text, "success");
  }
  static infomessage(title: string, text: string) {
    return Swal.fire(title, text, "info");
  }

  static error(title: string, text: string) {
    const formattedText = text.replace(/\n/g, "<br>");
    Swal.fire(title, formattedText, "error");
  }

  swalInit(obj: any) {
    return Swal.fire(obj);
  }

  prompt(options: any) {
    const baseOptions = {
      showCancelButton: true,
      confirmButtonText: "Submit",
      input: "text",
    };
    return Swal.fire(Object.assign(baseOptions, options));
  }

  static confirm(options: any) {
    const baseOptions = {
      showCancelButton: true,
      confirmButtonText: "Confirm",
      type: "warning",
    };
    return Swal.fire(Object.assign(baseOptions, options));
  }

  alert(options: any) {
    const baseOptions = {
      confirmButtonText: "OK",
      type: "info",
    };
    return Swal.fire(Object.assign(baseOptions, options));
  }

  question(options: any) {
    return this.alert(Object.assign({ type: "question" }, options));
  }

  success(options: any) {
    return this.alert(Object.assign({ type: "success" }, options));
  }

  error(options: any) {
    return this.alert(Object.assign({ type: "error" }, options));
  }

  warn(options: any) {
    return this.alert(Object.assign({ type: "warn" }, options));
  }

  info(options: any) {
    return this.alert(Object.assign({ type: "info" }, options));
  }
}
