import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useSidebarContext } from '../../../contexts/SidebarContext';
import { Col, Form, FormControl, InputGroup, Modal, NavLink, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-autocomplete-input/dist/bundle.css';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import api from '../../../services/api';
import { AutoComplete } from 'primereact/autocomplete';
import { AlertService } from '../../../components/AlertService';
import { InputNumber } from 'primereact/inputnumber';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TokenService from '../../../services/Auth/token.service';
import { showToast } from '../../../components/ToastComponent';

function ReajusteForm() {
  const [reajuste, setReajuste] = useState<any>({
    "empreendimento_id": TokenService.getEmpreendimentoId().id,
  });
  const [tipoIndice, setTipoIndice] = useState([]);
  const [memoriaCalculo, setMemoriaCalculo] = useState<any>();
  const [indice, setIndice] = useState([]);
  const { isVisible, updateVisibility } = useSidebarContext();
  const { showSubSidebar } = useAppContext();

  const toggleVisibility = () => {
    const newValue = !isVisible;
    updateVisibility(newValue);
  };

  const handleChange = (field: string, value: any) => {
    setReajuste((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };
  async function InitTipoIndice() {
    try {
      const response = await api.get(`${url}/api/v1/admin/financeiro/tipo-indice`);
      setTipoIndice(response.data.data);
    } catch (error) {
      AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
    }
  }
  async function InitIndice() {
    try {
      const response = await api.get(`${url}/api/v1/admin/financeiro/indice?totalitens=4`);
      setIndice(response.data.data);
    } catch (error) {
      AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
    }
  }
  const simularReajusteParcela = async () => {
    if (!reajuste?.num_contrato && !reajuste?.vencimento) {
      showToast("error", "É preciso informar o numero do contrato OU o vencimento")
      return;
    }
    if (reajuste?.num_contrato && reajuste?.vencimento) {
      showToast("error", "Informe somente um dos campos, OU numero do contrato OU o vencimento, não é permitido informar os dois!")
      return;
    }
    if (!reajuste?.tipo_indice_id) {
      showToast("error", "É obrigatorio informar o tipo de índice")
      return;
    }
    if (!reajuste?.tipo_parcela) {
      showToast("error", "É obrigatorio informar o tipo de parcela")
      return;
    }
    try {
      const response = await api.post(`${url}/api/v1/admin/financeiro/simularReajuste`, reajuste);
      if (response.status === 200) {
        setMemoriaCalculo(response.data.data);
      }
    } catch (error: any) {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error('Erro!', String(mensagens));
    }
  };

  const simularReajuste = async () => {
    try {
      const response = await api.post(`${url}/api/v1/admin/financeiro/simularReajuste`, reajuste);
      if (response.status === 200) {
        setMemoriaCalculo(response.data.data);
      }
    } catch (error: any) {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error('Erro!', String(mensagens));
    }
  };

  const reajusteParcela = async () => {
    if (!reajuste?.num_contrato && !reajuste?.vencimento) {
      showToast("error", "É preciso informar o numero do contrato OU o vencimento")
      return;
    }
    if (reajuste?.num_contrato && reajuste?.vencimento) {
      showToast("error", "Informe somente um dos campos, OU numero do contrato OU o vencimento, não é permitido informar os dois!")
      return;
    }
    if (!reajuste?.tipo_indice_id) {
      showToast("error", "É obrigatorio informar o tipo de índice")
      return;
    }
    if (!reajuste?.tipo_parcela) {
      showToast("error", "É obrigatorio informar o tipo de parcela")
      return;
    }
    try {
      const response = await api.post(`${url}/api/v1/admin/financeiro/reajuste`, reajuste);
      if (response.status === 200) {
        setMemoriaCalculo(response.data.data);
      }
    } catch (error: any) {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error('Erro!', String(mensagens));
    }
  };

  const formatNumber = (e: any) => {
    let value = e.target.value.replace(/[^0-9.,]/g, '');
    let parts = value.split(',');
    parts[0] = parts[0].replace(/\./g, '');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    if (parts.length > 2) {
      parts = [parts[0], parts.slice(1).join('')];
    }
    e.target.value = parts.join(',');
  };

  useEffect(() => {
    InitTipoIndice();
    InitIndice();
    simularReajuste();
  }, []);
  return (
    <div className='body-content-reajuste'>
      <div className='content-reajuste'>
        <div
          className={`${showSubSidebar ? "container-section-reajuste" : "container-section-reajuste-none"}`}
        >
          <div className="contentSearch-header">
            <NavLink onClick={toggleVisibility}>
              <img
                className="menu-context"
                src={iconMenuTresPonto}
              />
            </NavLink>
            <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Reajuste</Col>
          </div>
          <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
          <p style={{ display: 'flex', marginTop: '6px', textAlign: 'center', justifyContent: 'center', marginBottom: '0px' }}>Ultimos Indices Publicados</p>
          <div style={{ display: 'flex', justifyContent: 'center', marginRight: '13px', marginLeft: '13px', marginBottom: '13px' }}>
            {indice?.map((item: any, key: any) => (
              <div className='card-list-reajuste'>
                <Form.Label>{item.tipo_indice?.nome}</Form.Label>
                <Form.Label>{item.mes_referencia}</Form.Label>
                <Form.Label>{item.valor}%</Form.Label>
              </div>
            ))}
          </div>
          <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
          <p style={{ display: 'flex', marginTop: '6px', textAlign: 'center', justifyContent: 'center', marginBottom: '0px' }}>Parâmetros</p>
          <Form.Group className="imovel-form" style={{ marginLeft: '22px' }}>
            <Col className='col-reajuste'>
              <Form.Label>Número do Contrato</Form.Label>
              <Col md="3">
                <Form.Control placeholder='Número do contrato' value={reajuste.num_contrato} onChange={(e) => handleChange("num_contrato", e.target.value)} />
              </Col>
            </Col>
            <Col className='col-reajuste'>
              <Form.Label>Juros</Form.Label>
              <Col md="3">
                <FormControl
                  id="rate"
                  value={reajuste.juros ?? 0}
                  min={0.00}
                  step={0.001}
                  max={1.00}
                  onInput={formatNumber}
                  placeholder='1%'
                  onChange={(e) => handleChange('juros', e.target.value)}
                />
              </Col>
            </Col>
            <Col className='col-reajuste'  >
              <Form.Label>Tipo de Indice</Form.Label>
              <Col md="3">
                <Form.Select value={reajuste.tipo_indice_id} onChange={(e) => handleChange('tipo_indice_id', e.target.value)}>
                  <option>Selecione...</option>
                  {tipoIndice?.map((item: any, key: any) => (
                    <option key={key} value={item.id}>
                      {item.nome}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Col>
            <Col className='col-reajuste'>
              <Form.Label>Tipo Parcela</Form.Label>
              <Col md="3">
                <Form.Select value={reajuste.tipo_parcela ?? 1} onChange={(e) => handleChange('tipo_parcela', e.target.value)}>
                  <option value={1}>Parcela Ordinária (M)</option>
                  <option value={2}>Balão</option>
                  <option value={3}>Parcela Única (U)</option>
                  <option value={4}>Saldo Restante</option>
                  <option value={5}>Comissões</option>
                  <option value={6}>Temporario (Antecipação com desconto/Agrupamentos)</option>
                  <option value={7}>Condominio</option>
                  <option value={8}>Parcela Mensal Pos Habite-se (P)</option>
                  <option value={10}>Parcela Intermediação (I)</option>
                  <option value={11}>Parcela de Entrada (E)</option>
                  <option value={12}>Agrupado</option>
                  <option value={9}>Parcela Avulso Acordo(A)/(Renegociado através de contrato (aditivo), parcela adicional autorizada)</option>
                </Form.Select>
              </Col>
            </Col>
            <Col className='col-reajuste'>
              <Form.Label>Mes e Ano de Vencimento</Form.Label>
              <Col md="3">
                <Form.Control placeholder='Mês/Ano' value={reajuste.vencimento} onChange={(e) => handleChange("vencimento", e.target.value)} />
              </Col>
            </Col>
            <Col className='col-reajuste'>
              <Form.Label>Tipo de reajuste da parcela</Form.Label>
              <Col md="3">
                <Form.Select value={reajuste.tipo_reajuste_parcela ?? 0} onChange={(e) => handleChange('tipo_reajuste_parcela', e.target.value)}>
                  <option value={0}>Anual</option>
                  <option value={1}>Mensal</option>
                </Form.Select>
              </Col>
            </Col>
            <Col className='col-reajuste'>
              <Form.Label>QTD|Meses offset</Form.Label>
              <Col md="3">
                <Form.Control type="number" placeholder='1' value={reajuste.qtd_mes_offset ?? 0} onChange={(e) => handleChange("qtd_mes_offset", e.target.value)} />
              </Col>
            </Col>
            <Col className='col-reajuste'>
              <Form.Label>Calculo de juros</Form.Label>
              <Col md="3">
                <Form.Select value={reajuste.calc_juros ?? 0} onChange={(e) => handleChange('calc_juros', e.target.value)}>
                  <option value={0}>Antes</option>
                  <option value={1}>Após</option>
                </Form.Select>
              </Col>
            </Col>
          </Form.Group>
          <div className='div-buttons-reajuste'>
            <Button
              onClick={() => { simularReajusteParcela() }}
              style={{ height: '30px', marginBottom: '13px', marginRight: '24px', borderRadius: '20px', padding: '5px 10px', fontSize: '14px' }}
              type="button"
              severity="success"
              icon="pi pi-refresh"
              rounded>
              <p style={{ marginLeft: '5px', marginTop: '13px' }}>Simular Reajuste</p>
            </Button>
          </div>
        </div>
      </div>
      <div className='content-reajuste-memoria' >
        <div
          className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}
        >
          <div style={{ padding: '22px' }}>
            <Form.Label style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>Dados Básicos</Form.Label>
            <Col style={{ padding: '0px', display: 'flex', justifyContent: 'space-between' }}>
              <Form.Label>Descrição do cálculo</Form.Label>
              <Form.Label>{memoriaCalculo?.descricao_tipo_indice}</Form.Label>
            </Col>
            <Col style={{ padding: '0px', display: 'flex', justifyContent: 'space-between' }}>
              <Form.Label>Valor Nominal</Form.Label>
              <Form.Label>{memoriaCalculo?.valor_nominal}</Form.Label>
            </Col>
            <Col style={{ padding: '0px', display: 'flex', justifyContent: 'space-between' }}>
              <Form.Label>Período da correção</Form.Label>
              <Form.Label>{memoriaCalculo?.periodo}</Form.Label>
            </Col>
          </div>
        </div>
        <div
          style={{ marginTop: '10px', padding: '22px' }}
          className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}
        >
          <div className="contentSearch" style={{ marginBottom: '10px' }}>
            <div style={{ padding: '12px' }}>
              <Form.Label style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>Dados calculados</Form.Label>
              <Col style={{ padding: '0px', display: 'flex', justifyContent: 'space-between' }}>
                <Form.Label>Fator de correção do período</Form.Label>
                <Form.Label>{memoriaCalculo?.fator}</Form.Label>
              </Col>
              <Col style={{ padding: '0px', display: 'flex', justifyContent: 'space-between' }}>
                <Form.Label>Percentual correspondente</Form.Label>
                <Form.Label>{memoriaCalculo?.variacao}%</Form.Label>
              </Col>
              <Col style={{ padding: '0px', display: 'flex', justifyContent: 'space-between' }}>
                <Form.Label>Valor corrigido</Form.Label>
                <Form.Label>{memoriaCalculo?.valor_corrigido}</Form.Label>
              </Col>
            </div>
          </div>
        </div>
        <div
          style={{ marginTop: '10px', paddingLeft: '22px', paddingRight: '22px' }}
          className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}
        >
          <div className="contentSearch" style={{ marginBottom: '10px' }}>
            <div style={{ padding: '12px' }}>
              <Form.Label style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>Memória analítica do cálculo</Form.Label>
              <Col style={{ padding: '0px', display: 'flex', justifyContent: 'space-between' }}>
                <Form.Label>Valor inicial</Form.Label>
                <Form.Label>{memoriaCalculo?.valor_nominal}</Form.Label>
              </Col>
              <div className='table-memoria-calc'>
                <DataTable
                  stripedRows
                  lazy
                  value={memoriaCalculo ? memoriaCalculo.calculo : []}
                  tableStyle={{ minWidth: '50rem', maxHeight: '50rem' }}
                  emptyMessage={<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>}
                >
                  <Column
                    key={'periodo'} field={'periodo'} header={'Periodicidade'}
                    style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                  />
                  <Column
                    key={'fator_original'} field={'fator_original'} header={'Fator Original'}
                    style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                  />
                  <Column
                    key={'variacao'} field={'variacao'} header={'Variação'}
                    style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                  />
                  <Column
                    key={'valor'} field={'valor'} header={'Valor'}
                    style={{ textAlign: 'center', borderBottom: '1px solid #bdbdbd96' }}
                  />
                </DataTable>
              </div>
            </div>
          </div>
          <div className='div-buttons-reajuste'>
            <Button
              onClick={() => { reajusteParcela() }}
              style={{ height: '30px', marginBottom: '13px', marginRight: '24px', borderRadius: '20px', padding: '5px 10px', fontSize: '14px' }}
              type="button"
              severity="success"
              icon="pi pi-check"
              rounded>
              <p style={{ marginLeft: '5px', marginTop: '13px' }}>Confirmar Reajuste</p>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReajusteForm;