import { Col, Form, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import { useEffect, useState } from "react";
import pt from "date-fns/locale/pt";
import TokenService from "../../../../services/Auth/token.service";
import api from "../../../../services/api";
import { url } from "../../../../environments/environments-develop";
import { AlertService } from "../../../../components/AlertService";
import { InputNumber } from "primereact/inputnumber";

function ImovelForm(props: any) {
    const { identificador1, identificador2, unidade, area, incdec, status,
        corretor_id, logradouro, frente, fundo, confr_fundo, lado_direito, confr_lado_direito,
        lado_esquerdo, confr_lado_esquerdo, chanfro, imo_preco, imo_intermed, imo_matricula,
        construcao, observacao, tipo_imovel_id, sub_tipo_imovel_id, classif_preco_id } = props.imovelData || {};
    const [ult_pgto_iptu, setUltPgtoIptu] = useState<Date | null>(null);
    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };
    const [tipoImovel, setTipoImovel] = useState<any>([]);
    const [subTipoImovel, setSubTipoImovel] = useState<any>([]);
    const [classImovel, setClassImovel] = useState<any>([]);

    useEffect(() => {
        const ultPgtoIptuValue = props.imovelData.ult_pgto_iptu;
        if (ultPgtoIptuValue) {
            const parsedDate = new Date(ultPgtoIptuValue);
            if (!isNaN(parsedDate.getTime())) {
                setUltPgtoIptu(parsedDate);
            } else {
                console.error("Invalid date format for ult_pgto_iptu:", ultPgtoIptuValue);
            }
        }
    }, [props.imovelData.ult_pgto_iptu]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const consulta: any = {
                    consulta: {
                        order: "id;desc",
                        filtro: {
                            "empreendimento_id": TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().id : null
                        }
                    }
                };

                const queryParams: any = {};

                const camposEnviar = ['consulta'];

                camposEnviar.forEach((key: any) => {
                    const value = consulta[key];
                    if (value !== undefined && value !== null && value !== '') {
                        if (key === 'consulta') {
                            // Converte o objeto consulta em uma string JSON e codifica para URI
                            queryParams[key] = JSON.stringify(value);
                        } else {
                            queryParams[key] = value;
                        }
                    }
                });

                const queryString = Object.entries(queryParams)
                    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
                    .join('&');

                const [responseTiposImovel, responseSubtiposImovel, responseClassImovel] = await Promise.all([
                    api.get(`${url}/api/v1/admin/vendas/tipoimovel?${queryString}`),
                    api.get(`${url}/api/v1/admin/vendas/subtipoimovel?${queryString}`),
                    api.get(`${url}/api/v1/admin/vendas/classificacaoimoveis/index-rotulos?${queryString}`)
                ]);
                if (responseTiposImovel.status === 200) {
                    setTipoImovel(responseTiposImovel.data.data);
                }
                if (responseSubtiposImovel.status === 200) {
                    setSubTipoImovel(responseSubtiposImovel.data.data);
                }
                if (responseClassImovel.status === 200) {
                    setClassImovel(responseClassImovel.data.data);
                }
            } catch (error) {
                AlertService.error('Erro!', String('Erro desconhecido'));
            }
        };
        fetchData();
    }, []);
    return (
        <Form.Group className="imovel-form" style={{ overflowX: "hidden", overflowY: "scroll" }}>
            <Form.Group className="imovel-form">
                <Row className="mb-3">
                    <h3 className="label-form-create-imovel">Indentificador do imóvel</h3>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>Corretor</Form.Label>
                        <Form.Control value={corretor_id} onChange={(e) => handleChange('corretor_id', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>
                            {TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().alias_identificador1 : 'Identificador 1'}
                        </Form.Label>
                        <Form.Control value={identificador1} onChange={(e) => handleChange('identificador1', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>
                            {TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().alias_identificador2 : 'Identificador 2'}
                        </Form.Label>
                        <Form.Control value={identificador2} onChange={(e) => handleChange('identificador2', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Logradouro</Form.Label>
                        <Form.Control value={logradouro} onChange={(e) => handleChange('logradouro', e.target.value)} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>Unidade</Form.Label>
                        <Form.Control value={unidade} onChange={(e) => handleChange('unidade', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Area</Form.Label>
                        <Form.Control value={area} onChange={(e) => handleChange('area', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>INCDEC</Form.Label>
                        <Form.Control value={incdec} onChange={(e) => handleChange('incdec', e.target.value)} />
                    </Col>
                    <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Status</Form.Label>
                        <Form.Select value={status || ''} onChange={(e) => handleChange('status', e.target.value)}>
                            <option>Selecione...</option>
                            <option value={"1"}>Disponível</option>
                            <option value={"2"}>Reservado</option>
                            <option value={"3"}>Reserva Fixa</option>
                            <option value={"4"}>Negociado</option>
                            <option value={"5"}>Vendido</option>
                            <option value={"6"}>Reserva Técnica</option>
                            <option value={"7"}>Reserva Diretoria</option>
                        </Form.Select>
                    </Form.Group>
                </Row>
            </Form.Group>
            <Form.Group className="imovel-form">
                <Row className="mb-3">
                    <h3 className="label-form-create-imovel">Detalhes do imóvel</h3>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>Frente</Form.Label>
                        <Form.Control value={frente} onChange={(e) => handleChange('frente', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Fundo</Form.Label>
                        <Form.Control value={fundo} onChange={(e) => handleChange('fundo', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Confrontante do Fundo </Form.Label>
                        <Form.Control value={confr_fundo} onChange={(e) => handleChange('confr_fundo', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Lado direto</Form.Label>
                        <Form.Control value={lado_direito} onChange={(e) => handleChange('lado_direito', e.target.value)} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>Confrontante do Lado Direito</Form.Label>
                        <Form.Control value={confr_lado_direito} onChange={(e) => handleChange('confr_lado_direito', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Lado Esquerdo</Form.Label>
                        <Form.Control value={lado_esquerdo} onChange={(e) => handleChange('lado_esquerdo', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Confrontante do Lado Esquerdo </Form.Label>
                        <Form.Control value={confr_lado_esquerdo} onChange={(e) => handleChange('confr_lado_esquerdo', e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Label>Chanfro</Form.Label>
                        <Form.Control value={chanfro} onChange={(e) => handleChange('chanfro', e.target.value)} />
                    </Col>

                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>Preço do Imóvel</Form.Label>
                        <InputNumber
                            style={{ width: '100%', height: '38px' }}
                            value={imo_preco}
                            onChange={(e) => handleChange('imo_preco', e.value)}
                            mode="currency" currency="BRL" locale="pt-BR"
                        />
                    </Col>
                    <Col>
                        <Form.Label>Valor da Intermediação do Imóvel</Form.Label>
                        <InputNumber
                            style={{ width: '100%', height: '38px' }}
                            value={imo_intermed}
                            onChange={(e) => handleChange('imo_intermed', e.value)}
                            mode="currency" currency="BRL" locale="pt-BR"
                        />
                    </Col>
                    <Col>
                        <Form.Label>Matrícula do Imóvel </Form.Label>
                        <Form.Control value={imo_matricula} onChange={(e) => handleChange('imo_matricula', e.target.value)} />
                    </Col>

                    <Col controlId="formGridState" style={{ display: 'grid' }}>
                        <Form.Label>Data do Ult. Pgto do Iptu</Form.Label>
                        <DatePicker
                            selected={ult_pgto_iptu || null}
                            onChange={(date: Date) => {
                                setUltPgtoIptu(date);
                                handleChange('ult_pgto_iptu', date ? date.toISOString() : ''); // Garante que a string seja vazia se a data for nula
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            fixedHeight
                            locale={pt}
                            customInput={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="text"
                                        placeholder="Data do Ult. Pgto do Iptu"
                                        className="dateFormFinanceiro"
                                        value={ult_pgto_iptu ? ult_pgto_iptu.toLocaleDateString() : ''}
                                        readOnly
                                    />
                                    <img
                                        className="calendar-icon"
                                        src={CalendarIcon}
                                    />
                                </div>
                            }
                        />
                    </Col>
                    <Col md="1" style={{ padding: "0px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Form.Label>Construção</Form.Label>
                        <Form.Check
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", }}
                            checked={construcao || false}
                            onChange={(e) => handleChange("construcao", String(e.target.checked))}
                            type="switch"
                            id="custom-switch"
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>Tipo Imóvel</Form.Label>
                        <Form.Select value={tipo_imovel_id} onChange={(e) => handleChange('tipo_imovel_id', e.target.value)}>
                            <option>Selecione...</option>
                            {tipoImovel.map((tipoimovel: any) => (
                                <option value={tipoimovel.id}>{tipoimovel.nome}</option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Label>SubTipo Imovel</Form.Label>
                        <Form.Select value={sub_tipo_imovel_id} onChange={(e) => handleChange('sub_tipo_imovel_id', e.target.value)}>
                            <option>Selecione...</option>
                            {subTipoImovel.map((subtipo: any) => (
                                <option value={subtipo.id}>{subtipo.nome}</option>
                            ))}
                        </Form.Select>                    </Col>
                    <Col>
                        <Form.Label>Classificação do Imóvel</Form.Label>
                        <Form.Select value={classif_preco_id} onChange={(e) => handleChange('classif_preco_id', e.target.value)}>
                            <option>Selecione...</option>
                            {classImovel.map((classificacao: any) => (
                                <option value={classificacao.id}>{classificacao.rotulo}</option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Label>Observação</Form.Label>
                        <Form.Control value={observacao} onChange={(e) => handleChange('observacao', e.target.value)} />
                    </Col>
                </Row>
            </Form.Group >
        </Form.Group>
    );
}

export default ImovelForm;
