import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import TokenService from '../../../services/Auth/token.service';
import CentroCustoForm from './Formularios/CentroCustoForm';

// Add the correct path to the module

interface PropsData {
    codigoConcat?: string;
    codigo?: string;
    nome?: string;
    tipo?: string;
    parent_id?: string;
    status?: boolean;
    recebe_lancamento?: boolean;
    contabil?: boolean;
}

function CreateRenegociacao() {
    const { setUrl, setBody, setShowButtonSave } = useAppContext();
    const [formBasico, setFormBasico] = useState<PropsData>({});

    useEffect(() => {
        setUrl(`${url}/api/v1/admin/financeiro/centro-custo`);
        setShowButtonSave(true);
        setFormBasico((prevImovelData) => {
            return {
                ...prevImovelData,
                ['empreendimento_id']: TokenService.getEmpreendimentoId()?.id,
                ['status']: true,
                ['recebe_lancamento']: false,
                ['contabil']: false
            };
        });
        const storedData = localStorage.getItem('dadosEdicao');
        if (storedData) {
            setFormBasico((prevGrupoData) => {
                const dadosAtualizados = {
                    ...prevGrupoData,
                    ...JSON.parse(storedData)
                };
                return dadosAtualizados;
            });
        }
        return () => {
            localStorage.removeItem('dadosEdicao');
        };
    }, []);


    const handleChange = (field: string, value: string) => {
        setFormBasico((prevGrupoData) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    }

    useEffect(() => {
        setBody({
            codigo: formBasico.codigoConcat ? formBasico.codigoConcat + '.' + formBasico.codigo : formBasico.codigo,
            nome: formBasico.nome,
            tipo: formBasico.tipo,
            parent_id: formBasico.parent_id,
            status: formBasico.status,
            recebe_lancamento: formBasico.recebe_lancamento,
            contabil: formBasico.contabil,
        })
    }, [formBasico]);

    return (
        <>
            <div className="body-content">
                <div className="form-create-group">
                    <div className="form-create-group-header">
                        <h3>Cadastrar Centro de Custo</h3>
                    </div>
                </div>
                <div className="form-group-create">
                    <Container>
                        <CentroCustoForm
                            initialRightValues={[]}
                            Data={formBasico}
                            onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default CreateRenegociacao;
