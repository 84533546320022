import { Col, Form, InputGroup, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../assets/images/icon-calendar.png';
import { useEffect, useState } from "react";
import { mask } from "../../contexts/CpfCnpj";
import FotoPerfilForm from './fotoPerfil';
import noFoto from '../../../assets/images/profile.png';
import InputValidationMiddleware from "../../validators/InputValidationMiddleware";
import pt from "date-fns/locale/pt";
import { AlertService } from "../../components/AlertService";
import api from "../../services/api";
import { url } from "../../environments/environments-develop";
import TokenService from "../../services/Auth/token.service";

function PessoaForm(props: any,) {
    const [formData, setFormData] = useState<any>(props.pessoaData || {});
    const [dataNascimento, setDataNascimento] = useState<Date | null>(null);
    const [dataFundacao, setDataFundacao] = useState<Date | null>(null);
    const [touchedFields, setTouchedFields] = useState<string[]>([]);
    const [disableForm, setDisableForm] = useState(false);
    const [pessoaJuridica, setPessoaJuridica] = useState(props.typePessoaFisicaOrJuridica ? true : false);

    useEffect(() => {
        if (TokenService.getPerfil()?.data?.is_admin === true) {
            setDisableForm(false)
        } else if (validaPreenchimento().invalidCPF) {
            setDisableForm(true)
        }
    }, []);
    const handleChange = (field: string, value: any) => {
        if (field === 'cpf_cnpj') {
            if (/^[0-9.;\/\-]*$/.test(value) && value.length <= 18) {
                props.onInputChange(field, value);
                setFormData((data: any) => {
                    return {
                        ...data,
                        [field]: value
                    }
                });
            }
        } else if (field === 'nome') {
            if (!value || value.length <= 60) {
                props.onInputChange(field, value);
                setFormData((data: any) => {
                    return {
                        ...data,
                        [field]: value
                    }
                });
            }
        } else if (field === 'nome') {
            if (/^\d*$/.test(value) && value.length <= 60) {
                props.onInputChange(field, value);
                setFormData((data: any) => {
                    return {
                        ...data,
                        [field]: value
                    }
                });
            }
        } else if (field === 'inscricao_municipal') {
            if (/^[0-9.;\/\-]*$/.test(value) && value.length <= 25) {
                props.onInputChange(field, value);
                setFormData((data: any) => {
                    return {
                        ...data,
                        [field]: value
                    }
                });
            }
        } else if (field === 'inscricao_estadual') {
            if (/^[0-9.;\/\-]*$/.test(value) && value.length <= 25) {
                props.onInputChange(field, value);
                setFormData((data: any) => {
                    return {
                        ...data,
                        [field]: value
                    }
                });
            }
        } else if (field === 'email') {
            if (!value || value.length <= 60) {
                const newValue = value.toLowerCase();
                props.onInputChange(field, newValue);
                setFormData((data: any) => {
                    return {
                        ...data,
                        [field]: newValue
                    }
                });
            }
        } else if (field === 'filiacao_mae') {
            if (!value || value.length <= 60) {
                props.onInputChange(field, value);
                setFormData((data: any) => {
                    return {
                        ...data,
                        [field]: value
                    }
                });
            }
        } else if (field === 'filiacao_pai') {
            if (!value || value.length <= 60) {
                props.onInputChange(field, value);
                setFormData((data: any) => {
                    return {
                        ...data,
                        [field]: value
                    }
                });
            }
        } else if (field === 'rg') {
            if (/^\d*$/.test(value) && value.length <= 11) {
                props.onInputChange(field, value);
                setFormData((data: any) => {
                    return {
                        ...data,
                        [field]: value
                    }
                });
            }
        } else {
            props.onInputChange(field, value);
            setFormData((data: any) => {
                return {
                    ...data,
                    [field]: value
                }
            });
        }
        if (!touchedFields.includes(field)) {
            setTouchedFields([...touchedFields, field]);
        }
        if (field === 'cpf_cnpj') {
            const cpfCnpjLimpo = limparCPF_CNPJ(value);
            if (cpfCnpjLimpo) {
                if (cpfCnpjLimpo.length >= 14) {
                    setPessoaJuridica(true);
                } else {
                    setPessoaJuridica(false);
                }
            }
        }
    };
    useEffect(() => {
        if (window.location.href.includes("update")) {
            setFormData((data: any) => {
                return {
                    ...data,
                    ...props.pessoaData
                }
            });
        }
    }, [props.pessoaData])

    async function pesquisarCpfCnpj(valor: any) {
        try {
            const cpfCnpjLimpo = limparCPF_CNPJ(valor);
            if (cpfCnpjLimpo.length >= 11) {
                const response = await api.get(`${url}/api/v1/admin/core/pessoa/pesquisar-cpf-cnpj/${props.tipoPessoa}/${cpfCnpjLimpo}`);
                Object.keys(response.data.data).forEach((field) => {
                    handleChange(field, response.data.data[field]);
                    if (field == 'data_nascimento') {
                        setDataNascimento(new Date(response.data.data[field]));
                    } else if (field === 'data_fundacao') {
                        setDataFundacao(new Date(response.data.data[field]));
                    }
                });
            } else {
                AlertService.error('Erro!', 'Preencha o campo CPF/CNPJ');
            }
        } catch (error: any) {
            AlertService.error('Erro!', String(error?.response?.data?.error?.message || 'Erro ao pesquisar CPF/CNPJ'));
        }
    }

    const limparCPF_CNPJ = (valor: any) => {
        if (valor) {
            return valor.replace(/\D/g, '');
        }
        return null;
    };
    useEffect(() => {
        const cpfCnpjLimpo = limparCPF_CNPJ(formData.cpf_cnpj);
        if (cpfCnpjLimpo) {
            if (cpfCnpjLimpo.length >= 14) {
                setPessoaJuridica(true);
            } else {
                setPessoaJuridica(false);
            }
        }
        if (!dataNascimento) {
            setDataNascimento(props.pessoaData && props.pessoaData?.data_nascimento ? new Date(props.pessoaData?.data_nascimento) : null);
        }
        if (!dataFundacao) {
            setDataFundacao(props.pessoaData && props.pessoaData?.data_fundacao ? new Date(props.pessoaData?.data_fundacao) : null);
        }
    }, [formData.cpf_cnpj])

    const validaPreenchimento = () => {
        if (props.tipoAcao == "editar") {
            return { invalidCPF: true };
        } else {
            return { invalidCPF: false };
        }
    };

    return (
        <>
            <InputValidationMiddleware>
                <Form.Group className="pessoa-form">
                    {props.isEnablePerfil !== false && (<Col md="3" className="create-foto" style={{ display: 'flex', flexDirection: 'column' }}>
                        <FotoPerfilForm height={220} width={220} texto="Adcionar Foto" foto={noFoto} />
                    </Col>)}
                    <Col className={`${props.isEnablePerfil ? 'md-12' : 'md-9'}`}>
                        <Row className="mb-3">
                            <Col>
                                <Form.Label>CPF ou CNPJ</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        value={mask(formData.cpf_cnpj || '')}
                                        onChange={(e) => handleChange("cpf_cnpj", e.target.value)}
                                        isInvalid={!formData.cpf_cnpj}
                                        onBlur={(e) => pesquisarCpfCnpj(e.target.value)}
                                        required
                                        disabled={disableForm}
                                    />
                                </InputGroup>
                            </Col>
                            <Form.Group as={Col} controlId="validationCustomUsername">
                                {pessoaJuridica ? (<Form.Label>Nome Fantasia</Form.Label>) : (<Form.Label>Nome</Form.Label>)}
                                <InputGroup hasValidation>
                                    <Form.Control
                                        required
                                        value={formData.nome || ''}
                                        isInvalid={!formData.nome}
                                        onChange={(e) => handleChange("nome", e.target.value)}
                                        onBlur={(e) => handleChange("nome", e.target.value)}
                                    />
                                </InputGroup>
                            </Form.Group>
                            {pessoaJuridica && (<Col>
                                <Form.Label>Razão Social</Form.Label>
                                <Form.Control
                                    value={formData.razao_social || ''}
                                    onChange={(e) => handleChange("razao_social", e.target.value)}
                                />
                            </Col>)}
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Form.Label>Email</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        value={formData.email || ''}
                                        onChange={(e) => handleChange("email", e.target.value)}
                                        onBlur={(e) => handleChange("email", e.target.value)}
                                    />
                                </InputGroup>
                            </Col>
                            {!pessoaJuridica && (<Col>
                                <Form.Label>RG</Form.Label>
                                <Form.Control
                                    value={formData.rg || ''}
                                    onChange={(e) => handleChange("rg", e.target.value)}
                                />
                            </Col>)}
                            {!pessoaJuridica && (<Form.Group as={Col} controlId="formGridState">
                                <Form.Label>Estado Civil</Form.Label>
                                <Form.Select value={formData.estado_civil || ''} onChange={(e) => handleChange('estado_civil', e.target.value)}>
                                    <option>Selecione...</option>
                                    <option value={"1"}>Solteiro(a)</option>
                                    <option value={"2"}>Casado(a)</option>
                                    <option value={"3"}>Viúvo(a)</option>
                                    <option value={"4"}>Separado(a)</option>
                                    <option value={"5"}>Divorciado(a)</option>
                                    <option value={"6"}>União Estável</option>
                                </Form.Select>
                            </Form.Group>)}
                            {pessoaJuridica && (<Col>
                                <Form.Label>Inscrição Municipal</Form.Label>
                                <Form.Control value={formData.inscricao_municipal || ''} onChange={(e) => handleChange("inscricao_municipal", e.target.value)} />
                            </Col>)}
                            {pessoaJuridica && (<Col>
                                <Form.Label>Inscrição Estadual</Form.Label>
                                <Form.Control value={formData.inscricao_estadual || ''} onChange={(e) => handleChange("inscricao_estadual", e.target.value)} />
                            </Col>)}
                            {pessoaJuridica && (<Form.Group as={Col} controlId="formGridState" style={{ display: 'grid' }}>
                                <Form.Label>Data de Fundação</Form.Label>
                                <DatePicker
                                    selected={dataFundacao}
                                    onChange={(date: Date) => {
                                        setDataFundacao(date);
                                        handleChange('data_fundacao', date.toISOString());
                                    }}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    fixedHeight
                                    locale={pt}
                                    customInput={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="text"
                                                placeholder="Data de Fundação"
                                                className="dateFormFinanceiro"
                                                value={dataFundacao ? dataFundacao.toLocaleDateString() : ''}
                                                readOnly
                                            />
                                            <img
                                                className="calendar-icon"
                                                src={CalendarIcon}
                                            />
                                        </div>
                                    }
                                />
                            </Form.Group>)}
                        </Row>
                        {!pessoaJuridica && (<Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridState">
                                <Form.Label>Regime União</Form.Label>
                                <Form.Select value={formData.regime_uniao || ''} onChange={(e) => handleChange('regime_uniao', e.target.value)}>
                                    <option>Selecione...</option>
                                    <option value={"1"}>Nenhum</option>
                                    <option value={"2"}>Comunhão Parcial de Bens</option>
                                    <option value={"3"}>Comunhão Universal de Bens</option>
                                    <option value={"4"}>Separação Total de Bens</option>
                                    <option value={"5"}>Participação Final nos Aquestos</option>
                                    <option value={"6"}>Separação Obrigatória de Bens</option>
                                    <option value={"7"}>Regime Dotal</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridState">
                                <Form.Label>Sexo</Form.Label>
                                <Form.Select value={formData.sexo || ''} onChange={(e) => handleChange('sexo', e.target.value)}>
                                    <option>Selecione...</option>
                                    <option value={"2"}>Masculino</option>
                                    <option value={"1"}>Feminino</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridState" style={{ display: 'grid' }}>
                                <Form.Label>Data de Nascimento</Form.Label>
                                <DatePicker
                                    selected={dataNascimento}
                                    onChange={(date: Date) => {
                                        setDataNascimento(date);
                                        handleChange('data_nascimento', date.toISOString());
                                    }}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    fixedHeight
                                    locale={pt}
                                    customInput={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="text"
                                                placeholder="Data de Nascimento"
                                                className="dateFormFinanceiro"
                                                value={dataNascimento ? dataNascimento.toLocaleDateString() : ''}
                                                readOnly
                                            />
                                            <img
                                                className="calendar-icon"
                                                src={CalendarIcon}
                                            />
                                        </div>
                                    }
                                />
                            </Form.Group>
                        </Row>)}
                        {!pessoaJuridica && (<Row className="mb-3">
                            <Col>
                                <Form.Label>Nome da Mãe</Form.Label>
                                <Form.Control value={formData.filiacao_mae || ''} onChange={(e) => handleChange("filiacao_mae", e.target.value)} />
                            </Col>
                            <Col>
                                <Form.Label>Nome do Pai</Form.Label>
                                <Form.Control value={formData.filiacao_pai || ''} onChange={(e) => handleChange("filiacao_pai", e.target.value)} />
                            </Col>
                        </Row>)}
                    </Col>
                </Form.Group>
            </InputValidationMiddleware>
        </>
    );
}

export default PessoaForm;
