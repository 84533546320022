import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Form } from 'react-bootstrap';
import { Button } from 'primereact/button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import pt from 'date-fns/locale/pt';
import CalendarIcon from '../../../../../../../assets/images/icon-calendar.png';

interface FiltroAvancadoModalProps {
    showModal: boolean;
    onHide: () => void;
    listFiltros: any;
    setListFiltros: any | null;
    loadData: (filtros: any, currentPage: number, setLoading: (nodes: any) => void, setNodes: (nodes: any) => void, updateTitles: (titles: string[]) => void, updatePagination: (pages: number) => void, setShowModalFiltro: (show: boolean) => void) => void;
    currentPage: number;
    setLoading: (nodes: any) => void;
    setNodes: (nodes: any) => void;
    updateTitles: (titles: string[]) => void;
    updatePagination: (pages: number) => void;
    setShowModalFiltro: (show: boolean) => void;
    handleClose: () => void;
}

const FiltroAvancadoModal: React.FC<FiltroAvancadoModalProps> = ({
    showModal,
    onHide,
    listFiltros,
    setListFiltros,
    loadData,
    currentPage,
    setLoading,
    setNodes,
    updateTitles,
    updatePagination,
    setShowModalFiltro
}) => {
    return (
        <Modal
            show={showModal}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            size="lg"
            className="modal-input-venda"
        >
            <Modal.Header closeButton>
                <Modal.Title>Filtros Avançados</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md="3">
                        <Form.Label>Período</Form.Label>
                        <Form.Select
                            value={listFiltros?.tipo_consulta ?? null}
                            onChange={(e) => setListFiltros({ ...listFiltros, tipo_consulta: e.target.value })}
                        >
                            <option>Selecione um tipo</option>
                            <option value={1}>Data venda</option>
                        </Form.Select>
                    </Col>
                    <Col md="3">
                        <Form.Label>Data Início</Form.Label>
                        <Form.Group controlId="formGridState" >
                            <DatePicker
                                selected={listFiltros?.data_inicial ? new Date(listFiltros?.data_inicial) : null}
                                onChange={(date: Date) => {
                                    setListFiltros({ ...listFiltros, data_inicial: date.toISOString().split('T')[0] });
                                }}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                fixedHeight
                                locale={pt}
                                customInput={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="date"
                                            placeholder="Data Início"
                                            className="dateFormFinanceiro"
                                            value={listFiltros?.data_inicial ? listFiltros?.data_inicial.toISOString().split('T')[0] : ''}
                                            readOnly
                                        />
                                        <img
                                            className="calendar-icon"
                                            src={CalendarIcon}
                                        />
                                    </div>
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col md="3">
                        <Form.Label>Data Fim</Form.Label>
                        <Form.Group controlId="formGridState" >
                            <DatePicker
                                selected={listFiltros?.data_final ? new Date(listFiltros?.data_final) : null}
                                onChange={(date: Date) => {
                                    setListFiltros({ ...listFiltros, data_final: date.toISOString().split('T')[0] });
                                }}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                fixedHeight
                                locale={pt}
                                customInput={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="date"
                                            placeholder="Data Fim"
                                            className="dateFormFinanceiro"
                                            value={listFiltros?.data_final ? listFiltros?.data_final.toISOString().split('T')[0] : ''}
                                            readOnly
                                        />
                                        <img
                                            className="calendar-icon"
                                            src={CalendarIcon}
                                        />
                                    </div>
                                }
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="success"
                    icon="pi pi-filter"
                    rounded
                    onClick={() => loadData(listFiltros, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro)}
                >
                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
                </Button>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="danger"
                    icon="pi pi-delete-left"
                    rounded
                    onClick={() => {
                        setListFiltros({});
                    }}
                >
                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FiltroAvancadoModal;
