import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import ImovelForm from './Formularios/imovelForm';
import TokenService from '../../../services/Auth/token.service';
import { fi } from 'date-fns/locale';

interface PropsImovelData {
    identificador1?: string;
    identificador2?: string;
    unidade?: string;
    area?: string;
    incdec?: string;
    status?: boolean;
    corretor_id?: string;
    logradouro?: string;
    frente?: string;
    fundo?: string;
    confr_fundo?: string;
    lado_direito?: string;
    confr_lado_direito?: string;
    lado_esquerdo?: string;
    confr_lado_esquerdo?: string;
    chanfro?: string;
    imo_preco?: string;
    imo_intermed?: string;
    imo_matricula?: string;
    construcao?: boolean;
    ult_pgto_iptu?: string;
    observacao?: string;
    tipo_imovel_id?: number;
    sub_tipo_imovel_id?: number;
    classif_preco_id?: number;
}

function CreateImovel() {
    const { setUrl, setBody, setShowButtonSave } = useAppContext();
    const [imovelData, setImovelData] = useState<PropsImovelData>({});

    useEffect(() => {
        setUrl(`${url}/api/v1/admin/vendas/imovel`);
        setShowButtonSave(true);
        setImovelData((prevImovelData) => {
            return {
                ...prevImovelData,
                ['empreendimento_id']: TokenService.getEmpreendimentoId()?.id,
                ['construcao']: false
            };
        });
    }, []);


    const handleChange = (field: string, value: any) => {
        setImovelData((prevImovelData) => {
            return {
                ...prevImovelData,
                [field]: value
            };
        });
        setBody(imovelData)
    }
    return (
        <div className="body-content">
            <div className="form-create-group">
                <div className="form-create-group-header">
                    <h3>Cadastrar Imóvel</h3>
                </div>
            </div>
            <div className="form-group-create">
                <Container>
                    <ImovelForm
                        initialRightValues={[]}
                        imovelData={imovelData}
                        onInputChange={(field: string, value: any) => handleChange(field, value)}
                    />
                </Container>
            </div>
        </div>
    );
}

export default CreateImovel;
