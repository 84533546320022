import axios, { AxiosError } from "axios";
import TokenService from "./Auth/token.service";
import { url } from "../environments/environments-develop";

const instance = axios.create({
  baseURL: url + "/api",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    const contextUser = TokenService.getContextUser();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    if (contextUser) {
      config.headers["context_user"] = contextUser.data.context;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// instance.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (err: AxiosError) => {
//     const originalConfig = err.config;

//     if (
//       originalConfig &&
//       originalConfig.url !== "/auth/signin" &&
//       err.response
//     ) {
//       // Access Token was expired
//       if (err.response.status === 401) {
//         try {
//           const rs = await instance.post("/auth/refreshtoken", {
//             refreshToken: TokenService.getLocalRefreshToken(),
//           });

//           const { accessToken } = rs.data;
//           TokenService.updateLocalAccessToken(accessToken);

//           return instance(originalConfig);
//         } catch (_error) {
//           return Promise.reject(_error);
//         }
//       }
//     }

//     return Promise.reject(err);
//   }
// );

export default instance;
