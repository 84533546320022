import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import FormBasico from '../../Formularios/FormBasico';
import TokenService from '../../../services/Auth/token.service';

interface PropsData {
    nome?: string;
    descricao?: string;
    status?: boolean;
}

function CreateSubTipoImoveis() {
    const { setUrl, setBody, setShowButtonSave } = useAppContext();
    const [formBasico, setFormBasico] = useState<PropsData>({});

    useEffect(() => {
        setUrl(`${url}/api/v1/admin/vendas/subtipoimovel`);
        setShowButtonSave(true);
        setFormBasico((prevImovelData) => {
            return {
                ...prevImovelData,
                ['empreendimento_id']: TokenService.getEmpreendimentoId()?.id,
                ['status']: false
            };
        });
    }, []);


    const handleChange = (field: string, value: string) => {
        setFormBasico((prevGrupoData) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
    }
    useEffect(() => {
        setBody(formBasico)
    }, [formBasico])
    return (
        <>
            <div className="body-content">
                <div className="form-create-group">
                    <div className="form-create-group-header">
                        <h3>Cadastrar SubTipo Imovéis</h3>
                    </div>
                </div>
                <div className="form-group-create">
                    <Container>
                        <FormBasico
                            initialRightValues={[]}
                            Data={formBasico}
                            onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default CreateSubTipoImoveis;
