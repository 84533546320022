import React, { useState, useEffect } from 'react';
import { AlertService } from './AlertService';
import { url } from '../environments/environments-develop';
import api from '../services/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Column } from 'primereact/column';
import { MDBCol, MDBPageItem, MDBPageNav, MDBPagination, MDBRow } from 'mdbreact';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';


interface TableComponentsAuditListProps {
    urlSearch: string;
    urlShow: string;
    urlRedirectShow: string;
}

const TableComponentsAudit: React.FC<TableComponentsAuditListProps> = ({ urlSearch, urlShow, urlRedirectShow }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [nodes, setNodes] = useState<any | null>(null);
    const [titles, setTitles] = useState<any | null>(null);
    const [totalPages, setTotalPages] = useState(0);
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await api.get(`${url}${urlSearch}&page=${currentPage + 1}`);
                if (response.status === 200) {
                    const titles = response.data.headers.titles;
                    setTitles(titles)
                    const data = response.data.data;
                    const totalPages = response.data.meta.pagination.total_pages;
                    setTotalPages(totalPages);
                    setNodes(data);
                    setLoading(false);
                }
            } catch (error: any) {
                let mensagens: string[] = [];
                mensagens.push(error?.response?.data?.error?.message);
                AlertService.error('Erro!', String(mensagens));
            }
        };

        fetchData();
    }, [currentPage, urlSearch]);

    const renderPageNumbers = () => {
        const pageNumbers = [];

        for (let i = 0; i < totalPages; i++) {
            // Adicione as três primeiras páginas
            if (i < 3 || i === totalPages - 1 || (i >= currentPage - 1 && i <= currentPage + 1)) {
                pageNumbers.push(
                    <MDBPageItem key={i} active={i === currentPage}>
                        <MDBPageNav className="page-link" onClick={() => setCurrentPage(i)}>
                            <span key={i} onClick={() => setCurrentPage(i)} style={{ cursor: 'pointer', margin: '0 5px', fontWeight: i === currentPage ? 'bold' : 'normal' }}>
                                {i + 1}
                            </span>
                        </MDBPageNav>
                    </MDBPageItem>

                );
            }
        }

        return pageNumbers;
    };

    const actionTemplate = (value: any) => {
        return (
            <div className="flex flex-wrap gap-2" style={{ float: 'right' }}>
                <Link
                    to={urlRedirectShow}
                    onClick={(e) => setSessionEditData(value.id)}
                    className="link-perfil"
                >
                    <Button style={{ width: '30px', height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }} type="button" icon="pi pi-eye" severity="warning" rounded>
                    </Button>
                </Link>
            </div>
        );
    };
    const setSessionEditData = async (value: any) => {
        try {
            const response = await api.get(`${url}${urlShow}${value}`);
            if (response.status === 200) {
                await localStorage.setItem('dadosShow', JSON.stringify(response.data));
            }
        } catch (error: any) {
            let mensagens: string[] = [];
            mensagens.push(error?.response?.data?.error?.message);
            AlertService.error('Erro!', String(mensagens));
        }
    };

    return (
        <div className="card">
            <DataTable
                lazy
                size="small"
                stripedRows
                value={nodes ? nodes : []}
                emptyMessage={isLoading ? (<Skeleton />) : (<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>)}
            >
                {titles && titles.map((col: any) => (
                    <Column
                        style={{ borderBottom: '1px solid #bdbdbd96' }}
                        key={col.dataField}
                        field={col.dataField}
                        header={col.heading}
                        body={isLoading && (<Skeleton />)}
                    />
                ))}
                <Column style={{ width: '1%', borderBottom: '1px solid #bdbdbd96' }} body={(e) => actionTemplate(e)} headerClassName="w-10rem" />
            </DataTable>
            <div className="pagination-footer">
                <MDBRow>
                    <MDBCol>
                        <MDBPagination circle>
                            <MDBPageItem disabled={currentPage === 0}>
                                <MDBPageNav className="page-link" aria-label="Previous" onClick={() => setCurrentPage(currentPage - 1)}>
                                    <span aria-hidden="true">&laquo;</span>
                                </MDBPageNav>
                            </MDBPageItem>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {renderPageNumbers()}
                            </div>
                            <MDBPageItem disabled={currentPage === totalPages - 1}>
                                <MDBPageNav className="page-link" aria-label="Next" onClick={() => setCurrentPage(currentPage + 1)}>
                                    <span aria-hidden="true">&raquo;</span>
                                </MDBPageNav>
                            </MDBPageItem>
                        </MDBPagination>
                    </MDBCol>
                </MDBRow>
            </div>
        </div>
    );
};

export default TableComponentsAudit;
