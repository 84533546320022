import { useEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { url } from "../../../../environments/environments-develop";
import api from '../../../../services/api';
import { AlertService } from "../../../../components/AlertService";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import pt from 'date-fns/locale/pt';
import { TreeSelect } from "primereact/treeselect";
import { TreeNode } from "primereact/treenode";
import { AutoComplete } from "primereact/autocomplete";
import InputMask from 'react-input-mask';
import { searchCliente } from "../Service/MovCaixaService";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
interface PropsData {
    Data: {
        tipo?: number;
        tipo_documento?: number;
        justificativa?: string;
        nome?: string;
        cliente?: any;
        cliente_id?: string;
        recorrente?: boolean;
        numero_documento?: string;
        conta_financeiro_id?: string;
        plano_conta_id?: string;
        centro_custo?: string[];
        data_competencia?: string;
        num_parcela?: number;
        valor_pago?: string;
        valor_original?: number;
        data_vencimento?: string;
        data_pagamento?: string;
        data_efetivacao?: string;
        descricao_caixa?: string;
    };
    onInputChange: (field: string, value: any) => void;
}
function MovCaixaForm(props: any) {
    const { tipo_documento,
        justificativa,
        nome,
        cliente,
        cliente_id,
        numero_documento,
        conta_financeiro_id,
        valor_original,
        valor_pago,
        plano_conta_id,
        centro_custo,
        data_competencia,
        tipo,
        descricao_caixa
    } = props.Data || {};
    const [FilteredCliente, setFilteredCliente] = useState<any>(null);
    const [listPlanoConta, setListPlanoConta] = useState<TreeNode[]>([]);
    const [listCentroCusto, setListCentroCusto] = useState<TreeNode[]>([]);
    const [data_pagamento, setDataPagamento] = useState<Date | null>(null);
    const [listFiltros, setListFiltros] = useState<any>({});
    const [data_vencimento, setDataVencimento] = useState<Date | null>(null);
    const [listContaFinanceira, setListContaFinanceira] = useState([]);
    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };

    const [listCompradores, setListCompradores] = useState([]);

    useEffect(() => {
        setDataPagamento(props.Data.data_pagamento ? new Date(props.Data.data_pagamento) : null);
        setDataVencimento(props.Data.data_vencimento ? new Date(props.Data.data_vencimento) : null)
        data_competencia && handleChange('data_competencia', data_competencia);
    }, [props.Data.data_pagamento, props.Data.data_vencimento])

    async function InitCompradores() {
        try {
            const response = await api.get(`${url}/api/v1/admin/financeiro/cliente?include=pessoa`);
            setListCompradores(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    }
    async function InitContaFinanceira() {
        try {
            const response = await api.get(`${url}/api/v1/admin/financeiro/conta-financeira`);
            setListContaFinanceira(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    }
    addLocale('pt', {
        firstDayOfWeek: 0,
        dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });

    async function InitPlanoConta() {
        try {
            const includeParam = 'tipo_label,filhos.filhos.filhos.filhos.filhos.filhos';
            const response = await api.get(`${url}/api/v1/admin/financeiro/plano-conta?include=${includeParam}`);
            if (response.status === 200) {
                const data = response.data.data;

                const buildTreeNode = (item: any): TreeNode | null => {
                    if (item) {
                        const hasChildren = item.filhos?.data && item.filhos.data.length > 0;
                        return {
                            key: item.id.toString(),
                            label: item.nome || item.id.toString(),
                            data: item,
                            icon: hasChildren ? 'pi pi-fw pi-folder' : 'pi pi-fw pi-file',
                            children: hasChildren ? item.filhos.data.map((child: any) => buildTreeNode(child)) : null,
                            selectable: !hasChildren,  // Não pode ser selecionado se tiver filhos
                        };
                    }
                    return null;
                };

                const nodesData: TreeNode[] = data
                    ? data.reduce((acc: TreeNode[], item: any) => {
                        const treeNode = buildTreeNode(item);
                        if (treeNode) {
                            acc.push(treeNode);
                        }
                        return acc;
                    }, [])
                    : [];
                setListPlanoConta(nodesData);
            }
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro: ' + String(error));
        }
    }
    async function InitCentroCusto() {
        try {
            const includeParam = 'tipo_label,filhos.filhos.filhos.filhos.filhos.filhos'
            const response = await api.get(`${url}/api/v1/admin/financeiro/centro-custo?include=${includeParam}`);
            if (response.status === 200) {
                const data = response.data.data;

                const buildTreeNode = (item: any): TreeNode | null => {
                    if (item) {
                        const hasChildren = item.filhos?.data && item.filhos.data.length > 0;
                        return {
                            key: item.id.toString(),
                            label: item.nome || item.id.toString(),
                            data: item,
                            icon: hasChildren ? 'pi pi-fw pi-folder' : 'pi pi-fw pi-file',
                            children: hasChildren ? item.filhos.data.map((child: any) => buildTreeNode(child)) : null,
                            selectable: !hasChildren,  // Não pode ser selecionado se tiver filhos
                        };
                    }
                    return null;
                };

                const nodesData: TreeNode[] = data
                    ? data.reduce((acc: TreeNode[], item: any) => {
                        const treeNode = buildTreeNode(item);
                        if (treeNode) {
                            acc.push(treeNode);
                        }
                        return acc;
                    }, [])
                    : [];
                setListCentroCusto(nodesData);
            }
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    }

    useEffect(() => {
        InitContaFinanceira();
        InitPlanoConta();
        InitCentroCusto();
        InitCompradores();
    }, []);

    return (
        <>
            <Form.Group className="formulario-empreendimento">
                <Row className="group-form">
                    <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Cliente|Fornecedor *</Form.Label>
                        <AutoComplete
                            invalid={!nome}
                            field="nome"
                            style={{ height: "37px" }}
                            value={nome}
                            suggestions={FilteredCliente}
                            completeMethod={(e) => searchCliente(e, setFilteredCliente)}
                            onChange={(e) => {
                                setListFiltros({ ...listFiltros, nome: e.value })
                                handleChange('nome', e.value)
                            }}
                            onSelect={(e) => {
                                if (e?.value?.cliente_id) {
                                    handleChange('cliente_id', e.value.cliente_id)
                                } else if (e?.value?.fornecedor_id) {
                                    handleChange('fornecedor_id', e.value.fornecedor_id)
                                } else {
                                    handleChange('pessoa_id', e.value.id)
                                }
                                setListFiltros({ ...listFiltros, cliente_id: e.value.id, nome: e.value })
                            }}
                            dropdown
                        />
                    </Col>
                    <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Tipos de Documentos</Form.Label>
                        <Form.Select value={tipo_documento} onChange={(e) => handleChange('tipo_documento', e.target.value)}>
                            <option>Selecione...</option>
                            <option value={0}>Nota Fiscal</option>
                            <option value={1}>Fatura</option>
                            <option value={2}>Recibo</option>
                            <option value={3}>Boleto</option>
                            <option value={4}>Comprovante de Despesa</option>
                            <option value={5}>Cheque</option>
                            <option value={6}>Nota Promissória</option>
                            <option value={7}>Título Provisório</option>
                            <option value={8}>Rascunho</option>
                            <option value={9}>Transferência</option>
                            <option value={10}>Assinatura</option>
                            <option value={14}>Cupom Fiscal</option>
                            <option value={15}>Guia</option>
                        </Form.Select>
                    </Col>
                    <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Tipo de Lançamento</Form.Label>
                        <Form.Select isInvalid={!tipo} value={tipo} onChange={(e) => handleChange('tipo', e.target.value)}>
                            <option>Selecione...</option>
                            <option value={0}>Entrada</option>
                            <option value={1}>Saída</option>
                        </Form.Select>
                    </Col>
                    <Col md={3} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>
                            Data de Pagamento
                        </Form.Label>
                        <div className="card flex justify-center">
                            <Calendar
                                invalid={!data_pagamento}
                                value={data_pagamento || null}
                                onChange={(event: any) => {
                                    const selectedDates = event.value;
                                    setDataPagamento(selectedDates);
                                    handleChange('data_pagamento', selectedDates ? selectedDates.toISOString() : null);
                                }}
                                placeholder="Data de pagamento"
                                showButtonBar
                                selectionMode="single"
                                dateFormat="dd/mm/yy"
                                locale="pt"
                                readOnlyInput
                                style={{ textAlign: 'center' }}
                                panelClassName="custom-calendar-panel"
                                required
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="group-form">
                    <Col md={3} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>
                            Data de Vencimento
                        </Form.Label>
                        <div className="card flex justify-center">
                            <Calendar
                                value={data_vencimento || null}
                                onChange={(event: any) => {
                                    const selectedDates = event.value;
                                    setDataVencimento(selectedDates);
                                    handleChange('data_vencimento', selectedDates ? selectedDates.toISOString() : null);
                                }}
                                placeholder="Data de vencimento"
                                showButtonBar
                                selectionMode="single"
                                dateFormat="dd/mm/yy"
                                locale="pt"
                                readOnlyInput
                                style={{ textAlign: 'center' }}
                                panelClassName="custom-calendar-panel"  // Aplica a classe CSS ao painel
                            />
                        </div>
                    </Col>

                    <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Número Documento</Form.Label>
                        <Form.Control value={numero_documento || ''} onChange={(e) => handleChange('numero_documento', e.target.value)} />
                    </Col>
                    <Col md='6' style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Descrição de caixa</Form.Label>
                        <Form.Control placeholder="PGTO REF [Serviço/Produto] [VENC 99/99/9999] [PARC 1/9]" value={descricao_caixa} onChange={(e) => handleChange('descricao_caixa', e.target.value)} />

                    </Col>
                </Row>
                <Row className="group-form">
                    <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Conta Financeira</Form.Label>
                        <Form.Select value={conta_financeiro_id || ''} onChange={(e) => handleChange('conta_financeiro_id', e.target.value)}>
                            <option>Selecione...</option>
                            {listContaFinanceira?.map((item: any) => (
                                <option value={item.id} key={item.id}>
                                    {item.nome}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Plano de Contas</Form.Label>
                        <TreeSelect
                            value={plano_conta_id}
                            options={listPlanoConta}
                            onChange={(e) => {
                                const selectedValues: any = e.value;
                                handleChange('plano_conta_id', selectedValues)
                            }}
                            filter
                            selectionMode="single"
                            placeholder="Selecione um plano de conta"
                            display="chip"
                            className="w-full md:w-20rem"
                            style={{ width: '100%' }}
                            onFocus={InitPlanoConta}
                        />
                    </Col>
                    <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Centro de Custo</Form.Label>
                        <TreeSelect
                            value={centro_custo ? centro_custo?.reduce((acc: any, item: any) => {
                                acc[item] = true;
                                return acc;
                            }, []) : []}
                            options={listCentroCusto}
                            onChange={(e) => {
                                const selectedValues: any = e.value || [];
                                let centros: any[] = [];
                                Object.keys(selectedValues).forEach((key, index) => {
                                    centros.push(Number(key));
                                });
                                handleChange('centro_custo', centros)
                            }}
                            filter
                            placeholder="Selecione um centro de custo"
                            display="chip"
                            selectionMode="multiple"
                            className="w-full md:w-20rem"
                            style={{ width: '100%' }}
                            onFocus={InitCentroCusto}
                        />
                    </Col>
                    <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Valor Total do Lançamento</Form.Label>
                        <InputNumber
                            invalid={!valor_original}
                            value={typeof valor_original === 'string'
                                ? (valor_original ? parseFloat(valor_original.replace(',', '.')) : null)
                                : (valor_original ? valor_original : null)}
                            onChange={(e) => handleChange('valor_original', e.value)}
                            minFractionDigits={2}
                            maxFractionDigits={24}
                            style={{ width: '100%', height: '38px' }}
                            locale="pt-BR"
                        />
                    </Col>
                </Row>
                <Row className="group-form">
                    <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Data de Competência</Form.Label>
                        <InputMask
                            mask="99/9999"
                            value={data_competencia || ''}
                            onChange={(e) => handleChange('data_competencia', e.target.value)}
                            placeholder="xx/xxxx"
                            className="form-control"
                        />
                    </Col>
                    <Col md="9" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Justificativa do Lançamento</Form.Label>
                        <Form.Control isInvalid={!justificativa} value={justificativa || ''} onChange={(e) => handleChange("justificativa", e.target.value)} />
                    </Col>
                </Row>
            </Form.Group>
        </>
    );
}

export default MovCaixaForm;
