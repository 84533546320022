import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import TokenService from '../../../services/Auth/token.service';
import ProgressBarForm from '../../Formularios/progressBarForm';
import InumadoForm from './Forms/InumadoForm';
import CaracteristicaForm from './Forms/CaracteristicaForm';
import InformacoesForm from './Forms/InfoForm';
import DeclaranteForm from './Forms/DeclaranteForm';
import { showToast } from '../../../components/ToastComponent';

function CreateInumados() {
    const { setBody, setUrl, setValidationRequest } = useAppContext();
    const [currentPart, setCurrentPart] = useState(1);
    const totalParts = 4;
    const [steps, setSteps] = useState<string[]>(["Inumado", "Características", "Informações", "Declarante"]);
    const [formBasico, setFormBasico] = useState<any | null>();

    useEffect(() => {
        setUrl(`${url}/api/v1/admin/cemiterio/inumado`);
        setFormBasico((prevImovelData: any) => {
            return {
                ...prevImovelData,
                ['empreendimento_id']: TokenService.getEmpreendimentoId()?.id,
                ['corpo_embalsamado']: false,
            };
        });
        setValidationRequest(false)
    }, []);

    useEffect(() => {
        setBody(formBasico);
    }, [formBasico]);


    const handleBack = () => {
        if (currentPart > 1) {
            setCurrentPart((prevPart) => prevPart - 1);
        }
    };

    const handleNext = () => {
        if (!formBasico) {
            showToast("error", "Os campos de Nome, Nº Interno, Nº Exumação, Tipo de Serviço, Causa Mortis, Local - Data - Hora do falecimento, Situação e CPF ou CNPJ é obrigatório!")
            return;
        }
        if (!formBasico?.pessoa?.cpf_cnpj) {
            showToast("error", "O campo CPF é obrigatório!")
            return;
        }
        if (!formBasico.pessoa.nome && formBasico?.pessoa?.cpf_cnpj.length < 15) {
            showToast("error", "O campo Nome é obrigatório!")
            return;
        }
        if (!formBasico.numero_interno) {
            showToast("error", "O campo Nº Interno é obrigatório!")
            return;
        }
        if (!formBasico.tipo_servico) {
            showToast("error", "O campo Corpo será é obrigatório!")
            return;
        }
        if (!formBasico.causa_mortis) {
            showToast("error", "O campo Causa Mortis é obrigatório!")
            return;
        }
        if (!formBasico.local_falecimento) {
            showToast("error", "O campo Local do falecimento é obrigatório!")
            return;
        }
        if (!formBasico.data_falecimento) {
            showToast("error", "O campo Data do falecimento é obrigatório!")
            return;
        }
        if (!formBasico.hora_falecimento) {
            showToast("error", "O campo Hora do falecimento é obrigatório!")
            return;
        }
        if (!formBasico.situacao) {
            showToast("error", "O campo Situação é obrigatório!")
            return;
        }
        if (currentPart < totalParts) {
            setCurrentPart((prevPart) => prevPart + 1);
        }
    };

    const handleChange = (field: string, value: any) => {
        if (field.startsWith('pessoa.')) {
            const pessoaField = field.replace('pessoa.', '');
            setFormBasico((prevForm: any) => ({
                ...prevForm,
                pessoa: {
                    ...prevForm.pessoa,
                    [pessoaField]: value,
                },
            }));
        } else {
            setFormBasico((prevForm: any) => ({
                ...prevForm,
                [field]: value,
            }));
        }
    };

    const handleChangeCliente = (value: any) => {
        setFormBasico((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                ['cliente_id']: value?.id ? value?.id : value,
                ['cliente_label']: value?.nome ? value?.nome : value,
            };
        });
    };

    const handleChangeJazigo = (field: string, value: any) => {
        setFormBasico((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                ['jazigo_id']: value?.id ? value?.id : value,
                ['logradouro']: value?.logradouro ? value?.logradouro : value,
            };
        });
    };

    const handleChangeNaturalidade = (field: string, value: any) => {
        setFormBasico((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                pessoa: {
                    ...prevGrupoData.pessoa,
                    ['naturalidade_id']: value?.id ? value?.id : value,
                    ['naturalidade_label']: value?.nome ? value?.nome : value,
                },
            };
        });
    };

    const handleChangeProfissao = (field: string, value: any) => {
        setFormBasico((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                ['profissao_id']: value?.id ? value?.id : undefined,
                profissao: {
                    ...prevGrupoData.profissao,
                    [field]: value.descricao ? value.descricao : value
                }
            };
        });
    };

    const handleChangeReligiao = (field: string, value: any) => {
        setFormBasico((prevGrupoData: any) => {
            return {
                ...prevGrupoData,
                ['religiao_id']: value?.id ? value?.id : undefined,
                religiao: {
                    ...prevGrupoData.religiao,
                    [field]: value.descricao ? value.descricao : value
                }
            };
        });
    };

    useEffect(() => {
        if (
            formBasico?.pessoa?.cpf_cnpj &&
            formBasico?.pessoa?.nome &&
            formBasico?.numero_interno &&
            formBasico?.tipo_servico &&
            formBasico?.causa_mortis &&
            formBasico?.local_falecimento &&
            formBasico?.data_falecimento &&
            formBasico?.hora_falecimento &&
            formBasico?.situacao
        ) {
            setValidationRequest(true);
        }
        setBody(formBasico);
    }, [formBasico]);


    return (
        <div className="body-content">
            <div className="form-user-create">
                <Container>
                    <ProgressBarForm
                        currentPart={currentPart}
                        totalParts={totalParts}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        steps={steps}
                        body={formBasico}
                    >
                        {currentPart === 1 && (
                            <InumadoForm
                                inumado={formBasico}
                                onInputChange={(field: string, value: any) => handleChange(field, value)}
                                onInputChangeCliente={(value: any) => handleChangeCliente(value)}
                                onInputChangeJazigo={(field: string, value: any) => handleChangeJazigo(field, value)}
                            />
                        )}
                        {currentPart === 2 && (
                            <CaracteristicaForm
                                caracteristicaData={formBasico}
                                onInputChange={(field: string, value: any) => handleChange(field, value)}
                                onInputChangeNaturalidade={(field: string, value: any) => handleChangeNaturalidade(field, value)}
                                onInputChangeProfissao={(field: string, value: any) => handleChangeProfissao(field, value)}
                                onInputChangeReligiao={(field: string, value: any) => handleChangeReligiao(field, value)}
                            />
                        )}
                        {currentPart === 3 && (
                            <InformacoesForm
                                infoData={formBasico}
                                onInputChange={(field: string, value: any) => handleChange(field, value)}
                            />
                        )}
                        {currentPart === 4 && (
                            <DeclaranteForm
                                declaranteData={formBasico}
                                onInputChange={(field: string, value: any) => handleChange(field, value)}
                            />
                        )}
                    </ProgressBarForm>
                </Container>
            </div>
        </div>
    );
}

export default CreateInumados;
