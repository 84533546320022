import React, { useState, useEffect } from 'react';
import { AlertService } from '../../../../components/AlertService';
import { url } from '../../../../environments/environments-develop';
import api from '../../../../services/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Column } from 'primereact/column';
import { MDBCol, MDBPageItem, MDBPageNav, MDBPagination, MDBRow } from 'mdbreact';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { useAppContext } from '../../../../contexts/AppContext';
import ModalBaixaManual from '../../../../components/ModalBaixaManual';
import { Skeleton } from 'primereact/skeleton';

interface TableComponentsImovelProps {
    urlSearch: string;
    urlSearchDataPut: string;
    url_redirect_edit: string;
    urlRedirectCreated: string;
    filtros: any;
}


const TableComponentsImovel: React.FC<TableComponentsImovelProps> = ({
    urlRedirectCreated = '',
    urlSearch = '',
    urlSearchDataPut = '',
    url_redirect_edit = '',
    filtros = {},
}) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [nodes, setNodes] = useState<any | null>(null);
    const [titles, setTitles] = useState<any | null>(null);
    const [totalPages, setTotalPages] = useState(0);
    const { setUrl_button_create } = useAppContext();
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [show, setShow] = useState(false);
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        setUrl_button_create(urlRedirectCreated);
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let response: any = null;
                if (filtros) {
                    response = await api.get(`${url}${urlSearch}?${filtros}&page=${currentPage + 1}`);
                } else {
                    response = await api.get(`${url}${urlSearch}?page=${currentPage + 1}`);
                }
                if (response.status === 200) {
                    const titles = response.data.headers.titles;
                    setTitles(titles)
                    const data = response.data.data;
                    const totalPages = response.data.meta.pagination.total_pages;
                    setTotalPages(totalPages);
                    setNodes(data);
                    setLoading(false);
                }
            } catch (error: any) {
                let mensagens: string[] = [];
                mensagens.push(error?.response?.data?.error?.message);
                AlertService.error('Erro!', String(mensagens));
            }
        };

        fetchData();
    }, [currentPage, urlSearch]);
    async function estorno(value: any): Promise<void> {
        try {
            const includeParam = 'config_venda,config_carteira,pessoa.telefones,pessoa.enderecos,grupo_permissoes';
            const response: any = await api.post(`${url}/api/v1/admin/financeiro/contasPagar/estorno/${value}?include=${includeParam}`);
            if (response.status === 200) {
                AlertService.sucess('Sucesso', 'Lançamento Estornado com Sucesso!').then((e) => {
                    if (e.isConfirmed) {
                        window.location.reload();
                    }
                }
                )
            }
            if (response.error) {
                AlertService.error('Erro!', String(response.error.description));
            }
        } catch (error: any) {
            let mensagens: string[] = [];
            mensagens.push(error?.response?.data?.error?.message);
            AlertService.error('Erro!', String(mensagens));
        }
    }
    const getStatusColorCard = (status: any) => {
        switch (status) {
            case 'Disponível':
                return '#289b1e'; // Background color for Disponível
            case 'Reservado':
                return '#f5da41'; // Background color for Reservado
            case 'Reserva Fixa':
                return '#f5da41'; // Background color for Reserva Fixa
            case 'Negociado':
                return '#a8a8a8'; // Background color for Negociado
            case 'vendido':
                return '#ff3232'; // Background color for Vendido
            case 'Reserva Técnica':
                return '#fff'; // Background color for Reserva Técnica
            case 'Reserva Diretoria':
                return '#fff'; // Background color for Reserva Diretoria
            default:
                return '#fff';
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];

        for (let i = 0; i < totalPages; i++) {
            // Adicione as três primeiras páginas
            if (i < 3 || i === totalPages - 1 || (i >= currentPage - 1 && i <= currentPage + 1)) {
                pageNumbers.push(
                    <MDBPageItem key={i} active={i === currentPage}>
                        <MDBPageNav className="page-link" onClick={() => setCurrentPage(i)}>
                            <span key={i} onClick={() => setCurrentPage(i)} style={{ cursor: 'pointer', margin: '0 5px', fontWeight: i === currentPage ? 'bold' : 'normal' }}>
                                {i + 1}
                            </span>
                        </MDBPageNav>
                    </MDBPageItem>

                );
            }
        }

        return pageNumbers;
    };
    const actionTemplate = (value: any) => {

        return (
            <div className="flex flex-wrap gap-2" style={{ display: 'flex' }}>
                <Link
                    to={url_redirect_edit}
                    onClick={(e) => setSessionEditData(value.id)}
                    className="link-perfil">
                    <Button
                        style={{ width: '30px', height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        icon="pi pi-pencil"
                        severity="warning"
                        title='Editar'
                        rounded>
                    </Button>
                </Link>
            </div>
        );
    };
    const setSessionEditData = async (value: any) => {
        try {
            const includeParam = 'config_venda,config_carteira,pessoa.telefones,pessoa.enderecos,grupo_permissoes';
            const response = await api.get(`${url}${urlSearchDataPut}${value}?include=${includeParam}`);
            if (response.status === 200) {
                await localStorage.setItem('dadosEdicao', JSON.stringify(response.data));
            }
        } catch (error: any) {
            let mensagens: string[] = [];
            mensagens.push(error?.response?.data?.error?.message);
            AlertService.error('Erro!', String(mensagens));
        }
    };

    const statusTemplate = (value: any) => {
        return (
            <div style={{ backgroundColor: `${getStatusColorCard(value.status_name)}`, margin: '3px', marginRight: '8px', border: `2px solid ${getStatusColorCard(value.status_name)}`, borderRadius: '12px', marginTop: '12px', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)', padding: '8px' }}></div>
        )
    }

    return (
        <div className="card">
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '14px' }}>
                <div style={{ display: 'flex' }}>
                    <p style={{ margin: '0px' }}>Legenda: </p>
                    <div style={{ display: 'flex', marginLeft: '8px', }}>
                        <div style={{
                            backgroundColor: `#289b1e`,
                            margin: '3px',
                            height: '5px',
                            marginRight: '8px',
                            border: `2px solid #289b1e`,
                            borderRadius: '12px',
                            boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                            padding: '8px'
                        }}>
                        </div>
                        <p style={{ margin: '0px' }}>Disponível</p>
                    </div>
                    <div style={{ display: 'flex', marginLeft: '8px', }}>
                        <div style={{
                            backgroundColor: `#f5da41`,
                            margin: '3px',
                            height: '5px',
                            marginRight: '8px',
                            border: `2px solid #f5da41`,
                            borderRadius: '12px',
                            boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                            padding: '8px'
                        }}>
                        </div>
                        <p style={{ margin: '0px' }}>Reservado</p>
                    </div>
                    <div style={{ display: 'flex', marginLeft: '8px', }}>
                        <div style={{
                            backgroundColor: `#a8a8a8`,
                            margin: '3px',
                            marginRight: '8px',
                            height: '5px',
                            border: `2px solid #a8a8a8`,
                            borderRadius: '12px',
                            boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                            padding: '8px'
                        }}>
                        </div>
                        <p style={{ margin: '0px' }}>Negociado</p>
                    </div>
                    <div style={{ display: 'flex', marginLeft: '8px', }}>
                        <div style={{
                            backgroundColor: `#ff3232`,
                            margin: '3px',
                            marginRight: '8px',
                            height: '5px',
                            border: `2px solid #ff3232`,
                            borderRadius: '12px',
                            boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                            padding: '8px'
                        }}>
                        </div>
                        <p style={{ margin: '0px' }}>Vendido</p>
                    </div>
                    <div style={{ display: 'flex', marginLeft: '8px', }}>
                        <div style={{
                            backgroundColor: `#fff`,
                            margin: '3px',
                            marginRight: '8px',
                            height: '5px',
                            border: `2px solid #000`,
                            borderRadius: '12px',
                            boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                            padding: '8px'
                        }}>
                        </div>
                        <p style={{ margin: '0px' }}>Reserva Técnica</p>
                    </div>
                    <div style={{ display: 'flex', marginLeft: '8px', }}>
                        <div style={{
                            backgroundColor: `#fff`,
                            margin: '3px',
                            marginRight: '8px',
                            height: '5px',
                            border: `2px solid #000`,
                            borderRadius: '12px',
                            boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.32)',
                            padding: '8px'
                        }}>
                        </div>
                        <p style={{ margin: '0px' }}>Reserva Diretoria</p>
                    </div>
                </div>
            </div>
            <DataTable
                lazy
                size="small"
                stripedRows
                value={nodes ? nodes : []}
                tableStyle={{ minWidth: '50rem' }}
                emptyMessage={isLoading ? (<Skeleton />) : (<span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>)}
            >
                {nodes && (
                    <Column
                        style={{ width: '5px' }}
                        body={(e) => statusTemplate(e)}
                        headerClassName=""
                    />
                )}
                <Column
                    style={{ textAlign: 'right' }}
                    key={'id'}
                    field={'id'}
                    headerClassName="righted-header"
                    header={<div className="righted-content">Id</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'right' }}
                    key={'unidade'}
                    field={'unidade'}
                    headerClassName="righted-header"
                    header={<div className="righted-content">Unidade</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'right' }}
                    key={'area_label'}
                    field={'area_label'}
                    headerClassName="righted-header"
                    header={<div className="righted-content">Área</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'status_name'}
                    field={'status_name'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content">Status</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'right' }}
                    key={'imo_preco_format'}
                    field={'imo_preco_format'}
                    headerClassName="righted-header"
                    header={<div className="righted-content">Preço</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'right' }}
                    key={'imo_intermed_format'}
                    field={'imo_intermed_format'}
                    headerClassName="righted-header"
                    header={<div className="righted-content">Interm.</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'right' }}
                    key={'qtd_max_parcelas'}
                    field={'qtd_max_parcelas'}
                    headerClassName="righted-header"
                    header={<div className="righted-content">Qtd. Parcelas</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'right' }}
                    key={'valor_parcelas_calc'}
                    field={'valor_parcelas_calc'}
                    headerClassName="righted-header"
                    header={<div className="righted-content">Preço Parcelas</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'classificacao_preco_rotulo'}
                    field={'classificacao_preco_rotulo'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content">Classif.Preço</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    style={{ textAlign: 'left' }}
                    key={'construcao_label'}
                    field={'construcao_label'}
                    headerClassName="lefted-header"
                    header={<div className="lefted-content">Const.</div>}
                    body={isLoading && (<Skeleton />)}
                />
                <Column
                    body={(e) => actionTemplate(e)}
                    header="Menu"
                />
            </DataTable>
            <div className="pagination-footer">
                <MDBRow>
                    <MDBCol>
                        <MDBPagination circle>
                            <MDBPageItem disabled={currentPage === 0}>
                                <MDBPageNav className="page-link" aria-label="Previous" onClick={() => setCurrentPage(currentPage - 1)}>
                                    <span aria-hidden="true">&laquo;</span>
                                </MDBPageNav>
                            </MDBPageItem>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {renderPageNumbers()}
                            </div>
                            <MDBPageItem disabled={currentPage === totalPages - 1}>
                                <MDBPageNav className="page-link" aria-label="Next" onClick={() => setCurrentPage(currentPage + 1)}>
                                    <span aria-hidden="true">&raquo;</span>
                                </MDBPageNav>
                            </MDBPageItem>
                        </MDBPagination>
                    </MDBCol>
                </MDBRow>
            </div>
        </div>
    );
};
export default TableComponentsImovel;
