import { SetStateAction, useEffect, useState } from "react";
import { Col, Form, NavLink } from 'react-bootstrap';
import { Button } from "primereact/button";
import { useSidebarContext } from "../../../contexts/SidebarContext";
import { useAppContext as useResponseContext } from "../../../contexts/ResponseContext";
import { useAppContext } from "../../../contexts/AppContext";
import { loadData } from "./Service/RenegociacaoService";
import { actionTemplate } from "./Components/actionTemplate";
import Paginacao from "./Components/Paginacao";
import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
import RenegociacaoModal from "./Modal/RenegociacaoModal";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Skeleton } from "primereact/skeleton";
import SimularRenegociacao from "./Modal/SimularRenegociacao";
import SelecaoRenegociacao from "./Modal/SelecaoRenegociacao";
import ConfirmarHomologacao from "./Modal/ConfirmarHomologação";

function Renegociacao() {
  const { showSubSidebar } = useAppContext();
  const [listFiltros, setListFiltros] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [nodes, setNodes] = useState<any | null>(null);
  const { titles, updateTitles, pagination, updatePagination } = useResponseContext();
  const { setContextItemsButtonMenu } = useAppContext();
  const [showRenegociarModal, setShowRenegociarModal] = useState(false);
  const [showSimularRenegociacao, setShowSimularRenegociacao] = useState(false);
  const [showSelecaoRenegociacao, setShowSelecaoRenegociacao] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const { isVisible, updateVisibility } = useSidebarContext();
  const [showModalFiltro, setShowModalFiltro] = useState(false);
  const [showConfirmarHomologacao, setShowConfirmarHomologacao] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showModalHomologar, setShowModalHomologar] = useState(false);
  const urlRedirectEdit = "/financeiro/contas-receber/update";
  const [data, setData] = useState([]);
  const [Homologar, setHomologar] = useState([]);

  const handleData = (newData: any) => {
    setData(newData);
  };

  useEffect(() => {
    loadData(listFiltros, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro);
  }, [currentPage]);

  useEffect(() => {
    const commonStyle = {
      width: '35px',
      height: '35px',
      top: '0px',
      color: '#fff'
    };
    const items = [
      {
        label: 'Criar Renegociação',
        icon: 'pi pi-users',
        style: { ...commonStyle, backgroundColor: '#007bff', display: 'flex' },
        command: () => setShowSelecaoRenegociacao(true),
        tooltip: 'Criar Renegociação'
      }
    ];
    setContextItemsButtonMenu(items);
  }, [])

  const toggleVisibility = () => {
    updateVisibility(!isVisible);
  };

  console.log(data);

  return (
    <div className="body-content">
      <div className={showSubSidebar ? "container-section-header" : "container-section-header-none"}>
        <div className="contentSearch">
          <div className="contentSearch-header">
            <NavLink onClick={toggleVisibility}>
              <img className="menu-context" src={iconMenuTresPonto} alt="Menu" />
            </NavLink>
            <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>
              Lista de Renegociações
            </Col>
          </div>
          <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
          <div className="contentSearch-body">
            <div className='filter-campos'>
              <div className='div-calendar'>
                <Col md="4" style={{ marginRight: "8px" }}>
                  <Form.Label>Número documento</Form.Label>
                  <Form.Control
                    value={listFiltros?.numero_documento || ''}
                    onChange={(e) => setListFiltros({ ...listFiltros, numero_documento: e.target.value })}
                  />
                </Col>
                <Col md='6'>
                  <Form.Label>Pesquisar nome</Form.Label>
                  <Form.Control
                    value={listFiltros?.nome || ''}
                    onChange={(e) => setListFiltros({ ...listFiltros, nome: e.target.value })}
                  />
                </Col>
              </div>
            </div>
            <div className='div-filtros-avancados' style={{ marginTop: '35px' }}>
              <Button
                style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                severity="success"
                icon="pi pi-filter"
                rounded
                onClick={() => loadData(listFiltros, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro)}
              >
                <span style={{ marginLeft: '5px' }}>Pesquisar</span>
              </Button>
              <Button
                style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                severity="danger"
                icon="pi pi-delete-left"
                rounded
                onClick={() => {
                  setListFiltros({});
                  loadData(listFiltros, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro);
                }}
              >
                <span style={{ marginLeft: '5px' }}>Limpar</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className={showSubSidebar ? "container-section-body" : "container-section-body-none"}>
        <DataTable
          stripedRows
          lazy
          size="small"
          value={nodes || []}
          tableStyle={{ minWidth: '50rem' }}
          emptyMessage={isLoading ? <Skeleton /> : <span style={{ display: 'flex', justifyContent: 'center', fontStyle: 'italic' }}>Nenhum item encontrado.</span>}
        >
          <Column
            style={{ textAlign: 'left' }}
            key={'id'}
            field={'id'}
            headerClassName="lefted-header"
            header={<div className="lefted-content">Código</div>}
            body={isLoading ? <Skeleton /> : null}
          />
          <Column
            style={{ textAlign: 'left' }}
            key={'nome_cliente'}
            field={'nome_cliente'}
            headerClassName="lefted-header"
            header={<div className="lefted-content">Cliente</div>}
            body={isLoading ? <Skeleton /> : null}
          />
          <Column
            style={{ textAlign: 'left' }}
            key={'nome_empreendimento'}
            field={'nome_empreendimento'}
            headerClassName="lefted-header"
            header={<div className="lefted-content">Empreendimento</div>}
            body={isLoading ? <Skeleton /> : null}
          />
          <Column
            style={{ textAlign: 'right' }}
            key={'valor_multa'}
            field={'valor_multa'}
            headerClassName="righted-header"
            header={<div className="righted-content">Vlr Multa</div>}
            body={isLoading ? <Skeleton /> : null}
          />
          <Column
            style={{ textAlign: 'right' }}
            key={'valor_juros'}
            field={'valor_juros'}
            headerClassName="righted-header"
            header={<div className="righted-content">Vlr juros</div>}
            body={isLoading ? <Skeleton /> : null}
          />
          <Column
            style={{ textAlign: 'right' }}
            key={'valor_desconto'}
            field={'valor_desconto'}
            headerClassName="righted-header"
            header={<div className="righted-content">Vlr Desconto</div>}
            body={isLoading ? <Skeleton /> : null}
          />
          <Column
            style={{ textAlign: 'right' }}
            key={'valor_final'}
            field={'valor_final'}
            headerClassName="righted-header"
            header={<div className="righted-content">Vlr Final</div>}
            body={isLoading ? <Skeleton /> : null}
          />
          <Column
            style={{ textAlign: 'right' }}
            key={'valor_total_sem_juros'}
            field={'valor_total_sem_juros'}
            headerClassName="righted-header"
            header={<div className="righted-content">Vlr Total S/Juros</div>}
            body={isLoading ? <Skeleton /> : null}
          />
          <Column
            className='menu-context-grid-contas'
            body={(rowData) => actionTemplate(rowData, setSelectedRow, setHomologar, setShowModalHomologar)}
            header="Menu"
          />
        </DataTable>

        <SelecaoRenegociacao
          data={data}
          handleData={handleData}
          showSelecaoRenegociacao={showSelecaoRenegociacao}
          handleClose={() => {
            setShowSelecaoRenegociacao(false);
            handleData({});
          }}
          handleNext={() => {
            setShowRenegociarModal(true);
            setShowSelecaoRenegociacao(false);
          }}
        />

        <RenegociacaoModal
          data={data}
          handleData={handleData}
          showRenegociarModal={showRenegociarModal}
          handleClose={() => {
            setShowRenegociarModal(false),
              handleData({})
          }}
          handleBack={() => {
            setShowSelecaoRenegociacao(true)
            setShowRenegociarModal(false)
          }}
          handleNext={() => (setShowSimularRenegociacao(true), setShowRenegociarModal(false))}
        />

        <SimularRenegociacao
          data={data}
          handleData={handleData}
          showSimularRenegociacao={showSimularRenegociacao}
          handleBack={() => {
            setShowRenegociarModal(true)
            setShowSimularRenegociacao(false)
          }}
          handleClose={() => {
            setShowSimularRenegociacao(false)
          }}
        />

        <ConfirmarHomologacao
          Homologar={Homologar}
          handleData={handleData}
          showModalHomologar={showModalHomologar}
          handleClose={() => setShowModalHomologar(false)}
        />

        <Paginacao
          totalPages={pagination}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
}

export default Renegociacao;
