import iconMenuTresPonto from '../../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import 'react-autocomplete-input/dist/bundle.css';
import { useState } from 'react';
import { useSidebarContext } from '../../../../contexts/SidebarContext';
import { Row, Col, Form, NavLink, Modal, Container, InputGroup } from 'react-bootstrap';
import iconFiltroAvancado from '../../../../../assets/images/icon-filtro-avancado.png';
import DatePicker from 'react-datepicker';
import pt from 'date-fns/locale/pt';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import ModalResetBoleto from './ModalResetBoleto';
import { ambiente } from '../../../../environments/environments-develop';
import TokenService from '../../../../services/Auth/token.service';

interface ContextSearchProps {
    header?: string;
    filtrosData: {
        numero?: string;
        data_venda_format?: string;
        status?: number;
        qtd_parcelas?: number;
        dataInicio?: string;
        dataFim?: string;
        nome?: string;
    };
    onInputClearList: () => void;
    onInputChangeList: (field: string, value: any) => void;
    onInputChange: (field: string, value: any) => void;
}

function FiltroGestaoContratos(props: ContextSearchProps) {
    const { numero, data_venda_format, status, qtd_parcelas, nome } = props.filtrosData || {};
    const { isVisible, updateVisibility } = useSidebarContext();
    const [showModal, setShowModal] = useState(false);
    const [showModalResetBoletos, setShowModalResetBoletos] = useState(false);

    const [dataInicio, setDataInicio] = useState<string | null>(
        props.filtrosData && typeof props.filtrosData.dataInicio === 'string'
            ? props.filtrosData.dataInicio
            : null
    );

    const [dataFim, setDataFim] = useState<string | null>(
        props.filtrosData && typeof props.filtrosData.dataFim === 'string'
            ? props.filtrosData.dataFim
            : null
    );

    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };

    const handleChange = (field: string, value: any) => {
        props.onInputChange(field, value);
    };

    const handleChangeList = (field: string, value: any) => {
        props.onInputChangeList(field, value);
    };

    const handleClearList = () => {
        setShowModal(false);
        setDataInicio(null);
        setDataFim(null);
        props.onInputClearList();
    };

    const handleFilter = () => {
        setShowModal(false);
        if (numero) {
            handleChangeList('numero', numero);
        }
        if (nome) {
            handleChangeList('nome', nome);
        }
        if (data_venda_format) {
            handleChangeList('data_venda_format', data_venda_format);
        }
        if (status) {
            handleChangeList('status', status);
        }
        if (qtd_parcelas) {
            handleChangeList('qtd_parcelas', qtd_parcelas);
        }
        if (dataInicio) {
            handleChangeList('dataInicio', dataInicio);
        }
        if (dataFim) {
            handleChangeList('dataFim', dataFim);
        }
    };

    return (
        <div className="contentSearch">
            <div className="contentSearch-header">
                <NavLink onClick={toggleVisibility}>
                    <img
                        className="menu-context"
                        src={iconMenuTresPonto}
                    />
                </NavLink>
                <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>{props.header}</Col>
                {TokenService.getPerfil()?.data?.is_admin == true && ambiente === 'homolog' && (
                    <Button
                        rounded
                        outlined
                        severity="help"
                        style={{
                            borderRadius: '50px',
                            cursor: 'pointer',
                            transition: 'all 0.5s'
                        }}
                        onClick={() => setShowModalResetBoletos(true)}
                    >
                        Reset
                    </Button>
                )}
            </div>
            <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
            <div className="contentSearch-body">
                <div className='div-calendar'>
                    <Col md="2" style={{ marginRight: "8px" }}>
                        <Form.Label>N° Contrato</Form.Label>
                        <Form.Control
                            value={numero || ''}
                            onChange={(e) => handleChange("numero", e.target.value)}
                        />
                    </Col>
                    <Col md="4" style={{ marginRight: "8px" }}>
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            value={nome ? nome : ''}
                            onChange={(e) => handleChange("nome", e.target.value)}
                        />
                    </Col>
                    <Col md='4' style={{ marginRight: "8px" }}>
                        <Form.Label>Status</Form.Label>
                        <Form.Select value={status || ''} onChange={(e) => handleChange('status', e.target.value)}>
                            <option>Selecione...</option>
                            <option value={"1"}>Faltando Acertar</option>
                            <option value={"2"}>Intermediação Acertada</option>
                            <option value={"3"}>Vigente</option>
                            <option value={"4"}>Suspenso</option>
                            <option value={"5"}>Jurídico/Distrato</option>
                            <option value={"6"}>Cancelado</option>
                            <option value={"7"}>Quitado</option>
                        </Form.Select>
                    </Col>
                </div>
                <ModalResetBoleto
                    showModalResetBoletos={showModalResetBoletos}
                    handleShowModalResetBoletos={setShowModalResetBoletos}
                />
                <div className='div-filtros-avancados' style={{ marginTop: '35px' }}>
                    <Button
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        onClick={() => { setShowModal(true) }}
                        rounded outlined severity="secondary">
                        <img style={{ width: '25px', height: '35px' }} src={iconFiltroAvancado} />
                    </Button>
                    <Button
                        onClick={handleFilter}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="success"
                        icon="pi pi-filter"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
                    </Button>
                    <Button
                        onClick={handleClearList}
                        style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                        type="button"
                        severity="danger"
                        icon="pi pi-delete-left"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                    </Button>
                </div>
            </div>
            <Modal
                show={showModal}
                size='lg'
                onHide={() => setShowModal(false)}
                className='modal-filtro-avancado'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Filtros Avançados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col md={3}>
                                <Form.Label>Quantidade de Parcelas</Form.Label>
                                <Form.Control
                                    value={qtd_parcelas || ''}
                                    onChange={(e) => handleChange("qtd_parcelas", e.target.value)}
                                />
                            </Col>
                            <Col md={3}>
                                <Form.Label>Data Início</Form.Label>
                                <Form.Group controlId="formGridState" >
                                    <DatePicker
                                        selected={dataInicio ? new Date(dataInicio) : null}
                                        onChange={(date: Date) => {
                                            const formattedDate = date.toISOString().split('T')[0];
                                            setDataInicio(formattedDate);
                                            handleChange('dataInicio', formattedDate);
                                        }}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        fixedHeight
                                        locale={pt}
                                        customInput={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="date"
                                                    placeholder="Data Inicio"
                                                    className="dateFormFinanceiro"
                                                    value={dataInicio ? dataInicio : ''}
                                                    readOnly
                                                />
                                                <img
                                                    className="calendar-icon"
                                                    src={CalendarIcon}
                                                />
                                            </div>
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Label>Data Fim</Form.Label>
                                <Form.Group controlId="formGridState" >
                                    <DatePicker
                                        selected={dataFim ? new Date(dataFim) : null}
                                        onChange={(date: Date) => {
                                            const formattedDate = date.toISOString().split('T')[0];
                                            setDataFim(formattedDate);
                                            handleChange('dataFim', formattedDate);
                                        }}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        fixedHeight
                                        locale={pt}
                                        customInput={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="date"
                                                    placeholder="Data Fim"
                                                    className="dateFormFinanceiro"
                                                    value={dataFim ? String(dataFim) : ''}
                                                    readOnly
                                                />
                                                <img
                                                    className="calendar-icon"
                                                    src={CalendarIcon}
                                                />
                                            </div>
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={handleFilter}
                        style={{ marginTop: '3px', width: '100px', height: '30px', borderRadius: '5px', padding: '5px 10px', marginRight: '5px', fontSize: '14px', marginLeft: '10px' }}
                        type="button"
                        severity="success"
                        icon="pi pi-filter"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '15px' }}>Pesquisar</p>
                    </Button>
                    <Button
                        onClick={handleClearList}
                        style={{ marginTop: '3px', width: '100px', height: '30px', borderRadius: '5px', padding: '5px 10px', marginRight: '5px ', fontSize: '14px' }}
                        type="button"
                        severity="danger"
                        icon="pi pi-delete-left"
                        rounded>
                        <p style={{ marginLeft: '5px', marginTop: '15px' }}>Limpar</p>
                    </Button>
                </Modal.Footer>
            </Modal >
        </div >
    );
}

export default FiltroGestaoContratos;
