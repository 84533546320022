import React, { useEffect, useState } from 'react';
import { Modal, Container, Row, Col, Form } from 'react-bootstrap';
import { Button } from 'primereact/button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import pt from 'date-fns/locale/pt';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import { AlertService } from '../../../../components/AlertService';
import api from '../../../../services/api';
import { url } from '../../../../environments/environments-develop';

interface FiltroAvancadoModalProps {
    showModal: boolean;
    onHide: () => void;
    listFiltros: any;
    setListFiltros: any | null;
    loadData: (filtros: any, currentPage: number, setLoading: (nodes: any) => void, setNodes: (nodes: any) => void, updateTitles: (titles: string[]) => void, updatePagination: (pages: number) => void, setShowModalFiltro: (show: boolean) => void) => void;
    currentPage: number;
    setLoading: (nodes: any) => void;
    setNodes: (nodes: any) => void;
    updateTitles: (titles: string[]) => void;
    updatePagination: (pages: number) => void;
    setShowModalFiltro: (show: boolean) => void;
    handleClose: () => void;
}

const FiltroAvancadoModal: React.FC<FiltroAvancadoModalProps> = ({
    showModal,
    onHide,
    listFiltros,
    setListFiltros,
    setLoading,
    loadData,
    currentPage,
    setNodes,
    updateTitles,
    updatePagination,
    setShowModalFiltro
}) => {
    const [data_inicial, setDataInicio] = useState<Date | null>();
    const [data_final, setDataFim] = useState<Date | null>();
    const [tipo_consulta, setTipoConsulta] = useState<string>();
    const [plano_conta_id, setPlanoContaId] = useState<string>();
    const [centro_custo_id, setCentroCustoId] = useState([]);
    const [situacao_pagamento, setSituacaoPagamento] = useState<string>();
    const [tipo_parcela, setTipoParcela] = useState<string>();

    const [listPlanoConta, setListPlanoConta] = useState([]);
    const [listCentroCusto, setListCentroCusto] = useState([]);

    async function InitPlanoConta() {
        try {
            const response = await api.get(`${url}/api/v1/admin/financeiro/plano-conta`);
            setListPlanoConta(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    }

    async function InitCentroCusto() {
        try {
            const response = await api.get(`${url}/api/v1/admin/financeiro/centro-custo`);
            setListCentroCusto(response.data.data);
        } catch (error) {
            AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
        }
    }

    useEffect(() => {
        setDataInicio(listFiltros?.data_inicial ? new Date(listFiltros?.data_inicial) : null);
        setDataFim(listFiltros?.data_final ? new Date(listFiltros?.data_final) : null);
        setTipoConsulta(listFiltros?.tipo_consulta ? listFiltros?.tipo_consulta : null);
        setPlanoContaId(listFiltros?.plano_conta_id ? listFiltros?.plano_conta_id : null);
        setCentroCustoId(listFiltros?.centro_custo_id ? listFiltros?.centro_custo_id : null);
        setSituacaoPagamento(listFiltros?.situacao_pagamento ? listFiltros?.situacao_pagamento : null);
        setTipoParcela(listFiltros?.tipo_parcela ? listFiltros?.tipo_parcela : null);
    }, [listFiltros]);

    useEffect(() => {
        InitPlanoConta();
        InitCentroCusto();
    }, []);

    return (
        <Modal
            show={showModal}
            onHide={onHide}
            size="lg"
            className="modal-filtro-avancado"
        >
            <Modal.Header closeButton>
                <Modal.Title>Filtros Avançados</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row className='mb-3'>
                        <Col md="3">
                            <Form.Label>Tipo Consulta</Form.Label>
                            <Form.Select
                                value={tipo_consulta || ''}
                                onChange={(e) => setListFiltros({ ...listFiltros, tipo_consulta: e.target.value })}
                            >
                                <option>Selecione...</option>
                                <option value={'data_vencimento'}>Data Vencimento</option>
                                <option value={'data_pagamento'}>Data Pagamento</option>
                                <option value={'data_prog_pgto'}>Data Programada</option>
                                <option value={'data_baixa'}>Baixa</option>
                                <option value={'data_emissao'}>Emissão</option>
                                <option value={'data_credito'}>Crédito</option>
                            </Form.Select>
                        </Col>
                        <Col md="3">
                            <Form.Label>Data Início</Form.Label>
                            <Form.Group controlId="formGridState" >
                                <DatePicker
                                    selected={data_inicial ? new Date(data_inicial) : null}
                                    onChange={(date: Date) => {
                                        setDataInicio(date);
                                        setListFiltros({ ...listFiltros, data_inicial: date.toISOString().split('T')[0] });
                                    }}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    fixedHeight
                                    locale={pt}
                                    customInput={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="date"
                                                placeholder="Data Inicio"
                                                className="dateFormFinanceiro"
                                                value={data_inicial ? data_inicial.toISOString().split('T')[0] : ''}
                                                readOnly
                                            />
                                            <img
                                                className="calendar-icon"
                                                src={CalendarIcon}
                                            />
                                        </div>
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col md="3">
                            <Form.Label>Data Fim</Form.Label>
                            <Form.Group controlId="formGridState" >
                                <DatePicker
                                    selected={data_final ? new Date(data_final) : null}
                                    onChange={(date: Date) => {
                                        setDataFim(date);
                                        setListFiltros({ ...listFiltros, data_final: date.toISOString().split('T')[0] });
                                    }}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    fixedHeight
                                    locale={pt}
                                    customInput={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="date"
                                                placeholder="Data Fim"
                                                className="dateFormFinanceiro"
                                                value={data_final ? data_final.toISOString().split('T')[0] : ''}
                                                readOnly
                                            />
                                            <img
                                                className="calendar-icon"
                                                src={CalendarIcon}
                                            />
                                        </div>
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col>
                            <Form.Label>Justificativa / Descrição</Form.Label>
                            <Form.Control
                                type="text"
                                value={listFiltros?.justificativa || ''}
                                onChange={(e) => setListFiltros({ ...listFiltros, justificativa: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col md="3">
                            <Form.Label>Plano de Contas</Form.Label>
                            <Form.Select value={plano_conta_id || ''}
                                onChange={(e) => setListFiltros({ ...listFiltros, plano_conta_id: e.target.value })}>
                                <option>Selecione...</option>
                                {listPlanoConta?.map((item: any) => (
                                    <option value={item.id} key={item.id}>
                                        {item.nome}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col md="3">
                            <Form.Label>Centro de Custo</Form.Label>
                            <Form.Select value={centro_custo_id || ''}
                                onChange={(e) => setListFiltros({ ...listFiltros, centro_custo_id: e.target.value })}>
                                <option>Selecione...</option>
                                {listCentroCusto?.map((item: any) => (
                                    <option value={item.id} key={item.id}>
                                        {item.nome}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col md="3">
                            <Form.Label>Situação Pagamento</Form.Label>
                            <Form.Select value={situacao_pagamento || ''}
                                onChange={(e) => setListFiltros({ ...listFiltros, situacao_pagamento: e.target.value })}>
                                <option>Selecione...</option>
                                <option value={0}>Aberto</option>
                                <option value={1}>Baixado</option>
                                <option value={2}>Inadimplente</option>
                            </Form.Select>
                        </Col>
                        <Col md="3">
                            <Form.Label>Tipo Parcela</Form.Label>
                            <Form.Select value={tipo_parcela || ''}
                                onChange={(e) => setListFiltros({ ...listFiltros, tipo_parcela: e.target.value })}>
                                <option>Selecione...</option>
                                <option value={1}>Ordinária</option>
                                <option value={2}>Balão</option>
                                <option value={3}>Avulso</option>
                                <option value={4}>Saldo Restante</option>
                                <option value={5}>Comissões</option>
                                <option value={6}>Temporário</option>
                                <option value={7}>Condomínio</option>
                                <option value={8}>Parcela Mensal</option>
                                <option value={9}>Acordo Avulso</option>
                                <option value={10}>Intermediação</option>
                                <option value={11}>Entrada</option>
                                <option value={12}>Agrupado</option>
                                <option value={13}>Lançamento Pai</option>
                            </Form.Select>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="success"
                    icon="pi pi-filter"
                    rounded
                    onClick={() => loadData(listFiltros, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro)}
                >
                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
                </Button>
                <Button
                    style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                    type="button"
                    severity="danger"
                    icon="pi pi-delete-left"
                    rounded
                    onClick={() => {
                        setListFiltros({});
                        loadData(listFiltros, currentPage, setLoading, setNodes, updateTitles, updatePagination, setShowModalFiltro)
                    }}
                >
                    <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FiltroAvancadoModal;
