import { AlertService } from "../../../../../../components/AlertService";
import { url } from "../../../../../../environments/environments-develop";
import TokenService from "../../../../../../services/Auth/token.service";
import ContratoService from "../../../../../Carteira/Services/ContratoService";
import TipoJazigoService from "../../../../../Cemiterio/Services/TipoJazigoService";
import ClienteService from "../../../../../Pessoas/Services/ClienteService";
import api from "../../../../../../services/api";
import imovelGavetaService from "../../../../../Cemiterio/Services/imovelGavetaService";
import InumadoService from "../../../../../Cemiterio/Services/InumadoService";
interface Consulta {
  order?: string;
  filtro?: any;
}
const consulta: Consulta = {
  order: "id;desc",
};

export const loadDataContratoJazigo = (
  listFiltros: any,
  currentPage: any,
  setLoading: (value: any) => void,
  setNodes: (value: any) => void,
  updateTitles: (titles: string[]) => void,
  updatePagination: (totalPages: number) => void
) => {
  setLoading(true);
  const form = {
    contrato_id: listFiltros.contrato_id,
    unidade: listFiltros.unidade,
    cliente_id: listFiltros.cliente_id,
    jazigo_id: listFiltros.jazigo_id,
    status: listFiltros.status,
    dia_vencimento: listFiltros.dia_vencimento,
    tipo_consulta: listFiltros?.tipo_consulta,
    data_inicio: listFiltros.data_inicio,
    data_fim: listFiltros.data_fim,
    data_inicial: listFiltros.data_inicial,
    data_final: listFiltros.data_final,
    tipo_venda: listFiltros.tipo_venda,
    empreendimento_id: TokenService.getEmpreendimentoId()
      ? TokenService.getEmpreendimentoId()?.id
      : null,
  };

  const filtro = Object.fromEntries(
    Object.entries(form).filter(
      ([_, value]) => value !== null && value !== "" && value !== undefined
    )
  );

  if (consulta) {
    consulta.filtro = filtro;
  }
  ContratoListJazigo(
    consulta,
    currentPage,
    setLoading,
    setNodes,
    updateTitles,
    updatePagination
  );
};

export const listGavetas = (
  imovel_id: any,
  currentPage: any,
  setLoading: (value: any) => void,
  setNodes: (value: any) => void,
  updateTitles: (titles: string[]) => void,
  updatePagination: (totalPages: number) => void
) => {
  setLoading(true);
  imovelGavetaService
    .listGavetas(imovel_id, null, null, updateTitles, updatePagination)
    .subscribe(
      (response) => {
        setNodes(response);
        setLoading(false);
      },
      (error) => {
        let mensagens: string[] = [];
        mensagens.push(error?.response?.data?.error?.message);
        AlertService.error("Erro!", String(mensagens));
      }
    );
};
export const ContratoListJazigo = (
  filtros: any,
  currentPage: any,
  setLoading: (value: any) => void,
  setNodes: (value: any) => void,
  updateTitles: (titles: string[]) => void,
  updatePagination: (totalPages: number) => void
) => {
  ContratoService.listContratoJazigo(
    {
      include: "compradores,cliente,plano_conta,conta_financeiro,centro_custo",
      totalitems: 8,
      consulta: JSON.stringify(filtros),
      page: currentPage + 1,
    },
    null,
    updateTitles,
    updatePagination
  ).subscribe(
    (response) => {
      setNodes(response);
      setLoading(false);
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const VendaAvulsaList = (
  filtros: any,
  currentPage: any,
  setLoading: (value: any) => void,
  setNodes: (value: any) => void,
  updateTitles: (titles: string[]) => void,
  updatePagination: (totalPages: number) => void
) => {
  setLoading(true);
  const form = {
    contrato_id: filtros?.contrato_id,
    pessoa_id: filtros?.pessoa_id,
    tipo_venda: filtros?.tipo,
    dia_vencimento: filtros?.dia_vencimento,
    empreendimento_id: TokenService.getEmpreendimentoId()
      ? TokenService.getEmpreendimentoId()?.id
      : null,
    tipo_consulta: filtros?.tipo_consulta,
    data_inicial: filtros?.data_inicial,
    data_final: filtros?.data_final,
  };

  const filtro = Object.fromEntries(
    Object.entries(form).filter(
      ([_, value]) => value !== null && value !== "" && value !== undefined
    )
  );

  if (consulta) {
    consulta.filtro = filtro;
  }
  ContratoService.VendaAvulsaList(
    {
      totalitems: 8,
      consulta: JSON.stringify(consulta),
      page: currentPage + 1,
      include: "venda_itens.produto,contrato",
    },
    null,
    updateTitles,
    updatePagination
  ).subscribe(
    (response) => {
      setNodes(response);
      setLoading(false);
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const setSessionEditData = (value: any, url_redirect_edit: any) => {
  ContratoService.get(value).subscribe(
    (response) => {
      localStorage.setItem("dadosEdicao", JSON.stringify(response));
      window.location.href = url_redirect_edit;
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const handleOpenModal = (
  row: any,
  setSelectedRow: (value: any) => void,
  setShowModal: (value: any) => void
) => {
  setSelectedRow(row);
  setShowModal(true);
};

export const handleOpenModalAniversario = (
  row: any,
  setSelectedRow: (value: any) => void,
  setShowModalAniversario: (value: any) => void
) => {
  setSelectedRow(row);
  setShowModalAniversario(true);
};

export const searchCliente = (
  event: any,
  currentPage: any,
  setFilteredClient: (value: any) => void
) => {
  const query = event.query;
  const filtro = {
    nome: query,
  };

  if (consulta) {
    consulta.filtro = filtro;
  }

  ClienteService.list({
    include: "pessoa.enderecos,pessoa.telefones",
    totalitems: 8,
    consulta: JSON.stringify(consulta),
    page: currentPage + 1,
  }).subscribe(
    (response) => {
      setFilteredClient(response);
    },
    (error) => {
      console.error(error);
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};
export const searchJazigo = (
  event: any,
  currentPage: any,
  setFilteredClient: (value: any) => void
) => {
  const query = event.query;
  const filtro = {
    nome: query,
  };

  if (consulta) {
    consulta.filtro = filtro;
  }

  TipoJazigoService.list({
    totalitems: 8,
    consulta: JSON.stringify(consulta),
    page: currentPage + 1,
  }).subscribe(
    (response) => {
      setFilteredClient(response);
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const searchInumado = (
  event: any,
  currentPage: any,
  setFilteredInumado: (value: any) => void
) => {
  const query = event.query;
  const filtro = {
    nome: query,
    empreendimento_id: TokenService.getEmpreendimentoId()?.id || null,
    filial_id: TokenService.getFilialId()?.id || null,
  };
  if (consulta) {
    consulta.filtro = filtro;
  }
  InumadoService.listInumado({
    include: "pessoa.enderecos,pessoa.telefones",
    totalitems: 20,
    consulta: JSON.stringify(consulta),
    page: currentPage + 1,
  }).subscribe(
    (response) => {
      setFilteredInumado(response);
    },
    (error) => {
      let mensagens: string[] = [];
      mensagens.push(error?.response?.data?.error?.message);
      AlertService.error("Erro!", String(mensagens));
    }
  );
};

export const simularSeNecessario = (
  formBasico: any,
  handleChange: (field: string, value: any) => void
) => {
  try {
    const form = {
      imovel_id: formBasico?.imovel_id,
      tabela_preco_id: formBasico?.tabela_preco_id,
      entrada: formBasico?.entrada,
      valor_desconto: formBasico?.valor_desconto,
      intermed_valor_desc: formBasico?.descontoIntermed,
      valor_parc_intermed: formBasico?.primeira_parcela_intermed,
      qtd_parc_intermed: formBasico?.qtd_parc_intermed,
      qtd_parc_entrada: formBasico?.qtd_parcelas_entrada,
      valor_prim_parc_entrada: formBasico?.primeira_parcela_entrada,
      produtos_servicos: formBasico?.produtos_servicos,
      empreendimento_id: TokenService.getEmpreendimentoId()
        ? TokenService.getEmpreendimentoId()?.id
        : null,
    };

    ContratoService.SimularVendaContrato(JSON.stringify(consulta)).subscribe(
      (response) => {
        Object.keys(response).forEach((key: any) => {
          handleChange(key, response[key]);
        });
      },
      (error) => {
        let mensagens: string[] = [];
        mensagens.push(error?.response?.data?.error?.message);
        AlertService.error("Erro!", String(mensagens));
      }
    );
  } catch (error) {
    console.error(
      "Error",
      "Ocorreu um erro ao buscar a lista de permissões. Erro:" + String(error)
    );
  }
};
export async function cancelamento(
  selectedNodeKeys: any,
  formCancelamento: any,
  setShowModalCancelarContrato: (value: boolean) => void
) {
  try {
    const response: any = await api.post(
      `${url}/api/v1/admin/vendas/cancelar-contrato/${selectedNodeKeys[0].id}`,
      formCancelamento
    );
    if (response.data.success === true) {
      setShowModalCancelarContrato(false);
      AlertService.sucess("Sucesso", "Contrato Cancelado com sucesso");
    }
  } catch (error: any) {
    let mensagens: string[] = [];
    mensagens.push(error?.response?.data?.error?.message);
    AlertService.error("Erro!", String(mensagens));
  }
}

export const simularValorVenda = async (
  formBasico: any,
  handleChange: (field: string, value: any) => void
) => {
  try {
    const queryParams: any = {};

    const camposEnviar = [
      "imovel_id",
      "tabela_preco_id",
      "entrada",
      "valor_desconto",
      "intermed_valor_desc",
      "valor_parc_intermed",
      "qtd_parc_intermed",
      "qtd_parc_entrada",
      "valor_prim_parc_entrada",
    ];

    camposEnviar.forEach((key) => {
      const value = formBasico[key];
      if (value !== undefined && value !== null && value !== "") {
        queryParams[key] = value;
      }
    });

    const queryString = Object.entries(queryParams)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
      )
      .join("&");

    const response = await api.get(
      `${url}/api/v1/admin/vendas/contrato-simular-valor-venda?${queryString}`
    );

    if (response.status === 200) {
      Object.keys(response.data.data).forEach((key) => {
        handleChange(key, response.data.data[key]);
      });
    }
  } catch (error: any) {
    AlertService.error(
      "Erro!",
      String(error.response?.data?.message) || "Erro desconhecido"
    );
  }
};
