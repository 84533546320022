import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useSidebarContext } from '../../../contexts/SidebarContext';
import { Col, Form, InputGroup, NavLink, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../assets/images/icon-calendar.png';
import pt from 'date-fns/locale/pt';
import 'react-autocomplete-input/dist/bundle.css';
import { AlertService } from '../../../components/AlertService';
import TokenService from '../../../services/Auth/token.service';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import api from '../../../services/api';
import MultiSelectDropdown from '../../../components/MultiSelectCheckbox';
import { abrirPDF } from '../../../utils/PdfUtils';
import { showToast } from '../../../components/ToastComponent';
import { AutoComplete } from 'primereact/autocomplete';
import { searchPessoa } from '../ContasReceber/Service/ContasReceberService';
import { Skeleton } from 'primereact/skeleton';
import { TreeSelect } from 'primereact/treeselect';
import { TreeNode } from 'primereact/treenode';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';


function Relatorio() {
  const [listFiltros, setListFiltros] = useState<any>({});
  const [filtros, setFiltros] = useState<string>();
  const { isVisible, updateVisibility } = useSidebarContext();
  const [dataInicio, setDataInicio] = useState<string | null>(null);
  const [dataFim, setDataFim] = useState<string | null>(null);
  const { showSubSidebar } = useAppContext();
  const [listCompradores, setListCompradores] = useState([]);
  const [listContaFinanceira, setListContaFinanceira] = useState([]);
  const [listPlanoConta, setListPlanoConta] = useState<TreeNode[]>([]);
  const [loading, setLoading] = useState(false);
  const [listCentroCusto, setListCentroCusto] = useState<TreeNode[]>([]);
  const [listCampos, setListCampos] = useState([]);
  const [FilteredPessoa, setFilteredPessoa] = useState<any>(null);
  const consultaPorPeriodo = [
    { label: 'Baixa', value: 'data_baixa' },
    { label: 'Credito', value: 'data_credito' },
    { label: 'Criação', value: 'created_at' },
    { label: 'Emissão', value: 'data_emissao' },
    { label: 'Pagamento', value: 'data_pagamento' },
    { label: 'Programada', value: 'data_prog_pgto' },
    { label: 'Vencimento', value: 'data_vencimento' },
    { label: 'Alteração', value: 'updated_at' },
  ];
  const tipoLancamento = [
    { label: 'Contas à Receber', value: 0 },
    { label: 'Contas à Pagar', value: 1 },
    { label: 'Movimento de Caixa', value: 2 },
    { label: 'Conta Corrente', value: 3 },
  ];
  const getTipoParcela = [
    { label: 'Ordinaria', value: 0 },
    { label: 'Avulsa', value: 1 },
    { label: 'Agrupada', value: 2 },
  ];

  const getSituacaoPagamento = [
    { label: 'Aberto', value: 0 },
    { label: 'Baixado', value: 1 },
    { label: 'Inadimplente', value: 2 },
  ];

  const getCamposMovCaixa = [
    'id',
    'numero_documento',
    "cliente_id",
    "venda_id",
    "valor_pago",
    "conta_financeiro_id",
    'centro_custo_id',
    "plano_conta_id",
    'descricao_caixa',
  ];

  const getCamposContasPgRc = [
    'id',
    'numero_documento',
    "cliente_id",
    "venda_id",
    "data_vencimento",
    "data_baixa",
    'data_pagamento',
    "valor_original",
    "valor_pago",
    "conta_financeiro_id",
    'centro_custo_id',
    "plano_conta_id",
    'historico',
    "protocolar",
  ];
  const getCamposContaCorrente = [
    'id',
    'numero_documento',
    "cliente_id",
    "venda_id",
    "valor_pago",
    "data_ult_cobranca",
    "conta_financeiro_id",
    'centro_custo_id',
    "plano_conta_id",
  ];

  const toggleVisibility = () => {
    const newValue = !isVisible;
    updateVisibility(newValue);
  };
  async function gerarPDF(): Promise<void> {
    try {
      if (!listFiltros.tipo_relatorio) {
        AlertService.infomessage('Atenção!', 'Selecione qual o tipo de Relatório será emitido para gerar o relatório!');
        return;
      }
      setLoading(true);
      let campos = null;
      if (listFiltros.tipo_lancamento == 2) {
        campos = getCamposMovCaixa;
      } else if (listFiltros.tipo_lancamento == 3) {
        campos = getCamposContaCorrente;
      } else {
        campos = getCamposContasPgRc;
      }
      const consulta: any = {
        consulta: {
          order: "id;desc",
          filtro: {
            numero_contrato: listFiltros.numero_contrato,
            venda_id: listFiltros.venda_id,
            conta_financeiro_id: listFiltros.conta_financeiro_id,
            plano_conta_id: listFiltros.plano_conta_id,
            situacao_pagamento: listFiltros.situacao_pagamento,
            tipo_parcela: listFiltros.tipo_parcela,
            centro_custo: listFiltros.centro_custo,
            pessoa_id: listFiltros.pessoa_id,
            tipo_consulta: listFiltros.tipo_consulta,
            data_inicial: listFiltros.dataInicio,
            data_final: listFiltros.dataFim,
            tipo_lancamento: listFiltros.tipo_lancamento,
            campos: campos,
            tipo_relatorio: listFiltros.tipo_relatorio,
            empreendimento_id: TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().id : null
          }
        }
      };

      const queryParams: any = {};

      const camposEnviar = ['consulta'];

      camposEnviar.forEach((key: any) => {
        const value = consulta[key];
        if (value !== undefined && value !== null && value !== '') {
          if (key === 'consulta') {
            queryParams[key] = JSON.stringify(value);
          } else {
            queryParams[key] = value;
          }
        }
      });

      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
        .join('&');
      const response = await api.get(`${url}/api/v1/admin/financeiro/gerador-arquivo-relatorio?${queryString}`, {
        responseType: 'arraybuffer',
      });
      if (response.status === 200) {
        handleOpenPDF(response.data, listFiltros);
        setLoading(false);
      }

    } catch (error: any) {
      showToast('error', 'Nenhum Registro Encontrado!');
      setLoading(false);
    }
  }

  useEffect(() => {
    InitContaFinanceira();
    InitPlanoConta();
    InitCentroCusto();
    InitCompradores();
    InitCampos();
  }, []);

  const handleChange = (field: string, value: any) => {
    setListFiltros((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };

  async function InitCompradores() {
    try {
      const response = await api.get(`${url}/api/v1/admin/financeiro/cliente?include=pessoa.conjugue`);
      setListCompradores(response.data.data);
    } catch (error) {
      AlertService.error('Error', 'Ocorreu um erro ao buscar a de clientes. Erro:' + String(error));
    }
  }
  async function InitContaFinanceira() {
    try {
      const response = await api.get(`${url}/api/v1/admin/financeiro/conta-financeira`);
      setListContaFinanceira(response.data.data);
    } catch (error) {
      AlertService.error('Error', 'Ocorreu um erro ao buscar a das contas financeiras. Erro:' + String(error));
    }
  }

  async function InitPlanoConta() {
    try {
      const includeParam = 'tipo_label,filhos.filhos.filhos.filhos.filhos.filhos';
      const response = await api.get(`${url}/api/v1/admin/financeiro/plano-conta?include=${includeParam}`);
      if (response.status === 200) {
        const data = response.data.data;

        const buildTreeNode = (item: any): TreeNode | null => {
          if (item) {
            const hasChildren = item.filhos?.data && item.filhos.data.length > 0;
            return {
              key: item.id.toString(),
              label: item.nome || item.id.toString(),
              data: item,
              icon: hasChildren ? 'pi pi-fw pi-folder' : 'pi pi-fw pi-file',
              children: hasChildren ? item.filhos.data.map((child: any) => buildTreeNode(child)) : null,
              selectable: !hasChildren,  // Não pode ser selecionado se tiver filhos
            };
          }
          return null;
        };

        const nodesData: TreeNode[] = data
          ? data.reduce((acc: TreeNode[], item: any) => {
            const treeNode = buildTreeNode(item);
            if (treeNode) {
              acc.push(treeNode);
            }
            return acc;
          }, [])
          : [];
        setListPlanoConta(nodesData);
      }
    } catch (error) {
      AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro: ' + String(error));
    }
  }
  async function InitCentroCusto() {
    try {
      const includeParam = 'tipo_label,filhos.filhos.filhos.filhos.filhos.filhos'
      const response = await api.get(`${url}/api/v1/admin/financeiro/centro-custo?include=${includeParam}`);
      if (response.status === 200) {
        const data = response.data.data;

        const buildTreeNode = (item: any): TreeNode | null => {
          if (item) {
            const hasChildren = item.filhos?.data && item.filhos.data.length > 0;
            return {
              key: item.id.toString(),
              label: item.nome || item.id.toString(),
              data: item,
              icon: hasChildren ? 'pi pi-fw pi-folder' : 'pi pi-fw pi-file',
              children: hasChildren ? item.filhos.data.map((child: any) => buildTreeNode(child)) : null,
              selectable: !hasChildren,  // Não pode ser selecionado se tiver filhos
            };
          }
          return null;
        };

        const nodesData: TreeNode[] = data
          ? data.reduce((acc: TreeNode[], item: any) => {
            const treeNode = buildTreeNode(item);
            if (treeNode) {
              acc.push(treeNode);
            }
            return acc;
          }, [])
          : [];
        setListCentroCusto(nodesData);
      }
    } catch (error) {
      AlertService.error('Error', 'Ocorreu um erro ao buscar a lista de permissões. Erro:' + String(error));
    }
  }

  async function InitCampos() {
    try {
      const response = await api.get(`${url}/api/v1/admin/financeiro/lancamentofinanceiro/listColumns`);
      setListCampos(response.data.data);
    } catch (error) {
      AlertService.error('Error', 'Ocorreu um erro ao buscar a de centro de custo. Erro:' + String(error));
    }
  }
  const handleOpenPDF = (pdfBase64: string, listFiltros: any) => {
    abrirPDF(pdfBase64, listFiltros);
  }

  addLocale('pt', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'Hoje',
    clear: 'Limpar'
  });
  return (
    <div className="body-content">
      <div
        className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}
      >
        <div className="contentSearch">
          <div className="contentSearch-header">
            <NavLink onClick={toggleVisibility}>
              <img
                className="menu-context"
                src={iconMenuTresPonto}
              />
            </NavLink>
            <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Relatórios Financeiros</Col>
          </div>
          <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
          <Form.Group className="imovel-form" style={{ marginLeft: '22px' }}>
            <Row className="mb-3">
              {!loading ? (<Col xs={12} md={2} lg={2} style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Número do Contrato</Form.Label>
                <Form.Control
                  value={listFiltros?.numero_contrato || ''}
                  onChange={(e) => handleChange('numero_contrato', e.target.value)}
                />
              </Col>) : (<Skeleton />)}
              {!loading ? (<Col xs={12} md={2} lg={2} style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Contrato|Id</Form.Label>
                <Form.Control
                  value={listFiltros?.venda_id || ''}
                  onChange={(e) => handleChange('venda_id', e.target.value)}
                />
              </Col>) : (<Skeleton />)}
              {!loading ? (<Col md="2" style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Tipo de data</Form.Label>
                <Form.Select value={listFiltros.tipo_consulta || ''} onChange={(e) => handleChange('tipo_consulta', e.target.value)}>
                  <option>Selecione...</option>
                  {consultaPorPeriodo.map((item: any) => (
                    <option key={item.value} value={item.value}>{item.label}</option>
                  ))}
                </Form.Select>
              </Col>) : (<Skeleton />)}
              {!loading ? (<Col md={3} style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>
                  Data de Inicio
                </Form.Label>
                <div className=" flex justify-center">
                  <Calendar
                    value={dataInicio ? new Date(dataInicio) : null}
                    onChange={(event: any) => {
                      const selectedDates = event.value;
                      setDataInicio(selectedDates);
                      handleChange('dataInicio', selectedDates ? selectedDates.toISOString() : null);
                    }}
                    placeholder="Data de inicio"
                    showButtonBar
                    selectionMode="single"
                    dateFormat="dd/mm/yy"
                    locale="pt"
                    readOnlyInput
                    style={{ textAlign: 'center', width: '100%' }}
                    panelClassName="custom-calendar-panel"
                    required
                  />
                </div>
              </Col>) : (<Skeleton />)}
              {!loading ? (<Col md={3} style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>
                  Data de Fim
                </Form.Label>
                <div className=" flex justify-center">
                  <Calendar
                    value={dataFim ? new Date(dataFim) : null}
                    onChange={(event: any) => {
                      const selectedDates = event.value;
                      setDataFim(selectedDates);
                      handleChange('dataFim', selectedDates ? selectedDates.toISOString() : null);
                    }}
                    placeholder="Data Final"
                    showButtonBar
                    selectionMode="single"
                    dateFormat="dd/mm/yy"
                    locale="pt"
                    readOnlyInput
                    style={{ textAlign: 'center', width: '100%' }}
                    panelClassName="custom-calendar-panel"
                    required
                  />
                </div>
              </Col>) : (<Skeleton />)}


            </Row>
            <Row className="mb-3">
              {!loading ? (<Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Favorecido | Devedor</Form.Label>
                <AutoComplete
                  field="nome"
                  style={{ height: "37px" }}
                  value={listFiltros?.pessoa}
                  suggestions={FilteredPessoa}
                  completeMethod={(e) => searchPessoa(e, setFilteredPessoa)}
                  onChange={(e) => {
                    setListFiltros({ ...listFiltros, pessoa: e.value })
                    handleChange('pessoa', e.value)
                  }}
                  onSelect={(e) => {
                    handleChange('pessoa_id', e.value.id)
                    setListFiltros({ ...listFiltros, pessoa_id: e.value.id, pessoa: e.value })
                  }}
                />
              </Col>) : (<Skeleton />)}
              {!loading ? (<Col md="2" style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Tipo de Lançamento</Form.Label>
                <Form.Select value={listFiltros.tipo_lancamento || ''} isInvalid={!listFiltros.tipo_lancamento} onChange={(e) => handleChange('tipo_lancamento', e.target.value)}>
                  <option>Selecione...</option>
                  <option value={0}>Contas à Receber</option>
                  <option value={1}>Contas à Pagar</option>
                  <option value={2}>Movimento de caixa</option>
                  <option value={3}>Conta Corrente</option>
                </Form.Select>
              </Col>) : (<Skeleton />)}

              {!loading ? (<Col md="2" style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Conta Financeira</Form.Label>
                <Form.Select value={listFiltros.conta_financeiro_id || ''} onChange={(e) => handleChange('conta_financeiro_id', e.target.value)}>
                  <option>Selecione...</option>
                  {listContaFinanceira?.map((item: any) => (
                    <option value={item.id} key={item.id}>
                      {item.nome}
                    </option>
                  ))}
                </Form.Select>
              </Col>) : (<Skeleton />)}

              {!loading ? (<Col md="2" style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Plano de Contas</Form.Label>
                <TreeSelect
                  value={listFiltros.plano_conta_id}
                  options={listPlanoConta}
                  onChange={(e) => {
                    const selectedValues: any = e.value;
                    handleChange('plano_conta_id', selectedValues)
                  }}
                  filter
                  selectionMode="single"
                  placeholder="Selecione um plano de conta"
                  display="chip"
                  className="w-full md:w-20rem"
                  style={{ width: '100%' }}
                  onFocus={InitPlanoConta}
                />
              </Col>) : (<Skeleton />)}

              {!loading ? (<Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Centro de Custo</Form.Label>
                <TreeSelect
                  value={listFiltros.centro_custo ? listFiltros.centro_custo?.reduce((acc: any, item: any) => {
                    acc[item] = true;
                    return acc;
                  }, []) : []}
                  options={listCentroCusto}
                  onChange={(e) => {
                    const selectedValues: any = e.value || [];
                    let centros: any[] = [];
                    Object.keys(selectedValues).forEach((key, index) => {
                      centros.push(Number(key));
                    });
                    handleChange('centro_custo', centros)
                  }}
                  filter
                  placeholder="Selecione um centro de custo"
                  display="chip"
                  selectionMode="multiple"
                  className="w-full md:w-20rem"
                  style={{ width: '100%' }}
                  onFocus={InitCentroCusto}
                />
              </Col>) : (<Skeleton />)}
            </Row>
            <Row className="mb-3">
              {!loading ? (<Col md="2" style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Tipo de parcelas</Form.Label>
                <Form.Select value={listFiltros.tipo_parcela || ''} onChange={(e) => handleChange('tipo_parcela', e.target.value)}>
                  <option>Selecione...</option>
                  {getTipoParcela.map((item: any) => (
                    <option key={item.value} value={item.value}>{item.label}</option>
                  ))}
                </Form.Select>
              </Col>) : (<Skeleton />)}

              {!loading ? (<Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Situação do pagamento</Form.Label>
                <Form.Select value={listFiltros.situacao_pagamento || ''} onChange={(e) => handleChange('situacao_pagamento', e.target.value)}>
                  <option>Selecione...</option>
                  {getSituacaoPagamento?.map((item: any) => (
                    <option value={item.value} key={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Form.Select>
              </Col>) : (<Skeleton />)}

              {!loading ? (<Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Label>Tipo de Relatório</Form.Label>
                <Form.Select value={listFiltros.tipo_relatorio} isInvalid={!listFiltros.tipo_relatorio} onChange={(e) => handleChange('tipo_relatorio', e.target.value)}>
                  <option>Selecione...</option>
                  <option value={1} key={1}>PDF</option>
                  <option value={2} key={2}>EXCEL</option>
                </Form.Select>
              </Col>) : (<Skeleton />)}
            </Row>
          </Form.Group>
          <div className='div-filtros-avancados'>
            {listFiltros.tipo_relatorio && listFiltros.tipo_lancamento &&
              (!loading ? (<Button
                onClick={() => gerarPDF()}
                style={{ height: '30px', borderRadius: '8px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                type="button"
                severity="success"
                icon="pi pi-search"
                rounded>
                <p style={{ marginLeft: '5px', marginTop: '13px' }}>Gerar Relatório</p>
              </Button>) : (<Skeleton />))}
            {!loading ? (<Button
              style={{ height: '30px', borderRadius: '8px', padding: '5px 10px', marginRight: '25px', fontSize: '14px' }}
              type="button"
              severity="danger"
              icon="pi pi-delete-left"
              rounded
              onClick={() => {
                setListFiltros({});
                setDataInicio(null);
                setDataFim(null);
              }}
            >
              <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
            </Button>) : (<Skeleton />)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Relatorio;