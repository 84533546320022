import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import TokenService from '../../../services/Auth/token.service';
import ContaFinanceiraForm from './Formularios/ContaFinanceiraForm';
import { changeEndereco, changeEnderecoContaFinanceira } from '../../../utils/EnderecoUtils';

function CreateContaFinanceira() {
    const { setUrl, setBody, setShowButtonSave } = useAppContext();
    const [formBasico, setFormBasico] = useState<any>({});
    useEffect(() => {
        setUrl(`${url}/api/v1/admin/financeiro/conta-financeira`);
        setFormBasico((prevData: any) => {
            return {
                ...prevData,
                ['empreendimento_id']: TokenService.getEmpreendimentoId()?.id
            };
        });
    }, []);

    const handleChangeInformacaoBancaria = (field: string, value: any) => {
        if (setFormBasico) {
            if (formBasico) {
                if (field.startsWith('informacao_bancaria.')) {
                    const informacaoField = field.replace('informacao_bancaria.', '');
                    setFormBasico((prevGrupoData: any) => {
                        return {
                            ...prevGrupoData,
                            informacao_bancaria: {
                                ...prevGrupoData.informacao_bancaria,
                                [informacaoField]: value,
                            },
                        };
                    });
                }
            }
        }
    }
    const handleChangePessoaForm = (field: string, value: any) => {
        if (setFormBasico) {
            setFormBasico((prevGrupoData: any) => {
                return {
                    ...prevGrupoData,
                    informacao_bancaria: {
                        ...prevGrupoData?.informacao_bancaria,
                        pessoa: {
                            ...prevGrupoData?.informacao_bancaria?.pessoa,
                            [field]: value,
                        },
                    },
                };
            });
        }
    }
    const handleChangeBairro = (field: string, value: string) => {
        if (setFormBasico) {
            let newForm: any = { ...formBasico };

            if (formBasico) {
                if (field.startsWith('bairro.')) {
                    const bairroField = field.replace('bairro.', '');
                    if (newForm?.informacao_bancaria) {
                        const bairro = newForm?.informacao_bancaria.pessoa?.enderecos?.data
                            ? newForm?.informacao_bancaria.pessoa?.enderecos?.data
                            : newForm?.informacao_bancaria.pessoa?.enderecos;
                        if (newForm && bairro && newForm.informacao_bancaria.pessoa) {
                            const updatedEnderecos = newForm?.informacao_bancaria.pessoa?.enderecos?.data
                                ? [...newForm.informacao_bancaria.pessoa.enderecos?.data]
                                : [...newForm.informacao_bancaria.pessoa.enderecos];
                            updatedEnderecos[0] = {
                                ...updatedEnderecos[0],
                                bairro: {
                                    [bairroField]: value,
                                }
                            };
                            setFormBasico((prevGrupoData: any) => {
                                return {
                                    ...prevGrupoData,
                                    informacao_bancaria: {
                                        ...prevGrupoData.informacao_bancaria,
                                        pessoa: {
                                            ...prevGrupoData.informacao_bancaria.pessoa,
                                            enderecos: updatedEnderecos,
                                        },
                                    }
                                }
                            });
                        }
                    }
                }
            }
        }
    }
    const handleChangeEnderecos = (field: string, value: any) => {
        if (field.startsWith('enderecos.')) {
            setFormBasico((prevGrupoData: any) => {
                const newForm = { ...prevGrupoData };
                const enderecosField = field.replace('enderecos.', '');
                const enderecos = newForm.informacao_bancaria?.pessoa?.enderecos || [];
                const updatedEnderecos = [...enderecos];
                updatedEnderecos[0] = {
                    ...updatedEnderecos[0],
                    [enderecosField]: value,
                };
                return {
                    ...newForm,
                    informacao_bancaria: {
                        ...newForm.informacao_bancaria,
                        pessoa: {
                            ...newForm.informacao_bancaria?.pessoa,
                            enderecos: updatedEnderecos,
                        },
                    },
                };
            });
        }
    };

    const handleChangeTelefones = (field: string, value: any) => {
        if (setFormBasico) {
            let newForm: any = { ...formBasico };

            if (formBasico) {
                if (newForm?.informacao_bancaria) {
                    const telefones = newForm?.informacao_bancaria.pessoa?.telefones?.data
                        ? newForm?.informacao_bancaria.pessoa?.telefones?.data
                        : (newForm?.informacao_bancaria.pessoa?.telefones ? newForm?.informacao_bancaria.pessoa?.telefones : []);
                    if (newForm && telefones && newForm.informacao_bancaria.pessoa) {
                        const updatedTelefones = [...telefones];
                        updatedTelefones[0] = {
                            ...updatedTelefones[0],
                            [field]: value,
                        };
                        setFormBasico((prevGrupoData: any) => {
                            return {
                                ...prevGrupoData,
                                informacao_bancaria: {
                                    ...prevGrupoData.informacao_bancaria,
                                    pessoa: {
                                        ...prevGrupoData.informacao_bancaria.pessoa,
                                        telefones: updatedTelefones,
                                    },
                                }
                            }
                        });
                    }
                }
            }
        }
    }
    const handleChange = (field: string, value: string) => {
        if (setFormBasico) {
            if (formBasico) {
                setFormBasico((prevGrupoData: any) => {
                    return {
                        ...prevGrupoData,
                        [field]: value,
                    };
                });
            }
        }
    }

    useEffect(() => {
        setBody(formBasico)
        setShowButtonSave(true);
    }, [formBasico]);

    return (
        <>
            <div className="body-content">
                <div className="form-create-group">
                    <div className="form-create-group-header">
                        <h3>Cadastrar Conta Financeira</h3>
                    </div>
                </div>
                <div className="form-group-create">
                    <Container>
                        <ContaFinanceiraForm
                            initialRightValues={[]}
                            formBasico={formBasico}
                            onInputChangeInformacaoBancaria={(field: string, value: any) => handleChangeInformacaoBancaria(`informacao_bancaria.${field}`, value)}
                            onInputChangePessoaForm={(field: string, value: any) => handleChangePessoaForm(`${field}`, value)}
                            onInputChangeEnderecos={(field: string, value: any) => handleChangeEnderecos(`enderecos.${field}`, value)}
                            onInputChangeTelefones={(field: string, value: any) => handleChangeTelefones(`${field}`, value)}
                            onInputChangeBairro={(field: string, value: any) => handleChangeBairro(`bairro.${field}`, value)}
                            onInputChange={(field: string, value: any) => handleChange(`${field}`, value)}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default CreateContaFinanceira;
