import { InputNumber } from "primereact/inputnumber";
import { Col, Form, Row } from "react-bootstrap";
interface PropsData {
    Data: {
        nome_servico?: string;
        descricao_resumida?: string;
        descricao_detalhada?: string;
        identificador?: string;
        unidade?: number;
        n_serie?: string;
        acessorios?: string;
        patrimonio?: string;
        tipo?: number;
        control_saldo?: boolean;
        valor?: number;
        status?: number;
        tipo_comissao?: number;
        percentual_comissao?: number;
        stk_atual?: number;
        pontos?: number;
        condicoes_proposta?: string;
        localizacao?: number;
        vl_aquisic?: number;
    };
    initialRightValues: any;
    onInputChange: (field: string, value: any) => void;
}
function FormProduto(props: PropsData) {
    const {
        nome_servico,
        descricao_detalhada,
        descricao_resumida,
        identificador,
        unidade,
        n_serie,
        acessorios,
        patrimonio,
        tipo,
        control_saldo,
        valor,
        status,
        tipo_comissao,
        percentual_comissao,
        stk_atual,
        pontos,
        condicoes_proposta,
        localizacao,
        vl_aquisic
    } = props.Data || {};

    const handleChange = (field: string, value: any) => {
        if (field === 'nome_servico' || field === 'descricao_detalhada' || field === 'acessorios' || field === 'condicoes_proposta') {
            if (value.length <= 200) {
                props.onInputChange(field, value);
            }
        } else if (field === 'identificador' || field === 'patrimonio') {
            if (value.length <= 50) {
                props.onInputChange(field, value);
            }
        } else if ( field === 'vl_aquisic') {
            if (!isNaN(value) && value >= 0) {
                props.onInputChange(field, value);
            }
        } else if (field === 'percentual_comissao') {
            if (!isNaN(value) && value >= 0 && value <= 100) {
                props.onInputChange(field, value);
            }
        } else {
            props.onInputChange(field, value);
        }
    };

    const validaComissao = () => {
        const perc = percentual_comissao;
        const tipo = tipo_comissao;
        const invalidPerc = !perc && !!tipo;
        const invalidTipo = !tipo && !!perc;
        return { invalidPerc, invalidTipo };
    };

    return (
        <>
            <Form className="forms">
                <Row className="mb-3">
                    <Col md="4">
                        <Form.Label className="mb-0">Nome / Descrição Serviço</Form.Label>
                        <Form.Control
                            value={nome_servico || ""}
                            isInvalid={!nome_servico}
                            onChange={(e) => handleChange("nome_servico", e.target.value)}
                        />
                    </Col>
                    <Col md="2">
                        <Form.Label className="mb-0">Unidade</Form.Label>
                        <Form.Select
                            value={unidade || ""}
                            isInvalid={!unidade}
                            onChange={(e) => handleChange("unidade", e.target.value)}
                        >
                            <option>Selecione...</option>
                            <option value={1}>Unidade</option>
                            <option value={2}>Peça</option>
                            <option value={3}>Hora</option>
                        </Form.Select>
                    </Col>
                    <Col md="2">
                        <Form.Label className="mb-0">Tipo</Form.Label>
                        <Form.Select
                            value={tipo || ""}
                            isInvalid={!tipo}
                            onChange={(e) => handleChange("tipo", e.target.value)}
                        >
                            <option>Selecione...</option>
                            <option value="1">Serviço</option>
                            <option value="2">Mercadoria</option>
                            <option value="3">Patrimônio</option>
                        </Form.Select>
                    </Col>
                    <Col md="2">
                        <Form.Label className="mb-0">Status</Form.Label>
                        <Form.Select
                            value={status || ""}
                            isInvalid={!status}
                            onChange={(e) => handleChange("status", e.target.value)}
                        >
                            <option>Selecione...</option>
                            <option value="1">Ativo</option>
                            <option value="2">Inativo</option>
                        </Form.Select>
                    </Col>
                    <Col md="2">
                        <Form.Label className="mb-0">Control Saldo</Form.Label>
                        <Form.Check
                            type="switch"
                            id="control_saldo"
                            checked={control_saldo || false}
                            onChange={(e) => handleChange("control_saldo", e.target.checked)}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="2">
                        <Form.Label className="mb-0">Identificador</Form.Label>
                        <Form.Control
                            value={identificador || ""}
                            isInvalid={!identificador}
                            onChange={(e) => handleChange("identificador", e.target.value)}
                        />
                    </Col>
                    <Col md="2">
                        <Form.Label className="mb-0">Nº Série</Form.Label>
                        <Form.Control
                            value={n_serie || ""}
                            onChange={(e) => handleChange("n_serie", e.target.value)}
                        />
                    </Col>
                    <Col md="2">
                        <Form.Label className="mb-0">Patrimônio</Form.Label>
                        <Form.Control
                            value={patrimonio || ""}
                            onChange={(e) => handleChange("patrimonio", e.target.value)}
                        />
                    </Col>
                    <Col md="2">
                        <Form.Label className="mb-0">Estoque Atual</Form.Label>
                        <Form.Control
                            value={stk_atual || ""}
                            onChange={(e) => handleChange("stk_atual", e.target.value)}
                        />
                    </Col>
                    <Col md="3">
                        <Form.Label className="mb-0">Localização</Form.Label>
                        <Form.Control
                            value={localizacao || ""}
                            onChange={(e) => handleChange("localizacao", e.target.value)}
                        />
                    </Col>
                    <Col md="1">
                        <Form.Label className="mb-0">Pontos</Form.Label>
                        <Form.Control
                            value={pontos || ""}
                            onChange={(e) => handleChange("pontos", e.target.value)}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="3" style={{ display: 'grid' }}>
                        <Form.Label className="mb-0">Valor</Form.Label>
                        <InputNumber
                            value={valor}
                            onValueChange={(e) => handleChange("valor", e.value)}
                            mode="currency"
                            currency="BRL"
                            locale="pt-BR"
                        />
                    </Col>
                    <Col md="2" style={{ display: 'grid' }}>
                        <Form.Label className="mb-0">Valor Aquisição</Form.Label>
                        <InputNumber
                            value={vl_aquisic}
                            onValueChange={(e) => handleChange("vl_aquisic", e.value)}
                            mode="currency"
                            currency="BRL"
                            locale="pt-BR"
                        />
                    </Col>
                    <Col md="2" style={{ display: 'grid' }}>
                        <Form.Label className="mb-0">Percentual Comissão</Form.Label>
                        <Form.Control
                            value={percentual_comissao}
                            isInvalid={validaComissao().invalidPerc}
                            onChange={(e) => handleChange("percentual_comissao", e.target.value)}
                        />
                    </Col>
                    <Col md="2" style={{ display: 'grid' }}>
                        <Form.Label className="mb-0">Tipo Comissão</Form.Label>
                        <Form.Select
                            value={tipo_comissao}
                            isInvalid={validaComissao().invalidTipo}
                            onChange={(e) => handleChange("tipo_comissao", e.target.value)}
                        >
                            <option value={''}>Selecione...</option>
                            <option value={1}>Percentual</option>
                            <option value={2}>Valor</option>
                        </Form.Select>
                    </Col>
                    <Col md="3" style={{ display: 'grid' }}>
                        <Form.Label className="mb-0">Acessórios</Form.Label>
                        <Form.Control
                            value={acessorios || ""}
                            onChange={(e) => handleChange("acessorios", e.target.value)}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label className="mb-0">Descrição Resumida</Form.Label>
                        <Form.Control
                            value={descricao_resumida || ""}
                            onChange={(e) => handleChange("descricao_resumida", e.target.value)}
                        />
                    </Col>
                    <Col>
                        <Form.Label className="mb-0">Condições Proposta</Form.Label>
                        <Form.Control
                            value={condicoes_proposta || ""}
                            onChange={(e) => handleChange("condicoes_proposta", e.target.value)}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md="12">
                        <Form.Label className="mb-0">Descrição Detalhada</Form.Label>
                        <Form.Control
                            value={descricao_detalhada || ""}
                            onChange={(e) => handleChange("descricao_detalhada", e.target.value)}
                        />
                    </Col>
                </Row>
            </Form >
        </>
    );
}

export default FormProduto;
