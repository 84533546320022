import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import PessoaForm from '../../Formularios/pessoa';
import EnderecoForm from '../../Formularios/endereco';
import TelefoneForm from '../../Formularios/telefone';
import { addTelefone, changeTelefone, removeTelefone } from '../../../utils/TelefoneUtils';
import { addEndereco, changeEndereco, removeEndereco } from '../../../utils/EnderecoUtils';
import ProgressBarForm from '../../Formularios/progressBarForm';
import { AlertService } from '../../../components/AlertService';
import ObraForm from './Fomularios/obraForm';
import { showToast } from '../../../components/ToastComponent';
import ConfigVendaForm from './Fomularios/ConfigVendaForm';
import ConfigCarteiraForm from './Fomularios/ConfigCarteiraForm';
import TokenService from '../../../services/Auth/token.service';

interface PropsData {
    id_externo?: string,
    nome?: string;
    tipo_empreendimento?: string;
    pessoa: {
        id: string,
        nome: string,
        email: string,
        cpf_cnpj: string,
        sexo: string,
        razao_social: string,
        inscricao_municipal: string,
        inscricao_estadual: string,
        filiacao_mae: string,
        filiacao_pai: string,
        rg: string,
        estado_civil: string,
        regime_uniao: string,
        data_nascimento: string,
        data_fundacao: string,
        enderecos: { data: [] },
        telefones: { data: [] },
    },
    config_venda: PropsEmpreendimento,
    config_carteira: PropsCarteira
}
interface PropsCarteira {
    empreendimento_id?: string;
    juros?: string;
    multa?: string;
    qtd_parcela_balao?: string;
    balao_periodicidade?: string;
    balao_venc_primeira?: string;
    dias_protesto?: string;
    prazo_pgto_apos_vencimento?: string;
    carencia_inicio_multa?: string;
    carencia_juros_mora?: string;
    desconto_ate_vencimento?: string;
    tempo_reajuste?: string;
    tx_boleto?: string;
    tx_boleto_parcela?: string;
    vl_condominio?: string;
    parcela_seguro_saldo_devedor?: string;
    gera_comis_para?: string;
    coef_adm_carteira?: string;
    parcela_honorario_advogado?: string;
    base_reajuste?: number;
    servico_taxa_manutencao_id?: number;
    produto?: any;
}
interface PropsEmpreendimento {
    empreendimento_id: string;
    venda_direta?: boolean;
    coef_intermediacao?: number;
    coef_admin?: number;
    coef_entrada?: number;
    coef_coordenador?: number;
    coef_corr_comiss?: number;
    multa_contrato_cancel?: number;
    multa_contrato_extravio?: number;
    qtd_limite_vendas?: number;
    qtd_parc_entrada?: number;
    qtd_parc_intermed?: number;
    modo_preco_fv_pf?: string;
    num_contrato_manual?: boolean;
    editar_contrato?: boolean;
    imp_contrato_sem_acerto?: boolean;
    intermed_embut_preco_imov?: boolean;
    imp_rcb_intermed_corretagem?: boolean;
    rcb_parte_corretor?: boolean;
    permitir_blt_intd_entrd?: boolean;
    cad_client_resumido?: boolean;
    alias_intermed?: string;
    intermed_desc?: string;
    parcela_chave?: boolean;
    coef_saldo_entrega_chave?: string;
    coef_parte_parc_proprio?: string;
    processa_entrada?: boolean;
    intermed_desc_part_adm?: string;
}

function EditEmpreendimentosObra() {
    const { setBody, setUrl, setTypeRequest } = useAppContext();
    const [currentPart, setCurrentPart] = useState(1);
    const totalParts = 6;
    const [formBasico, setFormBasico] = useState<any>({
        id_externo: '',
        nome: '',
        tipo_empreendimento: '',
        pessoa: {
            id: '',
            nome: '',
            email: '',
            cpf_cnpj: '',
            sexo: '',
            razao_social: '',
            inscricao_municipal: '',
            inscricao_estadual: '',
            filiacao_mae: '',
            filiacao_pai: '',
            rg: '',
            estado_civil: '',
            regime_uniao: '',
            data_nascimento: '',
            data_fundacao: '',
            enderecos: { data: [] },
            telefones: { data: [] },
        },
        config_venda: {
            empreendimento_id: "",
            venda_direta: false,
            coef_intermediacao: 0,
            coef_admin: 0,
            coef_entrada: 0,
            coef_coordenador: 0,
            coef_corr_comiss: 0,
            multa_contrato_cancel: 0,
            multa_contrato_extravio: 0,
            modo_preco_fv_pf: "",
            qtd_limite_vendas: 0,
            qtd_parc_entrada: 0,
            qtd_parc_intermed: 0,
            num_contrato_manual: false,
            editar_contrato: false,
            imp_contrato_sem_acerto: false,
            intermed_embut_preco_imov: false,
            imp_rcb_intermed_corretagem: false,
            rcb_parte_corretor: false,
            permitir_blt_intd_entrd: false,
            cad_client_resumido: false,
            alias_intermed: "",
            intermed_desc: "",
            parcela_chave: false,
            coef_saldo_entrega_chave: "",
            coef_parte_parc_proprio: "",
            processa_entrada: false,
            intermed_desc_part_adm: "",
        },
        config_carteira: {
            empreendimento_id: "",
            juros: "",
            multa: "",
            qtd_parcela_balao: "",
            balao_periodicidade: "",
            balao_venc_primeira: "",
            dias_protesto: "",
            prazo_pgto_apos_vencimento: "",
            carencia_inicio_multa: "",
            carencia_juros_mora: "",
            desconto_ate_vencimento: "",
            tempo_reajuste: "",
            tx_boleto: "",
            tx_boleto_parcela: "",
            vl_condominio: "",
            parcela_seguro_saldo_devedor: "",
            gera_comis_para: "",
            coef_adm_carteira: "",
            parcela_honorario_advogado: "",
        }
    });
    useEffect(() => {
        try {
            const storedData = localStorage.getItem('dadosEdicao');
            if (storedData) {
                if (!JSON.parse(storedData).pessoa.enderecos) {
                    setFormBasico((data: any) => {
                        return {
                            ...data,
                            ...JSON.parse(storedData),
                            pessoa: {
                                ...JSON.parse(storedData).pessoa,
                                telefones: [...JSON.parse(storedData).pessoa.telefones.data],
                            },
                        }
                    });
                } else {
                    setFormBasico((data: any) => {
                        return {
                            ...data,
                            ...JSON.parse(storedData),
                            pessoa: {
                                ...JSON.parse(storedData).pessoa,
                                enderecos: [...JSON.parse(storedData).pessoa.enderecos.data],
                                telefones: [...JSON.parse(storedData).pessoa.telefones.data],
                            },
                        }
                    });
                }
                setTypeRequest('PUT');
                setUrl(`${url}/api/v1/admin/vendas/empreendimentos/${storedData && JSON.parse(storedData).id}`);
            }
            return () => {
                localStorage.removeItem('dadosEdicao');
            };
        } catch (error) {
            AlertService.error("error", String(error))
        }
    }, []);
    const handleNext = () => {
        if (!formBasico?.alias_identificador2) {
            showToast("error", "O campo Alias do Indentificador 2 é obrigatório!")
            return;
        }
        if (!formBasico?.alias_identificador1) {
            showToast("error", "O campo Alias do Indentificador 1 é obrigatório!")
            return;
        }
        if (!formBasico?.qtd_max_parcelas) {
            showToast("error", "O campo de QTD de maxima de parcelas é obrigatório!")
            return;
        }
        if (!formBasico?.tipo_imoveis) {
            showToast("error", "O campo Tipo de Imóvel é obrigatório!")
            return;
        }
        if (!formBasico?.tipo_empreendimento) {
            showToast("error", "O campo Tipo de Empreendimento é obrigatório!")
            return;
        }
        if (!formBasico?.pessoa?.cpf_cnpj && currentPart === 2) {
            showToast("error", "O campo CPF ou CNPJ é obrigatório!")
            return;
        }
        if (!formBasico.pessoa.nome && currentPart === 2) {
            showToast("error", "O campo Nome é obrigatório!")
            return;
        }
        if (!formBasico.pessoa.data_fundacao && formBasico.pessoa.cpf_cnpj.length > 14 && currentPart === 2) {
            showToast("error", "O campo de Data de Fundação é obrigatório!")
            return;
        }
        if (formBasico.pessoa.enderecos && formBasico.pessoa.enderecos.length === 0 && currentPart === 3) {
            showToast("error", "O empreendimento deve possuir ao menos um endereço!")
            return;
        }
        if (formBasico.pessoa.telefones.length === 0 && currentPart === 4) {
            showToast("error", "O empreendimento deve possuir ao menos um telefone!")
            return;
        }
        if (!formBasico.config_venda.modo_preco_fv_pf && currentPart === 5) {
            showToast("error", "O campo Modelo de Preço é obrigatório!")
            return;
        }
        if (!formBasico.config_venda.qtd_limite_vendas && currentPart === 5) {
            showToast("error", "O campo Qtd. Limite de Venda é obrigatório!")
            return;
        }
        if (!formBasico.config_venda.qtd_parc_entrada && currentPart === 5) {
            showToast("error", "O campo Qtd. parcelas para a Entrada é obrigatório!")
            return;
        }
        if (!formBasico.config_venda.qtd_parc_intermed && currentPart === 5) {
            showToast("error", "O campo Qtd. parcelas para a Intermediação é obrigatório!")
            return;
        }
        if (!formBasico.config_venda.coef_intermediacao && currentPart === 5) {
            showToast("error", "O campo coeficiente da intermediação é obrigatório!")
            return;
        }
        if (!formBasico.config_carteira.qtd_parcela_balao && currentPart === 6) {
            showToast("error", "O campo de quatidade de parcelas balão é obrigatório!")
            return;
        }
        if (currentPart < totalParts) {
            setCurrentPart((prevPart) => prevPart + 1);
        }
    };

    const handleBack = () => {
        if (currentPart > 1) {
            setCurrentPart((prevPart) => prevPart - 1);
        }
    };
    const atualizarEmpreendimento = () => {
        TokenService.setEmpreendimento(formBasico);
    }
    useEffect(() => {
        if (currentPart === totalParts) {
            atualizarEmpreendimento();
        }
    }, [currentPart]);

    useEffect(() => {
        setBody(formBasico);
    }, [formBasico]);
    const handleChange = (field: string, value: any) => {
        if (setFormBasico) {
            let newForm: PropsData = { ...formBasico };

            if (field.startsWith('pessoa.')) {
                const pessoaField = field.replace('pessoa.', '');
                setFormBasico((prevGrupoData: any) => {
                    return {
                        ...prevGrupoData,
                        pessoa: {
                            ...newForm.pessoa,
                            [pessoaField]: value,
                        },
                    };
                });
            } else if (field.startsWith('config_venda.')) {
                const vendaField = field.replace('config_venda.', '');
                setFormBasico((prevGrupoData: any) => {
                    return {
                        ...prevGrupoData,
                        config_venda: {
                            ...newForm.config_venda,
                            [vendaField]: value,
                        },
                    };
                });
            } else if (field.startsWith('config_carteira.')) {
                const carteiraField = field.replace('config_carteira.', '');
                setFormBasico((prevGrupoData: any) => {
                    return {
                        ...prevGrupoData,
                        config_carteira: {
                            ...newForm.config_carteira,
                            [carteiraField]: value,
                        },
                    };
                });
            }
            else {
                setFormBasico((prevGrupoData: any) => {
                    return {
                        ...prevGrupoData,
                        [field]: value,
                    };
                });
            }
        }
    };

    const handleEnderecoInputChange = (index: number, field: string, value: string) => {
        changeEndereco(formBasico, setFormBasico, index, field, value);
    };
    const handleAddEndereco = () => {
        addEndereco(formBasico, setFormBasico);
    };
    const handleRemoveEndereco = (index: number) => {
        removeEndereco(formBasico, setFormBasico, index);
    };
    const handleTelefoneInputChange = (index: number, field: string, value: string) => {
        changeTelefone(formBasico, setFormBasico, index, field, value);
    };
    const handleAddTelefone = () => {
        addTelefone(formBasico, setFormBasico);
    };
    const handleRemoveTelefone = (index: number) => {
        removeTelefone(formBasico, setFormBasico, index);
    };
    return (
        <div className="body-content">
            <div className="form-user-create">
                <Container>
                    <ProgressBarForm
                        currentPart={currentPart}
                        totalParts={totalParts}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        steps={["Empreendimento", "Pessoa", "Endereço", "Telefone", "Config.Vendas", "Config.Carteira"]}
                        body={formBasico}
                    >
                        {currentPart === 1 && (
                            <ObraForm
                                obraData={formBasico}
                                onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                            />
                        )}
                        {currentPart === 2 && (
                            <PessoaForm
                                typePessoaFisicaOrJuridica={true}
                                isEnablePerfil={false}
                                tipoPessoa={4}
                                tipoAcao={"editar"}
                                pessoaData={formBasico?.pessoa}
                                onInputChange={(field: string, value: string) => handleChange(`pessoa.${field}`, value)}
                            />
                        )}
                        {currentPart === 3 && (
                            <EnderecoForm
                                enderecosData={(formBasico?.pessoa?.enderecos?.data ? formBasico?.pessoa?.enderecos?.data : formBasico?.pessoa?.enderecos) || []}
                                onInputChange={(index: number, field: string, value: string) => handleEnderecoInputChange(index, field, value)}
                                onAddEndereco={handleAddEndereco}
                                onRemoveEndereco={handleRemoveEndereco}
                            />
                        )}

                        {currentPart === 4 && (
                            <TelefoneForm
                                telefonesData={formBasico?.pessoa?.telefones.data ? formBasico?.pessoa?.telefones.data : formBasico?.pessoa?.telefones || []}
                                onInputChange={(index: number, field: string, value: string) => {
                                    if (formBasico?.pessoa?.telefones && formBasico?.pessoa?.telefones?.length > 0) {
                                        handleTelefoneInputChange(index, field, value);
                                    } else {
                                        handleAddTelefone();
                                    }
                                }}
                                onAddTelefone={() => handleAddTelefone()}
                                onRemoveTelefone={(index: number) => handleRemoveTelefone(index)}
                            />
                        )}
                        {currentPart === 5 && (
                            <ConfigVendaForm
                                initialRightValues={[]}
                                Data={formBasico?.config_venda}
                                onInputChange={(field: string, value: any) => handleChange(`config_venda.${field}`, value)}
                            />
                        )}
                        {currentPart === 6 && (
                            <ConfigCarteiraForm
                                initialRightValues={[]}
                                Data={formBasico?.config_carteira}
                                onInputChange={(field: string, value: any) => handleChange(`config_carteira.${field}`, value)}
                            />
                        )}
                    </ProgressBarForm>
                </Container>
            </div>
        </div>
    );
}

export default EditEmpreendimentosObra;
