import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import PessoaForm from '../../Formularios/pessoa';
import EnderecoForm from '../../Formularios/endereco';
import TelefoneForm from '../../Formularios/telefone';
import { addTelefone, changeTelefone, removeTelefone } from '../../../utils/TelefoneUtils';
import { addEndereco, changeEndereco, removeEndereco } from '../../../utils/EnderecoUtils';
import ProgressBarForm from '../../Formularios/progressBarForm';
import TokenService from '../../../services/Auth/token.service';
import { showToast } from '../../../components/ToastComponent';
import FornecedoresForm from './Formularios/forms';

function CreateFornecedores() {
    const { setBody, setUrl } = useAppContext();
    const [currentPart, setCurrentPart] = useState(1);
    const totalParts = 4;
    const [formBasico, setFormBasico] = useState<any>({
        nome_representante_vendedor:'',
        telefone_representante:'',
        status: '1',
        data_nasc:'',
        obs:'',
        site:'',
        portfolio_atividade:'',
        condicao_venda:'',
        empreendimento_id: '',
        filial_id: '',
        pessoa: {
            id: '',
            nome: '',
            email: '',
            cpf_cnpj: '',
            sexo: '',
            razao_social: '',
            inscricao_municipal: '',
            inscricao_estadual: '',
            filiacao_mae: '',
            filiacao_pai: '',
            rg: '',
            estado_civil: '',
            regime_uniao: '',
            data_nascimento: '',
            data_fundacao: '',
            enderecos: [],
            telefones: [],
        },
    });
    useEffect(() => {
        setUrl(`${url}/api/v1/admin/financeiro/fornecedor`);
        setFormBasico((prevFornecedor: any) => {
            return {
                ...prevFornecedor,
                ['empreendimento_id']: TokenService.getEmpreendimentoId()?.id ,
                ['filial_id']: TokenService.getFilialId()?.id
            };
        });
    }, []);
    useEffect(() => {
        setBody(formBasico);
    }, [formBasico]);
    const handleBack = () => {
        if (currentPart > 1) {
            setCurrentPart((prevPart) => prevPart - 1);
        }
    };
    const handleNext = () => {
        if (!formBasico) {
            showToast("error", "Os campos de Nome, Email e CPF ou CNPJ é obrigatório!")
            return;
        }
        if (!formBasico?.pessoa?.cpf_cnpj) {
            showToast("error", "O campo CPF ou CNPJ é obrigatório!")
            return;
        }
        if (!formBasico.pessoa.nome) {
            showToast("error", "O campo Nome é obrigatório!")
            return;
        }
        if (currentPart < totalParts) {
            setCurrentPart((prevPart) => prevPart + 1);
        }
    };

    const handleChange = (field: string, value: any) => {
        if (setFormBasico) {
            let newForm: any = { ...formBasico } || {
                pessoa: { enderecos: [], telefones: [] },
            };

            if (formBasico) {
                if (field.startsWith('pessoa.')) {
                    const pessoaField = field.replace('pessoa.', '');
                    setFormBasico((prevGrupoData: any) => {
                        return {
                            ...prevGrupoData,
                            pessoa: {
                                ...newForm.pessoa,
                                [pessoaField]: value,
                            },
                        };
                    });
                } else {
                    setFormBasico((prevGrupoData: any) => {
                        return {
                            ...prevGrupoData,
                            [field]: value,
                        };
                    });
                }
            }
        }
    };
    const handleChangePessoa = (field: string, value: any) => {
        if (formBasico) {
            setFormBasico((prevGrupoData: any) => {
                return {
                    ...prevGrupoData,
                    pessoa: {
                        ...prevGrupoData.pessoa,
                        [field]: value,
                    },
                };
            });
        }
    };

    const handleEnderecoInputChange = (index: number, field: string, value: any) => {
        changeEndereco(formBasico, setFormBasico, index, field, value)
    };

    const handleAddEndereco = () => {
        addEndereco(formBasico, setFormBasico);
    };
    const handleRemoveEndereco = (index: number) => {
        removeEndereco(formBasico, setFormBasico, index);
    };

    const handleTelefoneInputChange = (index: number, field: string, value: any) => {
        changeTelefone(formBasico, setFormBasico, index, field, value);
    };
    const handleAddTelefone = () => {
        addTelefone(formBasico, setFormBasico);
    };
    const handleRemoveTelefone = (index: number) => {
        removeTelefone(formBasico, setFormBasico, index);
    };
    return (
        <div className="body-content">
            <div className="form-user-create">
                <Container>
                    <ProgressBarForm
                        currentPart={currentPart}
                        totalParts={totalParts}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        steps={["Pessoa", "Fornecedores", "Endereço", "Telefone"]}
                        body={formBasico}
                    >
                        {currentPart === 1 && (
                            <PessoaForm
                                isEnablePerfil={false}
                                tipoPessoa={3}
                                tipoAcao={"criar"}
                                pessoaData={formBasico?.pessoa}
                                onInputChange={(field: string, value: any) => handleChangePessoa(`${field}`, value)}
                            />
                        )}
                        {currentPart === 2 && (
                            <FornecedoresForm
                                fornecedorData={formBasico}
                                onInputChange={(field: string, value: any) => handleChange(`${field}`, value)}
                            />
                        )}
                        {currentPart === 3 && (
                            <EnderecoForm
                                enderecosData={formBasico?.pessoa?.enderecos || []}
                                onInputChange={(index: number, field: string, value: any) => handleEnderecoInputChange(index, field, value)}
                                onAddEndereco={handleAddEndereco}
                                onRemoveEndereco={handleRemoveEndereco}
                            />
                        )}
                        {currentPart === 4 && (
                            <TelefoneForm
                                telefonesData={formBasico?.pessoa?.telefones || []}
                                onInputChange={(index: number, field: string, value: any) => {
                                    if (formBasico?.pessoa?.telefones && formBasico?.pessoa?.telefones?.length > 0) {
                                        handleTelefoneInputChange(index, field, value);
                                    } else {
                                        handleAddTelefone();
                                    }
                                }}
                                onAddTelefone={() => handleAddTelefone()}
                                onRemoveTelefone={(index: number) => handleRemoveTelefone(index)}
                            />
                        )}
                    </ProgressBarForm>
                </Container>
            </div>
        </div>
    );
}

export default CreateFornecedores;
