import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import TokenService from '../../../services/Auth/token.service';
import IndiceForm from './Formularios/IndiceForm';

interface PropsData {
    tipo_indice_id?: number;
    mes_referencia?: string;
    valor?: number;
}

function CreateIndice() {
    const { setUrl, setBody, setShowButtonSave } = useAppContext();
    const [formBasico, setFormBasico] = useState<PropsData>({});

    useEffect(() => {
        setUrl(`${url}/api/v1/admin/financeiro/indice`);
        setShowButtonSave(true);
        setFormBasico((prevImovelData) => {
            return {
                ...prevImovelData,
                ['empreendimento_id']: TokenService.getEmpreendimentoId()?.id
            };
        });
    }, []);

    const handleChange = (field: string, value: any) => {
        setFormBasico((prevGrupoData) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
        setBody(formBasico)
    };
    return (
        <>
            <div className="body-content">
                <div className="form-create-group">
                    <div className="form-create-group-header">
                        <h3>Cadastrar Indice</h3>
                    </div>
                </div>
                <div className="form-group-create">
                    <Container>
                        <IndiceForm
                            initialRightValues={[]}
                            Data={formBasico}
                            onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default CreateIndice;
