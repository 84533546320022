import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';

import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';
import getValidationErrors from '../../utils/getValidationErros';

import { Container, Content, AnimationContainer, Background } from './styles';
import Omniteq from '../../../assets/images/logo_omniteq.png';
import img2 from '../../../assets/logo/carrosel2.png';
import img3 from '../../../assets/logo/carrosel3.png';
import img4 from '../../../assets/logo/carrosel4.png';
import img5 from '../../../assets/logo/carrosel5.png';


import Input from '../../components/Input';
import Button from '../../components/Button';
import { Carousel } from 'react-bootstrap';
import { AlertService } from '../../components/AlertService';
import AuthService from '../../services/Auth/auth.service';
import { useSidebarContext } from '../../contexts/SidebarContext';
import { showToast } from '../../components/ToastComponent';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const { isVisible, updateVisibility } = useSidebarContext();

  // Add a state variable for form submission
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        // Set isSubmitting to true when starting form submission
        setIsSubmitting(true);

        const schema = Yup.object().shape({
          email: Yup.string().required('Usuário obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response: any = await AuthService.login(data.email, data.password);
        if (response === true) {
          localStorage.setItem("isVisible", "false");
          updateVisibility(false);
          window.location.href = '/home';
        } else {
          AlertService.infomessage('Atenção!', response?.response?.data?.error?.message)
        }
      } catch (err: any) {
        let mensagens: string[] = [];
        mensagens.push(err.response.data.error.message);
        AlertService.error('Erro!', String(mensagens));
      } finally {
        // Set isSubmitting to false when form submission is complete
        setIsSubmitting(false);
      }
    },
    [navigate, updateVisibility],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img
            style={{ position: "relative", top: "10%" }}
            width="500"
            height="150"
            src={Omniteq}
          />
          <Form onPointerEnterCapture={''} onPointerLeaveCapture={''} placeholder={''} ref={formRef} onSubmit={handleSubmit}>
            <h1>Login de Acesso</h1>

            <Input name="email" icon={FiMail} placeholder="Usuário" />
            <Input
              name="password"
              icon={FiLock}
              type="password"
              placeholder="Senha"
            />

            {/* Disable the button when isSubmitting is true */}
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Entrando...' : 'Entrar'}
            </Button>

            <Link to="/forgot-password">Esqueci minha senha</Link>
          </Form>

          {/* <Link to="/signup">
            <FiLogIn />
            Criar conta
          </Link> */}
        </AnimationContainer>
      </Content>
      <Background>
        <Carousel data-bs-theme="dark" style={{ width: '100%', height: '100%' }}>
          <Carousel.Item>
            <img className="d-flex" style={{ width: '100%', height: '70%' }} src={img2} alt="GoBarber" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-flex" style={{ width: '100%', height: '70%' }} src={img3} alt="GoBarber" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-flex" style={{ width: '100%', height: '70%' }} src={img4} alt="GoBarber" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-flex" style={{ width: '100%', height: '70%' }} src={img5} alt="GoBarber" />
          </Carousel.Item>
        </Carousel>
      </Background>
    </Container>
  );
};

export default SignIn;
