import { useAppContext } from '../../../../contexts/AppContext';
import { useEffect, useState } from 'react';
import { AlertService } from '../../../../components/AlertService';
import TokenService from '../../../../services/Auth/token.service';
import FiltroJazigo from './filtro';
import TableComponents from './Componentes/TableComponents';

function Jazigo() {
  const { showSubSidebar } = useAppContext();
  const [listFiltros, setListFiltros] = useState<any>({});
  const [filtros, setFiltros] = useState<string>();
  const [formBasico, setFormBasico] = useState<any>({});
  const { setContextItemsButtonMenu } = useAppContext();

  async function montarFiltro(): Promise<void> {
    try {
      const consulta: any = {
        consulta: {
          order: "id;desc",
          filtro: {
            "logradouro": listFiltros ? listFiltros?.logradouro : null,
            "tipo_jazigo_id": listFiltros ? listFiltros?.tipo_jazigo_id : null,
            "identificador2": listFiltros ? listFiltros?.identificador2 : null,
            "identificador1": listFiltros ? listFiltros?.identificador1 : null,
            "empreendimento_id": TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().id : null
          }
        }
      };

      const queryParams: any = {};

      const camposEnviar = ['consulta'];
      camposEnviar.forEach((key: any) => {
        const value = consulta[key];
        if (value !== undefined && value !== null && value !== '') {
          if (key === 'consulta') {
            queryParams[key] = JSON.stringify(value);
          } else {
            queryParams[key] = value;
          }
        }
      });
      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
        .join('&');
      setFiltros(queryString);
    } catch (error: any) {
      AlertService.error('Erro!', String(error.response?.data?.message) || 'Erro desconhecido');
    }
  }
  useEffect(() => {
    montarFiltro();
  }, [listFiltros]);
  useEffect(() => {
    const commonStyle = {
      width: '35px',
      height: '35px',
      top: '0px',
      color: '#fff'
    };
    const items = [
      {
        label: 'Criar Jazigo',
        icon: 'pi pi-user-plus',
        style: { ...commonStyle, backgroundColor: '#19c022', display: 'flex' },
        command: () => {
          window.location.href = '/estrutura/jazigo/store';
        },
        tooltip: 'Criar Jazigo'
      },
      {
        label: 'Criar Jazigo em Grupos',
        icon: 'pi pi-users',
        style: { ...commonStyle, backgroundColor: '#007bff', display: 'flex' },
        command: () => {
          window.location.href = '/estrutura/jazigo-grupo';
        },
        tooltip: 'Criar Jazigo em Grupos'
      }
    ];
    setContextItemsButtonMenu(items);
  }, [])
  const handleChange = (field: string, value: any) => {
    setFormBasico((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };
  const handleChangeList = (field: string, value: any) => {
    setListFiltros((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };
  const handleClearList = () => {
    setListFiltros(null);
    setFormBasico(null);
    montarFiltro();
  };

  return (
    <div className="body-content">
      <div
        className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}
      >
        <FiltroJazigo
          onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
          onInputChangeList={(field: string, value: string) => handleChangeList(`${field}`, value)}
          onInputClearList={() => handleClearList()}
          filtrosData={formBasico}
          header={'Jazigo'}
        />
      </div>
      <div
        className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}
      >
        <TableComponents
          key={filtros}
          urlRedirectCreated=""
          urlSearch={`/api/v1/admin/cemiterio/jazigo?${filtros}`}
          urlSearchDataPut="/api/v1/admin/cemiterio/jazigo/"
          url_redirect_edit="/estrutura/jazigo/update"
          include="tipo_jazigo, sub_tipo"
        />
      </div>
    </div>
  );
}
export default Jazigo;