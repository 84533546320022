import { useAppContext } from '../../../contexts/AppContext';
import { useEffect, useState } from 'react';
import { AlertService } from '../../../components/AlertService';
import TableComponentsBoletos from '../../../components/TableComponentsBoletos';
import ContentSearchBoleto from '../../Layout/navbar-layout/components/ContentSearchBoleto';
import TokenService from '../../../services/Auth/token.service';
import { Button } from 'primereact/button';
import { Col, Form, NavLink } from 'react-bootstrap';
import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
import { useSidebarContext } from '../../../contexts/SidebarContext';

function GestaoBoletos() {
  const { showSubSidebar } = useAppContext();
  const [listFiltros, setListFiltros] = useState<any>();
  const [filtros, setFiltros] = useState<string>();
  const [activeSearch, setActiveSearch] = useState<any>(false);
  const { isVisible, updateVisibility } = useSidebarContext();

  const toggleVisibility = () => {
    const newValue = !isVisible;
    updateVisibility(newValue);
  };
  async function montarFiltro(): Promise<void> {
    try {
      const consulta: any = {
        consulta: {
          order: "id;desc",
          filtro: {
            nome: listFiltros && listFiltros.nome ? listFiltros.nome : null,
            tipo_parcela: listFiltros && listFiltros.tipo_parcela ? listFiltros.tipo_parcela : null,
            venda_id: listFiltros && listFiltros.contrato_id ? listFiltros.contrato_id : null,
            numero_contrato: listFiltros?.numero_contrato ?? null,
            exibir_impressos: listFiltros?.exibir_impressos ?? false,
            empreendimento_id: TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().id : null
          }
        }
      };

      const queryParams: any = {};

      const camposEnviar = ['consulta'];

      camposEnviar.forEach((key: any) => {
        const value = consulta[key];
        if (value !== undefined && value !== null && value !== '') {
          if (key === 'consulta') {
            queryParams[key] = JSON.stringify(value);
          } else {
            queryParams[key] = value;
          }
        }
      });

      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
        .join('&');
      setFiltros(queryString);
      setActiveSearch(true);
    } catch (error: any) {
      AlertService.error('Erro!', String(error.response?.data?.message) || 'Erro desconhecido');
    }
  }
  console.log('listFiltros', listFiltros)

  const handleChangeList = (field: string, value: any) => {
    setListFiltros((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };
  const handleClearList = () => {
    setActiveSearch(false);
    setListFiltros(null);
  };

  return (
    <div className="body-content">
      <div
        className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}
      >
        <div className="contentSearch">
          <div className="contentSearch-header">
            <NavLink onClick={toggleVisibility}>
              <img
                className="menu-context"
                src={iconMenuTresPonto}
              />
            </NavLink>
            <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Gestão de Boletos</Col>
          </div>
          <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
          <div className="contentSearch-body">
            <div className='div-calendar'>
              <Col md="2" style={{ marginRight: "8px" }}>
                <Form.Label>Número do Contrato</Form.Label>
                <Form.Control
                  value={listFiltros?.numero_contrato || ''}
                  onChange={(e) => handleChangeList("numero_contrato", e.target.value)}
                />
              </Col>
              <Col md="2" style={{ marginRight: "8px" }}>
                <Form.Label>Contrato|ID</Form.Label>
                <Form.Control
                  value={listFiltros?.contrato_id || ''}
                  onChange={(e) => handleChangeList("contrato_id", e.target.value)}
                />
              </Col>
              <Col md="3" style={{ marginRight: "8px" }}>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  value={listFiltros?.nome || ''}
                  onChange={(e) => handleChangeList("nome", e.target.value)}
                />
              </Col>
              <Col md="3" style={{ marginRight: "8px" }}>
                <Form.Label>Tipo de Parcela</Form.Label>
                <Form.Group controlId="formGridState" style={{ display: 'grid' }}>
                  <Form.Select value={listFiltros?.tipo_parcela || ''} onChange={(e) => handleChangeList('tipo_parcela', e.target.value)}>
                    <option value={1}>Ordinária</option>
                    <option value={2}>Balão</option>
                    <option value={3}>Avulso</option>
                    <option value={4}>Saldo Restante</option>
                    <option value={5}>Comissões</option>
                    <option value={6}>Temporário</option>
                    <option value={7}>Condomínio</option>
                    <option value={8}>Parcela Mensal</option>
                    <option value={9}>Acordo Avulso</option>
                    <option value={10}>Intermediação</option>
                    <option value={11}>Entrada</option>
                    <option value={12}>Agrupado</option>
                    <option value={13}>Lançamento Pai</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md="2" style={{ marginRight: "8px" }}>
                <Form.Label>Exibir Itens Impressos?</Form.Label>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  style={{ marginLeft: '50px' }}
                  onChange={(e) => handleChangeList("exibir_impressos", e.target.checked)}
                />
              </Col>
            </div>
            <div className="div-filtros-avancados" style={{ marginTop: "35px" }}>
              <Button
                onClick={() => montarFiltro()}
                style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                type="button"
                severity="success"
                icon="pi pi-filter"
                rounded>
                <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
              </Button>
              <Button
                onClick={handleClearList}
                style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
                type="button"
                severity="danger"
                icon="pi pi-delete-left"
                rounded>
                <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
              </Button>
            </div>
          </div>
        </div >
      </div>
      <div
        className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}
      >
        <TableComponentsBoletos
          key={filtros}
          id={listFiltros?.contrato_id}
          urlRedirectCreated="/financeiro/contas-receber/store"
          urlSearch={activeSearch ? `/api/v1/admin/financeiro/listar-boletos?${filtros}` : null}
          urlSearchDataPut="/api/v1/admin/financeiro/listar-boletos/"
          url_redirect_edit="/financeiro/contas-receber/update"
          typeLancamento="0"
        />
      </div>
    </div>
  );
}

export default GestaoBoletos;