import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../assets/images/icon-calendar.png';
import pt from "date-fns/locale/pt";
import { InputNumber } from "primereact/inputnumber";
import { AutoComplete } from "primereact/autocomplete";
import { searchContrato, searchContratoS, searchCorretor, searchPessoa } from "../Services/VendaService";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";

function SecaoVendaAvulsa(props: any) {
    const { dia_vencimento, contrato, buscarContrato,
        buscarCorretor, corretor_id, pessoa_id, buscarPessoa, pessoa, tipo_venda, corretor } = props.SecaoVendaAvulsaData || {};
    const [data_venda, setDataVenda] = useState<any | null>(null);
    const [FilteredContrato, setFilteredContrato] = useState<any>(null);
    const [FilteredPessoa, setFilteredPessoa] = useState<any>(null);
    useEffect(() => {
        setDataVenda(props.SecaoVendaAvulsaData?.data_venda ? new Date(props.SecaoVendaAvulsaData?.data_venda) : null);
    }, [props.SecaoVendaAvulsaData])
    const handleChange = (field: string, value: any) => {
        if (field === 'dia_vencimento') {
            if (/^\d+$/.test(value)) {
                props.onInputChange(field, value);
            }
        } else {
            props.onInputChange(field, value);
        }
    };
    addLocale('pt', {
        firstDayOfWeek: 0,
        dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
            height: '-webkit-fill-available',
            marginTop: '6px',
            minHeight: '350px'
        }}>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', backgroundColor: '#4949498a', color: '#fff', paddingLeft: '5px', paddingBottom: '12px', paddingTop: '12px', borderRadius: '7px' }}>

                <h5
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        textAlign: 'center'
                    }}
                >
                    Informações Da Venda
                </h5>
            </div>
            <hr />
            <Row className="mb-3">
                <Col md={3} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form.Label>
                        Data da Venda
                    </Form.Label>
                    <div className="flex justify-center">
                        <Calendar
                            invalid={!data_venda}
                            value={data_venda || null}
                            onChange={(event: any) => {
                                const selectedDates = event.value;
                                setDataVenda(selectedDates);
                                handleChange('data_venda', selectedDates ? selectedDates.toISOString() : null);
                            }}
                            placeholder="Data de Venda"
                            showButtonBar
                            selectionMode="single"
                            dateFormat="dd/mm/yy"
                            locale="pt"
                            readOnlyInput
                            style={{ textAlign: 'center', width: '100%' }}
                            panelClassName="custom-calendar-panel"
                            required
                        />
                    </div>
                </Col>
                <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form.Label>Contrato Vinculado</Form.Label>
                    <AutoComplete
                        placeholder='Pesquisar n° contrato'
                        field="nome_contrato"
                        style={{ height: "37px" }}
                        value={contrato?.nome_contrato ? contrato?.nome_contrato : (buscarContrato ? buscarContrato : '')}
                        suggestions={FilteredContrato}
                        completeMethod={(e) => searchContratoS(e, 1, setFilteredContrato, () => { }, () => { })}
                        onChange={(e) =>
                            handleChange('buscarContrato', e.value)}
                        onSelect={(e) => {
                            handleChange('contrato', e.value),
                                handleChange('contrato_id', e.value.id)
                        }
                        }
                        dropdown
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md="2" style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form.Label>Dia vencimento</Form.Label>
                    <InputNumber
                        invalid={!dia_vencimento}
                        style={{ width: '100%', height: '38px' }}
                        value={dia_vencimento ? dia_vencimento : null}
                        onChange={(e: any) => handleChange('dia_vencimento', e.value)}
                        locale="pt-BR"
                        min={0}
                        max={31}
                        maxLength={2}
                    />
                </Col>
                <Col md="2" style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form.Label>Tipo Venda</Form.Label>
                    <Form.Select value={tipo_venda} onChange={(e) => handleChange('tipo_venda', e.target.value)}>
                        <option value={1}>Orçamento</option>
                        <option value={2}>Venda</option>
                    </Form.Select>
                </Col>
                <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form.Label>Vendedor|Corretor</Form.Label>
                    <AutoComplete
                        invalid={!corretor_id}
                        placeholder='Pesquisar vendedor|corretor'
                        field="nome_corretor"
                        style={{ height: "37px" }}
                        value={corretor?.nome_corretor ? corretor?.nome_corretor : (buscarCorretor ? buscarCorretor : '')}
                        suggestions={FilteredPessoa}
                        completeMethod={(e) => searchCorretor(e, 1, setFilteredPessoa, () => { })}
                        onChange={(e) =>
                            handleChange('buscarCorretor', e.value)}
                        onSelect={(e) => {
                            handleChange('corretor', e.value),
                                handleChange('corretor_id', e.value.id)
                        }
                        }
                        dropdown
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md="3" style={{ display: 'flex', flexDirection: 'column' }}>
                    <Form.Label>Comprador</Form.Label>
                    <AutoComplete
                        invalid={!pessoa_id}
                        placeholder='Pesquisar Comprador'
                        field="nome"
                        style={{ height: "37px" }}
                        value={pessoa ? pessoa : (buscarPessoa ? buscarPessoa : '')}
                        suggestions={FilteredPessoa}
                        completeMethod={(e) => searchPessoa(e, setFilteredPessoa)}
                        onChange={(e) =>
                            handleChange('buscarPessoa', e.value)}
                        onSelect={(e) =>
                            handleChange('pessoa_id', e.value.id)}
                        dropdown
                    />
                </Col>
            </Row>
        </div >
    );
}

export default SecaoVendaAvulsa;
