import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url, url_financeiro } from '../../../environments/environments-develop';
import { AlertService } from '../../../components/AlertService';
import ProgressBarForm from '../../Formularios/progressBarForm';
import MovCaixaForm from './Formularios/MovCaixaForm';
import { Col, NavLink } from 'react-bootstrap';
import { useSidebarContext } from '../../../contexts/SidebarContext';
import iconMenuTresPonto from '../../../../assets/images/icon-menu-tres-pontos.png';
import AnexoForm from './Formularios/AnexoForm';
import TokenService from '../../../services/Auth/token.service';

interface PropsData {
    numero_documento?: string;
    conta_financeiro_id?: string;
    plano_conta_id?: string;
    centro_custo?: string[];
    tipo_documento?: number;
    justificativa?: string;
    cliente_id?: string;
    taxa_juros?: number;
    taxa_multa?: number;
    valor_multa?: number;
    valor_juros?: number;
    valor_desconto?: number;
    valor_taxa_bancaria?: number;
    valor_outros?: number;
    qtd_dias_carencia_multa?: number;
    qtd_dias_carencia_juros?: number;
    recorrente?: boolean;
    valor_original?: number;
    data_competencia?: string;
    num_parcela?: number;
    data_vencimento?: string;
    data_emissao?: string;
    data_efetivacao?: string;
    tipo?: number;
    id?: number;
    anexo?: Array<any>;
    empreeendimento_id?: string;
    filial_id?: string;
}

function EditMovCaixa() {
    const { setTypeRequest, setUrl, setBody, setShowButtonSave } = useAppContext();
    const [formBasico, setFormBasico] = useState<PropsData>({
        numero_documento: undefined,
        conta_financeiro_id: undefined,
        plano_conta_id: undefined,
        centro_custo: undefined,
        tipo_documento: undefined,
        justificativa: undefined,
        cliente_id: undefined,
        id: undefined,
        taxa_juros: 0,
        taxa_multa: 0,
        valor_multa: 0,
        valor_juros: 0,
        valor_desconto: 0,
        valor_taxa_bancaria: 0,
        valor_outros: 0,
        qtd_dias_carencia_multa: undefined,
        qtd_dias_carencia_juros: undefined,
        valor_original: undefined,
        recorrente: false,
        data_competencia: undefined,
        num_parcela: 0,
        data_vencimento: undefined,
        data_emissao: undefined,
        data_efetivacao: undefined,
        tipo: undefined,
        empreeendimento_id: undefined,
        filial_id: undefined,
    });
    const { isVisible, updateVisibility } = useSidebarContext();
    const [currentPart, setCurrentPart] = useState(1);
    const totalParts = 2;

    useEffect(() => {
        try {
            const storedData = localStorage.getItem('dadosEdicao');
            if (storedData) {
                setFormBasico((prevGrupoData: any) => {
                    const dadosAtualizados = {
                        ...prevGrupoData,
                        ...JSON.parse(storedData),
                        ['anexo']: JSON.parse(storedData)?.anexo?.data,
                        ['empreendimento_id']: TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId()?.id : null
                    };
                    return dadosAtualizados;
                });
                setTypeRequest('PUT');
                setUrl(`${url_financeiro}/api/v1/admin/financeiro/lancamentofinanceiro/${storedData && JSON.parse(storedData).id}`);
                setShowButtonSave(true);
            }
            return () => {
                localStorage.removeItem('dadosEdicao');
            };
        } catch (error) {
            AlertService.error("error", String(error))
        }
    }, []);

    const handleChange = (field: string, value: any) => {
        if (field == 'valor_original') {
            setFormBasico((prevGrupoData) => {
                return {
                    ...prevGrupoData,
                    ['valor_pago']: value,
                    [field]: value
                };
            });
        }
        setFormBasico((prevGrupoData) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
        setBody(formBasico)
        setShowButtonSave(true);
    };

    const toggleVisibility = () => {
        const newValue = !isVisible;
        updateVisibility(newValue);
    };

    useEffect(() => {
        setBody(formBasico)
    }, [formBasico]);

    const handleBack = () => {
        if (currentPart > 1) {
            setCurrentPart((prevPart) => prevPart - 1);
        }
    };
    const handleNext = () => {
        if (currentPart === 1 && formBasico.justificativa === undefined) {
            AlertService.infomessage('Atenção', 'Descrição não informada!')
            return;
        }
        if (currentPart < totalParts) {
            setCurrentPart((prevPart) => prevPart + 1);
        }
    };

    return (
        <div className="body-content">
            <div className="form-user-create">
                <div className="contentSearch-header">
                    <NavLink onClick={toggleVisibility}>
                        <img
                            className="menu-context"
                            src={iconMenuTresPonto}
                        />
                    </NavLink>
                    <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>Lançamento Imediato</Col>
                </div>
                <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
                <Container>
                    <ProgressBarForm
                        currentPart={currentPart}
                        totalParts={totalParts}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        steps={["Lançamento", "Anexos"]}
                        body={formBasico}
                    >
                        {currentPart === 1 && (
                            <MovCaixaForm
                                Data={formBasico}
                                onInputChange={(field: string, value: any) => handleChange(`${field}`, value)}
                            />
                        )}
                        {currentPart === 2 && (
                            <AnexoForm
                                Data={formBasico}
                                onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                            />
                        )}
                    </ProgressBarForm>
                </Container>
            </div>
        </div>
    );
}

export default EditMovCaixa;
