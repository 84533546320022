import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../../contexts/AppContext';
import { url } from '../../../../environments/environments-develop';
import TokenService from '../../../../services/Auth/token.service';
import FormJazigo from './forms';

interface PropsData {
    identificador1?: string;
    identificador2?: string;
    logradouro?: string;
    tipo_jazigo_id?: number;
    tipo_imovel_id?: number;
    sub_tipo_imovel_id?: number;
    tipo_particular_coletivo?: number;
    imo_preco?: number;
    construcao?: boolean;
}

function CreateJazigo() {
    const { setUrl, setBody, setShowButtonSave, setValidationRequest } = useAppContext();
    const [formBasico, setFormBasico] = useState<PropsData>({});

    useEffect(() => {
        setUrl(`${url}/api/v1/admin/cemiterio/jazigo`);
        setShowButtonSave(true);
        setFormBasico((prevImovelData) => {
            return {
                ...prevImovelData,
                ['empreendimento_id']: TokenService.getEmpreendimentoId()?.id,
                ['construcao']: false
            };
        });
        setValidationRequest(false);
    }, []);

    const handleChange = (field: string, value: string) => {
        setFormBasico((prevGrupoData) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
        setBody(formBasico)
    };

    useEffect(() => {
        if (
            formBasico?.identificador1 &&
            formBasico?.identificador2 &&
            formBasico?.logradouro &&
            formBasico?.tipo_jazigo_id &&
            formBasico?.tipo_imovel_id &&
            formBasico?.tipo_particular_coletivo &&
            formBasico?.imo_preco
        ) {
            setValidationRequest(true);
        }
        setBody(formBasico)
    }, [formBasico]);

    return (
        <>
            <div className="body-content">
                <div className="form-create-group">
                    <div className="form-create-group-header">
                        <h3>Cadastrar Jazigos</h3>
                    </div>
                </div>
                <div className="form-group-create">
                    <Container>
                        <FormJazigo
                            initialRightValues={[]}
                            Data={formBasico}
                            onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default CreateJazigo;
