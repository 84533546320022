import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useAppContext } from '../../../contexts/AppContext';
import { url } from '../../../environments/environments-develop';
import { AlertService } from '../../../components/AlertService';
import PlanoContaForm from './Formularios/PlanoContaForm';
interface PropsData {
    codigo?: string;
    nome?: string;
    tipo?: string;
    parent_id?: string;
    status?: boolean;
    recebe_lancamento?: boolean;
    contabil?: boolean;
}
function EditPlanoConta() {
    const { setUrl, setBody, setShowButtonSave, setTypeRequest } = useAppContext();
    const [Data, setData] = useState<PropsData>({});

    useEffect(() => {
        try {
            const storedData = localStorage.getItem('dadosEdicao');
            if (storedData) {
                setData((prevGrupoData) => {
                    const dadosAtualizados = {
                        ...prevGrupoData,
                        ...JSON.parse(storedData)
                    };
                    return dadosAtualizados;
                });
                setTypeRequest('PUT');
                setUrl(`${url}/api/v1/admin/financeiro/plano-conta/${storedData && JSON.parse(storedData).id}`);
                setShowButtonSave(true);
            }
            return () => {
                localStorage.removeItem('dadosEdicao');
            };
        } catch (error) {
            AlertService.error("error", String(error))
        }
    }, []);
    useEffect(() => {
        setBody(Data)
    }, [Data]);

    const handleChange = (field: string, value: string) => {
        setData((prevGrupoData) => {
            return {
                ...prevGrupoData,
                [field]: value
            };
        });
        setTypeRequest('PUT');
    }
    return (
        <>
            <div className="body-content">
                <div className="form-create-group">
                    <div className="form-create-group-header">
                        <h3>Alterar Plano de Contas</h3>
                    </div>
                </div>
                <div className="form-group-create">
                    <Container>
                        <PlanoContaForm
                            initialRightValues={Data}
                            Data={Data}
                            onInputChange={(field: string, value: any) => handleChange(field, value)}
                        />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default EditPlanoConta;
