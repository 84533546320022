import iconMenuTresPonto from '../../../../../assets/images/icon-menu-tres-pontos.png';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useSidebarContext } from '../../../../contexts/SidebarContext';
import { Col, Container, Form, NavLink, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from './../../../../../assets/images/icon-calendar.png';
import pt from 'date-fns/locale/pt';
import 'react-autocomplete-input/dist/bundle.css';
import { AlertService } from '../../../../components/AlertService';
import { url } from '../../../../environments/environments-develop';
import api from '../../../../services/api';
import { AutoComplete } from 'primereact/autocomplete';

interface ContextSearchProps {
  header?: string;
  filtrosData: {
    empreendimento_id?: number;
    filial_id?: number;
    event?: string;
    origem?: number;
    user_id?: number;
    dataInicio?: string;
    dataFim?: string;
  };
  onInputClearList: () => void;
  onInputChangeList: (field: string, value: any) => void;
  onInputChange: (field: string, value: any) => void;
}

function ContentSearchAudit(props: ContextSearchProps) {
  const { empreendimento_id, filial_id, event, user_id, origem }: any = props.filtrosData || {};
  const { isVisible, updateVisibility } = useSidebarContext();
  const [FilteredUsuarios, setFilteredUsuarios] = useState<any>(null);
  const [FilteredFiliais, setFilteredFiliais] = useState<any>(null);
  const [FilteredEmpreedimentos, setFilteredEmpreedimentos] = useState<any>(null);
  const [listaUsuarios, setListaUsuarios] = useState<any>([]);
  const [listaFiliais, setListaFiliais] = useState<any>([]);
  const [listaEmpreedimentos, setListaEmpreedimentos] = useState<any>([]);
  const [dataInicio, setDataInicio] = useState<Date | null>(null);

  const [dataFim, setDataFim] = useState<Date | null>(null);

  const toggleVisibility = () => {
    const newValue = !isVisible;
    updateVisibility(newValue);
  };

  const handleChange = (field: string, value: any) => {
    props.onInputChange(field, value);
  };

  const handleChangeList = (field: string, value: any) => {
    props.onInputChangeList(field, value);
  };

  const handleClearList = () => {
    setDataInicio(null);
    setDataFim(null);
    props.onInputClearList();
  };

  const handleFilter = () => {
    if (event) {
      handleChangeList('event', event);
    }
    if (empreendimento_id) {
      handleChangeList('empreendimento_id', empreendimento_id.id);
    }
    if (filial_id) {
      handleChangeList('filial_id', filial_id.id);
    }
    if (user_id) {
      handleChangeList('user_id', user_id.id);
    }
    if (origem) {
      handleChangeList('origem', origem);
    }
    if (dataInicio) {
      handleChangeList('dataInicio', dataInicio);
    }
    if (dataFim) {
      handleChangeList('dataFim', dataFim);
    }
  };
  const searchFilial = (event: any) => {
    // Timeout to emulate a network connection
    setTimeout(() => {
      let _Filteredfilial: any;

      if (!event.query.trim().length) {
        _Filteredfilial = [...listaFiliais];
      }
      else {
        _Filteredfilial = listaFiliais.filter((item: any) => {
          return item.nome_conta.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
      setFilteredFiliais(_Filteredfilial);
    }, 250);
  }
  const searchUsuarios = (event: any) => {
    // Timeout to emulate a network connection
    setTimeout(() => {
      let _FilteredUsuarios: any;

      if (!event.query.trim().length) {
        _FilteredUsuarios = [...listaUsuarios];
      }
      else {
        _FilteredUsuarios = listaUsuarios.filter((item: any) => {
          return item.nome.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
      setFilteredUsuarios(_FilteredUsuarios);
    }, 250);
  }
  const searchEmpreendimento = (event: any) => {
    // Timeout to emulate a network connection
    setTimeout(() => {
      let _FilteredEmpreendimentos: any;

      if (!event.query.trim().length) {
        _FilteredEmpreendimentos = [...listaEmpreedimentos];
      }
      else {
        _FilteredEmpreendimentos = listaEmpreedimentos.filter((item: any) => {
          return item.nome_empreendimento.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
      setFilteredEmpreedimentos(_FilteredEmpreendimentos);
    }, 250);
  }
  useEffect(() => {
    InitFiliais();
    InitUser();
    InitEmpreendimento();
  }, []);
  async function InitFiliais() {
    try {
      const response = await api.get(`${url}/api/v1/admin/core/user-filiais`);
      setListaFiliais(response.data.data);
    } catch (error) {
      AlertService.error('Error', 'Ocorreu um erro ao buscar a de clientes. Erro:' + String(error));
    }
  }
  async function InitUser() {
    try {
      const response = await api.get(`${url}/api/v1/admin/core/user`);
      setListaUsuarios(response.data.data);
    } catch (error) {
      AlertService.error('Error', 'Ocorreu um erro ao buscar a de clientes. Erro:' + String(error));
    }
  }
  async function InitEmpreendimento() {
    try {
      const response = await api.get(`${url}/api/v1/admin/vendas/empreendimentos`);
      setListaEmpreedimentos(response.data.data);
    } catch (error) {
      AlertService.error('Error', 'Ocorreu um erro ao buscar a de clientes. Erro:' + String(error));
    }
  }
  const formatDate = (date: Date | null) => {
    if (date) {
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);
      const seconds = ('0' + date.getSeconds()).slice(-2);
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }
    return '';
  };
  return (
    <div className="contentSearch">
      <div className="contentSearch-header">
        <NavLink onClick={toggleVisibility}>
          <img
            className="menu-context"
            src={iconMenuTresPonto}
          />
        </NavLink>
        <Col className='text-center' style={{ fontSize: '20px', color: '#494949' }}>{props.header}</Col>
      </div>
      <div style={{ opacity: '34%' }} className="contentTraçoBodyHeader"></div>
      <div className="contentSearch-body">
        <div>
          <Container>
            <Col className='md-12' >
              <Row style={{ marginTop: '21px', display: 'flex' }}>
                <Col md="3" style={{ height: "37px", marginRight: '12px' }}>
                  <Form.Label>Evento</Form.Label>

                  <Form.Control
                    value={event ? event : ''}
                    placeholder='Pesquisar por evento'
                    onChange={(e) => handleChange("event", e.target.value)}
                  />
                </Col>
                <Col md="2">
                  <Form.Label>Origem</Form.Label>
                  <Form.Select value={origem} onChange={(e) => handleChange('origem', e.target.value)}>
                    <option>Selecione...</option>
                    <option value={1}>Banco de Dados</option>
                    <option value={2}>Sistema</option>

                  </Form.Select>
                </Col>
                <Col className='div-calendar' >
                  <Col md="3" controlId="formGridState" style={{ display: 'grid' }}>
                    <Form.Label>Data Inicial</Form.Label>

                    <DatePicker
                      selected={dataInicio}
                      onChange={(date: Date | null) => {
                        setDataInicio(date);
                        const formattedDate = formatDate(date); // Formata a data no formato dd/mm/yyyy h:m:s
                        handleChange('dataInicio', formattedDate); // Adapte conforme necessário
                      }}
                      peekNextMonth
                      showTimeSelect
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      fixedHeight
                      locale={pt} // Substitua pt pelo seu objeto de localização, se necessário
                      customInput={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            type="text"
                            placeholder="Data Inicio"
                            className="dateFormFinanceiro"
                            value={dataInicio ? formatDate(dataInicio) : ''}
                            readOnly
                          />
                          <img
                            className="calendar-icon"
                            src={CalendarIcon}
                          />
                        </div>
                      }
                    />

                  </Col>
                  <Col md="1">
                    <Form.Label></Form.Label>
                    <p className='label-date'>Até</p>
                  </Col>
                  <Col md="3" controlId="formGridState" style={{ display: 'grid' }}>
                    <Form.Label>Data Final</Form.Label>
                    <DatePicker
                      selected={dataFim}
                      onChange={(date: Date | null) => {
                        setDataFim(date);
                        const formattedDate = formatDate(date); // Formata a data no formato dd/mm/yyyy h:m:s
                        handleChange('dataFim', formattedDate); // Adapte conforme necessário
                      }}
                      peekNextMonth
                      showTimeSelect
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      fixedHeight
                      locale={pt} // Substitua pt pelo seu objeto de localização, se necessário
                      customInput={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            type="text"
                            placeholder="Data Fim"
                            className="dateFormFinanceiro"
                            value={dataFim ? formatDate(dataFim) : ''}
                            readOnly
                          />
                          <img
                            className="calendar-icon"
                            src={CalendarIcon}
                          />
                        </div>
                      }
                    />
                  </Col>
                </Col>
              </Row>
              <Row style={{ marginTop: '21px' }}>
                <Col md="2" style={{ marginRight: '32px', flexDirection: 'column' }}>
                  <Form.Label>Filial</Form.Label>
                  <AutoComplete
                    required
                    placeholder='Pesquisar Filial'
                    field="nome_conta"
                    style={{ height: "37px" }}
                    value={filial_id ? filial_id : ''}
                    suggestions={FilteredFiliais}
                    completeMethod={searchFilial}
                    onChange={(e) => handleChange('filial_id', e.target.value)} />
                </Col>
                <Col md="2" style={{ marginRight: '32px', flexDirection: 'column' }}>
                  <Form.Label>Empreendimentos</Form.Label>
                  <AutoComplete
                    required
                    placeholder='Pesquisar Empreendimentos'
                    field="nome_empreendimento"
                    style={{ height: "37px" }}
                    value={empreendimento_id ? empreendimento_id : ''}
                    suggestions={FilteredEmpreedimentos}
                    completeMethod={searchEmpreendimento}
                    onChange={(e) => handleChange('empreendimento_id', e.target.value)} />
                </Col>
                <Col md="2" style={{ marginRight: '32px', flexDirection: 'column' }}>
                  <Form.Label>Usuarios</Form.Label>
                  <AutoComplete
                    required
                    placeholder='Pesquisar Usuarios'
                    field="nome"
                    style={{ height: "37px" }}
                    value={user_id ? user_id : ''}
                    suggestions={FilteredUsuarios}
                    completeMethod={searchUsuarios}
                    onChange={(e) => handleChange('user_id', e.target.value)} />
                </Col>
              </Row>
            </Col>
          </Container>
        </div>
        <div className='div-filtros-avancados'>
          <Button
            onClick={handleFilter}
            style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
            type="button"
            severity="success"
            icon="pi pi-filter"
            rounded>
            <p style={{ marginLeft: '5px', marginTop: '13px' }}>Pesquisar</p>
          </Button>
          <Button
            onClick={handleClearList}
            style={{ height: '30px', borderRadius: '20px', padding: '5px 10px', marginRight: '5px', fontSize: '14px' }}
            type="button"
            severity="danger"
            icon="pi pi-delete-left"
            rounded>
            <p style={{ marginLeft: '5px', marginTop: '13px' }}>Limpar</p>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ContentSearchAudit;
