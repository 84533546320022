import { AlertService } from '../../../components/AlertService';
import ExclusaoLotesTableComponent from '../../../components/ExclusaoLotesTableComponent';
import { useAppContext } from '../../../contexts/AppContext';
import { useEffect, useState } from 'react';
import '../../../styles/global.css'
import TokenService from '../../../services/Auth/token.service';
import ContentSearchExclusao from '../../Layout/navbar-layout/components/ContentSearchExclusao';
function ExclusaoLotesInit() {
  const { showSubSidebar } = useAppContext();
  const [listFiltros, setListFiltros] = useState<any>({});
  const [filtros, setFiltros] = useState<string>();
  const [formBasico, setFormBasico] = useState<any>({});

  async function montarFiltro(): Promise<void> {
    try {
      const consulta: any = {
        consulta: {
          order: "id;desc",
          filtro: {
            "vendas.imoveis.identificador1": listFiltros ? listFiltros.quadra : null,
            "vendas.imoveis.identificador2": listFiltros ? listFiltros.lote : null,
            "vendas.imoveis.empreendimento_id": TokenService.getEmpreendimentoId() ? TokenService.getEmpreendimentoId().id : null
          }
        }
      };

      const queryParams: any = {};

      const camposEnviar = ['consulta'];

      camposEnviar.forEach((key: any) => {
        const value = consulta[key];
        if (value !== undefined && value !== null && value !== '') {
          if (key === 'consulta') {
            // Converte o objeto consulta em uma string JSON e codifica para URI
            queryParams[key] = JSON.stringify(value);
          } else {
            queryParams[key] = value;
          }
        }
      });

      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
        .join('&');
      setFiltros(queryString);
    } catch (error: any) {
      AlertService.error('Erro!', String(error.response?.data?.message) || 'Erro desconhecido');
    }
  }

  useEffect(() => {
    montarFiltro();
  }, [listFiltros]);
  const handleChange = (field: string, value: string) => {
    setFormBasico((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };
  const handleChangeList = (field: string, value: string) => {
    setListFiltros((prevGrupoData: any) => {
      return {
        ...prevGrupoData,
        [field]: value
      };
    });
  };
  const handleClearList = () => {
    setListFiltros(null);
    setFormBasico(null);
    montarFiltro();
  };
  return (
    <div className="body-content">
      <div
        className={`${showSubSidebar ? "container-section-header" : "container-section-header-none"}`}
      >
        <ContentSearchExclusao
          onInputChange={(field: string, value: string) => handleChange(`${field}`, value)}
          onInputChangeList={(field: string, value: string) => handleChangeList(`${field}`, value)}
          onInputClearList={() => handleClearList()}
          filtrosData={formBasico}
          header="Exclusão de Lotes LGPD"
        />
      </div>
      <div
        className={`${showSubSidebar ? "container-section-body" : "container-section-body-none"}`}
      >
        <ExclusaoLotesTableComponent
          urlSearch={`/api/v1/admin/vendas/listagem-imoveis?${filtros}`}
        />
      </div>
    </div>
  );
}
export default ExclusaoLotesInit;